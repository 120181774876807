import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography, DialogContentText, ButtonBase } from "@material-ui/core";
import classnames from "classnames";
import styles from "./styles";
import { formatMessage } from "../../../i18n/formatters";

interface Props {
  id?: string;
  title: string;
  show?: boolean;
  description?: string;
  buttonCancelText?: string;
  buttonConfirmText?: string;
  handleCancel?: (buttonClicked: boolean) => void;
  handleConfirm?: (e: React.FormEvent<HTMLFormElement>) => void;
}

const AlertDialog: React.FC<Props> = ({
  id,
  title,
  show,
  buttonCancelText,
  buttonConfirmText,
  handleCancel,
  handleConfirm,
  description,
}: Props) => {
  const classes = styles();

  return (
    <Dialog
      id={id}
      open={show || false}
      onClose={
        /* istanbul ignore next */
        () => {
          if (handleCancel) {
            handleCancel(false);
          }
        }
      }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
      classes={{ paperFullWidth: classes.dialogContainer }}
      disableBackdropClick
    >
      <div className={classes.dialogBox}>
        <DialogTitle className={classes.titleBox}>
          <Typography id="alert-dialog-title" className={classes.title}>
            {title}
          </Typography>
        </DialogTitle>
        {description && (
          <DialogContent className={classes.descriptionContent}>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.description}
            >
              {description}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions className={classes.dialogAction}>
          <ButtonBase
            id="cancel-btn"
            className={classnames(classes.button, classes.cancelButton)}
            onClick={
              /* istanbul ignore next */
              () => {
                if (handleCancel) {
                  handleCancel(true);
                }
              }
            }
          >
            {buttonCancelText ?? formatMessage({ id: "BUTTON.CANCEL" })}
          </ButtonBase>
          <ButtonBase
            id="confirm-btn"
            className={classnames(classes.button, classes.confirmationButton)}
            onClick={handleConfirm}
            autoFocus
          >
            {buttonConfirmText ?? formatMessage({ id: "BUTTON.CONFIRM" })}
          </ButtonBase>
        </DialogActions>
      </div>
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  id: "",
  show: false,
  description: undefined,
  buttonCancelText: undefined,
  buttonConfirmText: undefined,
  handleCancel: undefined,
  handleConfirm: undefined,
};

export default AlertDialog;

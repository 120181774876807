import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      background: "#fff",
      boxSizing: "border-box",
      width: "470px",
      height: "auto",
      padding: "32px",
      display: "flex",
      overflowY: "auto",
      flexDirection: "column",
    },
    containerInner: {
      height: "auto",
      maxHeight: "400px",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#505050",
      paddingBottom: "8px",
      display: "flex",
      justifyContent: "space-between",
    },
    titlePocIdsTotal: {
      fontSize: 16,
      fontWeight: "bold",
      color: "#505050",
    },
    titleToBeContactedTotal: {
      fontSize: 12,
      color: "#d18d14",
      textAlign: "right",
    },
    titleContactedTotal: {
      fontSize: 12,
      color: "#0b9f50",
      textAlign: "right",
    },
    pocIdContent: {
      display: "flex",
      justifyContent: "space-between",
      margin: "8px 0",
      fontSize: 12,
    },
    pocID: {
      color: "#d00505",
      fontWeight: "bold",
    },
    error: {
      color: "#d00505",
    },
    contactedTotal: {
      borderRadius: 8,
      backgroundColor: "#0b9f50",
      padding: "1px 6px",
      color: "#fff",
    },
    totalToBeContacted: {
      borderRadius: 8,
      backgroundColor: "#d18d14",
      padding: "1px 6px",
      color: "#fff",
    },
    buttonsContainer: {
      display: "flex",
      width: "100%",
      padding: "0",
      marginTop: "40px",
    },
    button: {
      height: "40px",
      fontSize: "14px",
      fontWeight: 600,
      display: "flex",
      flex: 1,
      borderRadius: "3px",
      textTransform: "uppercase",
    },

    confirmButton: {
      backgroundColor: "#505050",
      color: palette.common.white,
      margingLeft: "5px",
    },

    dialog: {
      padding: "32px",
      margin: 0,
    },
    loading: {
      display: "flex",
      justifyContent: "center",
    },
  }),
);

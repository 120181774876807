import { makeStyles, createStyles } from "@material-ui/core/styles";

const CsvListStyle = makeStyles(() =>
  createStyles({
    container: {
      width: "322px",
      paddingBottom: "8px",
    },
    title: {
      color: "#505050",
      fontSize: "12px",
      fontWeight: "bold",
    },
    input: {
      marginTop: "0px !important",
      paddingTop: "0px !important",
      marginBottom: "0px !important",
      paddingBottom: "0px !important",
      fontSize: "12px",
    },
    inputRoot: {
      paddingTop: "2px !important",
      paddingLeft: "2px !important",
      paddingBottom: "2px !important",
      paddingRight: "2px !important",
      margin: "0px",
    },
    label: {
      backgroundColor: "#ffffff",
    },
    buttonDownload: {
      border: "none",
      cursor: "pointer",
      marginTop: "6px",
      textDecoration: "underline",
      backgroundColor: "#f8f8f8",
      color: "#505050",
    },
    containerDownloadCsv: {
      color: "#505050",
      display: "flex",
      marginTop: "8px",
      marginRight: "4px",
    },
    spanDownloadCsv: {
      marginRight: "8px",
      display: "flex",
    },
    errorMessage: {},
    iconButton: {
      padding: 0,
      margin: "0px 0px",
      justifyContent: "center",
      alignItems: "center",
    },
    csvAlertContainer: {
      display: "flex",
      marginTop: "12px",
      marginBottom: "16px",
    },
    loadingBees: {
      display: "flex !important",
      flexDirection: "column",
      width: "100% !important",
      alignItems: "center !important",
      justifyContent: "center !important",
    },
    currentBlockList: {
      width: "auto",
      height: "14px",
      margin: "4px 4px 4px 0",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#505050",
    },
  }),
);

export default CsvListStyle;

import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    loading: {
      color: "white",
    },
    button: {
      fontSize: "14px",
      lineHeight: "15px",
      padding: "8px 16px",
      borderRadius: "3px",
      fontWeight: 600,
    },
    contained: {
      backgroundColor: "#505050",
      color: "#ffffff",
    },
    outlined: {
      backgroundColor: "#white",
      color: palette.primary.main,
    },
    loadingOutlined: {
      color: palette.primary.main,
    },
    loadingContained: {
      color: "white",
    },
  }),
);

import React, { useState, useRef, useMemo, useCallback } from "react";

import {
  Box,
  Grid,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  ButtonBase,
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import { useStore } from "effector-react";
import moment from "moment";

import { isFeatureEnabled, getVendorName } from "grow-shared-services";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import AddIcon from "../../../../assets/icons/AddIcon";
import RemoveIcon from "../../../../assets/icons/MinusIcon";
import TableOptionsIcon from "../../../../assets/icons/TableOptionsIcon";
import Loading from "../../../../components/loadingView/LoadingView";
import ProductCard from "../../../../components/products/ProductCard";
import { CAMPAIGNS } from "../../../../config/constants";
import {
  TLP_CAMPAIGN_FOCUS_VENDOR,
  TLP_MULTICONTRACT_ITEM_SERVICE,
} from "../../../../config/featureToggles";
import { campaignUxButtonClicked } from "../../../../config/typewriter";
import { getUserInfos, truncateText } from "../../../../config/utils/functions";
import { Campaign } from "../../../../domains/Campaign";
import { Campaigns } from "../../../../domains/Campaigns";
import { Item } from "../../../../domains/items/CampaignItems";
import { formatMessage } from "../../../../i18n/formatters";
import CampaignItemsDb from "../../../../indexDB/campaignItems/CampaignItemsDb";
import { findByCampaignTag } from "../../../../indexDB/campaignItems/functions/findByCampaignTag";
import {
  getCampaignItemsEffect,
  setCalledTag,
} from "../../../../stores/campaigns/items/CampaignItemsEvents";
import CampaignsItemsStore from "../../../../stores/campaigns/items/CampaignItemsStore";
import PocIdsInfoButton from "../pocIdsInfoButton/PocIdsInfoButton";
import Tag from "../tag/Tag";
import useStyles from "./styles";

const B2O_TYPE = CAMPAIGNS[1].value;
const DATE_FORMAT = "MM/DD/YY";
interface RowProps {
  row: Campaign;
  openDeleteModal: (campaignTag: string) => void;
  openEditModal: (campaign: Campaign) => void;
  openPocIdsInfoModal: (
    campaignTag: string,
    campaignName: string,
    campaignType: string,
  ) => void;
}

interface TableProps extends Campaigns {
  openDeleteModal: (campaignTag: string) => void;
  openEditModal: (campaign: Campaign) => void;
  openPocIdsInfoModal: (
    campaignTag: string,
    campaignName: string,
    campaignType: string,
  ) => void;
}

function Row(props: RowProps) {
  const { row, openDeleteModal, openEditModal, openPocIdsInfoModal } = props;
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement>();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const campaingTagId = row.campaignTag.replace("#", "");
  const db = useRef(new CampaignItemsDb());
  const classes = useStyles();
  const { dispatchGenericEvent } = useAnalytics();
  const { loading, error, calledTag } = useStore(CampaignsItemsStore);
  const [campaignItems, setCampaignItems] = useState<Item[]>([]);

  const { vendorId: userVendorId, country: userCountry } = getUserInfos();

  const getIcon = () => {
    return open ? (
      <IconButton
        aria-label="expand row"
        size="small"
        className={classes.expandRowButton}
        data-testid="focus-sku-collapse-btn"
        onClick={() => handleRequestClose()}
      >
        <RemoveIcon />
      </IconButton>
    ) : (
      <IconButton
        aria-label="expand row"
        size="small"
        className={classes.expandRowButton}
        data-testid="focus-sku-expand-collapse-btn"
        onClick={() => handleOpen()}
      >
        <AddIcon />
      </IconButton>
    );
  };

  const isCampainFocusVendor = isFeatureEnabled(TLP_CAMPAIGN_FOCUS_VENDOR);

  const isMulticontractServiceEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_ITEM_SERVICE,
  );

  const handleRequestClose = () => {
    dispatchGenericEvent(campaignUxButtonClicked, {
      screen_name: "Campaigns",
      screen_section: "Campaign Listing",
      button_label: "collapse-campaign-button",
      button_name: "Collapse",
      filter_option: "",
    });
    setOpen(false);
  };

  const getSegmentAndDeliveryCenterId = useCallback(() => {
    const segment: string[] = [];
    const deliveryCenterId: string[] = [];

    row.products.forEach((product) => {
      const { segment: seg, deliveryCenterId: delId } = product.filterOptions;
      if (seg) segment.push(seg);
      if (delId) deliveryCenterId.push(delId);
    });

    return { segment, deliveryCenterId };
  }, [row]);

  const getCampaignItems = () => {
    const country = userCountry;
    let vendorId: string;
    let vendorName: string;

    if (isMulticontractServiceEnable) {
      vendorId = userVendorId ?? "";
      vendorName = "";
    } else {
      vendorId = isCampainFocusVendor ? userVendorId ?? "" : "";
      vendorName = isCampainFocusVendor ? getVendorName(vendorId) : "";
    }

    const { segment, deliveryCenterId } = getSegmentAndDeliveryCenterId();

    getCampaignItemsEffect({
      campaingTag: row.campaignTag,
      segment,
      deliveryCenterId,
      vendorId,
      vendorName,
      country,
    });
    /* istanbul ignore next */
    const unWatchGetCampaignsItems = getCampaignItemsEffect.done.watch(
      (response) => {
        const itemsFromSrv = response.result.campaignItems;
        db.current.campaignItems.bulkPut(itemsFromSrv);
        setCampaignItems(itemsFromSrv);
        if (typeof unWatchGetCampaignsItems === "function") {
          unWatchGetCampaignsItems();
        }
      },
    );
  };

  const handleOpen = async () => {
    setOpen(true);
    dispatchGenericEvent(campaignUxButtonClicked, {
      screen_name: "Campaigns",
      screen_section: "Campaign Listing",
      button_label: "expand-campaign-button",
      button_name: "Expand",
      filter_option: "",
    });

    const { campaignItems: itemsFromDB } = await findByCampaignTag(
      db.current.campaignItems,
      row.campaignTag,
    );

    if (itemsFromDB && itemsFromDB.length > 0) {
      setCampaignItems(itemsFromDB);
    } else {
      getCampaignItems();
    }

    setCalledTag(row.campaignTag);
  };

  const renderDDCAndSegment = (
    DDC: string | undefined,
    segment: string | undefined,
    campaignTag: string,
  ) => {
    const components = [];

    if (DDC != null) {
      components.push(
        <span key={`${campaignTag}-ddc`}>
          <Typography className={classes.ddcSegmentTitle} component="span">
            {formatMessage({ id: "CAMPAIGNS.DDC" })}
            {": "}
          </Typography>
          <Typography className={classes.ddcSegmentValue} component="span">
            {DDC}
          </Typography>
        </span>,
      );
    }

    if (DDC && segment) {
      components.push(
        <span key={`${campaignTag}-ddc-segment`}>
          <Typography className={classes.ddcSegmentTitle} component="span">
            {" / "}
            {formatMessage({ id: "CAMPAIGNS.SEGMENT" })}
            {": "}
          </Typography>
          <Typography className={classes.ddcSegmentValue} component="span">
            {segment}
          </Typography>
        </span>,
      );
    } else if (segment != null) {
      components.push(
        <span key={`${campaignTag}-segment`}>
          <Typography className={classes.ddcSegmentTitle} component="span">
            {formatMessage({ id: "CAMPAIGNS.SEGMENT" })}
            {": "}
          </Typography>
          <Typography className={classes.ddcSegmentValue} component="span">
            {segment}
          </Typography>
        </span>,
      );
    }

    return components;
  };

  const renderStartAndEndDate = (
    startDate: string | undefined,
    endDate: string | undefined,
    campaignTag: string,
  ) => {
    if (startDate != null && endDate != null) {
      const sdFormatted = moment(startDate).format(DATE_FORMAT);
      const edFormatted = moment(endDate).format(DATE_FORMAT);
      return [
        <span key={`${campaignTag}-start`}>
          <Typography className={classes.startDateTitle} component="span">
            {formatMessage({ id: "RULES_FLOW.START" })}
            {": "}
          </Typography>
          <Typography className={classes.startDateValue} component="span">
            {sdFormatted}
          </Typography>
        </span>,
        <span key={`${campaignTag}-end`}>
          <Typography className={classes.endDateTitle} component="span">
            &nbsp;&nbsp;
            {formatMessage({ id: "RULES_FLOW.END" })}
            {": "}
          </Typography>
          <Typography className={classes.endDateValue} component="span">
            {edFormatted}
          </Typography>
        </span>,
      ];
    }
    return null;
  };

  const renderSegmentation = (segmentation: string | undefined) => {
    if (segmentation != null && segmentation !== "") {
      return (
        <span>
          <Typography className={classes.segmentationTitle} component="span">
            {formatMessage({ id: "MODAL_CAMPAIGNS.SEGMENTATION" })}
            {": "}
          </Typography>
          <Typography className={classes.segmentationValue} component="span">
            {segmentation}
          </Typography>
        </span>
      );
    }
    return null;
  };

  const renderDescription = (description: string | undefined) => {
    if (description != null && description !== "") {
      const shortDescription = truncateText(description, 80);
      return (
        <span>
          <Typography className={classes.descriptionTitle} component="span">
            {formatMessage({ id: "MODAL_CAMPAIGNS.DESCRIPTION" })}
            {": "}
          </Typography>
          <Typography
            data-testid="description-value"
            className={classes.descriptionValue}
            component="span"
          >
            {shortDescription}
          </Typography>
        </span>
      );
    }
    return null;
  };
  const renderProductsContent = () => {
    /* istanbul ignore next */
    const items = campaignItems
      ? campaignItems.filter((data) => data.campaignTag === row.campaignTag)
      : [];
    if (row.campaignTag === calledTag && items.length === 0) {
      if (loading) {
        return (
          <div>
            <Loading />
          </div>
        );
      }
      if (error) {
        return (
          <div className={classes.noItemsText} data-testid="no-items-find">
            {formatMessage({ id: "PRODUCTS.NOT_FOUND" })}
          </div>
        );
      }
    }
    if (items.length > 0) {
      return items.map((productItem) => {
        return (
          <Grid
            key={productItem.sku}
            style={{
              marginTop: 16,
            }}
            data-testid={`focus-sku-row-item-${productItem.sku}`}
          >
            <ProductCard
              sku={productItem.sku}
              productName={productItem.productName}
              image={productItem.image}
              fullContainerDescription={productItem.fullContainerDescription}
              fullPackageDescription={productItem.fullPackageDescription}
            />
          </Grid>
        );
      });
    }
    return (
      <div className={classes.noItemsText} data-testid="no-items-find">
        {formatMessage({ id: "PRODUCTS.NOT_FOUND" })}
      </div>
    );
  };

  const isB2O = useMemo(
    () => row.campaignType === B2O_TYPE,
    [row.campaignType],
  );

  const handleOpenModalPocIDs = () => {
    openPocIdsInfoModal(row.campaignTag, row.campaignName, row.campaignType);
  };

  const handleOpenEditModal = (row: Campaign) => {
    openEditModal(row);
    setPopoverOpen(false);
  };

  const handleOpenDeleteModal = (row: Campaign) => {
    openDeleteModal(row.campaignTag);
    setPopoverOpen(false);
  };

  return (
    <TableRow className={classes.root} data-testid="focus-sku-row">
      <TableCell component="th" scope="row" style={{ boxShadow: "none" }}>
        <div className={classes.rowTitleContainer}>
          <Typography className={classes.title}>{row.campaignName}</Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Tag
              label={
                isB2O
                  ? formatMessage({ id: "TYPE.B2O" })
                  : formatMessage({ id: "TYPE.FOCUS_SKU" })
              }
              classes={classes.tag}
            />
            <PocIdsInfoButton handleOpenModal={handleOpenModalPocIDs} />
            {!isB2O ? (
              <IconButton
                size="small"
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setPopoverOpen(true);
                }}
                className={classes.optionsBtn}
                data-testid={`focus-sku-row-options-btn-${campaingTagId}`}
              >
                <TableOptionsIcon />
              </IconButton>
            ) : (
              <span className={classes.emptyB2OEdit} />
            )}
          </div>

          <Popover
            open={popoverOpen}
            anchorEl={anchorEl}
            className={classes.popover}
            data-testid={`focus-sku-options-popover-${campaingTagId}`}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={() => {
              setPopoverOpen(false);
            }}
          >
            <ButtonBase
              className={classes.optionButton}
              data-testid={`focus-sku-edit-button-${campaingTagId}`}
              onClick={() => handleOpenEditModal(row)}
            >
              {formatMessage({ id: "OPTIONS.EDIT" })}
            </ButtonBase>
            <ButtonBase
              className={classes.optionButton}
              data-testid={`focus-sku-delete-button-${campaingTagId}`}
              onClick={() => handleOpenDeleteModal(row)}
            >
              {formatMessage({ id: "OPTIONS.DELETE" })}
            </ButtonBase>
          </Popover>
        </div>
        <div className={classes.expandRowContainer}>
          <hr className={classes.line} />
          {getIcon()}
        </div>
      </TableCell>
      <TableCell className={classes.productContainer}>
        <div className={classes.productContent}>
          <Grid
            key={row.campaignTag}
            data-testid="focus-sku-row-first-item"
            className={open ? classes.productItemsOpen : classes.productItems}
          >
            <Typography className={classes.campaignTag}>
              {row.campaignTag}
            </Typography>
            <div>
              {renderDDCAndSegment(
                row.products[0].filterOptions?.deliveryCenterId,
                row.products[0].filterOptions?.segment,
                row.campaignTag,
              )}
            </div>
            {row.campaignType === B2O_TYPE && (
              <div>
                {renderStartAndEndDate(
                  row.startDate,
                  row.endDate,
                  row.campaignTag,
                )}
              </div>
            )}
            <div>{renderSegmentation(row?.segmentation)}</div>
            <div data-testid={`${row.campaignTag}-description`}>
              {renderDescription(row?.campaignDescription)}
            </div>
          </Grid>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.box}>{renderProductsContent()}</Box>
          </Collapse>
        </div>
      </TableCell>
    </TableRow>
  );
}

const FocusSkuTable: React.FC<TableProps> = (props) => {
  const { campaigns, openDeleteModal, openEditModal, openPocIdsInfoModal } =
    props;
  const classes = useStyles();
  return (
    <Box className={classes.tableContainer}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" data-testid="focus-sku-table">
          <TableBody>
            {campaigns?.map((row) => (
              <Row
                key={row.campaignTag}
                row={row}
                openDeleteModal={openDeleteModal}
                openEditModal={openEditModal}
                openPocIdsInfoModal={openPocIdsInfoModal}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FocusSkuTable;

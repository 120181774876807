import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    cardContainer: {
      fontFamily: "Work Sans",
      fontWeight: 500,
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      background: "#ffffff",
      borderRadius: "16px",
      padding: "2rem",
      "&.beesgrow-MuiPaper-elevation3": {
        boxShadow: "2px 2px 10px 0px rgba(0,0,0,0.1)",
      },
    },
    overrideInput: {
      fontFamily: "Work Sans",
    },
  }),
);

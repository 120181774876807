import * as BlockListService from "../../services/management/BlockListService";
import UpdateBlockListRequest from "../../services/management/domains/UpdateBlockListRequest";

export function updateBlockList(
  blockList: UpdateBlockListRequest,
  vendorId?: string,
): Promise<void> {
  return BlockListService.updateBlockList(blockList, vendorId);
}

export function getBlockList(vendorId?: string): Promise<ArrayBuffer> {
  return BlockListService.getBlockList(vendorId);
}

export function deleteBlockList(vendorId?: string): Promise<void> {
  return BlockListService.deleteBlockList(vendorId);
}

import React, { ReactElement } from "react";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import TooltipStyles from "./TooltipStyles";

interface Props {
  text?: TooltipProps["title"];
  children: React.ReactElement<JSX.Element>;
  placement?: TooltipProps["placement"];
  disabled?: boolean;
}

const AgentSetupTooltip: React.FC<Props> = ({
  text,
  children,
  placement = "top",
}: Props) => {
  const classes = TooltipStyles();
  const component: ReactElement = text ? (
    <Tooltip
      title={text}
      arrow
      placement={placement}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
  return component;
};

export default React.memo(AgentSetupTooltip);

import React from "react";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { isFeatureEnabled } from "grow-shared-services";

import DragAndDropBullets from "../../../../../assets/icons/DragAndDropBullets";
import TrashIcon from "../../../../../assets/icons/TrashIcon";
import Switch from "../../../../../components/Switch/Switch";
import { TLP_SERVICE_MISSION_DEDICATED } from "../../../../../config/featureToggles";
import { formatMessage } from "../../../../../i18n/formatters";
import { setIsAgentUpdated } from "../../../../../stores/agents/AgentsEvents";
import AgentSetupTooltip from "../../AgentSetupTooltip/AgentSetupTooltip";
import { ItemType } from "../Types";
import useStyle from "./DraggableCard.styles";

interface DraggableCardProps {
  listItemId?: string;
  type: ItemType;
  isActive?: boolean;
  handleAction: () => void;
  index?: number;
  title: string | undefined;
  handleRemove?: (index: number) => void;
}

const DraggableCard = ({
  listItemId,
  type,
  isActive,
  handleAction,
  index,
  title,
  handleRemove,
}: DraggableCardProps): JSX.Element => {
  const isServicesMissionDedicated = isFeatureEnabled(
    TLP_SERVICE_MISSION_DEDICATED,
  );

  const classes = useStyle();
  const servicesTooltipMessage =
    listItemId === "#digital_services" && isServicesMissionDedicated
      ? formatMessage({ id: "MISSIONS_MANAGEMENT.SERVICE_MISSION_TOOLTIP" })
      : undefined;

  const handleIconButtonClick = () => {
    if (handleRemove) {
      handleRemove(index ? index - 1 : 0);
    }
  };

  const handleSwitchChange = () => {
    handleAction();
    setIsAgentUpdated(true);
  };

  return (
    <article className={classes.card}>
      <div className={classes.cardContainer}>
        <Typography
          className={classes.sequence}
          data-testid="missions-sequence"
        >
          {index}
        </Typography>
        <section className={classes.info}>
          <Typography className={classes.title} data-testid="missions-title">
            {title}
          </Typography>
        </section>
        <section className={classes.buttonContent}>
          {type === "MISSION" && (
            <AgentSetupTooltip text={servicesTooltipMessage} placement="bottom">
              <div className={classes.switchAndIcon}>
                <Switch
                  onChange={handleSwitchChange}
                  checked={isActive}
                  data-testid="active-switch"
                />
              </div>
            </AgentSetupTooltip>
          )}

          {type === "SPECIALIZATION" && (
            <IconButton
              aria-label="delete-specialization"
              className={classes.switchAndIcon}
              onClick={() => handleIconButtonClick()}
              data-testid={`${title}-delete-specialization-button`}
            >
              <TrashIcon data-testid="specialization-trash-icon" />
            </IconButton>
          )}

          <div
            className={classes.draggableContainer}
            data-testid="draggable-icon-container"
          >
            {isActive && (
              <figure
                className={classes.draggable}
                data-testid="draggable-icon"
              >
                <DragAndDropBullets />
              </figure>
            )}
          </div>
        </section>
      </div>
    </article>
  );
};

export default DraggableCard;

DraggableCard.defaultProps = {
  isActive: true,
  index: null,
  handleRemove: null,
  listItemId: null,
};

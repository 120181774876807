import { GROW_ADMIN_CONFIG_SERVICE } from "@config/services";
import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { isFeatureEnabled } from "grow-shared-services";

import axios from "../../config/axios/axiosInstance";
import {
  GROW_ORGANIZATION_ACTIVATION,
  TLP_MULTICONTRACT_GROW_ADMIN_CONFIG_SERVICE,
} from "../../config/featureToggles";
import { getApiHost, getEndPoint } from "../host/HostService";
import UpdateBlockListRequest from "./domains/UpdateBlockListRequest";

export const ENDPOINT = "block-list";

enum ENDPOINT_VERSIONS {
  V2 = "v2",
  V3 = "v3",
}

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

/* istanbul ignore next */
const getFormRequest = (request: UpdateBlockListRequest) => {
  const { blockList, ...rest } = request;
  const formDataRequest = new FormData();
  if (blockList != null) {
    formDataRequest.append("blockList", blockList);
  }
  formDataRequest.append("request", JSON.stringify(rest));
  return formDataRequest;
};

const mountUrl = (version?: ENDPOINT_VERSIONS): string => {
  const host = getApiHost();
  const endpoint = getEndPoint(GROW_ADMIN_CONFIG_SERVICE);
  const pathService =
    version === ENDPOINT_VERSIONS.V3 ? "" : "/grow-admin-config";
  const versionPath = version ? `/${version}` : "";

  return `${host}${endpoint}${pathService}${versionPath}/${ENDPOINT}`;
};

const getRequestDetails = (vendorId?: string) => {
  const isMulticontractServiceEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_GROW_ADMIN_CONFIG_SERVICE,
  );

  const isOrganizationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);

  if (isOrganizationEnabled) {
    return {
      url: mountUrl(ENDPOINT_VERSIONS.V3),
      params: {},
    };
  }

  if (isMulticontractServiceEnable) {
    return {
      url: mountUrl(ENDPOINT_VERSIONS.V2),
      params: { vendorId: vendorId ?? "" },
    };
  }

  return { url: mountUrl() };
};

export function updateBlockList(
  request: UpdateBlockListRequest,
  vendorId?: string,
): Promise<void> {
  const log = useLogService();

  const { url, params } = getRequestDetails(vendorId);

  return axios
    .put(url, getFormRequest(request), {
      params,
    })
    .then(() => {
      log.info("Block List updated successfully");
    })
    .catch((error) => {
      log.error(error?.response);
      throw error?.response;
    });
}

export function getBlockList(vendorId?: string): Promise<ArrayBuffer> {
  const log = useLogService();

  const { url, params } = getRequestDetails(vendorId);

  return axios
    .get(url, { responseType: "blob", params })
    .then((response) => {
      log.info("Get Block List successfully");
      return response.data;
    })
    .catch((error) => {
      log.error(error?.response);
      throw error?.response;
    });
}

export function deleteBlockList(vendorId?: string): Promise<void> {
  const log = useLogService();

  const { url, params } = getRequestDetails(vendorId);

  return axios
    .delete(url, { params })
    .then(() => {
      log.info("Block List deleted successfully");
    })
    .catch((error) => {
      log.error(error?.response);
      throw error?.response;
    });
}

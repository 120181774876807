import React from "react";
import useStyles from "./styles";

interface Props {
  children: React.ReactNode;
}

const MainContent: React.FC<Props> = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <main id="main-content" className={classes.NewSideBarMenuContent}>
      {children}
    </main>
  );
};

export default MainContent;

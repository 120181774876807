import { createEffect } from "effector";
import * as CreateCampaignUseCase from "../../usecase/campaigns/CreateCampaignUseCase";
import CreateCampaignRequest from "../../services/campaigns/domains/CreateCampaignRequest";

interface CampaignWithCountry {
  country: string;
  vendorId: string;
  vendorName: string;
  campaign: CreateCampaignRequest;
  isCampaignFocusVendorEnabled?: boolean;
}

export const createCampaignEffect = createEffect({
  async handler(createCampaign: CampaignWithCountry) {
    const {
      country,
      vendorId,
      vendorName,
      campaign,
      isCampaignFocusVendorEnabled,
    } = createCampaign;
    return CreateCampaignUseCase.createCampaign(
      country,
      vendorId,
      vendorName,
      campaign,
      isCampaignFocusVendorEnabled,
    );
  },
});

import { Container, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles";

interface Props {
  className?: string;
  message: string;
  dataTest: string;
  autoHideDuration?: number;
  type?: "success" | "error" | "update" | "delete" | string;
  onMessageClose?: () => void | undefined;
}

const HeaderMessage: React.FC<Props> = ({
  className,
  message,
  dataTest,
  autoHideDuration,
  onMessageClose,
  type,
}: Props) => {
  const classes = styles();
  const [showMessage, setShowMessage] = useState(message !== "");

  const handleClose = useCallback(() => {
    setShowMessage(false);

    if (onMessageClose) {
      onMessageClose();
    }
  }, [onMessageClose]);

  /* istanbul ignore next */
  useEffect(() => {
    setShowMessage(message !== "");
    const timeout = setTimeout(() => handleClose(), autoHideDuration);
    return () => {
      clearTimeout(timeout);
    };
  }, [autoHideDuration, handleClose, message]);

  return (
    <>
      {showMessage ? (
        <Container
          className={classNames(className, classes.container, {
            [classes.error]: type === "error",
            [classes.success]: type === "success",
            [classes.update]: type === "update",
            [classes.delete]: type === "delete",
          })}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <span className={classes.message} data-testid={`${dataTest}-text`}>
              {message}
            </span>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              data-testid={`${dataTest}-button`}
            >
              <CloseIcon
                className={classes.closeIcon}
                data-testid={`${dataTest}-button-icon`}
              />
            </IconButton>
          </Grid>
        </Container>
      ) : (
        <div style={{ height: "34px", width: "100%" }} />
      )}
    </>
  );
};

HeaderMessage.defaultProps = {
  className: "",
  autoHideDuration: 7000,
  onMessageClose: undefined,
  type: "error",
};

export default HeaderMessage;

import MessageMap from "../domains/MessageMap";

export const translationEN: MessageMap = {
  FORMAT: {
    formattedDate: "{{date, MM/dd/yyyy}}",
    FORMAT_DATE_SHORT: "MM/dd/yyyy",
    FORMAT_DATE_SHORT_SEGMENT: "M/D/yyyy",
    FORMAT_DATE_SHORT_FILE: "MM-D-yyyy",
    FORMAT_DATE_LONG_FILE: "MM-DD-yyyy",
    DATE_FORMAT_TABLE_RENDER: "{{date, DD MMMM, YYYY}}",
    DATE_FORMAT_TABLE: "DD MMMM, YYYY",
  },
  ADMIN: {
    TITLE: "Rewards - Admin",
    PANEL_HEADER: "Admin Portal",
    LOADING: "Loading...",
    VIEW: "View",
    EDIT: "Edit",
    REMOVE: "Remove",
    DELETE: "Delete",
    DUPLICATE: "Duplicate",
    REVIEW_ID: "Review IDs",
    ACTIONS: "Actions",
    NAME: "Name",
    SKU: "Sku",
    SKU_ID: "SKU ID",
    SKU_ID_PLACEHOLDER: "Type the SKU ID here...",
    QUANTITY: "Quantity",
    IMAGE_SUPPORTED_MAX_1MB:
      "PNG and JPG file extensions are supported. Maximum file size is 1MB.",
    TABLE_PAGINATION_INITIAL_COUNT:
      "Showing {{showingCount}} out of {{totalRows}}",
    TABLE_PAGINATION_COUNT:
      "Showing {{showingCount}} to {{toCount}} out of {{totalRows}}",
    SIGN_OUT: "Sign out",
    LOGOUT: "Logout",
    LOGOUT_DIALOG: "Do you really want to sign out of BEES GROW ADMIN PORTAL?",
    STEP_1: "Step 1",
  },
  LOADING: {
    BEELOADING_TEXT: "Processing…",
  },
  BUTTON: {
    UPDATE: "Update",
    CONTINUE: "Continue",
    CANCEL: "Cancel",
    CLOSE: "Close",
    CONFIRM: "Confirm",
    SAVE: "Save",
    SAVE_DRAFT: "Save Draft",
    SUBMIT: "Submit",
    BACK: "Back",
    BACK_STEP: "Back to Previous Step",
    REMOVE: "Remove",
    DELETE: "Delete",
    ADD_FILTER: "Add a Filter",
    CREATE_ANOTHER_CHALLENGE: "CREATE ANOTHER CHALLENGE",
    UPLOAD_CSV: "Upload CSV",
    UPLOAD_CSV_FILE: "Upload a CSV File",
    YES_SIGN_OUT: "Yes, sign out",
    DOWNLOAD_CSV: "Download CSV",
    CREATE_NEW: "Create new",
  },
  ERROR_MESSAGE: {
    DUPLICATED_SKU: "Some SKU and Campaign tag is duplicated.",
    EMPTY_FIELD: "Please enter a value.",
    INVALID_DATE: "Invalid Date.",
    INVALID_START_DATE: "You can't create challenges on the past.",
    INVALID_END_DATE: "End Date must be later than Start Date.",
    INVALID_URL: "Please enter a valid url.",
    INVALID_NUMBER_VALUE: "Invalid number value. The minimum number is 0.",
    MAX_GREATER_THAN_0: "Max Quantity must be a whole number greater than 0.",
    MAX_SIZE_GROUP_FILE_UPLOAD: "Maximum file size is 1MB.",
    MAX_SIZE_5MB: "Maximum file size is 5MB.",
    UNSUPPORTED_FILE:
      "This is an invalid file, please try again following the instructions above.",
    UNSUPPORTED_IMAGE: "Supported file extensions: .jpg; .png",
    UNSUPPORTED_CSV: "Supported file extension: .csv",
    UNSUPPORTED_CSV_REWARDS_OFFERS_MULTIPLE:
      "Upload a .csv with maximum of 5,000 lines and columns poc_id, campaign_id, points. Columns separated by semicolon, campaign_id is optional.",
    EMPTY_FILE_FIELD: "Please select a file to upload.",
    UPSERT_ACCOUNT_GROUP:
      "There was an error creating the group, please try again.",
    UPLOAD_FILE_ACCOUNT_GROUP:
      'There was a problem with your file. Please upload a .csv with a list of POC IDs under column header "POC_ID"',
    NO_CHALLENGE_FOUND_BY_ID: "Challenge id is not valid.",
    CHALLENGE_FOUND_BY_ID:
      "The Challenge ID provided already exists, please provide a different ID",
    NO_SKU_FOUND_BY_ID: "Please enter a valid SKU.",
    NO_RULE_FOUND_BY_ID: "Rule id is not valid.",
    SKU_QUANTITY_INVALID: "Quantity must be a whole number greater than 0.",
    NO_DUPLICATED_SKU: "This SKU is already included.",
    GENERIC_ERROR: "Something went wrong. Please try again later.",
    PAGE_LEVEL_ERROR_DRAFT_MESSAGE:
      "To save this draft, please fill required fields in ",
    PAGE_LEVEL_ERROR_CHALLENGE_MESSAGE:
      "To submit this challenge, please fill all fields in ",

    PAGE_LEVEL_ERROR_CHALLENGE_MESSAGE_CONTINUE:
      "To continue, please fill all fields in ",

    INVALID_ORDER_ID: "Invalid Order ID",
    NO_REFUNDABLE_FOUND: "No refundable combos were found",
    CHALLENGE_SUBMIT_FAIL: "The challenge was not published. Please try again.",
    INVALID_POC_ID: "This POC is not enrolled in the Rewards Program",
    INVALID_POINTS_VALUE: "Points Value must be a whole number greater than 0.",

    GENERIC_LOAD_ERROR: "Ooops! Something went wrong.",
    GENERIC_TRY_AGAIN: "Try again",
  },
  ERROR_MESSAGE_USECASE: {
    UNABLE_UPSERT_ACCOUNT_GROUP: "Unable to create account group",
    UNABLE_UPSERT_CHALLENGE: "Unable to create challenge",
    UNABLE_LOAD_GROUPS: "Unable to create groups",
    UNABLE_LOAD_HISTORY_CHALLENGE: "Unable to load challenge history",
  },
  CHALLENGE_FLOW: {
    NAME: "Challenge",
    CHALLENGE_ID: "Challenge ID",
    CHALLENGE_NAME: "Challenge Name",
    FLOW_NAME: "Create a Challenge",
    FLOW_NAME_DUPLICATE: "Duplicate Challenge",
    STEP_NAME_1: "Challenge Setup",
    TAB_TITLE_1: "Challenge Setup",
    STEP_NAME_2: "Apply Filters",
    TAB_TITLE_2: "Apply Filters (optional)",
    STEP_NAME_3: "Challenge Details",
    TAB_TITLE_3: "Challenge Details",
    CHALLENGE_TYPE: "Select Type",
    DIGITAL_POC_ID: "Digital POC ID",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    FILTER: "Filter",
    FILTERS: "Filters",
    FILTER_VALUES: "Filter Values",
    FILTER_INSTRUCTION: "Start typing and select multiple values.",
    IMAGE_URL: "Image",
    CHALLENGE_TITLE: "Challenge Name",
    POINTS: "Points",
    POINTS_VALUE: "Points Value",
    STATUS: "Status",
    CHALLENGE_DESCRIPTION: "Description",
    GOOD_PHOTO_URL: "Good Photo Example",
    BAD_PHOTO_URL: "Bad Photo Example",
    CHALLENGE_INSTRUCTION: "Details Page Description",
    CHALLENGE_TYPE_1: "Photo",
    CHALLENGE_TYPE_2: "Mark as Complete",
    CHALLENGE_TYPE_PURCHASE_SINGLE: "Purchase - Single Order",
    CHALLENGE_TYPE_PURCHASE_MULTIPLE: "Purchase - Multiple Orders",
    FILTER_LIST_SEGMENT: "Segment",
    FILTER_LIST_POTENTIAL: "Potential",
    FILTER_LIST_DDC: "DDC",
    FILTER_LIST_GROUP_IDS: "Group",
    DIALOG_CONFIRMATION_HEADER: "Please confirm",
    DIALOG_CONFIRMATION_MESSAGE:
      "Please confirm that you would like to submit this challenge.",
    DIALOG_CONFIRMATION_MESSAGE_DELETE_DRAFT:
      "Are you sure you want to delete this draft?",
    DIALOG_CONFIRMATION_DRAFT_MESSAGE:
      "Please confirm that you would like to save a draft.",
    CHALLENGE_SUBMITTED_HEADER: "Challenge Submitted",
    CHALLENGE_SUBMITTED_DESCRIPTION:
      "Lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
    ERROR_UPSERT_CHALLENGE: "Error trying upsert this challenge.",
    CHALLENGE_CREATED_MESSAGE: "Your challenge has been created!",
    CHALLENGE_CREATED_DRAFT_MESSAGE: "Your draft has been saved",
    HISTORY_TITLE: "Challenge History",
    HISTORY_DETAILS_TITLE: "Challenge Details",
    HISTORY_NEW_CHALLENGE_BTN: "+ CREATE NEW CHALLENGE",
    HISTORY_EMPTY_DATA_TABLE:
      "You currently do not have any challenge history. Please create a new challenge.",
    HISTORY_DATE_FORMAT: "DD MMMM, YYYY",
    HISTORY_CHALLENGE_TYPE: "Challenge Type",
    HISTORY_POC_FILTER: "POC Filter",
    HISTORY_DETAIL_PAGE_IMAGE: "Image",
    HISTORY_GOOD_PHOTO_BAD_PHOTO: "Good Photo / Bad Photo",
    HISTORY_EMPTY_FILTER_TABLE: "No filters have been selected.",
    HISTORY_FILTERS_NOT_EDITABLE: "Filters can not be edited",
    HISTORY_STATUS_ACTIVE: "Active",
    HISTORY_STATUS_CREATED: "Created",
    HISTORY_STATUS_EXPIRED: "Expired",
    HISTORY_STATUS_DRAFT: "Draft",
    HISTORY_STATUS_CANCELLED: "Cancelled",

    CANCEL_DIALOG_TITLE: "Cancel Challenge",
    CANCEL_DIALOG_INSTRUCTION:
      "Please confirm you would like to cancel this challenge and select a reason below for cancelling.",
    CANCEL_DIALOG_REASON_TYPO_MISTAKE: "Typo / Mistake",
    CANCEL_DIALOG_REASON_STRATEGY_CHANGE: "Strategy change",
    CANCEL_DIALOG_REASON_INCORRECT_TARGETING: "Incorrect POC targeting",
    CANCEL_DIALOG_REASON_OTHER: "Other",
    SUBMIT: "Cancel Challenge",
    CANCEL_CHALLENGE: "Cancel Challenge",
    CANNOT_CANCEL_CHALLENGE:
      "This challenge cannot be cancelled because POCs have already started to complete it",
    CANCEL_CHALLENGE_SUCCESS_MESSAGE: "This challenge has been cancelled",
    CANCEL_CHALLENGE_ERROR_MESSAGE:
      "An error occurred. Please try again later.",
    EXECUTION_METHOD_MARK_AS_COMPLETE: "Mark as Complete",
    EXECUTION_METHOD_TAKE_A_PHOTO: "Photo",
    EXECUTION_METHOD_PURCHASE_SINGLE_ORDER: "Purchase - Single Order",
    EXECUTION_METHOD_PURCHASE_MULTIPLE_ORDER: "Purchase - Multiple Orders",
    SKU_ID_FIELD_LABEL: "Sku",
    SKU_QUANTITY_FIELD_LABEL: "Quantity",
    SKU_ADD_BUTTON: "ADD A SKU",
    SUCCESSFUL_EDITION: "Your changes have been saved!",
    MENU: "Challenges",
  },
  GROUP: {
    ADD_POC_GROUP: "Add Group",
    ADD_PLUS_NEW_GROUP: "+ Add New Group",
    GROUP_NAME: "Group Name",
  },
  DIALOG: {
    ROUTE_LEAVING_HEADER: "Please confirm",
    ROUTE_LEAVING_MESSAGE_2: "If you leave, this {{name}} will not be saved.",
  },
  TRANSACTION_FLOW: {
    NAME: "Transaction",
    TITLE: "Create Points Transaction",
    TRANSACTION_TYPE: "Transaction Type",
    TRANSACTION_TYPE_REFUND: "Refund",
    TRANSACTION_TYPE_REWARDS_OFFER: "Rewards Offer",
    TRANSACTION_SINGLE_OR_MULTIPLE_POC:
      "Is this transaction for a single POC or multiple POCs?",
    SINGLE_POC: "Single POC",
    MULTIPLE_POC: "Multiple POCs",
    ORDER_ID: "Order ID",
    CONFIRMATION_DIALOG_TITLE: "Are you sure you want to submit?",
    CONFIRMATION_DIALOG_CONFIRMATION: "Yes, submit",
    CONFIRMATION_DIALOG_MESSAGE:
      "This transaction cannot be cancelled once you submit.",
    REFUND_COMBO_ID: "Combo ID",
    REFUND_QUANTITY: "Qty",
    REFUND_POINTS_VALUE: "Points Value",
    REFUND_DT_COMBOS: "DT Combos",
    REFUND_POINTS: "Points",
    REFUND_POINTS_ABBREV: "pts",
    REFUND_SELECT_COMBOS_REFUND: "Select DT Combos to Refund",
    REFUND_FULLY_REFUNDED: "Fully Refunded",
    REFUND_ADD_COMBO_BUTTON: "ADD COMBO ID",
    REFUND_POC_ID: "POC ID",
    REFUND_ORDER_DATE: "Order Date",
    REFUND_STATUS: "Status",
    REFUND_DATE_FORMAT: "DD MMMM, YYYY",
    ORDER_STATUS_PENDING: "Pending",
    ORDER_STATUS_PLACED: "Placed",
    ORDER_STATUS_CONFIRMED: "Confirmed",
    ORDER_STATUS_DENIED: "Denied",
    ORDER_STATUS_MODIFIED: "Modified",
    ORDER_STATUS_IN_TRANSIT: "In Transit",
    ORDER_STATUS_CANCELLED: "Cancelled",
    ORDER_STATUS_DELIVERED: "Delivered",
    ORDER_STATUS_PARTIAL_DELIVERY: "Partial Delivery",
    ORDER_STATUS_INVOICED: "Invoiced",
    NOTIFICATION_SUCCESS: "Transaction has been created!",
    NOTIFICATION_REFUND_SUCCESS: "Refund has been processed successfully!",
    NOTIFICATION_ERROR:
      "Something went wrong: your Transaction was not created. Please try again.",
    REWARDS_OFFER_POC_ID: "POC ID",
    REWARDS_OFFER_POINTS: "Points",
    REWARDS_OFFER_POINTS_VALUE: "Points Value",
    REWARDS_OFFER_CAMPAIGN_ID: "Campaign ID",
    REWARDS_OFFER_DESCRIPTION: "Offer Description",
    REWARDS_OFFER_ERROR: "Error",
    ACTIVITY_LOG_TITLE: "Rewards offer - Multiple",
    ACTIVITY_LOG_INFO:
      "Below you will find a list of all POC IDs that need to be reviewed. Please, download the following file, fix the errors and create a new transaction with the updated CSV file.",
    ERROR_ZERO_POINTS: "Points cannot be zero",
    ERROR_NEGATIVE_POINTS: "Points cannot be negative",
    ERROR_COMMAS_OR_DOTS_POINTS: "Points cannot contain commas or dots",
    ERROR_DEFAULT: "Points column not valid",
    ERROR_INVALID_POC: "POC not enrolled",
    ERROR_UNDEFINED: "Undefined error",

    MENU: "Points Management",
    TYPE_REWARDS_OFFER_SINGLE: "Rewards Offer - Single POC",
    TYPE_REWARDS_OFFER_MULTIPLE: "Rewards Offer - Multiple POCs",
    TYPE_REFUND: "Refund",
    LOG_TITLE: "Transaction Log",
    LOG_NEW_TRANSACTION_BTN: "+ ADD NEW TRANSACTION",
    LOG_EXECUTION_DATE: "Date",
    LOG_STATUS: "Status",
    LOG_EMPTY_DATA_TABLE: "You currently do not have any transactions.",
  },
  COMBO_FLOW: {
    NAME: "DT Combo",
    MENU: "DT Combo Management",
    TITLE: "Add a DT Combo",
    SUBMIT_TEXT: "Publish",
    CONFIRMATION_DIALOG_TITLE: "Published changes will show in app immediately",
    CONFIRMATION_DIALOG_MESSAGE:
      "Please confirm you would like to save these changes. Changes will show in app as soon as you publish.",
    EDIT_DIALOG_TITLE: "Edit DT Combo",
    EDIT_DIALOG_NAME_LABEL: "Name",
    EDIT_DIALOG_ID_LABEL: "DT Combo ID",
    POINTS_VALUE: "Points",
    REMOVE_DIALOG_TITLE: "Remove DT Combo",
    REMOVE_DIALOG_MESSAGE:
      "Are you sure you want to remove this DT Combo? This will be removed immediately from the app. ",
    EDIT_QUANTITY_DIALOG_TITLE: "DT Combo Max Quantity",
    EDIT_QUANTITY_DIALOG_MESSAGE:
      "This quantity will be the maximum that can be redeemed for each DT Combo.",
    MAX_QUANTITY: "Max Quantity",
    DT_COMBOS_ID: "DT Combo ID",
    DT_COMBOS_POINTS: "Points",
    DT_COMBOS_REMOVE_BUTTON: "Remove",
    DT_COMBOS_ADD_COMBO_BUTTON: "Add DT Combo",
    ERROR_MESSAGE_INVALID_COMBO_ID: "Please enter a valid DT Combo ID",
    ERROR_MESSAGE_DT_COMBO_ALREADY_INCLUDED:
      "This DT Combo ID is already included",
    ERROR_MESSAGE_DT_COMBO_ALREADY_INCLUDED_PROGRAM:
      "This DT Combo ID is already included in the program",
    ERROR_MESSAGE_INVALID_POINTS_VALUE:
      "Points must be a whole number greater than 0",
    NOTIFICATION_SUCCESS: "New DT Combos have been published!",
    NOTIFICATION_ERROR:
      "The combos were not added to the program. Please try again.",
    EDIT_SUCCESS: "DT Combo has been updated!",
    EDIT_ERROR: "Something went wrong. Please try again later.",
    EDIT_MAX_QUANTITY_SUCCESS: "Redeem Max Quantity has been updated!",
    EDIT_MAX_QUANTITY_ERROR: "Something went wrong. Please try again later.",
    REMOVE_SUCCESS: "DT Combo has been removed!",
    REMOVE_ERROR: "Something went wrong. Please try again later.",
    EMPTY_MESSAGE: "No itens have been added",
    LIST_TITLE: "Active DT Combos",
    LIST_ADD_DT_COMBOS: "+ Add DT Combos",
    LIST_COLUMN_NAME: "Name",
    LIST_COLUMN_COMBO_ID: "DT Combo ID",
    LIST_COLUMN_POINTS: "Points",
    LIST_ACTION_EDIT: "Edit",
    LIST_ACTION_EDIT_REDEEM_LIMIT: "Edit",
    LIST_ACTION_REMOVE: "Remove",
    LIST_SEARCH_COMBOS: "Search DT Combo IDs",
    LIST_MAX_QUANTITY: "DT Combo Max Quantity:",
  },
  RULES_FLOW: {
    MENU: "Earning Rules",
    TITLE: "Earning Rules",
    EARNING_NAME: "{{point}} point for every {{amountSpent}} spent",
    EARNING_NAME_plural: "{{point}} points for every {{amountSpent}} spent",
    EDIT_SKU: "Edit a SKU",
    ADD_SKU: "Add a SKU",
    ADD_SKU_MESSAGE:
      "Add a sku to this earning rule so users can earn points. Skus can only be added to 1 earning rule.",
    SKU_ID_FIELD: "SKU",
    EARNING_RULE_TABLE: "Earning Rule",
    BUTTON_ADD: "ADD",
    START: "Start",
    END: "End",

    SKUS_LIST_ADD_SKU_BUTTON: "+ ADD SKU",
    SKUS_LIST_SEARCH_SKUS: "Search SKUs",
    SKUS_LIST_COLUMN_SKU: "SKU",
    SKUS_LIST_COLUMN_SKU_NAME: "SKU Name",
    SKUS_LIST_ACTION_CHANGE: "Change Rule",
    SKUS_LIST_ACTION_REMOVE: "Remove",
    SKUS_LIST_EMPTY_MESSAGE: "No items have been added",
    REMOVE_DIALOG_TITLE: "Remove SKU",
    REMOVE_DIALOG_MESSAGE:
      "Are you sure you want to remove this SKU? This will be removed immediately from the app. ",
    NOTIFICATION_SUCCESS_CHANGE_RULE: "SKU earning rule has been changed",
    NOTIFICATION_ERROR_CHANGE_RULE:
      "Something went wrong. Please ty again later.",
    NOTIFICATION_REMOVE_SUCCESS: "SKU has been removed",
    NOTIFICATION_REMOVE_ERROR: "Something went wrong. Please try again later.",
    ADD_SKU_SUCCESS: "SKU has been added",
    ADD_SKU_ERROR: "Something went wrong. Please try again later.",

    VALIDATION_SKU_INVALID: "Please enter a valid SKU",
    VALIDATION_SKU_ALREADY_INCLUDED:
      "This SKU is already included in the Earning Rule {{ruleName}}",
    OPTIONAL_FIELD: "(Optional)",
    OPTIONAL_FIELD_HELPER_TEXT: "Only enter if available",
    EMPTY_DATA_TABLE: "No items are available to display",
  },
  CAMPAIGNS: {
    CAMPAIGNS_TITLE: "Campaigns",
    DDC: "DDC",
    SEGMENT: "Segment",
    NOT_FOUND: "There are no campaigns to be displayed.",
  },
  PRODUCTS: {
    NO_DATA_AVAILABLE: "No data available",
    UNIT_PRICE: "Price (unit)",
    NOT_FOUND: "There are no items to be displayed.",
  },
  ErrorHandlerRetry: {
    INFORMATION_NOT_FOUND: "Information not found",
    RELOAD_BUTTON_LABEL: "Reload list",
  },
  MODAL_CAMPAIGNS: {
    CREATE_BUTTON: "create",
    EDIT_BUTTON: "confirm",
    CANCEL_BUTTON: "cancel",
    TITLE_MODAL_NEW_CAMPAIGN: "New campaign",
    TITLE_MODAL_EDIT_CAMPAIGN: "Edit campaign",
    CAMPAIGN_NAME: "Campaign name",
    CAMPAIGN_TAG: "Tag name",
    SKU_ID: "SKU ID",
    DDC_OPTIONAL: "DDC (Optional)",
    SEGMENT_OPTIONAL: "Segment (Optional)",
    CAMPAIGN_NAME_PLACEHOLDER: "Type the Campaign name here...",
    CAMPAIGN_DESCRIPTION: "Campaign description",
    DESCRIPTION: "Description",
    CAMPAIGN_SCRIPT: "Campaign script",
    CAMPAIGN_DESCRIPTION_PLACEHOLDER: "Type the description here...",
    CAMPAIGN_SCRIPT_PLACEHOLDER: "Type the script here...",
    TAG_PLACEHOLDER: "Type the Tag name here...",
    DDC_PLACEHOLDER: "Type the DDC code here...",
    SEGMENT_PLACEHOLDER: "Type the Segment code here...",
    ERROR_HANDLER: "Something went wrong. Please try again later.",
    ERROR_LIMIT_CSV: "The field csv exceeds its maximum permitted size of 1MB.",
    MESSAGE_SUCCESS: "The campaign was successfully created.",
    MESSAGE_SUCCESS_UPDATE: "The campaign was successfully edited.",
    MESSAGE_SUCCESS_DELETE: "The campaign was successfully deleted.",
    MSG_MANDATORY_CAMPAIGN_NAME: "This field is required.",
    MSG_MANDATORY_CAMPAIGN_TAG: "This field is required.",
    MSG_MANDATORY_CAMPAIGN_DESCRIPTION: "This field is required.",
    MSG_MANDATORY_CAMPAIGN_SCRIPT: "This field is required.",
    MSG_MAX_SIZE_CAMPAIGN_TAG:
      "The sentence is too long. Please, do not exceed 30 characters.",
    MSG_NO_ACCEPT_SPACES_CAMPAIGN_TAG:
      "This field does not accept spaces. Please write without spacing.",
    MSG_START_WITH_PLACEHOLDER_CAMPAIGN_TAG:
      'Please insert "#" before the tag name.',
    MSG_AT_LEAST_ONE_CAMPAIGN_SKU: "This field is required.",
    START_DATE: "Start date",
    END_DATE: "End date",
    CSV_LIST: "Upload POC IDs",
    COVER_IMAGE: "Cover image (670x90)",
    DRAG_DROP_CSV: "Drag and drop a CSV list file",
    DRAG_DROP_IMAGE: "Drag and drop a .png or .jpg image",
    CSV_FILE_REQUIRED: "This field is required.",
    MSG_END_DATE_BEFORE_START_DATE:
      "End date cannot be before or equal to the Start date.",
    SEGMENTATION: "Agent specialization",
    SEGMENTATION_PLACEHOLDER: "ex: 387",
    PRIMARY: "Primary Mission",
    SECONDARY: "Secondary Mission",
    PRIMARY_TOOLTIP:
      "The campaign's POCs will be selected for contact with B2O Campaign as a primary mission.",
    SECONDARY_TOOLTIP:
      "The campaign's POCs will be included as an additional mission for the POC that was already planned to be contacted in the day.",
    AGENT_SPECIALIZATION_TOOLTIP:
      "Define a segmentation to identify specific agents that will work on this campaign.",
    DOWNLOAD_FILE: "Download here file example",
  },
  DELETE_CAMPAIGNS: {
    DELETE_CAMPAIGN: "Delete campaign",
    DELETE_CAMPAIGN_DESCRIPTION: "Do you really want to delete this campaign?",
    DELETE: "DELETE",
  },
  REQUIRED: {
    FIELD_REQUIRED: "This field is required",
  },
  OPTIONS: {
    EDIT: "edit",
    DELETE: "delete",
  },
  SEARCH_OPTIONS: {
    TOTAL_CAMPAIGN_NAMES: "results in Campaign Name",
    TOTAL_CAMPAIGN_NAME: "result in Campaign Name",
    TOTAL_DELIVERY_CENTER_IDS: "results in DDC",
    TOTAL_DELIVERY_CENTER_ID: "result in DDC",
    TOTAL_SEGMENTS: "results in Segment",
    TOTAL_SEGMENT: "result in Segment",
    TOTAL_SKUS: "results in SKU",
    TOTAL_SKU: "result in SKU",
    NO_CAMPAIGN: "There are no campaigns to be displayed.",
  },
  AGENT_SEGMENTATION: {
    CONFIRM_SEGMENTATION: "Confirmation",
    DESCRIPTION_CONFIRM_SEGMENTATION:
      "Are you sure you want to update the agent segmentation?",
    MODAL_TITLE: "Agent setup",
    SEGMENTATION: "Segmentation",
    AGENT: "Agent",
    CONFIRM_BUTTON: "CONFIRM",
    PLACEHOLDER_SEGMENTATION: "Select or type the name of the agent",
    UPDATE_MESSAGE: "The agent segmentation was successfully updated.",
    ERROR_HANDLER: "Something went wrong. Please try again later.",

    AGENT_SETUP: "Agent setup",
    AGENT_SETUP_SUBTITLE:
      "It is possible to define specializations for an agent.",
    AGENT_SETUP_PLACEHOLDER: "Select an agent",
    MISSIONS_MANAGEMENT_TITLE: "Missions management",
    SPECIALIZATION_TITLE: "Specialization",
    INSERT_SPECIALIZATION_PLACEHOLDER: "Insert specialization",
    INSERT_SPECIALIZATION_ADD_BUTTON: "Add",
    SAVE_CHANGES: "The changes have been successfully applied.",
    SAVE_ERROR:
      "Something went wrong! The changes were not save, try applying them again.",
    MISSIONS_AND_SPECIALIZATION_APPLY_CHANGES: "Apply changes",
    NO_AGENT_FOUND: "No agent found",
    ERROR_TO_LOAD: "It was not possible to load the agent setup",

    APPLY_CHANGES_MODAL_TITLE: "Are you sure you want to apply the changes?",
    APPLY_CHANGES_MODAL_DESCRIPTION:
      "it will take just a few minutes to activate your changes.",
    APPLY_CHANGES_CONFIRM: "Yes, apply",
    APPLY_CHANGES_CANCEL: "No, cancel",
  },
  GLOBAL_MESSAGE: {
    NO_DATA_AVAILABLE: "No data available",
  },
  TYPE: {
    B2O: "B2O",
    FOCUS_SKU: "Focus SKU",
  },
  POC: {
    POC_WITH_ERROR: "POCs with errors ",
    CONTACTED: "Contacted ",
    TO_BE_CONTACTED: "To be contacted ",
  },
  BLOCK_LIST: {
    MODAL_TITLE: "Block list",
    CSV_TITLE: "CSV list",
    CONFIRM_BUTTON: "Confirm",
    DESCRIPTION: "Are you sure you want to update the block list?",
    UPDATE_MESSAGE:
      "The block list was successfully updated and will become effective tomorrow.",
    DELETE_MESSAGE:
      "The block list was successfully deleted. This change will become effective tomorrow.",
    ERROR_HANDLER: "Something went wrong. The block list was not updated.",
    NO_CSV: "The block list couldn't be updated. Please, try again.",
    CURRENT_BLOCK_LIST: "The current block list couldn't be recovered.",
    LOADING: "Loading the block list…",
    DRAG_DROP_CSV: "Drag and drop a CSV file",
    CURRENT_CSV: "Download current block list",
    DELETE_DESCRIPTION:
      "Are you sure you want to delete the current block list?",
    REFRESH_PAGE: "Refresh page",
  },
  MANAGEMENT: {
    TITLE: "Management",
  },
  MISSIONS_MANAGEMENT: {
    TITLE: "Missions management",
    PROMOTE_REWARDS_CHALLENGE_MISSION_TITLE: "Challenges",
    SPONSORED_MISSION_TITLE: "Sponsored",
    SERVICE_MISSION_TITLE: "Services",
    SERVICE_MISSION_TOOLTIP: "This mission requires agent exclusivity",
    LOCAL_MISSIONS_TITLE: "Local missions",
  },
  LOCAL_MISSIONS: {
    TITLE: "Local missions",
  },
  TEAM_STRUCTURE: {
    TITLE: "Team structure",
  },
  GLOBAL_MISSIONS: {
    TITLE: "Global missions",
  },
  TEAM_MANAGEMENT: {
    TITLE: "Teams",
  },
};

import axios from "@config/axios/axiosInstance";
import { TLP_MULTICONTRACT_GROW_ADMIN_CONFIG_SERVICE } from "@config/featureToggles";
import { useLogService } from "admin-portal-shared-services";
import { isFeatureEnabled } from "grow-shared-services";

import {
  MissionsResponseToMissions,
  ResponseMissions,
} from "../../converters/missions/MissionsResponseToMissionsState";
import { MISSION_TYPES } from "../../domains/Missions";
import { getApiHost } from "../host/HostService";

const GROW_ADMIN = "grow-admin-config";
const MISSIONS_MANAGEMENT = "missions-management";
const MISSIONS_MANAGEMENT_V2 = "v2/missions-management";

export async function getPrimaryMissions(
  vendorId?: string,
): Promise<ResponseMissions> {
  const log = useLogService();
  const isMulticontractServiceEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_GROW_ADMIN_CONFIG_SERVICE,
  );
  const endpoint = `${GROW_ADMIN}/${
    isMulticontractServiceEnable ? MISSIONS_MANAGEMENT_V2 : MISSIONS_MANAGEMENT
  }`;
  const url = `${getApiHost()}/api/v1/grow-admin-config-service/${endpoint}`;
  const params = isMulticontractServiceEnable
    ? { type: "PRIMARY", vendorId: vendorId || "" }
    : { type: "PRIMARY" };
  try {
    const response = await axios.get<ResponseMissions>(url, {
      params,
    });
    log.info(
      `Convert getPrimaryMissions from ${endpoint} to our model`,
      response,
    );
    return MissionsResponseToMissions(response.data);
  } catch (error) {
    log.error(error);
    throw error;
  }
}

export async function getGlobalAndLocalMissions(
  vendorId?: string,
): Promise<ResponseMissions> {
  const log = useLogService();

  const isMulticontractServiceEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_GROW_ADMIN_CONFIG_SERVICE,
  );

  const endpoint = `${GROW_ADMIN}/${
    isMulticontractServiceEnable ? MISSIONS_MANAGEMENT_V2 : MISSIONS_MANAGEMENT
  }`;

  const url = `${getApiHost()}/api/v1/grow-admin-config-service/${endpoint}`;

  const params = new URLSearchParams();
  params.append("type", `${MISSION_TYPES.GLOBAL},${MISSION_TYPES.LOCAL}`);

  if (isMulticontractServiceEnable) params.append("vendorId", vendorId || "");
  try {
    const response = await axios.get<ResponseMissions>(url, {
      params,
    });
    log.info(
      `Convert getGlobalAndLocalMissions from ${endpoint} to our model`,
      response,
    );
    return MissionsResponseToMissions(response.data);
  } catch (error) {
    log.error(error);
    throw error;
  }
}

import React from "react";
import { Paper } from "@material-ui/core";
import { AgentOptions } from "../../Types";
import AgentSelectStyles from "./AgentSelect.styles";
import AutoComplete from "../../../../components/Autocomplete/Autocomplete";
import { formatMessage } from "../../../../i18n/formatters";

interface Props {
  options: AgentOptions[];
  value: AgentOptions;
  onChange: (agent: AgentOptions) => void;
}

const AgentSelect: React.FC<Props> = ({ value, options, onChange }) => {
  const classes = AgentSelectStyles();

  return (
    <Paper elevation={3} className={classes.cardContainer}>
      <AutoComplete
        placeholder={formatMessage({
          id: "AGENT_SEGMENTATION.AGENT_SETUP_PLACEHOLDER",
        })}
        noOptionsText={formatMessage({
          id: "AGENT_SEGMENTATION.NO_AGENT_FOUND",
        })}
        value={value}
        options={options}
        onChange={onChange}
        overrideInput={classes.overrideInput}
      />
    </Paper>
  );
};

export default AgentSelect;

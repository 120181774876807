import { PocInformation, PocInformationV3 } from "../../domains/PocInformation";

import {
  PocInformation as GetAllFailedPocsResponse,
  PocInformationV3 as GetAllFailedPocsResponseV3,
} from "../../services/campaigns/domains/GetAllFailedPocsResponse";

export function GetAllFailedPocsConverter(
  response: GetAllFailedPocsResponse,
): PocInformation {
  const pocInformation = {
    invalid: {
      totalCompleted: response?.totalCompleted,
      totalPending: response?.totalPending,
      failedPocs: response?.failedIds,
    },
  };

  return { pocInformation };
}

export function GetAllFailedPocsConverterV3(
  response: GetAllFailedPocsResponseV3,
): PocInformationV3 {
  const { totalCompleted, totalPending, failedIds, accountIds } = response;
  let pocInformationV3;

  if (accountIds === undefined) {
    pocInformationV3 = {
      invalid: {
        totalCompleted,
        totalPending,
        failedPocs: failedIds,
      },
    };
    return { pocInformationV3 };
  }

  pocInformationV3 = {
    all: {
      ...response,
    },
  };

  return { pocInformationV3 };
}

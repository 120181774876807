import React from "react";
import BEEIcon from "../../assets/Bee";
import BeeLoadingStyle from "./BeeLoading.styles";
import BouncingDotsLoader from "../BouncingDotsLoader/BouncingDotsLoader";

interface Props {
  testid: string;
}

const BeeLoading: React.FC<Props> = ({ testid }) => {
  const classes = BeeLoadingStyle();
  return (
    <div
      className={classes.container}
      data-testid={`${testid}-bee-loading-component`}
    >
      <BEEIcon />
      <BouncingDotsLoader />
    </div>
  );
};

export default BeeLoading;

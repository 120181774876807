import React from "react";
import { IconButton } from "@material-ui/core";

import HomeIcon from "../../../../assets/icons/HouseIcon";
import PocIdsInfoButtonStyles from "./PocIdsInfoButtonStyle";

interface PocIdsInfoButtonProps {
  handleOpenModal: () => void;
}

const PocIdsInfoButton: React.FC<PocIdsInfoButtonProps> = ({
  handleOpenModal,
}) => {
  const classes = PocIdsInfoButtonStyles();
  return (
    <div>
      <IconButton onClick={handleOpenModal} className={classes.homeIcon}>
        <HomeIcon />
      </IconButton>
    </div>
  );
};

export default PocIdsInfoButton;

import { createStore } from "effector";
import { GetAllCampaignsState } from "./GetAllCampaignsState";
import { getAllCampaignsEffect } from "./GetAllCampaignsEvents";

const initialState = <GetAllCampaignsState>{
  campaigns: [],
  loading: false,
  error: false,
  done: false,
};

const getAllCampaignsStore = createStore(initialState)
  .on(getAllCampaignsEffect.done, (state, value) => {
    return {
      ...state,
      campaigns: value.result?.campaigns,
      loading: false,
      error: false,
      done: true,
    };
  })
  .on(getAllCampaignsEffect.fail, (state) => {
    return {
      ...state,
      loading: false,
      error: true,
      done: true,
    };
  })
  .on(getAllCampaignsEffect, (state) => {
    return {
      ...state,
      loading: true,
      error: false,
      done: false,
    };
  });

export default getAllCampaignsStore;

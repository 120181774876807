import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      paddingLeft: 32,
      paddingRight: 32,
    },
    NewSideBarContainer: {
      borderRadius: 27,
      paddingTop: 0,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      margin: "0rem 0em",
      width: "100%",
      marginBottom: "0em",
      height: "100%",
      backgroundColor: "#FFFFFF",
      boxShadow: "0rem 0.5rem 1.5rem 0rem rgb(0 0 0 / 16%)",
    },
    CampaignsContent: {
      display: "flex",
      paddingLeft: "7.4em",
      paddingRight: "1.7em",
      width: "100%",
      position: "fixed",
      height: "calc(95% - 60px)",
      marginTop: "1rem",
      top: "50px",
    },
    noFocusSkuText: {
      height: "78vh",
      fontSize: "1rem",
      color: palette.secondary.main,
      paddingBottom: "14px",
      textAlign: "center",
      paddingTop: "36vh",
    },
    loading: {
      paddingBottom: "14px",
      paddingTop: "14px",
      textAlign: "center",
    },
    NewSideBarFocusGrid: {
      height: "90%",
      justifyContent: "space-between",
      alignContent: "center",
      flexWrap: "nowrap",
    },
    focusGrid: {
      height: "78vh",
      justifyContent: "space-between",
      alignContent: "center",
      flexWrap: "nowrap",
    },
  }),
);

/* eslint-disable react/require-default-props */
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { Box, Typography } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import classNames from "classnames";

import DatePickerStyles from "./DatePickerStyles";

interface DatePickerProps {
  title: string;
  date: Date | null;
  handleChange: (date: Date) => void;
  error?: boolean;
  testId?: string;
  minDate?: Date;
}

const DatePicker: React.FC<DatePickerProps> = ({
  title,
  date,
  handleChange,
  error,
  testId,
  minDate,
}) => {
  const classes = DatePickerStyles();
  const [isOpen, setIsOpen] = useState(false);
  const handleDateChange = (date: Date | null) => {
    if (date) {
      handleChange(date);
    }
    setIsOpen(false);
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid>
          <KeyboardDatePicker
            open={isOpen}
            onClick={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            InputProps={{
              classes: {
                root: classNames(classes.input, {
                  [classes.error]: error,
                }),
              },
              readOnly: true,
            }}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={date || null}
            minDate={minDate || null}
            minDateMessage=""
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "true",
              disabled: true,
            }}
            keyboardIcon={null}
            placeholder="MM/DD/AA"
            data-testid={testId}
            disablePast
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </Box>
  );
};

export default DatePicker;

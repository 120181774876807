import React from "react";
import { Chip } from "@material-ui/core";
import useStyles from "./TagStyle";

const Tag: React.FC<Props> = ({ label, classes, testId }) => {
  const styleClasses = useStyles();

  const getClasses = () => {
    return `${styleClasses.tag} ${classes}`;
  };

  return (
    <Chip
      data-testid={testId}
      label={label}
      size="small"
      className={getClasses()}
    />
  );
};

export default React.memo(Tag);

Tag.defaultProps = {
  testId: "tag-component",
};

interface Props {
  label: string;
  // eslint-disable-next-line react/require-default-props
  classes?: string;
  testId?: string;
}

import React, { useState } from "react";

import { getUserInfos } from "@config/utils/functions";
import { Alert } from "@hexa-ui/components";
import { Box, IconButton, Typography } from "@material-ui/core";
import { useStore } from "effector-react";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import fileDownload from "js-file-download";

import { useAnalytics } from "../../../../analytics/useAnalytics";
import DownloadIcon from "../../../../assets/icons/DowloadIcon";
import TrashIcon from "../../../../assets/icons/TrashIcon";
import AlertDialog from "../../../../components/core/AlertDialog/AlertDialog";
import LoadingBlockList from "../../../../components/loadingBlockList/LoadingBlockList";
import {
  blockListDownloaded,
  blockListDeleted,
} from "../../../../config/typewriter";
import { AlertInfo } from "../../../../domains/Alert";
import { formatMessage } from "../../../../i18n/formatters";
import {
  deleteBlockListEffect,
  getBlockListEffect,
} from "../../../../stores/management/BlockListEvents";
import BlockListStore from "../../../../stores/management/BlockListStore";
import DragAndDrop from "../../../campaigns/components/dragDrop/DragDrop";
import useStyles from "./CsvListStyle";

interface Props {
  testId: string;
  title: string;
  setFieldValue: (
    field: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  alertStatus: AlertInfo;
  touched: FormikTouched<FormikValues>;
  setAlertStatus: React.Dispatch<React.SetStateAction<AlertInfo>>;
  setIsFileInputed: (isFileInputed: boolean) => void;
}
const CsvList: React.FC<Props> = ({
  testId,
  title,
  values,
  errors,
  touched,
  alertStatus,
  setFieldValue,
  setAlertStatus,
  setIsFileInputed,
}) => {
  const classes = useStyles();
  const blockListStore = useStore(BlockListStore);
  const { dispatchGenericEvent } = useAnalytics();
  const [openAlert, setOpenAlert] = useState(false);

  const { vendorId, country } = getUserInfos();

  const deleteBlockList = () => {
    setOpenAlert(false);
    dispatchGenericEvent(blockListDeleted, {
      block_list:
        values.name ??
        `${formatMessage({ id: "BLOCK_LIST.CURRENT_CSV" })} ${country}.csv`,
    });
    deleteBlockListEffect({
      vendorId,
    });
  };

  /* istanbul ignore next */
  const deleteToastMessage = () => {
    setAlertStatus({ ...alertStatus, message: "" });
  };

  /* istanbul ignore next */
  const downloadBlocklist = () => {
    getBlockListEffect({
      vendorId,
    }).then((blockList) => {
      const blockListDefaultName = `${formatMessage({
        id: "BLOCK_LIST.CURRENT_CSV",
      })} ${country}.csv`;
      dispatchGenericEvent(blockListDownloaded, {
        block_list: values.name ?? blockListDefaultName,
      });
      return fileDownload(blockList, blockListDefaultName);
    });
  };

  /* istanbul ignore next */
  const hasBlockListError = () => {
    return (
      <div className={classes.containerDownloadCsv}>
        <div className={classes.spanDownloadCsv}>
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              downloadBlocklist();
              deleteToastMessage();
            }}
            data-testid="button-block-list-download"
          >
            <span className={classes.currentBlockList}>
              {formatMessage({ id: "BLOCK_LIST.CURRENT_CSV" })}
            </span>
            <DownloadIcon />
          </IconButton>
        </div>
        <span className={classes.spanDownloadCsv}>
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              setOpenAlert(true);
              deleteToastMessage();
            }}
            data-testid="icon-download-button"
          >
            <TrashIcon />
          </IconButton>
        </span>
      </div>
    );
  };

  const handleOnSelectDrangAndDrop = (files: File[]) => {
    const hasValidFile = files.length > 0;
    setIsFileInputed(hasValidFile);

    const file = hasValidFile ? files[0] : null;
    setFieldValue("blockList", file);
    setFieldValue("name", file?.name ?? null);
  };

  return (
    <Box data-testid={testId} className={classes.container}>
      {blockListStore.isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
          data-testid="loading-block-list"
        >
          <LoadingBlockList />
        </div>
      ) : (
        <>
          <Typography className={classes.title}>{title}</Typography>
          <label id="block-list-id" htmlFor="csvList">
            <DragAndDrop
              onSelect={handleOnSelectDrangAndDrop}
              fileTypeAccepted={[".csv"]}
              maxFiles={1}
              text={formatMessage({ id: "BLOCK_LIST.DRAG_DROP_CSV" })}
              testId={testId}
              classesName="white"
            />
            {
              /* istanbul ignore next */
              !!errors.csvList && touched.csvList && (
                <span
                  className={classes.errorMessage}
                  data-testid="modal-block-list-error"
                >
                  {errors.csvList}
                </span>
              )
            }
          </label>
          {hasBlockListError()}
        </>
      )}

      {alertStatus.message !== "" && (
        <span className={classes.csvAlertContainer}>
          {alertStatus.refresh ? (
            <Alert
              type={alertStatus.type}
              message={alertStatus.message}
              actions={[
                {
                  action: /* istanbul ignore next */ () => {
                    getBlockListEffect({
                      vendorId,
                    });
                    deleteToastMessage();
                  },
                  name: formatMessage({ id: "BLOCK_LIST.REFRESH_PAGE" }),
                },
              ]}
            />
          ) : (
            <Alert
              type={alertStatus.type}
              message={alertStatus.message}
              showCloseButton={alertStatus.type === "success"}
              onClose={/* istanbul ignore next */ () => deleteToastMessage()}
            />
          )}
        </span>
      )}

      <AlertDialog
        show={openAlert}
        id="block-list-dialog"
        title={formatMessage({
          id: "BLOCK_LIST.CONFIRM_BUTTON",
        })}
        description={formatMessage({
          id: "BLOCK_LIST.DELETE_DESCRIPTION",
        })}
        handleCancel={() => setOpenAlert(false)}
        handleConfirm={() => deleteBlockList()}
      />
    </Box>
  );
};

export default React.memo(CsvList);

import React, { useState, useEffect, useRef, useCallback } from "react";

import BeeLoading from "@components/BeeLoading/BeeLoading";
import DeleteDialog from "@components/core/DeleteDialog/DeleteDialog";
import ErrorHandlerRetry from "@components/errorHandlerRetry/ErrorHandlerRetry";
import HeaderMessage from "@components/headerMessage/HeaderMessage";
import { CAMPAIGN_CLASSIFICATION, CAMPAIGN_TYPE } from "@config/constants";
import { ERROR_EXCEEDED } from "@config/errorCodes";
import {
  GROW_ORGANIZATION_ACTIVATION,
  TLP_CAMPAIGN_FOCUS_VENDOR,
} from "@config/featureToggles";
import {
  errorMessageViewed,
  campaignDeleted,
  campaignEdited,
  campaignStatusViewed,
} from "@config/typewriter";
import { getUserInfos } from "@config/utils/functions";
import { Box, Grid } from "@material-ui/core";
import { useStore } from "effector-react";
import _ from "lodash";
import Moment from "moment";

import { isFeatureEnabled, getVendorName } from "grow-shared-services";
import { useAnalytics } from "../../analytics/useAnalytics";
import { Campaign } from "../../domains/Campaign";
import { formatMessage } from "../../i18n/formatters";
import { createCampaignB2OEffect } from "../../stores/campaigns/CreateCampaignB2OEvents";
import { createCampaignEffect } from "../../stores/campaigns/CreateCampaignEvents";
import { deleteCampaignEffect } from "../../stores/campaigns/DeleteCampaignEvents";
import { getAllCampaignsEffect } from "../../stores/campaigns/GetAllCampaignsEvents";
import GetAllCampaignsStore from "../../stores/campaigns/GetAllCampaignsStore";
import {
  getAllFailedPocsEffect,
  getAllOrInvalidPocsEffectV3,
} from "../../stores/campaigns/GetAllFailedPocsEvents";
import { clearCampaignItems } from "../../stores/campaigns/items/CampaignItemsEvents";
import { updateCampaignEffect } from "../../stores/campaigns/UpdateCampaignsEvents";
import CampaignsMainPageStyle from "./CampaignsMainPageStyle";
import FocusSkuHeader from "./components/focusSkuHeader/FocusSkuHeader";
import FocusSkuTable from "./components/focusSkuTable/FocusSkuTable";
import ModalEditCampaign from "./components/modalEditCampaign/ModalEditCampaign";
import Pagination from "./components/pagination/Pagination";
import PocIdsInfoModal from "./components/pocIdsInfoModal/PocIdsInfoModal";

const LIMIT_PER_PAGE = 6;

enum ITEM_TYPES {
  DDC = "DDC",
  SKUS = "SKUS",
  CAMPAIGN_NAME = "CAMPAIGN_NAME",
  SEGMENT = "SEGMENT",
}

const CampaignsMainPage: React.FC = () => {
  const classes = CampaignsMainPageStyle();
  const [headerMessage, setHeaderMessage] = useState("");

  const [pocIdsModalOpen, setPocIdsModalOpen] = useState(false);

  const [pocIdsCampaignTag, setPocIdsCampaignTag] = useState("");
  const [headerMessageType, setHeaderMessageType] = useState("error");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadInformation, setLoadInformation] = useState(false);
  const [filteredDDCList, setFilteredDDCList] = useState<Campaign[]>([]);
  const [filteredSKUList, setFilteredSKUList] = useState<Campaign[]>([]);
  const [filteredCampaignNameList, setFilteredCampaignNameList] = useState<
    Campaign[]
  >([]);
  const [filteredSegmentList, setFilteredSegmentList] = useState<Campaign[]>(
    [],
  );
  const [page, setPage] = useState(1);
  const [items, setItems] = useState<Campaign[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const { error, campaigns, loading } = useStore(GetAllCampaignsStore);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectTag, setSelectTag] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectCampaign, setSelectCampaign] = useState<Campaign>();
  const [isB2OCampaign, setIsB2OCampaign] = useState(false);
  const { dispatchGenericEvent } = useAnalytics();
  const [updateCampaignEffectUnsubscribe, setUpdateCampaignEffectUnsubscribe] =
    useState<() => void>();

  const handleChange = (value: number) => {
    setPage(value);
  };

  const closeMessage = () => {
    setHeaderMessage("");
  };

  const { vendorId, country } = getUserInfos();

  const vendorRef = useRef("id");

  const vendorName = getVendorName(vendorId);

  const isCampaignFocusVendorEnabled = isFeatureEnabled(
    TLP_CAMPAIGN_FOCUS_VENDOR,
  );

  const isGrowOrganizationActivationEnabled = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const handleOpenDeleteModal = (selectCampaignTag: string) => {
    setOpenDeleteModal(true);
    setSelectTag(selectCampaignTag);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectTag("");
  };

  const handleConfirmDeleteModal = () => {
    const campaign = items.find(
      (campaign) => campaign.campaignTag === selectTag,
    )?.id;
    const idCampaign = campaign ?? "";
    deleteCampaignEffect({
      country,
      campaignTag: selectTag,
      vendorId,
      vendorName,
      isCampaignFocusVendorEnabled,
      idCampaign,
    });
  };

  const handleItemsOnChoose = (value: string) => {
    if (value === ITEM_TYPES.DDC) {
      setItems(filteredDDCList);
      return;
    }
    if (value === ITEM_TYPES.CAMPAIGN_NAME) {
      setItems(filteredCampaignNameList);
      return;
    }
    if (value === ITEM_TYPES.SEGMENT) {
      setItems(filteredSegmentList);
      return;
    }
    if (value === ITEM_TYPES.SKUS) {
      setItems(filteredSKUList);
      return;
    }
    setItems(campaigns);
  };

  const handleOpenPocIdsInfoModal = (
    campaignTag: string,
    campaignName: string,
    campaignType: string,
  ) => {
    setIsB2OCampaign(campaignType === "b2o");
    setPocIdsModalOpen(true);
    setPocIdsCampaignTag(campaignTag);
    dispatchGenericEvent(campaignStatusViewed, {
      campaign_name: campaignName,
      tag_name: campaignTag,
    });
  };

  useEffect(() => {
    vendorRef.current = vendorId;
    clearCampaignItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const deleteEffectDone = deleteCampaignEffect.done.watch(() => {
      clearCampaignItems();
      handleCloseDeleteModal();

      /* istanbul ignore next */
      if (typeof deleteEffectDone === "function") {
        deleteEffectDone();
      }
    });
  }, [openDeleteModal]);

  const handleOpenEditModal = (selectEditCampaign: Campaign) => {
    setOpenEditModal(true);
    setSelectCampaign(selectEditCampaign);
  };

  const hanleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const hanleClosePocIdsModal = () => {
    setPocIdsModalOpen(false);
    setPocIdsCampaignTag("");
  };

  const findAndSplitResults = (value: string) => {
    if (value === "") {
      const offset = LIMIT_PER_PAGE;
      /* istanbul ignore next */
      if (campaigns?.length > 0) {
        setItems(campaigns.slice(offset, offset + LIMIT_PER_PAGE));
        setPage(1);
      }

      return;
    }

    if (!value) {
      handleItemsOnChoose("");
      return;
    }
    setLoadingSearch(true);
    const returnValueIfExists = (item: string | undefined) => {
      if (!item) {
        return "";
      }
      return item;
    };

    const contains = (list: string[], valueToContain: string) => {
      return list.filter((each) =>
        each.toLowerCase().includes(valueToContain.toLowerCase()),
      );
    };

    const filterDdcOrSegment = (filter: "segment" | "deliveryCenterId") => {
      return campaigns.filter((each) => {
        const valuesList = each?.products.map((item) => {
          return returnValueIfExists(
            item.filterOptions ? item.filterOptions[filter] : undefined,
          );
        });
        const findings = contains(valuesList, value);
        return findings.length > 0;
      });
    };

    const filteredSkus = campaigns.filter((each) => {
      const skus = each?.products.map((eachProduct) => eachProduct.sku);
      const ListOfSkuThatExist = contains(skus, value);
      return ListOfSkuThatExist.length > 0;
    });
    const filteredDDC = filterDdcOrSegment("deliveryCenterId");
    const filteredSegment = filterDdcOrSegment("segment");
    const filteredCampaignName = campaigns.filter((each) =>
      each?.campaignName.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredDDCList(filteredDDC);
    setFilteredSKUList(filteredSkus);
    setFilteredCampaignNameList(filteredCampaignName);
    setFilteredSegmentList(filteredSegment);

    setLoadingSearch(false);
  };
  useEffect(() => {
    const checkoutEffectDone = createCampaignEffect.done.watch(() => {
      setHeaderMessageType("success");
      setHeaderMessage(
        formatMessage({ id: "MODAL_CAMPAIGNS.MESSAGE_SUCCESS" }),
      );
    });

    return () => {
      /* istanbul ignore next */
      if (typeof checkoutEffectDone === "function") {
        checkoutEffectDone();
      }
    };
  }, []);

  useEffect(() => {
    const checkoutB2OEffectDone = createCampaignB2OEffect.done.watch(() => {
      setHeaderMessageType("success");
      setHeaderMessage(
        formatMessage({ id: "MODAL_CAMPAIGNS.MESSAGE_SUCCESS" }),
      );
    });

    return () => {
      /* istanbul ignore next */
      if (typeof checkoutB2OEffectDone === "function") {
        checkoutB2OEffectDone();
      }
    };
  }, []);

  const handleError = useCallback(
    (response, screenSection) => {
      let message;

      if (response?.error?.data) {
        message = response.error.data?.message;

        if (message && message.includes(ERROR_EXCEEDED)) {
          message = formatMessage({ id: "MODAL_CAMPAIGNS.ERROR_LIMIT_CSV" });
        }

        setHeaderMessage(message);
      } else {
        message = formatMessage({ id: "MODAL_CAMPAIGNS.ERROR_HANDLER" });
        setHeaderMessage(message);
      }
      setHeaderMessageType("error");
      dispatchGenericEvent(errorMessageViewed, {
        error_message: message,
        error_type: "Create campaign error",
        screen_name: "Campaigns",
        screen_section: screenSection,
        time_of_day: Moment().local().hour(),
      });
    },
    [setHeaderMessage, setHeaderMessageType, dispatchGenericEvent],
  );

  useEffect(() => {
    const checkoutEffectFail = createCampaignEffect.fail.watch((response) =>
      handleError(response, "Campaign Creation Modal"),
    );

    const checkoutB2OEffectFail = createCampaignB2OEffect.fail.watch(
      (response) => handleError(response, "Campaign B2O Creation Modal"),
    );

    /* istanbul ignore next */
    return () => {
      if (typeof checkoutEffectFail === "function") {
        checkoutEffectFail();
      }
      if (typeof checkoutB2OEffectFail === "function") {
        checkoutB2OEffectFail();
      }
    };
  }, [handleError]);

  /* istanbul ignore next */
  useEffect(() => {
    const checkoutEffectUpdate = updateCampaignEffect.done.watch(() => {
      setHeaderMessageType("update");
      setHeaderMessage(
        formatMessage({ id: "MODAL_CAMPAIGNS.MESSAGE_SUCCESS_UPDATE" }),
      );

      dispatchGenericEvent(campaignEdited, {
        campaign_name: selectCampaign?.campaignName,
        tag_name: selectCampaign?.campaignTag,
        products: selectCampaign?.products,
        DDC: selectCampaign?.products[0].filterOptions.deliveryCenterId,
        segment: selectCampaign?.products[0].filterOptions.segment,
        start_date: selectCampaign?.startDate
          ? Moment(selectCampaign?.startDate).format("YYYY-MM-DD")
          : null,
        end_date: selectCampaign?.endDate
          ? Moment(selectCampaign?.endDate).format("YYYY-MM-DD")
          : null,
        campaign_priority:
          selectCampaign?.classification === CAMPAIGN_CLASSIFICATION.PRIMARY
            ? 1
            : 2,
      });
    });

    return () => {
      if (typeof checkoutEffectUpdate === "function") {
        checkoutEffectUpdate();
      }
    };
  }, [dispatchGenericEvent, selectCampaign]);

  /* istanbul ignore next */
  useEffect(() => {
    const checkoutEffectUpdate = updateCampaignEffect.fail.watch((response) => {
      let message;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (response?.error && (response.error as any)?.data) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message = (response.error as any)?.data?.message;
        setHeaderMessage(message);
      } else {
        message = formatMessage({ id: "MODAL_CAMPAIGNS.ERROR_HANDLER" });
        setHeaderMessage(message);
      }
      setHeaderMessageType("error");

      dispatchGenericEvent(errorMessageViewed, {
        error_message: message,
        error_type: "Edit campaign error",
        screen_name: "Campaigns",
        screen_section: "Campaign Editing Modal",
        time_of_day: Moment().local().hour(),
      });
    });

    return () => {
      if (typeof checkoutEffectUpdate === "function") {
        checkoutEffectUpdate();
      }
    };
  }, [dispatchGenericEvent]);

  const validateDateToAnalitycs = (campaignType: string, date: string) => {
    if (_.isEmpty(campaignType) || CAMPAIGN_TYPE.FOCUS_SKU === campaignType) {
      return null;
    }

    return Moment(date).format("YYYY-MM-DD");
  };

  useEffect(() => {
    const checkoutEffectDelete = deleteCampaignEffect.done.watch((result) => {
      setHeaderMessageType("delete");
      setHeaderMessage(
        formatMessage({ id: "MODAL_CAMPAIGNS.MESSAGE_SUCCESS_DELETE" }),
      );

      const { campaignTag } = result?.params;

      const campaignWasDeleted = campaigns?.find(
        (c) => c.campaignTag === campaignTag,
      );

      if (campaignWasDeleted != null) {
        const {
          campaignName,
          products,
          endDate,
          startDate,
          campaignType,
          classification,
        } = campaignWasDeleted;

        const analitcsProducts = products.map((product) => {
          return { sku: product.sku };
        });
        /* istanbul ignore next */
        dispatchGenericEvent(campaignDeleted, {
          campaign_name: campaignName,
          products: analitcsProducts,
          start_date: validateDateToAnalitycs(campaignType, startDate),
          end_date: validateDateToAnalitycs(campaignType, endDate),
          campaign_priority:
            classification === CAMPAIGN_CLASSIFICATION.PRIMARY ? 1 : 2,
        });
      }
    });

    return () => {
      /* istanbul ignore next */
      if (typeof checkoutEffectDelete === "function") {
        checkoutEffectDelete();
      }
    };
  }, [dispatchGenericEvent, campaigns]);

  useEffect(() => {
    const checkoutEffectDelete = deleteCampaignEffect.fail.watch((response) => {
      let message;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (response?.error && (response.error as any)?.data) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message = (response.error as any)?.data?.message;
        setHeaderMessage(message);
      } else {
        message = formatMessage({ id: "MODAL_CAMPAIGNS.ERROR_HANDLER" });
        setHeaderMessage(message);
      }
      setHeaderMessageType("error");

      dispatchGenericEvent(errorMessageViewed, {
        error_message: message,
        error_type: "Delete campaign error",
        screen_name: "Campaigns",
        screen_section: "Campaign Exclusion Modal",
        time_of_day: Moment().local().hour(),
      });
    });

    return () => {
      /* istanbul ignore next */
      if (typeof checkoutEffectDelete === "function") {
        checkoutEffectDelete();
      }
    };
  }, [dispatchGenericEvent]);

  useEffect(() => {
    let getAllFailedPocsEffectDone: () => void;
    let getAllFailedPocsV3EffectDone: () => void;

    if (isGrowOrganizationActivationEnabled) {
      getAllFailedPocsV3EffectDone = getAllOrInvalidPocsEffectV3.done.watch(
        () => {
          setLoadInformation(false);
        },
      );
    } else {
      getAllFailedPocsEffectDone = getAllFailedPocsEffect.done.watch(() => {
        setLoadInformation(false);
      });
    }

    return () => {
      /* istanbul ignore next */
      const effectDoneFunction = isGrowOrganizationActivationEnabled
        ? getAllFailedPocsV3EffectDone
        : getAllFailedPocsEffectDone;

      if (typeof effectDoneFunction === "function") {
        effectDoneFunction();
      }
    };
  }, [isGrowOrganizationActivationEnabled, setLoadInformation]);

  useEffect(() => {
    let checkoutGetAllFailedPocsV3EffectFail: () => void;
    let checkoutGetAllFailedPocsEffectFail: () => void;

    const handleErrorOnFail = (response: unknown) => {
      setPocIdsModalOpen(false);
      setLoadInformation(false);
      handleError(response, "Campaign Creation Modal");
    };
    if (isGrowOrganizationActivationEnabled) {
      checkoutGetAllFailedPocsV3EffectFail =
        getAllOrInvalidPocsEffectV3.fail.watch((response) =>
          handleErrorOnFail(response),
        );
    } else {
      checkoutGetAllFailedPocsEffectFail = getAllFailedPocsEffect.fail.watch(
        (response) => handleErrorOnFail(response),
      );
    }

    /* istanbul ignore next */
    return () => {
      const effectFailFunction = isGrowOrganizationActivationEnabled
        ? checkoutGetAllFailedPocsV3EffectFail
        : checkoutGetAllFailedPocsEffectFail;

      if (typeof effectFailFunction === "function") {
        effectFailFunction();
      }
    };
  }, [handleError, isGrowOrganizationActivationEnabled]);

  useEffect(() => {
    if (campaigns?.length > 0) {
      setPage(1);
      setTotalPages(Math.ceil(campaigns.length / LIMIT_PER_PAGE));
    }
  }, [campaigns]);

  useEffect(() => {
    const offset = (page - 1) * LIMIT_PER_PAGE;
    if (campaigns?.length > 0) {
      setItems(campaigns.slice(offset, offset + LIMIT_PER_PAGE));
    } else {
      setItems([]);
    }
  }, [page, campaigns]);

  const vendorRefPreviusValue = vendorRef.current ?? vendorId;

  useEffect(() => {
    if (vendorRefPreviusValue !== vendorId) {
      getAllCampaignsEffect({
        isCanaryRelease: false,
        vendorId,
        vendorName,
        isCampaignFocusVendorEnabled,
      });
    }
  }, [
    isCampaignFocusVendorEnabled,
    vendorRefPreviusValue,
    vendorId,
    vendorName,
    country,
  ]);

  useEffect(() => {
    createCampaignB2OEffect.done.watch(() => {
      getAllCampaignsEffect({
        isCanaryRelease: false,
        vendorId,
        vendorName,
        isCampaignFocusVendorEnabled,
      });
    });
  }, [isCampaignFocusVendorEnabled, vendorId, vendorName, country]);

  useEffect(() => {
    createCampaignEffect.done.watch(() => {
      getAllCampaignsEffect({
        isCanaryRelease: false,
        vendorId,
        vendorName,
        isCampaignFocusVendorEnabled,
      });
    });

    deleteCampaignEffect.done.watch(() => {
      setPage(1);
      getAllCampaignsEffect({
        isCanaryRelease: false,
        vendorId,
        vendorName,
        isCampaignFocusVendorEnabled,
      });
    });

    if (updateCampaignEffectUnsubscribe) updateCampaignEffectUnsubscribe();
    const { unsubscribe } = updateCampaignEffect.done.watch(() => {
      setPage(1);
      getAllCampaignsEffect({
        isCanaryRelease: false,
        vendorId,
        vendorName,
        isCampaignFocusVendorEnabled,
      });
    });
    return () => {
      setUpdateCampaignEffectUnsubscribe(unsubscribe);
    };
  }, [
    isCampaignFocusVendorEnabled,
    vendorId,
    vendorName,
    country,
    updateCampaignEffectUnsubscribe,
  ]);

  const onRetryHandler = () => {
    getAllCampaignsEffect({
      isCanaryRelease: false,
      vendorId,
      vendorName,
      isCampaignFocusVendorEnabled,
    });
  };

  const noResultsText = () => (
    <div
      className={classes.noFocusSkuText}
      data-testid="no-fokus-sku-items-find"
    >
      {formatMessage({ id: "CAMPAIGNS.NOT_FOUND" })}
    </div>
  );

  const renderTableOrNoFocusFound = () =>
    items.length > 0 ? (
      <>
        <FocusSkuTable
          campaigns={items}
          openDeleteModal={handleOpenDeleteModal}
          openEditModal={handleOpenEditModal}
          openPocIdsInfoModal={handleOpenPocIdsInfoModal}
        />
        <Grid container style={{ justifyContent: "flex-end" }}>
          {items.length !== 0 && totalPages > 1 && (
            <Pagination onChange={handleChange} totalPages={totalPages} />
          )}
        </Grid>
      </>
    ) : (
      noResultsText()
    );

  const renderContent = () =>
    error ? (
      <ErrorHandlerRetry
        onClick={onRetryHandler}
        testId="fokus-sku"
        screenName="Campaigns"
        screenSection="Campaign Listing"
      />
    ) : (
      renderTableOrNoFocusFound()
    );

  return (
    <>
      <Box className={classes.CampaignsContent}>
        <Box width="100%" className={classes.NewSideBarContainer}>
          {headerMessage ? (
            <HeaderMessage
              message={headerMessage}
              type={headerMessageType}
              dataTest="handler-message"
              onMessageClose={closeMessage}
            />
          ) : (
            <div style={{ display: "none", height: "38px", width: "100%" }} />
          )}
          <Grid>
            <FocusSkuHeader
              searchFunction={findAndSplitResults}
              totalCampaignNames={filteredCampaignNameList.length}
              totalDeliveryCenterIds={filteredDDCList.length}
              totalSegments={filteredSegmentList.length}
              totalSkus={filteredSKUList.length}
              handleItemsOnChoose={handleItemsOnChoose}
              loadingSearch={loadingSearch}
            />
          </Grid>

          <Grid
            container
            direction="column"
            className={classes.NewSideBarFocusGrid}
          >
            {loading ? (
              <BeeLoading testid="campaign-setup-loading" />
            ) : (
              renderContent()
            )}
          </Grid>
        </Box>
      </Box>
      <DeleteDialog
        show={openDeleteModal}
        handleConfirm={handleConfirmDeleteModal}
        handleCancel={handleCloseDeleteModal}
      />
      <ModalEditCampaign
        open={openEditModal}
        onClose={hanleCloseEditModal}
        campaign={selectCampaign}
      />
      <PocIdsInfoModal
        campaignTag={pocIdsCampaignTag}
        open={pocIdsModalOpen}
        onClose={hanleClosePocIdsModal}
        loadInfos={loadInformation}
        isB2OCampaign={isB2OCampaign}
        setLoadInfos={setLoadInformation}
      />
    </>
  );
};

export default CampaignsMainPage;

import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { createFeatureToggleServiceV2 } from "admin-portal-shared-services";
import App from "./bootstrap/App";
import { axiosIntercept } from "./config/axios/axiosInstance";
import { getPortalConfig } from "./config/utils/functions";
import { WRAPPER_PROJECT_NAME_INSTANCE } from "./config/constants";

const sdkKey: string = getPortalConfig().OPTIMIZELY_KEY;

axiosIntercept();
createFeatureToggleServiceV2(WRAPPER_PROJECT_NAME_INSTANCE, sdkKey);

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary() {
    return <></>;
  },
});

export const { bootstrap } = reactLifecycles;
export const { mount } = reactLifecycles;
export const { unmount } = reactLifecycles;

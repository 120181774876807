export const TLP_CAMPAIGN_SERVICE = "tlp-campaign-service";
export const ONCUSTOMER_B2C_USERS = "oncustomer-b2c-users";
export const TLP_ITEM_SERVICE = "tlp-item-service";
export const TLP_ACCOUNT_SERVICE = "tlp-account-service";
export const GROW_ADMIN_CONFIG_SERVICE = "grow-admin-config-service";

/**
 * Use to call microservices on uat
 * [TLP_CAMPAIGN_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
 * [ONCUSTOMER_B2C_USERS, "https://cx-tlp-uat.bees-platform.dev"],
 * [TLP_ITEM_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
 * [TLP_ACCOUNT_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
 * [GROW_ADMIN_CONFIG_SERVICE,"https://cx-tlp-uat.bees-platform.dev"],
 */
export const MICRO_SERVICES_HOSTS = new Map<string, string>([
  [TLP_CAMPAIGN_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
  [ONCUSTOMER_B2C_USERS, "https://cx-tlp-uat.bees-platform.dev"],
  [TLP_ITEM_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
  [TLP_ACCOUNT_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
  [GROW_ADMIN_CONFIG_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
]);

import React from "react";

const RetryIcon: React.FunctionComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16">
    <path
      d="M12.499 1a.5.5 0 00-.5.5v1.826A6.485 6.485 0 001.002 7.998a.5.5 0 001 0 5.498 5.498 0 019.554-3.711l-2.214.738a.5.5 0 10.316.95l2.999-1a.502.502 0 00.342-.476v-3a.5.5 0 00-.5-.499zm.999 6.498a.5.5 0 00-.5.5 5.497 5.497 0 01-9.554 3.712l2.216-.74a.5.5 0 10-.316-.949l-3 1a.498.498 0 00-.341.475v3a.5.5 0 001 0V12.67a6.485 6.485 0 0010.996-4.672.5.5 0 00-.5-.5z"
      fill="#505050"
      fillRule="nonzero"
    />
  </svg>
);

export default RetryIcon;

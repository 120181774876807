import React, { ReactNode, useEffect } from "react";

import { getUserInfos } from "@config/utils/functions";
import { useUserMetadata } from "admin-portal-shared-services";
import { useUserConfigService } from "grow-shared-services";
import { RawIntlProvider } from "react-intl";

import { updateIntlProvider } from "../../i18n/formatters";
import intlProvider from "../../i18n/provider/provider";

interface IntlProviderProps {
  children: ReactNode;
}

const IntlProvider = ({ children }: IntlProviderProps): JSX.Element => {
  const userConfigService = useUserConfigService();
  const { preferredLanguage } = getUserInfos();

  const intl = intlProvider(preferredLanguage);

  const { data } = useUserMetadata();

  useEffect(() => {
    const metaDataLanguage = data?.profile?.preferredLanguage;

    if (!!metaDataLanguage && metaDataLanguage !== preferredLanguage) {
      userConfigService.setPreferredLanguage(metaDataLanguage);
      updateIntlProvider(metaDataLanguage);
    }
  }, [data?.profile?.preferredLanguage, preferredLanguage, userConfigService]);

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};

export default IntlProvider;

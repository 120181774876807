import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    toastContainer: {
      width: "500px !important",
      fontFamily: "Work Sans",
      marginTop: "3rem",
      transform: "inherit !important",
      left: "inherit !important",
      right: "20px",
    },
    toast: {
      minHeight: "1.5rem !important",
      borderRadius: "0.5rem !important",
      color: "#212121 !important",
      "& .Toastify__toast-body>div:last-child": {
        fontFamily: "Work Sans",
        fontSize: 14,
      },
    },
    closeIcon: {
      fontSize: "16px",
      margin: "10px",
    },
    headerIcon: {
      "&:hover": {
        cursor: "default",
      },
    },
  }),
);

import React from "react";

const LoadingErrorIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 519 434">
    <defs>
      <filter
        x="-8.5%"
        y="-10.3%"
        width="117.1%"
        height="120.7%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="2"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(378)" fill="#FFF" filter="url(#a)">
        <path d="M106.498 3.724c13.299 0 24.08 10.78 24.08 24.08v47.01c0 13.298-10.781 24.08-24.08 24.08l-74.549-.11c-12.216-1.162-21.77-11.45-21.77-23.97v-47.01c0-13.3 10.78-24.08 24.079-24.08h72.24z" />
        <path d="M39.414 113.198l15.547-14.706-15.767-6.693z" />
      </g>
      <path
        d="M452.24 61.88a.926.926 0 00.68-.28.926.926 0 00.28-.68V58.2c0-1.44.453-2.653 1.36-3.64.907-.987 2.293-2.147 4.16-3.48 1.867-1.333 3.347-2.533 4.44-3.6 1.093-1.067 2.053-2.453 2.88-4.16.827-1.707 1.24-3.733 1.24-6.08 0-4.533-1.547-8.173-4.64-10.92s-7.227-4.12-12.4-4.12c-3.467 0-6.533.653-9.2 1.96-2.667 1.307-4.747 3.147-6.24 5.52-1.493 2.373-2.24 5.08-2.24 8.12V39c0 .267.093.507.28.72.187.213.413.32.68.32l7.36.32c.64 0 .96-.293.96-.88V37.8c0-2.08.747-3.787 2.24-5.12 1.493-1.333 3.467-2 5.92-2 2.347 0 4.24.627 5.68 1.88 1.44 1.253 2.16 2.92 2.16 5 0 1.867-.533 3.427-1.6 4.68-1.067 1.253-2.667 2.653-4.8 4.2-2.453 1.76-4.32 3.427-5.6 5-1.28 1.573-1.92 3.64-1.92 6.2v3.28c0 .267.093.493.28.68.187.187.413.28.68.28h7.36zm-3.92 17.2c1.6 0 2.933-.533 4-1.6 1.067-1.067 1.6-2.427 1.6-4.08s-.52-3-1.56-4.04-2.387-1.56-4.04-1.56-3.013.52-4.08 1.56c-1.067 1.04-1.6 2.387-1.6 4.04s.533 3.013 1.6 4.08c1.067 1.067 2.427 1.6 4.08 1.6z"
        fill="#000"
        fillRule="nonzero"
      />
      <ellipse
        cx="126.652"
        cy="16.788"
        rx="126.652"
        ry="16.788"
        transform="translate(124.177 399.924)"
        fill="#000"
        fillOpacity=".05"
      />
      <g fillRule="nonzero">
        <path
          d="M195.13 94.061c-69.76-65.639-72.06-74.826-98.71-74.826-20.843 0-51.307 15.701-51.307 52.802 0 11.465 2.935 24.151 10.4 31.303l139.616-9.279z"
          fill="#E5DEFC"
        />
        <path
          d="M199.054 89.236C106.262 63.012 82.96 54.721 67.116 54.721c-32.382 0-67.553 27.734-67.553 68.797 0 27.209 19.457 58.074 40.2 64.137l159.29-98.419z"
          fill="#F0ECFC"
        />
        <path
          d="M317.246 319.664c-60.048 19.951-170.838-24.209-200.63-80.618 5.804 68.78 50.187 120.479 65.609 126.676 15.42 6.197 83.236-.416 135.024-46.059l.007-.003-.01.004zm59.42-111.832s-41.993 15.855-124.191-16.93C170.447 157.694 151.1 117.19 151.1 117.19l-.003.006c-8.012 11.013-15.538 24.673-22.314 41.536-3.925 9.769-6.845 19.434-8.901 28.928l.004.01-.001.003.013.033c.707 1.602 21.293 46.944 101.869 79.606 81.587 32.5 127.939 13.306 128.662 13.002-9.556 15.545-20.916 28.536-33.174 39.346h.002c12.259-10.81 23.62-23.802 33.175-39.347l.01-.004c5.084-8.277 9.664-17.275 13.59-27.044 6.776-16.862 10.795-31.932 12.63-45.426zM186.756 86.735c22.265 38.297 145.373 87.613 186.508 74.05l.064-.16c17.293-43.36-18.72-86.712-66.178-105.783C259.69 35.77 203.695 42.149 186.176 85.419l-.064.159z"
          fill="#000"
        />
        <path
          d="M340.157 114.985c-1.27 8.183 1.925 13.706 6.805 14.463 4.88.758 9.599-3.538 10.868-11.72 1.27-8.184-1.39-17.155-6.27-17.912-4.88-.758-10.134 6.987-11.403 15.17z"
          fill="#FFF"
        />
        <path
          d="M221.768 267.312c81.587 32.5 127.939 13.306 128.662 13.002-9.556 15.545-20.916 28.536-33.174 39.346l-.01.004c-60.05 19.952-170.845-24.211-200.633-80.623l-.004-.01c-1.37-16.286-.582-33.526 3.275-51.36.313.72 20.494 46.65 101.884 79.64zm-70.664-150.129l-.003.007s19.346 40.503 101.374 73.713c82.198 32.784 124.191 16.93 124.191 16.93l.003-.007c2.542-18.61.86-34.027-3.405-47.042-41.55 13.701-166.738-36.754-187.152-75.206-6.047 3.224-12.032 7.33-17.896 12.517-5.863 5.204-11.592 11.495-17.112 19.088z"
          fill="#FF0"
        />
      </g>
    </g>
  </svg>
);

export default LoadingErrorIcon;

import { createEffect, createEvent } from "effector";

import * as FetchAllAgentsUseCase from "../../usecase/agents/FetchAllAgentsUseCase";
import {
  MissionsManagement,
  AgentUpdateMissionPriority,
  AgentUpdateMissionSegmentation,
  AddNewSegmentationPayload,
  AgentUpdateNewMissionPriority,
} from "./AgentsEventsInterfaces";

interface FetchAllAgentsProps {
  isCanaryRelease: boolean;
  vendorId?: string;
}

export const fetchAllAgents = createEffect({
  async handler(fetchAllAgentsProps: FetchAllAgentsProps) {
    const { isCanaryRelease, vendorId } = fetchAllAgentsProps;
    return FetchAllAgentsUseCase.execute(isCanaryRelease, vendorId);
  },
});

export const setMissionsManagement = createEvent<MissionsManagement>(
  "setMissionsManagement",
);

export const setAgentMissionPriority = createEvent<AgentUpdateMissionPriority>(
  "setAgentMissionPriority",
);

export const setAgentNewMissionPriority =
  createEvent<AgentUpdateNewMissionPriority>("setAgentNewMissionPriority");

export const setAgentSegmentation = createEvent<AgentUpdateMissionSegmentation>(
  "setAgentSegmentation",
);

export const setAgentMissions = createEvent<{
  id: string;
  missionsManagement: MissionsManagement;
}>("setAgentMissions");

export const addNewSegmentation =
  createEvent<AddNewSegmentationPayload>("AddNewSegmentation");

export const setIsAgentUpdated = createEvent<boolean>("setIsAgentUpdated");

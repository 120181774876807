import { isFeatureEnabled } from "grow-shared-services";
import { TLP_MULTICONTRACT_CAMPAIGN_SERVICE } from "../../config/featureToggles";
import * as CreateCampaignsService from "../../services/campaigns/CreateCampaignsService";
import CreateCampaignRequest from "../../services/campaigns/domains/CreateCampaignRequest";

export function createCampaign(
  country: string,
  vendorId: string,
  vendorName: string,
  campaign: CreateCampaignRequest,
  isCampaignFocusVendorEnabled?: boolean,
): Promise<void> {
  const isMulticontractCampaignServiceEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
  );

  let vendorIdParam = "";
  let vendorNameParam = "";

  if (
    !isMulticontractCampaignServiceEnable &&
    vendorId &&
    vendorName &&
    isCampaignFocusVendorEnabled
  ) {
    vendorIdParam = vendorId;
    vendorNameParam = vendorName;
  }

  if (isMulticontractCampaignServiceEnable && vendorId) {
    vendorIdParam = vendorId;
  }

  return CreateCampaignsService.createCampaign(
    country,
    vendorIdParam,
    vendorNameParam,
    campaign,
  );
}

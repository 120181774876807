import { getItemsByCampaign } from "../../../services/campaigns/items/GetItemsByCampaignService";
import CampaignItems from "../../../domains/items/CampaignItems";

export function execute(
  campaignTag: string,
  vendorId: string,
  vendorName: string,
  segment: string,
  deliveryCenterId: string,
  country?: string,
): Promise<CampaignItems> {
  return getItemsByCampaign(
    campaignTag,
    vendorId,
    vendorName,
    segment,
    deliveryCenterId,
    country,
  );
}

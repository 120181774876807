import React, { useState } from "react";
import { InputBase } from "@material-ui/core";
import { setIsAgentUpdated } from "../../../../stores/agents/AgentsEvents";
import Button from "../../../../components/Button/Button";
import { formatMessage } from "../../../../i18n/formatters";
import SpecializationInputStyles from "./SpecializationInput.styles";

interface SpecializationInputProps {
  handleAddSegmentation: (value: string) => void;
}

const SpecializationInput = ({
  handleAddSegmentation,
}: SpecializationInputProps): JSX.Element => {
  const classes = SpecializationInputStyles();
  const [value, setValue] = useState("");

  const handleAddSpecialization = () => {
    if (value) {
      handleAddSegmentation(value);
      setValue("");
      setIsAgentUpdated(true);
    }
  };

  return (
    <InputBase
      data-testid="missions-management-specialization-input"
      placeholder={formatMessage({
        id: "AGENT_SEGMENTATION.INSERT_SPECIALIZATION_PLACEHOLDER",
      })}
      fullWidth
      className={classes.input}
      classes={{
        input: classes.overrideInput,
      }}
      value={value}
      onChange={(event) => setValue(event.target.value)}
      endAdornment={
        <Button
          testId="missions-management-specialization-add-button"
          variant="outlined"
          className={classes.addButton}
          onClick={handleAddSpecialization}
          disabled={!value}
        >
          {formatMessage({
            id: "AGENT_SEGMENTATION.INSERT_SPECIALIZATION_ADD_BUTTON",
          })}
        </Button>
      }
    />
  );
};

export default SpecializationInput;

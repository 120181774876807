import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { ListItem, Box } from "@material-ui/core";
import { DnDListItem } from "../Types";
import DraggableCard from "../DraggableCard/DraggableCard";
import DraggableListItemStyles from "./DraggableListItem.styles";
import useMissionsManagement from "../../../hooks/useMissionsManagement";

interface DraggableListItemProps {
  listItem: DnDListItem;
  index: number;
  isLastItem?: boolean;
  handleRemove?: (index: number) => void;
}

const DraggableListItem = ({
  listItem,
  index,
  isLastItem,
  handleRemove,
}: DraggableListItemProps): JSX.Element => {
  const classes = DraggableListItemStyles();
  const { handleToggleMission } = useMissionsManagement();

  return (
    <Draggable
      draggableId={listItem.id}
      index={index}
      isDragDisabled={!listItem.isEnabled}
      data-testid="draggable-list-item"
    >
      {(provided, snapshot) => (
        <Box>
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={
              snapshot.isDragging ? classes.draggingListItem : classes.listItem
            }
            data-testid="list-item"
          >
            <DraggableCard
              listItemId={listItem.id}
              type={listItem.type}
              isActive={listItem.isEnabled}
              handleAction={() => {
                handleToggleMission(listItem.id, "disabled");
              }}
              index={index + 1}
              title={listItem.title}
              data-testid="mission-card-list-item"
              handleRemove={handleRemove}
            />
          </ListItem>
          {!isLastItem && (
            <Box
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={classes.inner}
            >
              &nbsp;
            </Box>
          )}
        </Box>
      )}
    </Draggable>
  );
};

export default DraggableListItem;

DraggableListItem.defaultProps = {
  isLastItem: false,
  handleRemove: () => {},
};

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./LoadingView.styles";

interface LoadingViewProps {
  color?: string;
}

function LoadingView({ color }: LoadingViewProps): React.ReactElement {
  const classes = useStyles({ color });
  return <CircularProgress disableShrink className={classes.loading} />;
}

LoadingView.defaultProps = {
  color: undefined,
};
export default LoadingView;

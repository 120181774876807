import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    container: {
      width: "100%",
      height: "87vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#FAFAFA",
      marginTop: "-2.7em",
    },
    modalContent: {
      background: "#ffffff",
      borderRadius: "24px",
      padding: "16px 14px 16px 15px",
      boxShadow: "0 4px 16px 0 rgba(0,0,0,0.16)",
    },
    modal: {
      background: "#f8f8f8",
      minHeight: "280px",
      width: "370px",
      border: "solid 1px #eaeaea",
      borderRadius: "3px",
      padding: "24px 24px 32px 24px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    title: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#505050",
      letterSpacing: "0",
      lineHeight: "24px",
      borderBottom: "1px solid #9e9e9e",
      paddingBottom: "8px",
    },
    button: {
      width: "322px",
      height: "40px",
      background: "#505050",

      borderRadius: "3px",
      color: "#ffffff",
      fontSize: "14px",
      fontWeight: "bold",
    },
    cancelButton: {
      backgroundColor: "#eaeaea",
      color: "#7a7a7a",
    },
    MuiTextField: {
      root: {
        borderRadius: "3px",
      },
    },
    form: {
      // height: "21%",
      // width: "87%",
      // backgroundColor: "#ffffff",
    },
    registerButton: {},
    confirmButtonDisabled: {
      opacity: "0.2",
      cursor: "not-allowed !important",
      pointerEvents: "visible !important",
    },
  }),
);

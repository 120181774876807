import React from "react";

const DragAndDropBullets = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 12 18"
    >
      <defs>
        <path
          id="a"
          d="M1.8125,3.6 C2.81351611,3.6 3.625,2.79411255 3.625,1.8 C3.625,0.80588745 2.81351611,0 1.8125,0 C0.811483891,0 0,0.80588745 0,1.8 C0,2.79411255 0.811483891,3.6 1.8125,3.6 Z M1.8125,10.8 C2.81351611,10.8 3.625,9.99411255 3.625,9 C3.625,8.00588745 2.81351611,7.2 1.8125,7.2 C0.811483891,7.2 0,8.00588745 0,9 C0,9.99411255 0.811483891,10.8 1.8125,10.8 Z M1.8125,18 C2.81351611,18 3.625,17.1941125 3.625,16.2 C3.625,15.2058875 2.81351611,14.4 1.8125,14.4 C0.811483891,14.4 0,15.2058875 0,16.2 C0,17.1941125 0.811483891,18 1.8125,18 Z M9.0625,3.6 C10.0635161,3.6 10.875,2.79411255 10.875,1.8 C10.875,0.80588745 10.0635161,0 9.0625,0 C8.06148389,0 7.25,0.80588745 7.25,1.8 C7.25,2.79411255 8.06148389,3.6 9.0625,3.6 Z M9.0625,10.8 C10.0635161,10.8 10.875,9.99411255 10.875,9 C10.875,8.00588745 10.0635161,7.2 9.0625,7.2 C8.06148389,7.2 7.25,8.00588745 7.25,9 C7.25,9.99411255 8.06148389,10.8 9.0625,10.8 Z M9.0625,18 C10.0635161,18 10.875,17.1941125 10.875,16.2 C10.875,15.2058875 10.0635161,14.4 9.0625,14.4 C8.06148389,14.4 7.25,15.2058875 7.25,16.2 C7.25,17.1941125 8.06148389,18 9.0625,18 Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(.75)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g fillOpacity=".9" mask="url(#b)">
          <rect
            width="24"
            height="24"
            fill="#000"
            transform="translate(-6.75 -3)"
          />
        </g>
      </g>
    </svg>
  );
};

export default DragAndDropBullets;

import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    searchTextField: {
      height: "32px",
      color: palette.secondary.main,
      "&.Mui-focused fieldset.tlp-MuiOutlinedInput-notchedOutline": {
        borderColor: palette.secondary.main,
        borderWidth: "1px",
      },
      "& fieldset": {
        borderColor: palette.secondary.main,
        borderRight: 0,
      },
    },
    searchIconColor: {
      color: palette.secondary.main,
    },
    searchInputContainer: {
      display: "flex",
      justifyContent: "space-between",
      height: "32px",
      width: "270px",
      marginRight: "32px",
    },
    searchIconContainer: {
      border: `1px solid ${palette.secondary.main}`,
      borderRadius: "0px 3px 3px 0px",
      padding: "2px 10px 0 10px",
    },
    searchClearContainer: {
      fontSize: "12px",
      width: "150px",
      border: `1px solid ${palette.secondary.main}`,
      borderRight: "none",
      borderLeft: "none",
      textDecoration: "underline",
      fontWeight: "bold",
      paddingRight: "3px",
      color: "#505050",
      maxWidth: "165px",
      whiteSpace: "nowrap",
    },
  }),
);

import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#FAFAFA",
    },
    beeloadingContainer: {
      fontFamily: "Work Sans",
      fontWeight: 500,
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      height: 138,
      background: "#ffffff",
      borderRadius: "16px",
      padding: "1.75rem",
      "&.beesgrow-MuiPaper-elevation3": {
        boxShadow: "2px 2px 10px 0px rgba(0,0,0,0.1)",
      },
    },
    content: {
      height: "100%",
      width: "70%",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      paddingTop: "4rem",
      paddingBottom: "2rem",
    },
    missionsAndSpecializationContainer: {
      marginTop: "1.75rem",
    },
  }),
);

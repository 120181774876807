import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      justifyContent: "space-between",
      padding: "25px 20px 26px 20px",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    NewSideBarMenuTitleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      marginLeft: 10,
      fontWeight: 500,
      fontSize: "18px",
      letterSpacing: 0,
      color: theme.palette.primary.dark,
      textTransform: "uppercase",
      opacity: 1,
    },
    inputButton: {
      display: "flex",
    },

    NewSideBarMenuInputButton: {
      display: "flex",
      alignItems: "center",
    },
  }),
);

export default styles;

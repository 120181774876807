import React from "react";

const AddIcon: React.FC = () => {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
      <title>Group 2</title>
      <g
        id="Admin"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="SPRINT-61-Admin---Portal-SEARCH"
          transform="translate(-1273.000000, -259.000000)"
          fill="#505050"
        >
          <g id="02" transform="translate(235.000000, 231.000000)">
            <g id="Group-2" transform="translate(1038.000000, 28.000000)">
              <rect id="Rectangle" x="7" y="0" width="2" height="16" rx="1" />
              <path
                d="M8,0 C8.55228475,-1.01453063e-16 9,0.44771525 9,1 L9,15 C9,15.5522847 8.55228475,16 8,16 C7.44771525,16 7,15.5522847 7,15 L7,1 C7,0.44771525 7.44771525,1.01453063e-16 8,0 Z"
                id="Rectangle-Copy-2"
                transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AddIcon;

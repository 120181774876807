import { createEffect } from "effector";
import * as CreateCampaignB2OUseCase from "../../usecase/campaigns/CreateCampaignB2OUseCase";
import { CreateCampaignB2ORequest } from "../../services/campaigns/domains/CreateCampaignRequest";

interface CampaignWithCountry {
  country: string;
  vendorId: string;
  vendorName: string;
  campaign: CreateCampaignB2ORequest;
  isCampaignFocusVendorEnabled?: boolean;
}

export const createCampaignB2OEffect = createEffect({
  async handler(createCampaign: CampaignWithCountry) {
    const {
      country,
      vendorId,
      vendorName,
      campaign,
      isCampaignFocusVendorEnabled,
    } = createCampaign;
    return CreateCampaignB2OUseCase.createCampaign(
      country,
      vendorId,
      vendorName,
      campaign,
      isCampaignFocusVendorEnabled,
    );
  },
});

/* eslint-disable camelcase */
import axios, {
  AxiosRequestConfig,
  AxiosError,
  AxiosStatic,
  AxiosResponse,
} from "axios";
import { v4 as guid } from "uuid";
import {
  useLogService,
  useAuthenticationService,
} from "admin-portal-shared-services";
import {
  getTokenLocalStorage,
  getEuropeClusterOrUrlParam,
  getUserInfos,
} from "@config/utils/functions";
import {
  LOCAL_STORAGE_REFRESH_TOKEN,
  LOCAL_STORAGE_AZURE_AUTH,
} from "@config/constants";
import { UNAUTHORIZED } from "../errorCodes";
import { logout } from "../logout/logout";

const axiosInstance = axios.create();

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  retry?: boolean;
  "axios-retry"?: {
    retryCount: number;
  };
}

const axiosIntercept = (): void => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getTokenLocalStorage();

      const { country, orgId } = getUserInfos();

      config.headers.Authorization = token;
      config.headers.requestTraceId = guid();
      config.headers.country = country;

      if (orgId) {
        config.headers.orgId = orgId;
      }

      if (config.url) {
        config.url = getEuropeClusterOrUrlParam(config.url);
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      const log = useLogService();
      const originalRequest: CustomAxiosRequestConfig = error?.config || {};

      const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
      const azureAuth = localStorage.getItem(LOCAL_STORAGE_AZURE_AUTH);

      if (
        error.response &&
        error.response.status === 401 &&
        originalRequest?.url &&
        originalRequest.url.includes("/refresh")
      ) {
        window.location.href = window.location.origin;
        return Promise.reject(error);
      }

      if (isRefreshToken(error, originalRequest)) {
        originalRequest.retry = true;

        if (!azureAuth || !refreshToken) {
          logout();
          return Promise.reject(error);
        }

        callRefreshToken(refreshToken)
          .then(() => axios(originalRequest))
          .catch((err) => log.error(err));
      }

      return Promise.reject(error);
    },
  );
};

const callRefreshToken = (token: string): Promise<AxiosStatic> => {
  const authenticationService = useAuthenticationService();

  return (
    axios
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .post<any, AxiosResponse>("/auth/token/refresh", {
        refreshToken: token,
      })
      .then((res) => {
        if (res.status === 201 && res.data) {
          const { access_token, token_type, expires_in, refresh_token } =
            res.data;

          if (access_token && token_type && expires_in && refresh_token) {
            authenticationService.setAuthHeader(
              `${token_type} ${access_token}`,
            );

            localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, token);
          }
        }

        return res;
      })
      .catch((err) => err)
  );
};

const isRefreshToken = (
  error: AxiosError,
  originalRequest: CustomAxiosRequestConfig,
) => {
  if (error.response?.status === UNAUTHORIZED) {
    return !originalRequest?.retry;
  }

  return false;
};

export { axiosIntercept };
export default axiosInstance;

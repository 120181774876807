import MessageMap from "../domains/MessageMap";

export const translationES: MessageMap = {
  FORMAT: {
    formattedDate: "{{date, DD/MM/YYYY}}",
    FORMAT_DATE_SHORT: "DD/MM/YYYY",
    FORMAT_DATE_SHORT_SEGMENT: "D/M/yyyy",
    FORMAT_DATE_SHORT_FILE: "D-MM-yyyy",
    FORMAT_DATE_LONG_FILE: "DD-MM-YYYY",
    DATE_FORMAT_TABLE_RENDER: "{{date, DD MMMM, YYYY}}",
    DATE_FORMAT_TABLE: "DD MMMM, YYYY",
  },
  ADMIN: {
    TITLE: "Rewards - Admin",
    PANEL_HEADER: "Admin Portal",
    LOADING: "Cargando...",
    VIEW: "Vista",
    EDIT: "Editar",
    REMOVE: "Eliminar",
    DELETE: "Borrar",
    DUPLICATE: "Duplicado",
    REVIEW_ID: "Revisar IDs",
    ACTIONS: "Comportamiento",
    NAME: "Nombre",
    SKU: "Sku",
    SKU_ID: "SKU ID",
    SKU_ID_PLACEHOLDER: "Escriba el ID de SKU aquí...",
    QUANTITY: "Cantidad",
    IMAGE_SUPPORTED_MAX_1MB:
      "Se admiten las extensiones de archivo PNG y JPG. El tamaño máximo de archivo es de 1 MB.",
    TABLE_PAGINATION_INITIAL_COUNT:
      "Mostrando {{showingCount}} de {{totalRows}",
    TABLE_PAGINATION_COUNT:
      "Mostrando {{showingCount}} a {{toCount}} de {{totalRows}}",
    SIGN_OUT: "Desconectar",
    LOGOUT: "Cerrar sesión",
    LOGOUT_DIALOG:
      "¿Realmente desea cerrar sesión en el PORTAL DE ADMINISTRACIÓN DE BEES GROW?",
    STEP_1: "Step 1",
  },
  LOADING: {
    BEELOADING_TEXT: "Procesando…",
  },
  BUTTON: {
    UPDATE: "Actualizar",
    CONTINUE: "Continuar",
    CANCEL: "Cancelar",
    CLOSE: "Cerca",
    CONFIRM: "Confirmar",
    SAVE: "Ahorrar",
    SAVE_DRAFT: "Guardar borrador",
    SUBMIT: "Entregar",
    BACK: "Atrás",
    BACK_STEP: "Volver al paso anterior",
    REMOVE: "Eliminar",
    DELETE: "Borrar",
    ADD_FILTER: "Agregar un filtro",
    CREATE_ANOTHER_CHALLENGE: "CREA OTRO RETO",
    UPLOAD_CSV: "Subir CSV",
    UPLOAD_CSV_FILE: "Subir un archivo CSV",
    YES_SIGN_OUT: "Sí, cerrar sesión",
    DOWNLOAD_CSV: "Descargar CSV",
    CREATE_NEW: "Crear nuevo",
  },
  ERROR_MESSAGE: {
    DUPLICATED_SKU: "Algunos SKU y tag de campaña están duplicados.",
    EMPTY_FIELD: "Por favor introduzca un valor.",
    INVALID_DATE: "Fecha invalida.",
    INVALID_START_DATE: "No puedes crear desafíos en el pasado.",
    INVALID_END_DATE:
      "La fecha de finalización debe ser posterior a la fecha de inicio.",
    INVALID_URL: "Por favor introduzca un URL válido.",
    INVALID_NUMBER_VALUE: "Valor numérico no válido. El número mínimo es 0.",
    MAX_GREATER_THAN_0:
      "La cantidad máxima debe ser un número entero mayor que 0.",
    MAX_SIZE_GROUP_FILE_UPLOAD: "El tamaño máximo de archivo es de 1 MB.",
    MAX_SIZE_5MB: "El tamaño máximo de archivo es de 5 MB.",
    UNSUPPORTED_FILE:
      "Este es un archivo inválido, inténtelo de nuevo siguiendo las instrucciones anteriores.",
    UNSUPPORTED_IMAGE: "Extensiones de archivo admitidas: .jpg; .png",
    UNSUPPORTED_CSV: "Extensión de archivo admitida: .csv",
    UNSUPPORTED_CSV_REWARDS_OFFERS_MULTIPLE:
      "Sube un .csv con un máximo de 5000 líneas y columnas poc_id, Campaign_id, puntos. Columnas separadas por punto y coma, Campaign_id es opcional.",
    EMPTY_FILE_FIELD: "Seleccione un archivo para cargar.",
    UPSERT_ACCOUNT_GROUP:
      "Hubo un error al crear el grupo, inténtalo de nuevo.",
    UPLOAD_FILE_ACCOUNT_GROUP:
      'Hubo un problema con su archivo. Cargue un archivo .csv con una lista de ID de POC en el encabezado de columna "POC_ID"',
    NO_CHALLENGE_FOUND_BY_ID: "La identificación del desafío no es válida.",
    CHALLENGE_FOUND_BY_ID:
      "La identificación del desafío proporcionada ya existe, proporcione una identificación diferente",
    NO_SKU_FOUND_BY_ID: "Por favor ingrese un SKU válido.",
    NO_RULE_FOUND_BY_ID: "El ID de la regla no es válido.",
    SKU_QUANTITY_INVALID: "La cantidad debe ser un número entero mayor que 0.",
    NO_DUPLICATED_SKU: "Este SKU ya está incluido.",
    GENERIC_ERROR: "Algo salió mal. Por favor, inténtelo de nuevo más tarde.",
    PAGE_LEVEL_ERROR_DRAFT_MESSAGE:
      "Para guardar este borrador, complete los campos obligatorios en ",
    PAGE_LEVEL_ERROR_CHALLENGE_MESSAGE:
      "To submit this challenge, please fill all fields in ",
    PAGE_LEVEL_ERROR_CHALLENGE_MESSAGE_CONTINUE:
      "To continue, please fill all fields in ",
    INVALID_ORDER_ID: "Invalid Order ID",
    NO_REFUNDABLE_FOUND: "No refundable combos were found",
    CHALLENGE_SUBMIT_FAIL: "The challenge was not published. Please try again.",
    INVALID_POC_ID: "This POC is not enrolled in the Rewards Program",
    INVALID_POINTS_VALUE: "Points Value must be a whole number greater than 0.",

    GENERIC_LOAD_ERROR: "Algo salió mal.",
    GENERIC_TRY_AGAIN: "Volver a intentar",
  },
  RULES_FLOW: {
    START: "Comienzo",
    END: "Fin",
    SKUS_LIST_ADD_SKU_BUTTON: "+ ADD SKU",
    SKUS_LIST_SEARCH_SKUS: "Buscar SKU",
    SKUS_LIST_COLUMN_SKU: "SKU",
    SKUS_LIST_COLUMN_SKU_NAME: "SKU Nombre",
    SKUS_LIST_ACTION_CHANGE: "Cambiar regla",
    SKUS_LIST_ACTION_REMOVE: "Eliminar",
    SKUS_LIST_EMPTY_MESSAGE: "No se han añadido artículos.",
    REMOVE_DIALOG_TITLE: "Quitar SKU",

    VALIDATION_SKU_INVALID: "Por favor ingrese un SKU válido",
    OPTIONAL_FIELD: "(Opcional)",
    EMPTY_DATA_TABLE: "No hay artículos disponibles para mostrar",
  },
  CAMPAIGNS: {
    CAMPAIGNS_TITLE: "Campañas",
    DDC: "DDC",
    SEGMENT: "Segmento",
    NOT_FOUND: "No hay campañas para mostrar.",
  },
  PRODUCTS: {
    NO_DATA_AVAILABLE: "Datos no disponibles",
    UNIT_PRICE: "Precio (unit)",
    NOT_FOUND: "No hay artículos para mostrar.",
  },
  ErrorHandlerRetry: {
    INFORMATION_NOT_FOUND: "Información no encontrada",
    RELOAD_BUTTON_LABEL: "Recargar lista",
  },
  MODAL_CAMPAIGNS: {
    CREATE_BUTTON: "Crear",
    EDIT_BUTTON: "Confirmar",
    CANCEL_BUTTON: "Cancelar",
    TITLE_MODAL_NEW_CAMPAIGN: "Nueva campaña",
    TITLE_MODAL_EDIT_CAMPAIGN: "Editar campaña",
    CAMPAIGN_NAME: "Nombre de campaña",
    CAMPAIGN_TAG: "Nombre de etiqueta",
    SKU_ID: "SKU ID",
    DDC_OPTIONAL: "DDC (Opcional)",
    SEGMENT_OPTIONAL: "Segmento (Opcional)",
    CAMPAIGN_NAME_PLACEHOLDER: "Escriba el nombre de la campaña aquí...",
    CAMPAIGN_DESCRIPTION: "Descripción de la campaña",
    DESCRIPTION: "Descripción",
    CAMPAIGN_SCRIPT: "Guión de campaña",
    CAMPAIGN_DESCRIPTION_PLACEHOLDER: "Escriba la descripción aquí...",
    CAMPAIGN_SCRIPT_PLACEHOLDER: "Escriba el guión aquí...",
    TAG_PLACEHOLDER: "Escriba el nombre de la etiqueta aquí...",
    DDC_PLACEHOLDER: "Escriba el código DDC aquí...",
    SEGMENT_PLACEHOLDER: "Escriba el código de segmento aquí...",
    ERROR_HANDLER: "Algo salió mal. Por favor, inténtelo de nuevo más tarde.",
    ERROR_LIMIT_CSV: "El campo csv excede su tamaño máximo permitido de 1MB.",
    MESSAGE_SUCCESS: "La campaña fue creada con éxito.",
    MESSAGE_SUCCESS_UPDATE: "La campaña fue editada con éxito.",
    MESSAGE_SUCCESS_DELETE: "La campaña fue eliminada con éxito.",
    MSG_MANDATORY_CAMPAIGN_NAME: "Este campo es obligatorio.",
    MSG_MANDATORY_CAMPAIGN_TAG: "Este campo es obligatorio.",
    MSG_MANDATORY_CAMPAIGN_DESCRIPTION: "Este campo es obligatorio.",
    MSG_MANDATORY_CAMPAIGN_SCRIPT: "Este campo es obligatorio.",
    MSG_MAX_SIZE_CAMPAIGN_TAG:
      "La oración es demasiado larga. Por favor, no exceda los 30 caracteres.",
    MSG_NO_ACCEPT_SPACES_CAMPAIGN_TAG:
      "Este campo no acepta espacios. Por favor escriba sin espacios.",
    MSG_START_WITH_PLACEHOLDER_CAMPAIGN_TAG:
      'Inserte "#" antes del nombre de la etiqueta',
    MSG_AT_LEAST_ONE_CAMPAIGN_SKU: "Este campo es obligatorio.",
    START_DATE: "Fecha de inicio",
    END_DATE: "Fecha final",
    CSV_LIST: "Subir ID de POC",
    COVER_IMAGE: "Imagen de portada (670x90)",
    DRAG_DROP_CSV: "Arrastre y suelte un archivo de lista CSV",
    DRAG_DROP_IMAGE: "Arrastra y suelta una imagen .png o .jpg",
    CSV_FILE_REQUIRED: "Este campo es obligatorio.",
    MSG_END_DATE_BEFORE_START_DATE:
      "La fecha de finalización no puede ser anterior o igual a la fecha de inicio.",
    SEGMENTATION: "Especialización de agentes",
    SEGMENTATION_PLACEHOLDER: "ex: 387",
    PRIMARY: "Misión principal",
    SECONDARY: "Misión secundaria",
    PRIMARY_TOOLTIP:
      "Los puntos de contacto de la campaña serán seleccionados para el contacto con la campaña B2O como misión principal.",
    SECONDARY_TOOLTIP:
      "Los puntos de contacto de la campaña serán seleccionados para el contacto con la campaña B2O como misión principal.",
    AGENT_SPECIALIZATION_TOOLTIP:
      "Defina una segmentación para identificar agentes específicos que trabajarán en esta campaña.",
    DOWNLOAD_FILE: "Descargar aquí archivo ejemplo",
  },
  DELETE_CAMPAIGNS: {
    DELETE_CAMPAIGN: "Eliminar campaña",
    DELETE_CAMPAIGN_DESCRIPTION: "¿Realmente desea eliminar esta campaña??",
    DELETE: "Eliminar",
  },
  OPTIONS: {
    EDIT: "Editar",
    DELETE: "Eliminar",
  },
  SEARCH_OPTIONS: {
    TOTAL_CAMPAIGN_NAMES: "resultados en nombre de campaña",
    TOTAL_CAMPAIGN_NAME: "resultado en nombre de campaña",
    TOTAL_DELIVERY_CENTER_IDS: "resultados en DDC",
    TOTAL_DELIVERY_CENTER_ID: "resultado en DDC",
    TOTAL_SEGMENTS: "resultados en segmento",
    TOTAL_SEGMENT: "resultado en segmento",
    TOTAL_SKUS: "resultados en SKU",
    TOTAL_SKU: "resultado en SKU",
    NO_CAMPAIGN: "No hay campañas para mostrar.",
  },
  AGENT_SEGMENTATION: {
    CONFIRM_SEGMENTATION: "Confirmación",
    DESCRIPTION_CONFIRM_SEGMENTATION:
      "¿Está seguro de que desea actualizar la segmentación de agentes?",
    MODAL_TITLE: "Configuración del agente",
    SEGMENTATION: "Segmentación",
    AGENT: "Agente",
    CONFIRM_BUTTON: "Confirmar",
    PLACEHOLDER_SEGMENTATION: "Seleccione o escriba el nombre del agente",
    UPDATE_MESSAGE: "Seleccione o escriba el nombre del agente.",
    ERROR_HANDLER: "Algo salió mal. Por favor, inténtelo de nuevo más tarde.",

    AGENT_SETUP: "Configuración del agente",
    AGENT_SETUP_SUBTITLE:
      "Es posible definir especializaciones para un agente.",
    AGENT_SETUP_PLACEHOLDER: "Seleccione un agente",
    MISSIONS_MANAGEMENT_TITLE: "Administración de misiones",
    SPECIALIZATION_TITLE: "Especialización",
    INSERT_SPECIALIZATION_PLACEHOLDER: "Insertar especialización",
    INSERT_SPECIALIZATION_ADD_BUTTON: "Añadir",
    SAVE_CHANGES: "Los cambios han sido aplicados exitosamente.",
    SAVE_ERROR:
      "Algo salió mal. Los cambios no se guardaron, intenta aplicarlos de nuevo.",
    MISSIONS_AND_SPECIALIZATION_APPLY_CHANGES: "Aplicar cambios",
    NO_AGENT_FOUND: "Ningún agente encontrado",
    ERROR_TO_LOAD: "No fue posible cargar la configuración del agente",

    APPLY_CHANGES_MODAL_TITLE: "¿Seguro que deseas aplicar los cambios?",
    APPLY_CHANGES_MODAL_DESCRIPTION:
      "La activación de tus cambios tomará algunos minutos.",
    APPLY_CHANGES_CONFIRM: "Sí, aplicar",
    APPLY_CHANGES_CANCEL: "No, cancelar",
  },
  GLOBAL_MESSAGE: {
    NO_DATA_AVAILABLE: "Data no disponible",
  },
  TYPE: {
    B2O: "B2O",
    FOCUS_SKU: "Focus SKU",
  },
  POC: {
    POC_WITH_ERROR: "POCs con errores ",
    CONTACTED: "Contactado ",
    TO_BE_CONTACTED: "Para ser contactado ",
  },
  BLOCK_LIST: {
    MODAL_TITLE: "Block list",
    CSV_TITLE: "Lista CSV ",
    CONFIRM_BUTTON: "Confirmar",
    DESCRIPTION: "¿Está seguro de que desea actualizar la block list?",
    UPDATE_MESSAGE:
      "La lista de bloqueos se actualizó exitosamente y se hará efectiva mañana.",
    DELETE_MESSAGE:
      "La lista de bloqueo se eliminó exitosamente. Este cambio se hará efectivo mañana.",
    ERROR_HANDLER: "Algo salió mal, la block list no se actualizó.",
    NO_CSV:
      "La lista de bloqueos no se pudo actualizar. Por favor, vuelve a intentar.",
    CURRENT_BLOCK_LIST: "La lista actual de bloqueos no se pudo recuperar.",
    LOADING: "Cargando la block list",
    DRAG_DROP_CSV: "Arrastre y suelte un archivo de lista CSV",
    CURRENT_CSV: "Descargar la block list actual",
    DELETE_DESCRIPTION: "¿Está seguro de que desea eliminar la block list?",
    REFRESH_PAGE: "Actualizar página",
  },
  MANAGEMENT: {
    TITLE: "Management",
  },
  MISSIONS_MANAGEMENT: {
    TITLE: "Administración de misiones",
    PROMOTE_REWARDS_CHALLENGE_MISSION_TITLE: "Desafíos",
    SPONSORED_MISSION_TITLE: "Patrocinadas",
    SERVICE_MISSION_TITLE: "Servicios",
    SERVICE_MISSION_TOOLTIP: "Esta misión requiere exclusividad del agente",
    LOCAL_MISSIONS_TITLE: "Misiones locales",
  },
  LOCAL_MISSIONS: {
    TITLE: "Misiones locales",
  },
  TEAM_STRUCTURE: {
    TITLE: "Estructura del equipo",
  },
  GLOBAL_MISSIONS: {
    TITLE: "Misiones globales",
  },
  TEAM_MANAGEMENT: {
    TITLE: "Equipos",
  },
};

export interface Mission {
  id: string;
  missionId?: string;
  country: string;
  order: number;
  enabled: boolean;
  mission: string;
  type: MISSION_TYPES;
}

export type PrimaryMissionsType = {
  enabled: Mission[];
  disabled: Mission[];
};

export enum MISSION_TYPES {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  GLOBAL = "GLOBAL",
  LOCAL = "LOCAL",
}

export enum MISSION_STATUSES {
  SCHEDULED = "SCHEDULED",
  ONGOING = "ONGOING",
  PAUSED = "PAUSED",
  CONCLUDED = "CONCLUDED",
  EXPIRED = "EXPIRED",
}

export interface DefaultMission {
  missionId: string;
  mission: string;
  type: MISSION_TYPES;
  vendorId: string;
  vendorName: string;
  enabled: boolean;
  country: string;
  order: number;
  missionName: undefined;
  status: undefined;
}

export interface FlexibleMission {
  missionId: string;
  mission: string;
  type: MISSION_TYPES;
  vendorId: string;
  vendorName: string;
  missionName: string;
  category: string[];
  segmentationId: string;
  startDate: string;
  endDate: string;
  description: string;
  traitsToInclude: Trait[];
  traitsToExclude: Trait[];
  audit: Audit;
  enabled: boolean;
  status: MISSION_STATUSES;
  country: string;
  deliveryWindow: boolean;
  script: string;
  effectiveness: Effectiveness;
  order: number;
}

interface Trait {
  code: string;
  operator: string;
  value: string;
  order: number;
}

interface Audit {
  fullName: string;
  email: string;
  createDate: string;
}

interface Effectiveness {
  type: string;
  value: string;
  timeWindow: string;
  quantityOfDays: number;
}

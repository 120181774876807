import React, { useEffect } from "react";

import {
  CssBaseline,
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
} from "@material-ui/core";
import {
  generateIncrementalCounter,
  useAnalyticsService,
  useFeatureToggleService,
} from "admin-portal-shared-services";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { InitialDataWrapper } from "grow-shared-services";

import { WRAPPER_PROJECT_NAME_INSTANCE } from "@config/constants";
import theme from "../theme";
import AppRouter from "./AppRouter";
import IntlProvider from "./providers/IntlProvider";
import "../i18n/i18n";

interface AppProps {
  TLP_SEGMENT_KEY: string;
  ONCUSTOMER_OPTIMIZELY_KEY: string;
  europeCountries: string;
}

/* istanbul ignore next */
const counterGenerator = generateIncrementalCounter("beesgrow");

function addEuropeCountriesToLocalStorage(europeCountries?: string): void {
  localStorage.setItem("europeCountries", europeCountries || "");
}

function useSegmentAnalytics(TLP_SEGMENT_KEY: string) {
  const service = useAnalyticsService();

  useEffect(() => {
    service.load(TLP_SEGMENT_KEY);
  }, [TLP_SEGMENT_KEY, service]);
}

function App(props: AppProps): JSX.Element {
  const { TLP_SEGMENT_KEY, ONCUSTOMER_OPTIMIZELY_KEY, europeCountries } = props;

  const generateClassName = createGenerateClassName({
    productionPrefix: "beesgrow",
    seed: "beesgrow",
    customGetNextCounterId: counterGenerator,
  });

  addEuropeCountriesToLocalStorage(europeCountries);
  useSegmentAnalytics(TLP_SEGMENT_KEY);
  useFeatureToggleService().loadToggles();

  return (
    <InitialDataWrapper
      projectName={WRAPPER_PROJECT_NAME_INSTANCE}
      optKey={ONCUSTOMER_OPTIMIZELY_KEY}
    >
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <IntlProvider>
            <StylesProvider generateClassName={generateClassName}>
              <CssBaseline />
              <BrowserRouter>
                <AppRouter />
              </BrowserRouter>
            </StylesProvider>
          </IntlProvider>
        </CookiesProvider>
      </ThemeProvider>
    </InitialDataWrapper>
  );
}

export default App;

import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      background: "#fff",
      boxSizing: "border-box",
      width: "370px",
      height: "auto",
      padding: "32px",
      display: "flex",
      overflowY: "auto",
      flexDirection: "column",
    },
    containerInner: {
      height: "auto",
      maxHeight: "400px",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#505050",
      paddingBottom: "8px",
      borderBottom: "1px solid #9e9e9e",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    label: {
      fontSize: "0.75rem",
      fontWeight: 600,
      color: "#505050",
      display: "flex",
      flexDirection: "column",
    },
    labelText: {
      marginBottom: "8px",
      marginTop: 24,
      display: "flex",
    },
    FirstlabelText: {
      marginBottom: "8px",
      marginTop: 0,
    },
    inputText: {
      reisze: "none",
      fontSize: "0.8rem",
      height: "32px",
      borderRadius: "3px",
      border: `solid 1px ${palette.text.secondary}`,
      padding: "10px",
      outline: "none",
      "&:focus": {
        border: `solid 2px ${palette.common.black}`,
      },
    },
    inputButtonSku: {
      display: "grid",
      padding: "0",
      gridTemplateColumns: "6fr 1fr",
    },
    iconButton: {
      marginLeft: "auto",
      padding: "0",
    },
    buttonsContainer: {
      display: "flex",
      width: "100%",
      padding: "0",
      marginTop: "40px",
    },
    button: {
      height: "40px",
      fontSize: "14px",
      fontWeight: 600,
      display: "flex",
      flex: 1,
      borderRadius: "3px",
      textTransform: "uppercase",
    },
    cancelButton: {
      backgroundColor: "#eaeaea",
      color: "#7a7a7a",
      marginRight: "16px",
    },
    registerButton: {
      backgroundColor: "#505050",
      color: palette.common.white,
      margingLeft: "5px",
    },
    textarea: {
      resize: "none",
      height: "72px",
      borderRadius: "3px",
      border: `solid 1px ${palette.text.secondary}`,
      padding: "10px",
      outline: "none",
      "&:focus": {
        border: `solid 2px ${palette.primary.main}`,
      },
    },
    dialog: {
      padding: "32px",
      margin: 0,
    },
    details: {
      border: `solid 1px ${palette.text.secondary}`,
    },
    detailsError: {
      border: `solid 1px ${palette.error.main}`,
      borderRadius: "3px",
    },
    errorMessage: {
      marginTop: "4px",
      marginBottom: "-24px",
      fontSize: "10px",
      fontFamily: "Roboto",
      fontWeight: "normal",
      color: palette.error.main,
    },
    containerDownloadCsv: {
      display: "flex",
      justifyContent: "flex-end",
    },
    buttonDownload: {
      border: "none",
      backgroundColor: "transparent",
      cursor: "pointer",
      color: "#505050",
      marginTop: "6px",
      textDecoration: "underline",
    },
    radioButton: {
      marginTop: "24px",
      backgroundColor: "#f8f8f8",
      borderRadius: "3px",
      marginRight: "0px",
      marginLeft: "0px",
      paddingRight: "0px",
      paddingLeft: "0px",
    },
    agentSpecializationDisable: {
      pointerEvents: "none",
      opacity: 0.5,
    },
  }),
);

import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    label: {
      fontSize: "0.75rem",
      color: "#505050",
      fontWeight: 600,
      padding: "10px 0",
      alignItems: "center",
      width: "max-content",
      marginRight: "4px",
    },
    span: {
      padding: "4px",
      marginRight: "0px",
    },
    boxPrimary: {
      display: "table",
      width: "100%",
    },
    tooltip: {
      alignItems: "center",
    },
    textLabel: {
      alignItems: "center",
      display: "flex",
    },
  }),
);

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface LoadingProps {
  color: string | undefined;
}

export default makeStyles<Theme, LoadingProps>(() =>
  createStyles({
    loading: {
      color: ({ color }) => color || "#000000",
    },
  }),
);

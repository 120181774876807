import { createStore } from "effector";
import {
  getAllFailedPocsEffect,
  getAllOrInvalidPocsEffectV3,
} from "@stores/campaigns/GetAllFailedPocsEvents";

import { GetAllFailedPocsState } from "./GetAllFailedPocsState";

const invalid = {
  failedPocs: [],
  totalCompleted: 0,
  totalPending: 0,
};

const all = {
  campaignTag: "",
  segmentation: "",
  startDate: "",
  endDate: "",
  accountIds: [],
  failedIds: [],
  campaignType: "",
  classification: "",
  _id: "",
};

const initialState = <GetAllFailedPocsState>{
  pocInformation: {
    invalid: {
      totalCompleted: 0,
      totalPending: 0,
      failedPocs: [],
    },
    loading: false,
    error: false,
    done: false,
  },
  pocInformationV3: {
    invalid,
    all,
    loading: false,
    error: false,
    done: false,
  },
};

const getAllFailedPocsStore = createStore(initialState)
  .on(getAllFailedPocsEffect.done, (state, { result }) => {
    const { pocInformation: resultPocInfo } = result || {};
    const { pocInformation: statePocInfo } = state;

    return {
      ...state,
      pocInformations: {
        ...state.pocInformation,
        invalid:
          resultPocInfo.invalid !== undefined
            ? resultPocInfo?.invalid
            : statePocInfo.invalid,
      },
      loading: false,
      error: false,
      done: true,
    };
  })
  .on(getAllFailedPocsEffect.fail, (state) => {
    return {
      ...state,
      loading: false,
      error: true,
      done: true,
    };
  })
  .on(getAllFailedPocsEffect, (state) => {
    return {
      ...state,
      loading: true,
      error: false,
      done: false,
    };
  })
  .on(getAllOrInvalidPocsEffectV3.done, (state, { result }) => {
    const { pocInformationV3: resultPocInfoV3 } = result;
    const { pocInformationV3: statePocInfoV3 } = state;

    return {
      ...state,
      pocInformationV3: {
        ...statePocInfoV3,
        invalid: resultPocInfoV3?.invalid ?? statePocInfoV3?.invalid,
        all: resultPocInfoV3?.all ?? statePocInfoV3?.all,
      },
      loading: false,
      error: false,
      done: true,
    };
  })
  .on(getAllOrInvalidPocsEffectV3.fail, (state) => {
    return {
      ...state,
      loading: false,
      error: true,
      done: true,
    };
  })
  .on(getAllOrInvalidPocsEffectV3, (state) => {
    return {
      ...state,
      loading: true,
      error: false,
      done: false,
    };
  });

export default getAllFailedPocsStore;

import React, { useState, useEffect } from "react";
import { DropResult } from "react-beautiful-dnd";
import Divider from "@material-ui/core/Divider";
import { setIsAgentUpdated } from "../../../../stores/agents/AgentsEvents";
import { DnDListItem } from "../DragAndDropComponents/Types";
import DraggableList from "../DragAndDropComponents/DraggableList/DraggableList";
import SpecializationInput from "../SpecializationInput/SpecializationInput";
import SpecializationsStyles from "./Specializations.styles";

interface SpecializationsProps {
  specializationsList: Array<DnDListItem>;
  setSegmentations: React.Dispatch<React.SetStateAction<string[]>>;
  handleRemove: (index: number) => void;
}

const Specializations = ({
  specializationsList,
  setSegmentations,
  handleRemove,
}: SpecializationsProps): JSX.Element => {
  const classes = SpecializationsStyles();
  const [draggableItems, setDraggableItems] = useState<Array<DnDListItem>>([]);

  useEffect(() => {
    setDraggableItems(specializationsList);
  }, [specializationsList]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const result = Array.from(draggableItems);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);
    setSegmentations(result.map((each) => each?.title));
    setDraggableItems(result);

    setIsAgentUpdated(true);
  };

  const handleAddSegmentation = (value: string) => {
    /* istanbul ignore next */
    setSegmentations((prevState: Array<string>) => [...prevState, value]);
  };

  return (
    <div className={classes.container}>
      <div className={classes.flexContainer}>
        <DraggableList
          data-testid="draggable-list"
          draggableList={draggableItems}
          onDragEnd={onDragEnd}
          hasLastItem
          handleRemove={handleRemove}
        />

        {draggableItems.length > 0 && (
          <div className={classes.divider}>
            <Divider />
          </div>
        )}

        <SpecializationInput
          handleAddSegmentation={handleAddSegmentation}
          data-testid="specialization-input"
        />
      </div>
    </div>
  );
};

export default React.memo(Specializations);

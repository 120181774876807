import React from "react";
import DisappearedLoadingNew from "./bouncingDotsLoader.styles";

interface Props {
  color?: string;
}

const BouncingDotsLoader = ({ color }: Props): React.ReactElement => {
  const classes = DisappearedLoadingNew();

  return (
    <div className={classes.container} data-testid="BouncingDotsLoader">
      <div className={`${classes.dot}`} style={{ backgroundColor: color }} />
      <div
        className={`${classes.dot} ${classes.dot2}`}
        style={{ backgroundColor: color }}
      />
      <div
        className={` ${classes.dot}  ${classes.dot3}`}
        style={{ backgroundColor: color }}
      />
    </div>
  );
};

BouncingDotsLoader.defaultProps = {
  color: "#000",
};

export default BouncingDotsLoader;

import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    selectOutline: {
      height: "24px",
      "& fieldset": {
        borderColor: "#5e5e5e",
      },
    },
    select: {
      borderRadius: "3px",
      padding: "4px 10px 4px 10px",
      boxSizing: "content-box",
      display: "flex",
      alignItems: "center",
    },
    focusedSelect: {
      "&:focus": {
        backgroundColor: "white",
      },
    },
    errorMessage: {
      fontSize: "10px",
      color: "#e02020",
    },
    selectItem: {
      color: "#525252",
    },
  }),
);

import Dexie from "dexie";
import { Item } from "../../domains/items/CampaignItems";

export type CampaignItemsTable = Dexie.Table<Item>;

class CampaignItemsDb extends Dexie {
  campaignItems: CampaignItemsTable;

  constructor() {
    super("CampaignItemsDb");

    this.version(1).stores({
      campaignItems:
        "campaignAndSku,sku,campaignTag,unitPrice,productName,image,fullContainerDescription,fullPackageDescription",
    });

    this.campaignItems = this.table("campaignItems");
  }
}

export default CampaignItemsDb;

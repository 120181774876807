import React from "react";
import { Switch as SwitchComponent } from "@material-ui/core/";
import SwitchStyles from "./Switch.styles";

interface SwitchProps {
  onChange: () => void;
  checked?: boolean;
}

const Switch = ({ onChange, checked }: SwitchProps): JSX.Element => {
  const classes = SwitchStyles();

  return (
    <SwitchComponent
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
      }}
      onChange={() => onChange()}
      checked={checked}
      data-testid="switch-component"
    />
  );
};

export default Switch;

Switch.defaultProps = {
  checked: true,
};

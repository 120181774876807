import React from "react";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { DnDListItem } from "../Types";
import DraggableListItem from "../DraggableListItem/DraggableListItem";

interface DraggableListProps {
  draggableList: Array<DnDListItem>;
  onDragEnd: OnDragEndResponder;
  hasLastItem: boolean;
  handleRemove?: (index: number) => void;
}

const DraggableList = ({
  draggableList,
  onDragEnd,
  hasLastItem,
  handleRemove,
}: DraggableListProps) => {
  return (
    <DragDropContext onDragEnd={onDragEnd} data-testid="dragNdrop-context">
      <Droppable droppableId="droppable-list" data-testid="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {draggableList?.map((item: DnDListItem, index: number) => {
              return (
                <DraggableListItem
                  listItem={item}
                  index={index}
                  key={item.id}
                  data-testid="draggable-list-item"
                  isLastItem={
                    !!(hasLastItem && draggableList.length - 1 === index)
                  }
                  handleRemove={handleRemove}
                />
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default React.memo(DraggableList);

DraggableList.defaultProps = {
  handleRemove: null,
};

import {
  getAllFailedPocs,
  getAllFailedPocsV3,
} from "../../services/campaigns/GetAllFailedPocsService";
import { PocInformation, PocInformationV3 } from "../../domains/PocInformation";

export const execute = (
  campaignTag: string,
  campaignType: string,
  vendorId?: string,
  vendorIdMulticontract?: string,
  vendorName?: string,
  isCampaignFocusVendorEnabled?: boolean,
): Promise<PocInformation | PocInformationV3> => {
  let vendorIdHeaderParam = "";
  let vendorNameHeaderParam = "";

  if (vendorId && vendorName && isCampaignFocusVendorEnabled) {
    vendorIdHeaderParam = vendorId;
    vendorNameHeaderParam = vendorName;
  }

  const formatedCampaignTag = campaignTag.replace("#", "");

  return getAllFailedPocs(
    formatedCampaignTag,
    campaignType,
    vendorIdHeaderParam,
    vendorNameHeaderParam,
    vendorIdMulticontract,
  );
};

export const executeV3 = (
  campaignTag: string,
  country: string,
  orgId: string,
  requestTraceId: string,
  projection: "INVALID_ACCOUNTS" | "ALL",
): Promise<PocInformationV3 | PocInformation> => {
  const formatedCampaignTag = campaignTag.replace("#", "");

  return getAllFailedPocsV3(
    formatedCampaignTag,
    country,
    orgId,
    requestTraceId,
    projection,
  );
};

import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    applyButton: {
      backgroundColor: "#fff",
      color: "#000",
      border: "solid 1px #000",
      padding: "10px 20px",
      fontFamily: "Work Sans",
      borderRadius: "20px",
    },
    cancelButton: {
      padding: "10px 20px",
      marginRight: 16,
      fontFamily: "Work Sans",
      borderRadius: "20px",
    },
    closeButton: {
      padding: 0,
      height: "32px",
    },
    genericModalContainer: {
      height: "15rem",
      width: "33rem",
      display: "flex",
      flexDirection: "column",
    },

    icon: {},
    modalButtonsContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    modalDescriptionContainer: {
      display: "flex",
      marginTop: "1rem",
      heigth: "100%",
      flex: 1,
    },
    modalDescriptionText: {
      fontWeight: 400,
      fontSize: "1rem",
    },

    modalHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    modalHeaderTitle: {
      width: "65%",
    },
  }),
);

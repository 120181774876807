import React from "react";

function WarningIconToast(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 21 21"
    >
      <defs>
        <filter id="warningSignala" colorInterpolationFilters="auto">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.622339 0 0 0 0 0.361825 0 0 0 0 0.000000 0 0 0 1.000000 0"
          />
        </filter>
        <path
          id="warningSignalb"
          d="M9 0c.824 0 1.589.43 2.018 1.14l6.666 11.127a2.359 2.359 0 01-2.025 3.538H2.332a2.359 2.359 0 01-2.01-3.55l6.661-11.12A2.359 2.359 0 019 0zm0 1.572a.784.784 0 00-.67.375L1.677 13.053a.786.786 0 00.664 1.18h13.31a.786.786 0 00.678-1.17L9.672 1.952A.786.786 0 009 1.572zm.007 9.516a.786.786 0 01.092 1.567l-.1.005a.786.786 0 01-.091-1.567l.1-.005zM9 4.798c.403 0 .735.303.78.694l.006.092V8.73a.786.786 0 01-1.567.092l-.006-.092V5.584c0-.434.352-.786.787-.786z"
        />
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        filter="url(#warningSignala)"
        transform="translate(-10 -7)"
      >
        <g transform="translate(13 11.125)">
          <mask id="warningSignalc" fill="#fff">
            <use xlinkHref="#warningSignalb" />
          </mask>
          <use fill="#000" fillRule="nonzero" xlinkHref="#warningSignalb" />
          <g fill="#9f5c00" mask="url(#warningSignalc)">
            <path d="M-3-4.125h24v24H-3z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(WarningIconToast);

/* eslint-disable react/require-default-props */
import React, { useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import useStyles from "./styles";
import FocusSkuIcon from "../../../../assets/icons/FocusSkuIcon";
import ButtonCreateNewFocusSku from "./focusSkuCreateNewButton/FocusSkuCreateNewButton";
import SearchBar from "./searchCampaign/SearchBar";
import { formatMessage } from "../../../../i18n/formatters";

interface Props {
  searchFunction: (value: string) => void;
  handleItemsOnChoose: (value: string) => void;
  totalCampaignNames?: number;
  totalDeliveryCenterIds?: number;
  totalSegments?: number;
  totalSkus?: number;
  loadingSearch: boolean;
}

const FocusSkuHeader: React.FC<Props> = ({
  searchFunction,
  totalCampaignNames,
  totalDeliveryCenterIds,
  totalSegments,
  totalSkus,
  handleItemsOnChoose,
  loadingSearch,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState("");

  return (
    <Grid container alignItems="center" className={classes.headerContainer}>
      <Box
        data-testid="focus-sku-title"
        className={classes.NewSideBarMenuTitleContainer}
      >
        <FocusSkuIcon />
        <Typography variant="subtitle1" classes={{ root: classes.title }}>
          {formatMessage({ id: "CAMPAIGNS.CAMPAIGNS_TITLE" })}
        </Typography>
      </Box>

      <Box className={classes.NewSideBarMenuInputButton}>
        <SearchBar
          value={value}
          setValue={setValue}
          onSearch={searchFunction}
          testId="sku-search-bar"
          handleItemsOnChoose={handleItemsOnChoose}
          totalCampaignNames={totalCampaignNames}
          totalDeliveryCenterIds={totalDeliveryCenterIds}
          totalSegments={totalSegments}
          totalSkus={totalSkus}
          loadingSearch={loadingSearch}
        />

        <ButtonCreateNewFocusSku />
      </Box>
    </Grid>
  );
};

export default FocusSkuHeader;

import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { isFeatureEnabled } from "grow-shared-services";

import axios from "../../config/axios/axiosInstance";
import { TLP_MULTICONTRACT_ONCUSTOMER_B2C_USERS } from "../../config/featureToggles";
import { ONCUSTOMER_B2C_USERS } from "../../config/services";
import { getEndPoint, getApiHost } from "../host/HostService";
import UpdateAgentRequest from "./domains/UpdateAgentRequest";

export const B2C_ENDPOINT = "/users/admin";
export const B2C_V2_ENDPOINT = "/users/v2/admin";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function updateAgent(
  id: string,
  country: string,
  request: UpdateAgentRequest,
  vendorId?: string,
): Promise<void> {
  const log = useLogService();

  const isMulticontractOncustomerEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_ONCUSTOMER_B2C_USERS,
  );

  const endpointPath = isMulticontractOncustomerEnable
    ? `${B2C_V2_ENDPOINT}/${id}?vendorId=${vendorId ?? ""}`
    : `${B2C_ENDPOINT}/${id}`;

  const url = `${getApiHost()}${getEndPoint(
    ONCUSTOMER_B2C_USERS,
  )}${endpointPath}`;

  return axios
    .put(url, request, {
      headers: {
        country,
      },
    })
    .then(() => {
      log.info("Agent updated Successfully");
    })
    .catch((error) => {
      log.error(error?.response);
      throw error?.response;
    });
}

import { makeStyles, createStyles } from "@material-ui/core/styles";

const styles = makeStyles(() =>
  createStyles({
    tag: {
      marginRight: "0px",
      height: "18px",
      fontSize: "12px",
      color: "#ffffff",
      fontWeight: 500,
      backgroundColor: "#7a7a7a",
    },
  }),
);

export default styles;

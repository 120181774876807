/* eslint-disable react/require-default-props */
import React from "react";
import { Popover, ButtonBase, Typography } from "@material-ui/core";
import SearchOptionsStyle from "./SearchOptionsStyle";
import LoadingView from "../../../../components/loadingView/LoadingView";
import { formatMessage } from "../../../../i18n/formatters";

interface OptionsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  anchorEl?: EventTarget & HTMLInputElement;
  handleItemsOnChoose: (value: string) => void;
  totalCampaignNames?: number;
  totalDeliveryCenterIds?: number;
  totalSegments?: number;
  totalSkus?: number;
  loadingSearch: boolean;
}

enum ITEM_TYPES {
  DDC = "DDC",
  SKUS = "SKUS",
  CAMPAIGN_NAME = "CAMPAIGN_NAME",
  SEGMENT = "SEGMENT",
}

const SearchOptions: React.FC<OptionsProps> = ({
  open,
  setOpen,
  anchorEl,
  totalCampaignNames,
  totalDeliveryCenterIds,
  totalSegments,
  totalSkus,
  loadingSearch,
  handleItemsOnChoose,
}) => {
  const classes = SearchOptionsStyle();

  const getOption = (
    onlyOne: string,
    moreThanOne: string,
    type: string,
    total?: number,
  ) => {
    if (!total || total <= 0) return undefined;

    return (
      <ButtonBase
        className={classes.optionBtn}
        id={`${type}-option-button`}
        onClick={() => {
          handleItemsOnChoose(type);
          setOpen(false);
        }}
      >
        <b>{total}</b>&nbsp;
        {` ${
          total > 1
            ? formatMessage({ id: `SEARCH_OPTIONS.${moreThanOne}` })
            : formatMessage({ id: `SEARCH_OPTIONS.${onlyOne}` })
        }`}
      </ButtonBase>
    );
  };

  const renderAllSearchOptions = () => {
    if (
      !totalCampaignNames &&
      !totalDeliveryCenterIds &&
      !totalSegments &&
      !totalSkus
    ) {
      return (
        <div className={classes.container}>
          <Typography style={{ fontSize: 12, fontWeight: 400 }}>
            {formatMessage({ id: "SEARCH_OPTIONS.NO_CAMPAIGN" })}
          </Typography>
        </div>
      );
    }
    return (
      <>
        {getOption(
          "TOTAL_CAMPAIGN_NAME",
          "TOTAL_CAMPAIGN_NAMES",
          ITEM_TYPES.CAMPAIGN_NAME,
          totalCampaignNames,
        )}
        {getOption(
          "TOTAL_DELIVERY_CENTER_ID",
          "TOTAL_DELIVERY_CENTER_IDS",
          ITEM_TYPES.DDC,
          totalDeliveryCenterIds,
        )}
        {getOption(
          "TOTAL_SEGMENT",
          "TOTAL_SEGMENTS",
          ITEM_TYPES.SEGMENT,
          totalSegments,
        )}
        {getOption("TOTAL_SKU", "TOTAL_SKUS", ITEM_TYPES.SKUS, totalSkus)}
      </>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      className={classes.popover}
      data-testid="focus-sku-search-options"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleClose}
    >
      {loadingSearch ? (
        <div className={classes.container}>
          <LoadingView />
        </div>
      ) : (
        renderAllSearchOptions()
      )}
    </Popover>
  );
};

export default SearchOptions;

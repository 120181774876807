import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      flexDirection: "column",
    },
    loadingContainer: {
      width: 67,
      height: 42,
      display: "flex",
      alignItems: "baseline",
    },
    loadingText: {
      marginTop: 24.84,
      height: 20,
      width: 265,
      color: "rgba(0,0,0,0.9)",
      fontFamily: "Barlow",
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "20px",
      textAlign: "center",
    },
  }),
);

import React, { useState } from "react";
import { IconButton, ButtonBase } from "@material-ui/core";

import { usePagination } from "@material-ui/lab/Pagination";
import DoubleArrowRight from "../../../../assets/icons/DoubleArrowRight";
import DoubleArrowLeft from "../../../../assets/icons/DoubleArrowLeft";
import SingleArrowLeft from "../../../../assets/icons/SingleArrowLeft";
import SingleArrowRight from "../../../../assets/icons/SingleArrowRight";
import useStyles from "./styles";

interface props {
  totalPages: number;
  onChange: (value: number) => void;
}

const Pagination: React.FC<props> = ({ totalPages, onChange }) => {
  const classes = useStyles();
  const [pageValue, setPage] = useState(1);
  const { items } = usePagination({
    count: totalPages,
    page: pageValue,
    showFirstButton: false,
    showLastButton: false,
    boundaryCount: 1,
    siblingCount: 1,
  });
  const handleClick = (page: number) => {
    onChange(page);
    setPage(page);
  };

  const isFirstOrLastPageOnEllipsis = (index: number) => {
    return (
      items[index + 1]?.type === "start-ellipsis" ||
      items[index - 1]?.type === "end-ellipsis"
    );
  };

  const isEllipsis = (type: string) => {
    return type === "start-ellipsis" || type === "end-ellipsis";
  };

  return (
    <nav className={classes.NewSideBarMenuNav}>
      <ul className={classes.NewSideBarMenuUl}>
        {pageValue !== 1 && (
          <li>
            <IconButton
              data-testid="focus-sku-firstpage-btn"
              onClick={() => {
                handleClick(1);
              }}
            >
              <DoubleArrowLeft />
            </IconButton>
          </li>
        )}

        {items.map(({ page, type, selected, disabled, onClick }, index) => {
          let children = null;
          if (!disabled) {
            /* istanbul ignore next */
            if (isFirstOrLastPageOnEllipsis(index) || isEllipsis(type)) {
              return null;
            }
            if (type === "page") {
              children = (
                <ButtonBase
                  type="button"
                  className={classes.paginationButton}
                  data-testid={`focus-sku-page-${page}-btn`}
                  style={{
                    color: selected ? "#FFFFFF" : "#505050",
                    backgroundColor: selected ? "#505050" : "transparent",
                  }}
                  onClick={(e) => {
                    handleClick(page);
                    onClick(e);
                  }}
                >
                  {page}
                </ButtonBase>
              );
            } else {
              children = (
                <IconButton
                  onClick={(e) => {
                    handleClick(page);
                    onClick(e);
                  }}
                  data-testid={
                    type === "next"
                      ? "focus-sku-nextpage-btn"
                      : "focus-sku-prevpage-btn"
                  }
                >
                  {type === "next" ? <SingleArrowRight /> : <SingleArrowLeft />}
                </IconButton>
              );
            }
          }

          return <li key={`${index + page}${page}`}>{children}</li>;
        })}
        {pageValue !== totalPages && (
          <li>
            <IconButton
              data-testid="focus-sku-lastpage-btn"
              onClick={() => {
                handleClick(totalPages);
              }}
            >
              <DoubleArrowRight />
            </IconButton>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;

import React, { useEffect, useState } from "react";

import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useStore } from "effector-react";
import { isFeatureEnabled } from "grow-shared-services";

import Button from "../../../../components/Button/Button";
import { TLP_PRIMARY_MISSIONS_MANAGEMENT } from "../../../../config/featureToggles";
import { formatMessage } from "../../../../i18n/formatters";
import { setIsAgentUpdated } from "../../../../stores/agents/AgentsEvents";
import AgentsStore from "../../../../stores/agents/AgentsStore";
import useMissionsManagement from "../../hooks/useMissionsManagement";
import { DnDListItem } from "../DragAndDropComponents/Types";
import ConfirmationModal from "../GenericModal/ConfirmationModal";
import MissionsManagement from "../MissionsManagement/MissionsManagement";
import Specializations from "../Specializations/Specializations";
import MissionsAndSpecializationContainerStyles from "./MissionsAndSpecializationContainer.styles";

interface MissionsAndSpecializationContainerProps {
  agentId: string;
  segmentations: Array<string>;
  handleApplyChanges: () => void;
  handleRemove: (index: number) => void;
  setSegmentations: React.Dispatch<React.SetStateAction<string[]>>;
  isLoading: boolean;
}

const MissionsAndSpecializationContainer = ({
  agentId,
  segmentations,
  handleApplyChanges,
  handleRemove,
  setSegmentations,
  isLoading,
}: MissionsAndSpecializationContainerProps): JSX.Element => {
  const classes = MissionsAndSpecializationContainerStyles();

  const { isAgentUpdated } = useStore(AgentsStore);

  const { draggableItems, disabledDraggableItems, updateMissionsTemporarily } =
    useMissionsManagement();

  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  const flagMissionsManagement = isFeatureEnabled(
    TLP_PRIMARY_MISSIONS_MANAGEMENT,
  );

  useEffect(() => {
    setIsAgentUpdated(false);
  }, [agentId]);

  const formattedSpecializations = segmentations?.map((segmentation, index) => {
    return {
      id: `${segmentation}-${index}`,
      type: "SPECIALIZATION",
      title: segmentation,
      isEnabled: true,
    } as DnDListItem;
  });

  const handleSubmit = () => {
    handleApplyChanges();
    setConfirmationModalIsOpen(false);
  };

  return (
    <>
      <ConfirmationModal
        open={confirmationModalIsOpen}
        onClose={setConfirmationModalIsOpen}
        handleSubmit={handleSubmit}
      />
      <Paper elevation={3} className={classes.paperRoot}>
        <section
          className={classes.content}
          data-testid="missions-and-specialization-section"
        >
          {flagMissionsManagement && (
            <>
              <div className={classes.container}>
                <Typography
                  variant="h5"
                  className={classes.title}
                  data-testid="missions-container-title"
                >
                  {formatMessage({
                    id: "AGENT_SEGMENTATION.MISSIONS_MANAGEMENT_TITLE",
                  })}
                </Typography>

                <div className={classes.draggableListContainer}>
                  <div className={classes.scrollContainer}>
                    <MissionsManagement
                      activatedMissions={draggableItems}
                      inactivatedMissions={disabledDraggableItems}
                      updateMissionsTemporarily={updateMissionsTemporarily}
                      data-testid="missions-container"
                    />
                  </div>
                </div>
              </div>

              <Divider
                orientation="vertical"
                flexItem
                data-testid="vertical-divider"
                className={classes.divider}
              />
            </>
          )}

          <div className={classes.container}>
            <Typography
              variant="h5"
              className={classes.title}
              data-testid="specializations-container-title"
            >
              {formatMessage({ id: "AGENT_SEGMENTATION.SPECIALIZATION_TITLE" })}
            </Typography>
            <div className={classes.draggableListContainer}>
              <div className={classes.scrollContainer}>
                <Specializations
                  setSegmentations={setSegmentations}
                  specializationsList={formattedSpecializations}
                  data-testid="specializations-container"
                  handleRemove={handleRemove}
                />
              </div>
            </div>
          </div>
        </section>

        <section
          className={classes.applyChangesButtonContainer}
          data-testid="apply-changes-button-section"
        >
          <Button
            testId="apply-changes-button"
            onClick={() => setConfirmationModalIsOpen(true)}
            loading={isLoading}
            disabled={isLoading || !isAgentUpdated}
          >
            <Typography
              className={classes.applyChangesButtonLabel}
              data-testid="apply-changes-button-label"
            >
              {formatMessage({
                id: "AGENT_SEGMENTATION.MISSIONS_AND_SPECIALIZATION_APPLY_CHANGES",
              })}
            </Typography>
          </Button>
        </section>
      </Paper>
    </>
  );
};

export default MissionsAndSpecializationContainer;

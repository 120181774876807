import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { isFeatureEnabled } from "grow-shared-services";

import axios from "../../../config/axios/axiosInstance";
import {
  ResponseToCampaignItemsConverter,
  ResponseToCampaignItemsConverterV3,
} from "../../../converters/items/ResponseToCampaignItemsConverter";
import CampaignItems from "../../../domains/items/CampaignItems";
import GetItemsByCampaignsResponse from "../domains/GetItemsByCampaignsResponse";
import {
  GROW_ORGANIZATION_ACTIVATION,
  TLP_MULTICONTRACT_ITEM_SERVICE,
} from "../../../config/featureToggles";
import { CAMPAIGNS_PROJECTION } from "../../../config/constants";
import { getApiHost, getEndPoint } from "../../host/HostService";
import { TLP_ITEM_SERVICE } from "../../../config/services";

export const TLP_ITEMS_ENDPOINT = getEndPoint(
  `${TLP_ITEM_SERVICE}/tlp-item/admin/campaign/items`,
);
export const TLP_ITEMS_ENDPOINT_V2 = getEndPoint(
  `${TLP_ITEM_SERVICE}/tlp-item/admin/v2/campaign/items`,
);
export const TLP_ITEMS_ENDPOINT_V3 = getEndPoint(
  `${TLP_ITEM_SERVICE}/v3/items-campaign`,
);

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

const ENDPOINTS: { [key: string]: string } = {
  default: TLP_ITEMS_ENDPOINT,
  multicontract: TLP_ITEMS_ENDPOINT_V2,
  organization: TLP_ITEMS_ENDPOINT_V3,
};

const selectEndpoint = (
  isMulticontractServiceEnable: boolean,
  isOrganizationActivationEnabled: boolean,
) => {
  let endpointKey = "default";
  if (isOrganizationActivationEnabled) {
    endpointKey = "organization";
  } else if (isMulticontractServiceEnable) {
    endpointKey = "multicontract";
  }

  const baseUrl = getApiHost();
  return `${baseUrl}${ENDPOINTS[endpointKey]}`;
};

const getParams = (
  isOrganizationActivationEnabled: boolean,
  isMulticontractServiceEnable: boolean,
  campaignTag: string,
  vendorId: string,
  segment: string,
  deliveryCenterId: string,
) => {
  const params = new URLSearchParams();
  if (isOrganizationActivationEnabled) {
    params.append("campaignTags", campaignTag);
    params.append("projection", CAMPAIGNS_PROJECTION.ALL);
    params.append("segment", segment);
    params.append("deliveryCenterId", deliveryCenterId);
    return params;
  }
  if (isMulticontractServiceEnable) {
    params.append("campaignTag", campaignTag);
    params.append("vendorId", vendorId);
    return params;
  }

  params.append("campaignTag", campaignTag);
  return params;
};

const getHeaders = (
  isMulticontractServiceEnable: boolean,
  vendorId: string,
  vendorName: string,
) => {
  return isMulticontractServiceEnable ? {} : { vendorId, vendorName };
};

export function getItemsByCampaign(
  campaignTag: string,
  vendorId: string,
  vendorName: string,
  segment: string,
  deliveryCenterId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  country?: string,
): Promise<CampaignItems> {
  const log = useLogService();
  const isOrganizationActivationEnabled = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );
  const isMulticontractServiceEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_ITEM_SERVICE,
  );

  const url = selectEndpoint(
    isMulticontractServiceEnable,
    isOrganizationActivationEnabled,
  );
  const params = getParams(
    isOrganizationActivationEnabled,
    isMulticontractServiceEnable,
    campaignTag,
    vendorId,
    segment,
    deliveryCenterId,
  );
  const headers = getHeaders(
    isMulticontractServiceEnable,
    vendorId,
    vendorName,
  );

  return axios
    .get<Array<GetItemsByCampaignsResponse>>(`${url}`, {
      params,
      headers,
    })
    .then((response) => {
      log.info(`Convert All Items received from ${url} to our model`);

      return isOrganizationActivationEnabled
        ? ResponseToCampaignItemsConverterV3(response.data)
        : ResponseToCampaignItemsConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}

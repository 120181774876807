import React from "react";
import Typography from "@material-ui/core/Typography";
import { formatMessage } from "../../../../i18n/formatters";
import TitleStyles from "./Title.styles";

const Title = (): JSX.Element => {
  const classes = TitleStyles();

  return (
    <>
      <Typography
        variant="h1"
        className={classes.mainTitle}
        data-testid="missions-management-main-title"
      >
        {formatMessage({ id: "AGENT_SEGMENTATION.AGENT_SETUP" })}
      </Typography>
      <Typography className={classes.subtitle}>
        {formatMessage({ id: "AGENT_SEGMENTATION.AGENT_SETUP_SUBTITLE" })}
      </Typography>
    </>
  );
};

export default Title;

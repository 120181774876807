import React from "react";
import { Modal as ModalBase, Box } from "@material-ui/core";
import { ModalProps } from "@material-ui/core/Modal";
import ModalStyles from "./Modal.styles";

const Modal: React.FunctionComponent<ModalProps> = ({
  open,
  onClose,
  children,
  ...props
}) => {
  const classes = ModalStyles();
  return (
    <ModalBase
      open={open}
      onClose={onClose}
      className={classes.modalBaseContainer}
      {...props}
    >
      <Box className={classes.modalContentContainer}>{children}</Box>
    </ModalBase>
  );
};

export default Modal;

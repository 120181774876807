import React from "react";
import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import Modal from "../../../../components/modal";
import GenericModalStyles from "./GenericModal.styles";
import Button from "../../../../components/Button/Button";

interface Props {
  open: boolean;
  onClose: (open: boolean) => void;
  onSubmit: () => void;
  title: string;
  description: string;
  submitText: string;
  cancelText: string;
  testId: string;
}

const GenericModal = ({
  open,
  onClose,
  onSubmit,
  title,
  description,
  submitText,
  cancelText,
  testId,
}: Props): JSX.Element => {
  const classes = GenericModalStyles();
  return (
    <Modal open={open} onClose={onClose} data-testid={`${testId}-container`}>
      <div className={classes.genericModalContainer}>
        <header className={classes.modalHeaderContainer}>
          <Typography
            className={classes.modalHeaderTitle}
            variant="h2"
            data-testid={`${testId}-title`}
          >
            {title}
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={() => onClose(false)}
            data-testid={`${testId}-close-button`}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        </header>
        <section className={classes.modalDescriptionContainer}>
          <Typography
            variant="subtitle1"
            className={classes.modalDescriptionText}
            data-testid={`${testId}-description`}
          >
            {description}
          </Typography>
        </section>
        <section className={classes.modalButtonsContainer}>
          <Button
            className={classes.cancelButton}
            onClick={() => onClose(false)}
            data-testid={`${testId}-cancel-button`}
          >
            {cancelText}
          </Button>
          <Button
            className={classes.applyButton}
            onClick={() => onSubmit()}
            data-testid={`${testId}-apply-button`}
          >
            {submitText}
          </Button>
        </section>
      </div>
    </Modal>
  );
};

export default GenericModal;

import React from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import BlockListMainPage from "../routes/blockList/BlockListMainPage";
import CampaignsMainPage from "../routes/campaigns/CampaignsMainPage";
import AgentSetupPageNewLayout from "../routes/agentsSetup/AgentSetupPageNewLayout/AgentSetupPageNewLayout";
import { BASE_NAME } from "../config/constants";
import useSideBar from "../hooks/sideBar/useSideBar";

const Routes: React.FC = () => {
  const location = useLocation();
  const {
    isTeamStructureEnabled,
    isMissionsManagementEnabled,
    isGrowAdminLocalMissionMenuEnabled,
    isDisableB2oCampaign,
    isDisableFocusSKUCampaign,
    isGrowAdminBetweenMissionManagement,
    isGrowOrganizationActivation,
    hasBeesGrowAll,
    hasAgentWritePermission,
    hasCampaignWritePermission,
    hasBlockListWritePermission,
    hasTeamStructureWritePermission,
    hasTeamStructureReadPermission,
    hasMissionManagementWrite,
    hasTeamsOrganizationPermission,
  } = useSideBar();

  const isCoordinator =
    hasAgentWritePermission &&
    hasCampaignWritePermission &&
    hasTeamStructureReadPermission;

  /* istanbul ignore next */
  const getNotFoundRedirect = () => {
    if (isGrowOrganizationActivation && hasTeamsOrganizationPermission)
      return "teams";
    if (
      (hasBeesGrowAll || hasMissionManagementWrite) &&
      isMissionsManagementEnabled &&
      !isGrowAdminBetweenMissionManagement
    )
      return "management";
    if (hasBeesGrowAll && isGrowAdminBetweenMissionManagement)
      return "global-missions";
    if (hasBeesGrowAll || hasCampaignWritePermission) return "campaigns";
    if (hasBeesGrowAll || hasBlockListWritePermission) return "block-list";
    if (hasBeesGrowAll || hasAgentWritePermission) return "agent";
    if (hasBeesGrowAll && isTeamStructureEnabled) return "team-structure";

    return "management";
  };

  /* istanbul ignore next */
  const conditionCheck = () => {
    return (
      (hasBeesGrowAll || hasMissionManagementWrite) &&
      isMissionsManagementEnabled
    );
  };

  /* istanbul ignore next */
  const isTeamStructureAllowed = (): boolean =>
    !!(
      isTeamStructureEnabled &&
      (hasBeesGrowAll || hasTeamStructureWritePermission || isCoordinator)
    );

  /* istanbul ignore next */
  const getMfeRedirects = () => {
    if (
      conditionCheck() &&
      !isGrowAdminBetweenMissionManagement &&
      location.pathname === "/beesgrow/management"
    ) {
      return "management";
    }
    if (
      conditionCheck() &&
      location.pathname === "/beesgrow/local-missions/sponsored-missions"
    ) {
      return "local-missions/sponsored-missions";
    }
    if (
      hasBeesGrowAll &&
      isMissionsManagementEnabled &&
      location.pathname === "/beesgrow/local-missions/new-sponsored-mission"
    ) {
      return "local-missions/new-sponsored-mission";
    }
    if (
      hasBeesGrowAll &&
      isGrowAdminLocalMissionMenuEnabled &&
      location.pathname === "/beesgrow/local-missions"
    ) {
      return "local-missions";
    }
    if (
      hasBeesGrowAll &&
      isGrowAdminLocalMissionMenuEnabled &&
      location.pathname === "/beesgrow/local-missions/details"
    ) {
      return "local-missions/details";
    }
    if (
      hasBeesGrowAll &&
      isGrowAdminLocalMissionMenuEnabled &&
      location.pathname === "/beesgrow/local-missions/duplicate"
    ) {
      return "local-missions/duplicate";
    }
    if (
      hasBeesGrowAll &&
      isMissionsManagementEnabled &&
      location.pathname === "/beesgrow/local-missions/edit-sponsored-mission"
    ) {
      return "local-missions/edit-sponsored-mission";
    }
    if (
      hasBeesGrowAll &&
      isMissionsManagementEnabled &&
      location.pathname === "/beesgrow/management/services-mission"
    ) {
      return "management/services-mission";
    }
    if (
      isTeamStructureAllowed() &&
      location.pathname === "/beesgrow/team-structure"
    ) {
      return "team-structure";
    }
    if (
      hasBeesGrowAll &&
      isGrowAdminBetweenMissionManagement &&
      location.pathname === "/beesgrow/global-missions"
    ) {
      return "global-missions";
    }
    if (
      hasBeesGrowAll &&
      isGrowAdminBetweenMissionManagement &&
      location.pathname === "/beesgrow/global-missions/new-global-mission"
    ) {
      return "global-missions/new-global-mission";
    }

    if (
      isGrowOrganizationActivation &&
      hasTeamsOrganizationPermission &&
      location.pathname === "/beesgrow/teams"
    ) {
      return "teams";
    }
    return getNotFoundRedirect();
  };

  /* istanbul ignore next */
  return (
    <Switch>
      {(hasBeesGrowAll || hasCampaignWritePermission) &&
        !(
          (isDisableB2oCampaign || isGrowAdminLocalMissionMenuEnabled) &&
          isDisableFocusSKUCampaign
        ) && (
          <Route path={`${BASE_NAME}/campaigns`}>
            <CampaignsMainPage />
          </Route>
        )}
      {(hasBeesGrowAll || hasAgentWritePermission) &&
        !isGrowOrganizationActivation && (
          <Route path={`${BASE_NAME}/agent`}>
            <AgentSetupPageNewLayout />
          </Route>
        )}
      {(hasBeesGrowAll || hasBlockListWritePermission) && (
        <Route path={`${BASE_NAME}/block-list`}>
          <BlockListMainPage />
        </Route>
      )}

      <Route path="*">
        <Redirect to={`/beesgrow/${getMfeRedirects()}`} />
      </Route>

      <Redirect
        exact
        from="/beesgrow"
        to={`/beesgrow/${getNotFoundRedirect()}`}
      />
    </Switch>
  );
};

export default Routes;

import React from "react";

const DoubleArrowLeft: React.FC = () => {
  return (
    <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
      <title>Group 2</title>
      <g
        id="Admin"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="Artboard"
          transform="translate(-83.000000, -40.000000)"
          fillRule="nonzero"
          stroke="#505050"
          strokeWidth="1.5"
        >
          <g
            id="Group-2"
            transform="translate(89.000000, 46.000000) scale(-1, 1) translate(-89.000000, -46.000000) translate(84.000000, 41.000000)"
          >
            <path
              d="M0,0 L4.29237589,4.28609742 C4.68318665,4.67633503 4.68365005,5.30949984 4.29341169,5.70030985 C4.29341143,5.7003101 4.29341118,5.70031036 4.29341017,5.70030985 L0,10 L0,10"
              id="Path-3"
            />
            <path
              d="M5,0 L9.29237589,4.28609742 C9.68318665,4.67633503 9.68365005,5.30949984 9.29341169,5.70030985 C9.29341143,5.7003101 9.29341118,5.70031036 9.29341017,5.70030985 L5,10 L5,10"
              id="Path-3"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DoubleArrowLeft;

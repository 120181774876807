import React, { ReactElement } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import TooltipStyles from "./TooltipStyles";

interface Props {
  text: string;
}

const GeneralTooltip: React.FC<Props> = ({ text }: Props) => {
  const classes = TooltipStyles();
  const component: ReactElement = (
    <Tooltip
      title={text}
      arrow
      placement="top"
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18px"
        height="18px"
        viewBox="0 0 24 24"
      >
        <path d="M12 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm1-6h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z" />
      </svg>
    </Tooltip>
  );
  return component;
};

export default React.memo(GeneralTooltip);

import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { isFeatureEnabled } from "grow-shared-services";
import axios from "../../config/axios/axiosInstance";
import {
  TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
  GROW_ORGANIZATION_ACTIVATION,
} from "../../config/featureToggles";
import { TLP_CAMPAIGN_SERVICE } from "../../config/services";
import { getEndPoint, getApiHost } from "../host/HostService";
import CreateCampaignRequest from "./domains/CreateCampaignRequest";

export const CAMPAINGS_ENDPOINT = "/tlp-campaign";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

const getFormRequest = (request: CreateCampaignRequest) => {
  const { csvList, ...rest } = request;
  const formDataRequest = new FormData();
  if (csvList != null) {
    formDataRequest.append("csv", csvList);
  }
  formDataRequest.append("request", JSON.stringify(rest));
  return formDataRequest;
};

const getUrl = (
  baseUrl: string,
  isGrowOrganizationActivationEnable: boolean,
  isMulticontractCampaignServiceEnable: boolean,
  vendorId: string | null,
) => {
  if (isGrowOrganizationActivationEnable) return `${baseUrl}/v3/campaigns`;
  if (isMulticontractCampaignServiceEnable)
    return `${baseUrl}${CAMPAINGS_ENDPOINT}/v2/admin/products_with_files?vendorId=${vendorId}`;
  return `${baseUrl}${CAMPAINGS_ENDPOINT}/admin/products_with_files`;
};

const getHeaders = (
  country: string,
  vendorId: string,
  vendorName: string,
  {
    isMulticontractCampaignServiceEnable,
    isGrowOrganizationActivationEnable,
  }: {
    isMulticontractCampaignServiceEnable: boolean;
    isGrowOrganizationActivationEnable: boolean;
  },
  /* istanbul ignore next */
  headers = {},
) => {
  if (isGrowOrganizationActivationEnable) return {};

  let headersData;
  if (isMulticontractCampaignServiceEnable)
    headersData = { country, "Content-Type": "multipart/form-data" };
  else
    headersData = {
      country,
      vendorId,
      vendorName,
      "Content-Type": "multipart/form-data",
    };
  return { ...headersData, ...headers };
};

export function createCampaign(
  country: string,
  vendorId: string,
  vendorName: string,
  request: CreateCampaignRequest,
  headers = {},
): Promise<void> {
  const isMulticontractCampaignServiceEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
  );

  const isGrowOrganizationActivationEnable = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const log = useLogService();

  const url = getUrl(
    `${getApiHost()}${getEndPoint(TLP_CAMPAIGN_SERVICE)}`,
    isGrowOrganizationActivationEnable,
    isMulticontractCampaignServiceEnable,
    vendorId,
  );

  return axios
    .post(url, getFormRequest(request), {
      headers: getHeaders(
        country,
        vendorId,
        vendorName,
        {
          isMulticontractCampaignServiceEnable,
          isGrowOrganizationActivationEnable,
        },
        headers,
      ),
    })
    .then(() => {
      log.info("Campaign created Successfully");
    })
    .catch((error) => {
      log.error(error?.response);
      throw error?.response;
    });
}

import {
  removeListLocalStorage,
  isAdminLogin,
  getPortalConfig,
} from "../utils/functions";
import { UPDATE_LOCAL_STORAGE_LOGOUT } from "../constants";

const urlLogout = () => {
  const portalConfig = getPortalConfig();

  if (!isAdminLogin(portalConfig)) return undefined;

  return portalConfig.SIGN_OUT_URL;
};

export const logout = (): void => {
  const url = urlLogout();
  if (url) {
    removeListLocalStorage(UPDATE_LOCAL_STORAGE_LOGOUT);
    window.location.href = url;
  }
};

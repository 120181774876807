import React from "react";

const DownloadIcon: React.FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      stroke="#484b4d"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      version="1.1"
    >
      <title>Download icon</title>
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
      <polyline points="7 10 12 15 17 10" />
      <line x1="12" y1="15" x2="12" y2="3" />
    </svg>
  );
};

export default DownloadIcon;

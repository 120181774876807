/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { Box } from "@material-ui/core";
import classNames from "classnames";
import { addDays } from "date-fns";
import { FormikValues, FormikTouched, FormikErrors } from "formik";
import Moment from "moment";
import CsvDownloader from "react-csv-downloader";
import { isFeatureEnabled } from "grow-shared-services";

import { useAnalytics } from "../../../../analytics/useAnalytics";
import GeneralTooltip from "../../../../components/core/Tooltip/GeneralTooltip";
import { TLP_FLAG_MISSION } from "../../../../config/featureToggles";
import { campaignUxButtonClicked } from "../../../../config/typewriter";
import { formatMessage } from "../../../../i18n/formatters";
import DatePicker from "../datePicker/DatePicker";
import DragAndDrop from "../dragDrop/DragDrop";
import FlagMission from "../flagMission/FlagMission";
import useStyles from "../modalGeneric/ModalGenericCampaignStyle";
import SkuID from "../skuID/SkuID";

interface B2OFormProps {
  titleModal: string;
  valuesForm: FormikValues;
  handleChange: (
    eventOrPath: string | React.ChangeEvent<any>,
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  touched: FormikTouched<FormikValues>;
  errors: FormikErrors<FormikValues>;
}

const B2OForm: React.FC<B2OFormProps> = ({
  titleModal,
  valuesForm,
  handleChange,
  setFieldValue,
  touched,
  errors,
}) => {
  const classes = useStyles();
  const { dispatchGenericEvent } = useAnalytics();
  const flagMissionEnable = isFeatureEnabled(TLP_FLAG_MISSION);

  const columns = [
    {
      id: "POC_ID",
      displayName: "POC_ID",
    },
  ];
  const datas = [
    {
      POC_ID: "12345",
    },
    {
      POC_ID: "56789",
    },
  ];

  const onClick = () => {
    dispatchGenericEvent(campaignUxButtonClicked, {
      screen_name: "Campaigns",
      screen_section: "Campaign Creation Modal",
      button_label: "modal-campaign-download-button",
      button_name: "Download here file example",
      filter_option: "",
    });
  };

  const isSecondaryClassification = (classification: string) => {
    return classification === "SECONDARY";
  };

  const removeTimeFromDate = (date: Date): Date => {
    const dateWithoutTimezone = new Date(date);
    dateWithoutTimezone.setHours(0, 0, 0, 0);

    return dateWithoutTimezone;
  };

  const handleChangeDateWithoutTimezone = (fieldName: string, date: Date) => {
    const dateWithoutTimezone = removeTimeFromDate(date);
    const isoDateWithoutTimezone = Moment(dateWithoutTimezone).format(
      "yyyy-MM-DDTHH:mm:ss.000Z",
    );

    setFieldValue(fieldName, isoDateWithoutTimezone);
  };

  return (
    <>
      <Box className={classes.containerInner}>
        <label htmlFor="campaignName" className={classes.label}>
          <span
            className={classes.FirstlabelText}
            data-testid="modal-campaign-label-name"
          >
            {formatMessage({ id: "MODAL_CAMPAIGNS.CAMPAIGN_NAME" })}
          </span>

          <input
            id="campaignName"
            name="campaignName"
            type="text"
            placeholder={formatMessage({
              id: "MODAL_CAMPAIGNS.CAMPAIGN_NAME_PLACEHOLDER",
            })}
            className={classNames(classes.inputText, {
              [classes.details]: !(
                /* istanbul ignore next */
                (!!errors.campaignName && touched.campaignName)
              ),
              [classes.detailsError]:
                /* istanbul ignore next */
                !!errors.campaignName && touched.campaignName,
            })}
            value={valuesForm.campaignName}
            onChange={handleChange}
            data-testid="modal-campaign-input-name"
          />
          {
            /* istanbul ignore next */
            !!errors.campaignName && touched.campaignName && (
              <span
                className={classes.errorMessage}
                data-testid="modal-campaign-input-name-error"
              >
                {errors.campaignName}
              </span>
            )
          }
        </label>
        <label htmlFor="campaignTag" className={classes.label}>
          <span
            className={classes.labelText}
            data-testid="modal-campaign-label-tag"
          >
            {formatMessage({ id: "MODAL_CAMPAIGNS.CAMPAIGN_TAG" })}
          </span>
          <input
            id="campaignTag"
            name="campaignTag"
            type="text"
            placeholder={`# ${formatMessage({
              id: "MODAL_CAMPAIGNS.TAG_PLACEHOLDER",
            })}`}
            className={classNames(classes.inputText, {
              [classes.details]: !(
                /* istanbul ignore next */
                (!!errors.campaignTag && touched.campaignTag)
              ),
              [classes.detailsError]:
                /* istanbul ignore next */
                !!errors.campaignTag && touched.campaignTag,
            })}
            value={valuesForm.campaignTag}
            onChange={handleChange}
            data-testid="modal-campaign-input-tag"
            disabled={
              titleModal ===
              formatMessage({ id: "MODAL_CAMPAIGNS.TITLE_MODAL_EDIT_CAMPAIGN" })
            }
          />
          {
            /* istanbul ignore next */
            !!errors.campaignTag && touched.campaignTag && (
              <span
                className={classes.errorMessage}
                data-testid="modal-campaign-input-tag-error"
              >
                {errors.campaignTag}
              </span>
            )
          }
        </label>
        {flagMissionEnable && (
          <div className={classes.radioButton} data-testid="classification-b2o">
            <FlagMission
              defaultValue={valuesForm.classification}
              handleChange={(value) => {
                setFieldValue("classification", value);
              }}
              testId="classification-b2o"
            />
          </div>
        )}
        <label
          htmlFor="segmentation"
          className={classNames(classes.label, {
            [classes.agentSpecializationDisable]: isSecondaryClassification(
              valuesForm.classification,
            ),
          })}
        >
          <span
            className={classes.labelText}
            data-testid="modal-campaign-label-segmentation"
          >
            <span style={{ marginRight: "5px" }}>
              {`${formatMessage({
                id: "MODAL_CAMPAIGNS.SEGMENTATION",
              })} ${formatMessage({ id: "RULES_FLOW.OPTIONAL_FIELD" })}`}
            </span>
            <GeneralTooltip
              text={formatMessage({
                id: "MODAL_CAMPAIGNS.AGENT_SPECIALIZATION_TOOLTIP",
              })}
            />
          </span>
          <input
            id="segmentation"
            name="segmentation"
            type="text"
            placeholder={formatMessage({
              id: "MODAL_CAMPAIGNS.SEGMENTATION_PLACEHOLDER",
            })}
            className={classNames(classes.inputText, {
              [classes.details]: !(
                /* istanbul ignore next */
                (!!errors.segmentation && touched.segmentation)
              ),
              [classes.detailsError]:
                /* istanbul ignore next */
                !!errors.segmentation && touched.segmentation,
            })}
            value={valuesForm.segmentation}
            onChange={handleChange}
            data-testid="modal-campaign-input-segmentation"
            disabled={
              titleModal ===
              formatMessage({ id: "MODAL_CAMPAIGNS.TITLE_MODAL_EDIT_CAMPAIGN" })
            }
          />
        </label>
        <div
          style={{
            display: "flex",
          }}
        >
          <div className={classes.label}>
            <DatePicker
              title={formatMessage({ id: "MODAL_CAMPAIGNS.START_DATE" })}
              date={
                valuesForm.startDate &&
                removeTimeFromDate(new Date(valuesForm.startDate))
              }
              handleChange={(date) =>
                handleChangeDateWithoutTimezone("startDate", date)
              }
              error={!!(errors.startDate && touched.startDate)}
              testId="start-date-b2o"
            />
            {
              /* istanbul ignore next */
              !!errors.startDate && touched.startDate && (
                <span
                  className={classes.errorMessage}
                  data-testid="modal-campaign-input-startdate-error"
                >
                  {errors.startDate}
                </span>
              )
            }
          </div>
          <div className={classes.label}>
            <DatePicker
              date={
                valuesForm.endDate &&
                removeTimeFromDate(new Date(valuesForm.endDate))
              }
              handleChange={(date) => {
                handleChangeDateWithoutTimezone("endDate", date);
              }}
              error={!!(errors.endDate && touched.endDate)}
              title={formatMessage({ id: "MODAL_CAMPAIGNS.END_DATE" })}
              testId="end-date-b2o"
              minDate={addDays(new Date(valuesForm.startDate), 1)}
            />
            {
              /* istanbul ignore next */
              !!errors.endDate && touched.endDate && (
                <span
                  className={classes.errorMessage}
                  data-testid="modal-campaign-input-enddate-error"
                >
                  {errors.endDate}
                </span>
              )
            }
          </div>
        </div>
        <label htmlFor="csvList" className={classes.label}>
          <span
            className={classes.labelText}
            data-testid="modal-campaign-label-csvList"
          >
            {formatMessage({ id: "MODAL_CAMPAIGNS.CSV_LIST" })}
          </span>
          <DragAndDrop
            onSelect={(files) => {
              setFieldValue("csvList", files[0]);
            }}
            fileTypeAccepted={[".csv"]}
            maxFiles={1}
            text={formatMessage({ id: "MODAL_CAMPAIGNS.DRAG_DROP_CSV" })}
            testId="csv-list-b2o"
          />
          {
            /* istanbul ignore next */
            !!errors.csvList && touched.csvList && (
              <span
                className={classes.errorMessage}
                data-testid="modal-campaign-input-tag-error"
              >
                {errors.csvList}
              </span>
            )
          }
        </label>

        {/* eslint-disable-next-line */}
        <div
          className={classes.containerDownloadCsv}
          onClick={onClick}
          data-testid="div-campaign-download-button"
        >
          <CsvDownloader
            filename="pocs"
            extension=".csv"
            columns={columns}
            datas={datas}
            text={formatMessage({ id: "MODAL_CAMPAIGNS.DOWNLOAD_FILE" })}
            className={classes.buttonDownload}
            data-testid="modal-campaign-download-button"
          />
        </div>

        {/**
         *  Commented on because of task 7899 - disable image uploader
         */}
        {/* <label htmlFor="coverImage" className={classes.label}>
          <span
            className={classes.labelText}
            data-testid="modal-campaign-label-coverImage"
          >
            {t("MODAL_CAMPAIGNS.COVER_IMAGE")}
          </span>
          <DragAndDrop
            onSelect={(files) => {
              setFieldValue("coverImage", files[0]);
            }}
            maxFiles={1}
            text={t("MODAL_CAMPAIGNS.DRAG_DROP_IMAGE")}
            testId="cover-image-b2o"
          />
          {
            !!errors.coverImage && touched.coverImage && (
              <span
                className={classes.errorMessage}
                data-testid="modal-campaign-input-tag-error"
              >
                {errors.coverImage}
              </span>
            )
          }
        </label> */}

        <SkuID
          skus={valuesForm.skus}
          handleChange={
            /* istanbul ignore next */
            (skus) => {
              setFieldValue("skus", skus);
              setFieldValue("sku_0", skus[0]);
            }
          }
          errors={errors}
          touched={touched}
        />
        <label htmlFor="campaignDescription" className={classes.label}>
          <span
            className={classes.labelText}
            data-testid="campaign-description-label"
          >
            {formatMessage({ id: "MODAL_CAMPAIGNS.CAMPAIGN_DESCRIPTION" })}
          </span>
          <textarea
            id="campaignDescription"
            name="campaignDescription"
            placeholder={formatMessage({
              id: "MODAL_CAMPAIGNS.CAMPAIGN_DESCRIPTION_PLACEHOLDER",
            })}
            className={classNames(classes.textarea, {
              [classes.details]: !(
                /* istanbul ignore next */
                (!!errors.campaignDescription && touched.campaignDescription)
              ),
              [classes.detailsError]:
                /* istanbul ignore next */
                !!errors.campaignDescription && touched.campaignDescription,
            })}
            value={valuesForm.campaignDescription}
            onChange={handleChange}
            data-testid="campaign-description-value"
          />
          {
            /* istanbul ignore next */
            !!errors.campaignDescription && touched.campaignDescription && (
              <span
                className={classes.errorMessage}
                data-testid="modal-campaign-input-description-error"
              >
                {errors.campaignTag}
              </span>
            )
          }
        </label>
        <label htmlFor="campaignScript" className={classes.label}>
          <span
            className={classes.labelText}
            data-testid="campaign-script-label"
          >
            {formatMessage({ id: "MODAL_CAMPAIGNS.CAMPAIGN_SCRIPT" })}
          </span>
          <textarea
            id="campaignScript"
            name="campaignScript"
            placeholder={formatMessage({
              id: "MODAL_CAMPAIGNS.CAMPAIGN_SCRIPT_PLACEHOLDER",
            })}
            className={classNames(classes.textarea, {
              [classes.details]: !(
                /* istanbul ignore next */
                (!!errors.campaignScript && touched.campaignScript)
              ),
              [classes.detailsError]:
                /* istanbul ignore next */
                !!errors.campaignScript && touched.campaignScript,
            })}
            value={valuesForm.campaignScript}
            onChange={handleChange}
            data-testid="campaign-script-value"
          />
          {
            /* istanbul ignore next */
            !!errors.campaignScript && touched.campaignScript && (
              <span
                className={classes.errorMessage}
                data-testid="modal-campaign-input-script-error"
              >
                {errors.campaignScript}
              </span>
            )
          }
        </label>
      </Box>
    </>
  );
};

export default B2OForm;

import * as DeleteCampaignService from "../../services/campaigns/DeleteCampaignService";

export function deleteCampaign(
  country: string,
  campaignTag: string,
  vendorId?: string,
  vendorName?: string,
  isCampaignFocusVendorEnabled?: boolean,
  idCampaign?: string,
): Promise<void> {
  let vendorIdHeaderParam = "";
  let vendorNameHeaderParam = "";

  if (vendorId && vendorName && isCampaignFocusVendorEnabled) {
    vendorIdHeaderParam = vendorId;
    vendorNameHeaderParam = vendorName;
  }

  return DeleteCampaignService.deleteCampaign(
    country,
    campaignTag?.replace("#", ""),
    vendorIdHeaderParam,
    vendorNameHeaderParam,
    undefined,
    undefined,
    idCampaign,
  );
}

/* eslint-disable import/no-internal-modules */
// eslint-disable-next-line import/no-internal-modules
import React from "react";

import LogoutDialog from "@components/core/LogoutDialog/LogoutDialog";
import MainContent from "@components/core/MainContent/MainContent";
import SideBarAdmin from "@components/core/SideBarAdmin/SideBarAdmin";
import CloseIcon from "@material-ui/icons/Close";
import { toast, ToastContainer } from "react-toastify";

import Routes from "./Routes";
import ToastfyCustomStyles from "./ToastifyOverride.styles";
import "./global.css";
import "react-toastify/dist/ReactToastify.min.css";

const AppRouter = (): JSX.Element => {
  const toastClasses = ToastfyCustomStyles();

  return (
    <>
      <LogoutDialog />
      <div style={{ display: "flex", height: "100%" }}>
        <SideBarAdmin />
        <MainContent>
          <ToastContainer
            className={toastClasses.toastContainer}
            toastClassName={toastClasses.toast}
            position={toast.POSITION.TOP_CENTER}
            autoClose={3000}
            hideProgressBar
            draggable={false}
            closeButton={<CloseIcon className={toastClasses.closeIcon} />}
          />
          <Routes />
        </MainContent>
      </div>
    </>
  );
};

export default AppRouter;

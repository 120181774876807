import {
  DefaultMission,
  FlexibleMission,
  MISSION_STATUSES,
  Mission,
} from "../../domains/Missions";

export type ResponseFlexibleMissions = FlexibleMission[] | DefaultMission[];

export const FlexibleMissionsResponseToMissions = (
  data: ResponseFlexibleMissions,
): Mission[] => {
  const missions = [...data];
  const activeMissions = missions.filter(
    (mission) =>
      mission.status === MISSION_STATUSES.SCHEDULED ||
      mission.status === MISSION_STATUSES.PAUSED ||
      mission.status === MISSION_STATUSES.ONGOING ||
      mission.mission !== "FLEXIBLE_MISSIONS",
  );
  return activeMissions.map((mission) => {
    const missionName = mission.missionName ?? mission.mission;

    return {
      id: mission.missionId,
      missionId: mission.missionId,
      country: mission.country,
      order: mission.order,
      enabled: mission.enabled,
      mission:
        mission.mission === "FLEXIBLE_MISSIONS" ? missionName : mission.mission,
      type: mission.type,
    };
  });
};

import React from "react";
import {
  Select as BaseSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import SelectStyles from "./SelectStyles";
import { noop } from "../../config/utils/functions";
import { formatMessage } from "../../i18n/formatters";

interface Option {
  value: string | number;
  label: string;
}

interface Props {
  name: string;
  value: string | number;
  error?: string;
  touched?: boolean;
  options: Array<Option>;
  onChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  onOpen?: (e: React.ChangeEvent<unknown>) => void;
  onClose?: (e: React.ChangeEvent<unknown>) => void;
  testId: string;
  disabled?: boolean;
  className: string;
}

export interface FormValues {
  classification: string;
  classificationDetails: string;
}

const Select: React.FunctionComponent<Props> = ({
  name,
  value,
  error,
  touched,
  options,
  onChange,
  onOpen,
  onClose,
  testId,
  disabled,
  className,
}) => {
  const classes = SelectStyles();

  return (
    <>
      <BaseSelect
        disabled={disabled}
        value={value}
        onChange={onChange}
        onOpen={onOpen}
        onClose={onClose}
        displayEmpty
        className={[classes.selectOutline, className].join(" ")}
        variant="outlined"
        classes={{
          root: classes.select,
          select: classes.focusedSelect,
        }}
        inputProps={{
          name,
        }}
        data-testid={testId}
      >
        {options.map((r, index) => (
          <MenuItem
            value={r.value}
            disabled={!r.value}
            key={r.value}
            className={classes.selectItem}
            data-testid={`${testId}-${index}-option`}
          >
            {formatMessage({ id: r.label })}
          </MenuItem>
        ))}
      </BaseSelect>
      {error && touched && (
        <FormHelperText
          classes={{ root: classes.errorMessage }}
          data-testid={`${testId}-error-message`}
        >
          {error}
        </FormHelperText>
      )}
    </>
  );
};

Select.defaultProps = {
  error: "",
  touched: false,
  onOpen: noop,
  onClose: noop,
  disabled: false,
};

export default Select;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useAuthenticationService } from "admin-portal-shared-services";
import {
  UserConfig,
  useUserConfigService,
  getVendorName,
} from "grow-shared-services";

import {
  LOCAL_STORAGE_PORTAL_CONFIG,
  PORTAL_CONFIG_PORTAL_TYPE,
  PORTAL_CONFIG_ENV_PROD,
  PORTAL_CONFIG_ENV_UAT,
  PORTAL_CONFIG_ENV_SIT,
  PORTAL_CONFIG_ENV_DEV,
  LOCAL_STORAGE_AUTH_HEADER,
} from "../constants";

// This functions do nothing, is just to we use when have default value as a empty function and we dont wanna cover it with tests
export const noop = (): void => {};

const isPortalAdmin = (portalConfig: any) => {
  return portalConfig?.PORTAL_TYPE === PORTAL_CONFIG_PORTAL_TYPE;
};

const isValidEnv = (portalConfig: any) => {
  return [
    PORTAL_CONFIG_ENV_PROD,
    PORTAL_CONFIG_ENV_UAT,
    PORTAL_CONFIG_ENV_SIT,
    PORTAL_CONFIG_ENV_DEV,
  ].some((env) => env === portalConfig?.ENV);
};

export const getPortalConfig = () => {
  const portalLocalStorage = localStorage.getItem(LOCAL_STORAGE_PORTAL_CONFIG);

  if (!portalLocalStorage) return undefined;

  return JSON.parse(portalLocalStorage);
};

export const isAdminLogin = (portalConfig: any): boolean => {
  return isPortalAdmin(portalConfig) && isValidEnv(portalConfig);
};

export const isB2CLogin = (): boolean => {
  const portalConfig = getPortalConfig();
  return isAdminLogin(portalConfig);
};

export const isCanary = (): boolean | false => {
  return localStorage.getItem("version") === "canary";
};

const removeItemsLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const removeListLocalStorage = (keys: string[]): void => {
  if (keys.length > 0) keys.forEach((key) => removeItemsLocalStorage(key));
};

const logoutBeesGrow = () => {
  if (isB2CLogin()) {
    const portalConfig = getPortalConfig();
    window.location.href = portalConfig?.BASE_URL;
  } else {
    window.location.href = `${window.location.origin}/#/login`;
  }
};

export const getTokenLocalStorage = (): string => {
  const token = useAuthenticationService().getAuthHeader();

  if (!token) {
    const authHeader = localStorage.getItem(LOCAL_STORAGE_AUTH_HEADER);

    if (authHeader) {
      useAuthenticationService().setAuthHeader(authHeader);
      return authHeader;
    }

    logoutBeesGrow();
    return "";
  }

  return token;
};

export const getEnvironment = (): string => {
  const portalLocalStorage =
    localStorage.getItem(LOCAL_STORAGE_PORTAL_CONFIG) || "{}";
  const portalConfig = JSON.parse(portalLocalStorage);
  switch (portalConfig.ENV) {
    case "qa":
      return "SIT";
    case "uat":
      return "UAT";
    case "prod":
      return "PROD";
    default:
      return "DEV";
  }
};

export const truncateText = (text: string, limit: number) => {
  if (text != null && text !== "") {
    return text.length > limit ? `${text.substr(0, limit)}...` : text;
  }
  return null;
};

interface Navigation {
  fromButtonName: string;
  toButtonName: string;
  fromURI: string;
  toURI: string;
  uri: string;
  event: React.SyntheticEvent;
  navigate: () => void;
}
export const handleNavigationEvent = ({
  fromButtonName,
  toButtonName,
  fromURI,
  navigate,
  uri,
  event,
}: Navigation) => {
  if (toButtonName !== fromButtonName && uri === fromURI) {
    event.preventDefault();
    const exitPage = new CustomEvent("exitPageBySideMenuEvent", {
      detail: {
        navigate,
        destination: uri,
      },
    });
    window.dispatchEvent(exitPage);
  }
};

export const getStorageItem = (key: string) => {
  const data = window.localStorage.getItem(key);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return JSON.parse(data!);
};

export const returnVendorId = (vendorId: string): string => {
  return getVendorName(vendorId) && vendorId ? vendorId : "";
};

export const getCountryByToken = (): string => {
  const supportedCountries = useAuthenticationService().getSupportedCountries();

  if (supportedCountries && supportedCountries.length > 0) {
    return supportedCountries[0];
  }

  return useAuthenticationService().getCountryB2C();
};

export const getUserInfos = (): UserConfig => {
  const { data } = useUserConfigService().getStoreValue();

  return { ...data };
};

export const getEuropeClusterOrUrlParam = (url: string) => {
  const europeCountries = localStorage.getItem("europeCountries");

  if (europeCountries?.includes(getCountryByToken())) {
    return process.env.EUROPE_HOST + removeUrlPrefix(url);
  }

  return url;
};

const removeUrlPrefix = (url: string) => {
  return url.replaceAll("/api/v1", "").replaceAll("/api/eu", "");
};

import { useCallback } from "react";

type EventType = (config: any) => void;

type UseAnalyticsType = () => {
  dispatchGenericEvent: (
    event: EventType,
    config: Record<string, unknown>,
  ) => void;
};

export const useAnalytics: UseAnalyticsType = () => {
  const dispatchGenericEvent = useCallback(
    (event: EventType, config: Record<string, unknown>) => {
      event(config);
    },
    [],
  );

  return { dispatchGenericEvent };
};

/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import GenericModal from "../GenericModal";
import { formatMessage } from "../../../../../i18n/formatters";

interface Props {
  open: boolean;
  onClose: (open: boolean) => void;
  handleSubmit: () => void;
}

const ConfirmationModal = ({
  open,
  onClose,
  handleSubmit,
}: Props): JSX.Element => {
  return (
    <GenericModal
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={`${formatMessage({
        id: "AGENT_SEGMENTATION.APPLY_CHANGES_MODAL_TITLE",
      })}`}
      description={formatMessage({
        id: "AGENT_SEGMENTATION.APPLY_CHANGES_MODAL_DESCRIPTION",
      })}
      submitText={formatMessage({
        id: "AGENT_SEGMENTATION.APPLY_CHANGES_CONFIRM",
      })}
      cancelText={formatMessage({
        id: "AGENT_SEGMENTATION.APPLY_CHANGES_CANCEL",
      })}
      testId="confirmation-modal"
    />
  );
};

export default ConfirmationModal;

import React from "react";

const HouseIcon: React.FC = () => {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
      <title>Shape</title>
      <g
        id="(Current)-Admin---Old-Layout"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="SPRINT-64-Admin---Pocs-Icon"
          transform="translate(-1231.000000, -151.000000)"
          fill="#505050"
          fillRule="nonzero"
        >
          <path
            d="M1247.2,151.9654 C1246.9468,151.225 1246.4686,151 1246,151 L1234,151 C1233.5314,151 1233.0532,151.225 1232.8,151.9654 L1231,157 C1231,159.175 1231.9378,159.4 1232.8,159.4 C1233.7186,159.4 1234.6,158.9752 1234.6,158.2 C1234.6,158.9752 1235.4814,159.4 1236.4,159.4 C1237.3186,159.4 1238.2,158.9752 1238.2,158.2 C1238.2,158.9752 1239.0814,159.4 1240,159.4 C1240.9186,159.4 1241.8,158.9752 1241.8,158.2 C1241.8,158.9752 1242.6814,159.4 1243.6,159.4 C1244.5186,159.4 1245.4,158.9752 1245.4,158.2 C1245.4,158.9752 1246.2814,159.4 1247.2,159.4 C1248.0628,159.4 1249,159.175 1249,157 L1247.2,151.9654 Z M1245.4,160.1596 C1244.9086,160.4398 1244.2918,160.6 1243.6,160.6 C1242.9082,160.6 1242.2914,160.4398 1241.8,160.1596 C1241.3086,160.4398 1240.6918,160.6 1240,160.6 C1239.3082,160.6 1238.6914,160.4398 1238.2,160.1596 C1237.7086,160.4398 1237.0918,160.6 1236.4,160.6 C1235.7082,160.6 1235.0914,160.4398 1234.6,160.1596 C1234.1086,160.4398 1233.4918,160.6 1232.8,160.6 C1232.6038,160.6 1232.4016,160.585 1232.2,160.5538 L1232.2,167.8 C1232.2,168.46 1232.74,169 1233.4,169 L1234.6,169 L1234.6,163 L1238.2,163 L1238.2,169 L1246.6,169 C1247.26,169 1247.8,168.46 1247.8,167.8 L1247.8,160.5538 C1247.5984,160.585 1247.3962,160.6 1247.2,160.6 C1246.5082,160.6 1245.8914,160.4398 1245.4,160.1596 Z M1245.4,166.6 L1240.6,166.6 L1240.6,163 L1245.4,163 L1245.4,166.6 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
};

export default HouseIcon;

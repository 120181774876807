import { createEffect } from "effector";

import * as GetAllCampaignsUseCase from "../../usecase/campaigns/GetAllCampaignsUseCase";

interface GetAllCampaigns {
  isCanaryRelease?: boolean;
  vendorId?: string;
  vendorName?: string;
  isCampaignFocusVendorEnabled?: boolean;
}

export const getAllCampaignsEffect = createEffect({
  async handler(campaign: GetAllCampaigns) {
    return GetAllCampaignsUseCase.execute(
      campaign.isCanaryRelease,
      campaign.vendorId,
      campaign.vendorName,
      campaign.isCampaignFocusVendorEnabled,
    );
  },
});

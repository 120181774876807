import { CampaignItemsTable } from "../CampaignItemsDb";
import CampaignItems, { Item } from "../../../domains/items/CampaignItems";

export const findByCampaignTag = async (
  campaignItems: CampaignItemsTable,
  campaignTag: string,
): Promise<CampaignItems> => {
  const result = await campaignItems
    .filter((item) => {
      return hasCampaignTag(item, campaignTag);
    })
    .toArray();

  return { campaignItems: result };
};

export const hasCampaignTag = (item: Item, campaignTag: string): boolean => {
  return item.campaignTag === campaignTag;
};

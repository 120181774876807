import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { isFeatureEnabled } from "grow-shared-services";

import axios from "../../config/axios/axiosInstance";
import { TLP_MULTICONTRACT_ONCUSTOMER_B2C_USERS } from "../../config/featureToggles";
import FetchAllAgentsConverter from "../../converters/agents/FetchAllAgentsConverter";
import { Agents, FetchAllAgentsResponse } from "../../domains/Agents";
import { getApiHost } from "../host/HostService";

export const FETCH_ALL_AGENTS_ENDPOINT = "/users/admin";
export const FETCH_ALL_AGENTS_MULTICONTRACT_ENDPOINT = "/users/v2/admin";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function fetchAllAgents(
  isCanaryRelease: boolean,
  vendorId?: string,
): Promise<Agents> {
  const log = useLogService();

  const isMulticontractOncustomerEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_ONCUSTOMER_B2C_USERS,
  );

  const endpointPath = isMulticontractOncustomerEnable
    ? `${FETCH_ALL_AGENTS_MULTICONTRACT_ENDPOINT}?vendorId=${vendorId ?? ""}`
    : FETCH_ALL_AGENTS_ENDPOINT;

  let url = `/api/v1/oncustomer-b2c-users${endpointPath}`;

  /* istanbul ignore next */
  if (isCanaryRelease) {
    url = `${getApiHost()}${endpointPath}`;
  }

  return axios
    .get<FetchAllAgentsResponse[]>(`${url}`)
    .then((response) => {
      log.info(`Convert fetch all agents received from ${url} to our model`);
      return FetchAllAgentsConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}

import { isFeatureEnabled } from "grow-shared-services";
import {
  GROW_ADMIN_DISABLE_B2O_CAMPAIGN,
  GROW_ADMIN_LOCAL_MISSION_MENU,
  GROW_DISABLE_FOCUS_SKU,
} from "../../config/featureToggles";
import { Campaigns } from "../../domains/Campaigns";
import GetAllCampaignsResponse from "../../services/campaigns/domains/GetAllCampaignsResponse";

export default function GetAllCampaignsConverter(
  response: Array<GetAllCampaignsResponse>,
  isGrowOrganizationActivationEnable: boolean,
): Campaigns {
  const isLocalMissionsMenuEnabled = isFeatureEnabled(
    GROW_ADMIN_LOCAL_MISSION_MENU,
  );

  const isDisableB2oCampaign = isFeatureEnabled(
    GROW_ADMIN_DISABLE_B2O_CAMPAIGN,
  );

  const isDisableFocusSKUCampaign = isFeatureEnabled(GROW_DISABLE_FOCUS_SKU);

  let campaigns: GetAllCampaignsResponse[] | undefined = [];

  campaigns = response
    ? response?.map((campaign) => ({
        campaignTag: campaign.campaignTag,
        campaignName: campaign.campaignName,
        campaignType: campaign.campaignType,
        campaignDescription: campaign.campaignDescription,
        id: campaign.id,
        segmentation: campaign.segmentation,
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        products:
          campaign[
            isGrowOrganizationActivationEnable ? "campaignItems" : "products"
          ]?.map((product) => ({
            sku: product[
              isGrowOrganizationActivationEnable ? "vendorItemId" : "sku"
            ],
            filterOptions: {
              deliveryCenterId: product?.filterOptions?.deliveryCenterId,
              segment: product?.filterOptions?.segment,
            },
          })) || [],
      }))
    : undefined;

  if ((isLocalMissionsMenuEnabled || isDisableB2oCampaign) && campaigns)
    campaigns = [...campaigns].filter(
      (campaign) => campaign.campaignType !== "b2o",
    );

  if (isDisableFocusSKUCampaign && campaigns) {
    campaigns = [...campaigns].filter(
      (campaign) => campaign.campaignType !== "focus_sku",
    );
  }

  const sortedCampaigns = campaigns
    ? [...campaigns].sort((a, b) => {
        return a.campaignName
          .normalize()
          .localeCompare(b.campaignName.normalize());
      })
    : [];

  return {
    campaigns: sortedCampaigns,
  };
}

import MessageMap from "../domains/MessageMap";

export const translationPT: MessageMap = {
  FORMAT: {
    formattedDate: "{{date, DD/MM/YYYY}}",
    FORMAT_DATE_SHORT: "DD/MM/YYYY",
    FORMAT_DATE_SHORT_SEGMENT: "D/M/yyyy",
    FORMAT_DATE_SHORT_FILE: "D-MM-yyyy",
    FORMAT_DATE_LONG_FILE: "DD-MM-YYYY",
    DATE_FORMAT_TABLE_RENDER: "{{date, DD MMMM, YYYY}}",
    DATE_FORMAT_TABLE: "DD MMMM, YYYY",
  },
  ADMIN: {
    TITLE: "Rewards - Admin",
    PANEL_HEADER: "Admin Portal",
    LOADING: "Carregando...",
    VIEW: "Visualizar",
    EDIT: "Editar",
    REMOVE: "Remover",
    DELETE: "Deletar",
    DUPLICATE: "Duplicado",
    REVIEW_ID: "Revisar IDs",
    ACTIONS: "Ações",
    NAME: "Nome",
    SKU: "Sku",
    SKU_ID: "SKU ID",
    SKU_ID_PLACEHOLDER: "Digite o SKU ID aqui...",
    QUANTITY: "Quantidade",
    IMAGE_SUPPORTED_MAX_1MB:
      "São suportadas extensões de arquivo PNG e JPG. O tamanho máximo do arquivo é 1 MB.",
    TABLE_PAGINATION_INITIAL_COUNT:
      "Mostrando {{showingCount}} de {{totalRows}}",
    TABLE_PAGINATION_COUNT:
      "Mostrando {{showingCount}} a {{toCount}} de {{totalRows}}",
    SIGN_OUT: "Sair",
    LOGOUT: "Logout",
    LOGOUT_DIALOG: "Deseja realmente sair do BEES GROW ADMIN PORTAL?",
    STEP_1: "Step 1",
  },
  LOADING: {
    BEELOADING_TEXT: "Processando…",
  },
  BUTTON: {
    UPDATE: "Atualizar",
    CONTINUE: "Continuar",
    CANCEL: "Cancelar",
    CLOSE: "Fechar",
    CONFIRM: "Confirmar",
    SAVE: "Salvar",
    SAVE_DRAFT: "Salvar rascunho",
    SUBMIT: "Enviar",
    BACK: "Voltar",
    BACK_STEP: "Voltar para a etapa anterior",
    REMOVE: "Remover",
    DELETE: "Deletar",
    ADD_FILTER: "Adicionar um filtro",
    CREATE_ANOTHER_CHALLENGE: "Criar outro desafio",
    UPLOAD_CSV: "Carregar CSV",
    UPLOAD_CSV_FILE: "Carregar um arquivo CSV",
    YES_SIGN_OUT: "Sim, sair",
    DOWNLOAD_CSV: "Download CSV",
    CREATE_NEW: "Criar nova",
  },
  ERROR_MESSAGE: {
    DUPLICATED_SKU: "Alguns SKU e tags de campanha estão duplicados.",
    EMPTY_FIELD: "Por favor insira um valor.",
    INVALID_DATE: "Data inválida.",
    INVALID_START_DATE: "Você não pode criar desafios no passado.",
    INVALID_END_DATE: "A data de término deve ser posterior à data de início.",
    INVALID_URL: "Por favor, insira um URL válido.",
    INVALID_NUMBER_VALUE: "Valor numérico inválido. O número mínimo é 0.",
    MAX_GREATER_THAN_0:
      "Quantidade máxima deve ser um número inteiro maior que 0.",
    MAX_SIZE_GROUP_FILE_UPLOAD: "O tamanho máximo do arquivo é 1 MB.",
    MAX_SIZE_5MB: "O tamanho máximo do arquivo é 5 MB.",
    UNSUPPORTED_FILE:
      "Este é um arquivo inválido, tente novamente seguindo as instruções acima.",
    UNSUPPORTED_IMAGE: "Extensões de arquivo compatíveis: .jpg; .png",
    UNSUPPORTED_CSV: "Extensão de arquivo compatível: .csv",
    UNSUPPORTED_CSV_REWARDS_OFFERS_MULTIPLE:
      "Faça upload de um .csv com no máximo 5.000 linhas e colunas poc_id, campaign_id, points. Colunas separadas por ponto e vírgula, campaign_id é opcional.",
    EMPTY_FILE_FIELD: "Selecione um arquivo para enviar.",
    UPSERT_ACCOUNT_GROUP: "Ocorreu um erro ao criar o grupo, tente novamente.",
    UPLOAD_FILE_ACCOUNT_GROUP:
      'Houve um problema com seu arquivo. Faça upload de um .csv com uma lista de IDs POC no cabeçalho da coluna "POC_ID"',
    NO_CHALLENGE_FOUND_BY_ID: "O ID do desafio não é válido.",
    CHALLENGE_FOUND_BY_ID:
      "O ID do desafio fornecido já existe. Forneça um ID diferente",
    NO_SKU_FOUND_BY_ID: "Insira um SKU válido.",
    NO_RULE_FOUND_BY_ID: "O ID da regra não é válido.",
    SKU_QUANTITY_INVALID:
      "A quantidade deve ser um número inteiro maior que 0.",
    NO_DUPLICATED_SKU: "Este SKU já está incluído.",
    GENERIC_ERROR: "Algo deu errado. Por favor, tente novamente mais tarde.",
    PAGE_LEVEL_ERROR_DRAFT_MESSAGE:
      "Para salvar este rascunho, preencha os campos obrigatórios em ",
    PAGE_LEVEL_ERROR_CHALLENGE_MESSAGE:
      "Para enviar este desafio, preencha todos os campos em ",
    PAGE_LEVEL_ERROR_CHALLENGE_MESSAGE_CONTINUE:
      "Para continuar, preencha todos os campos ",

    INVALID_ORDER_ID: "ID do pedido inválido",
    NO_REFUNDABLE_FOUND: "Nenhum combo reembolsável foi encontrado",
    CHALLENGE_SUBMIT_FAIL:
      "O desafio não foi publicado. Por favor, tente novamente.",

    GENERIC_LOAD_ERROR: "Algo deu errado.",
    GENERIC_TRY_AGAIN: "Tentar de novo",
  },

  RULES_FLOW: {
    START: "Começa",
    END: "Fim",
    SKUS_LIST_ADD_SKU_BUTTON: "+ ADD SKU",
    SKUS_LIST_SEARCH_SKUS: "Buscar SKUs",
    SKUS_LIST_COLUMN_SKU: "SKU",
    SKUS_LIST_COLUMN_SKU_NAME: "Nome SKU",
    SKUS_LIST_ACTION_CHANGE: "Alterar regra",
    SKUS_LIST_ACTION_REMOVE: "Remover",
    SKUS_LIST_EMPTY_MESSAGE: "Nenhum item foi adicionado",
    REMOVE_DIALOG_TITLE: "Remover SKU",
    VALIDATION_SKU_INVALID: "Insira um SKU válido",
    OPTIONAL_FIELD: "(Opcional)",
    EMPTY_DATA_TABLE: "Nenhum item está disponível para exibição",
  },
  CAMPAIGNS: {
    CAMPAIGNS_TITLE: "Campanhas",
    DDC: "DDC",
    SEGMENT: "Segmento",
    NOT_FOUND: "Não há campanhas a serem exibidas.",
  },
  PRODUCTS: {
    NO_DATA_AVAILABLE: "Sem dados disponíveis",
    UNIT_PRICE: "Preço (un)",
    NOT_FOUND: "Não há itens a serem exibidos.",
  },
  ErrorHandlerRetry: {
    INFORMATION_NOT_FOUND: "Informação não encontrada",
    RELOAD_BUTTON_LABEL: "Recarregar lista",
  },
  MODAL_CAMPAIGNS: {
    CREATE_BUTTON: "Criar",
    EDIT_BUTTON: "Confirmar",
    CANCEL_BUTTON: "Cancelar",
    TITLE_MODAL_NEW_CAMPAIGN: "Nova campanha",
    TITLE_MODAL_EDIT_CAMPAIGN: "Editar campanha",
    CAMPAIGN_NAME: "Nome de campanha",
    CAMPAIGN_TAG: "Nome da etiqueta",
    SKU_ID: "SKU ID",
    DDC_OPTIONAL: "DDC (Opcional)",
    SEGMENT_OPTIONAL: "Segment (Opcional)",
    CAMPAIGN_NAME_PLACEHOLDER: "Digite o nome da campanha aqui...",
    CAMPAIGN_DESCRIPTION: "Descrição da campanha",
    DESCRIPTION: "Descrição",
    CAMPAIGN_SCRIPT: "Roteiro da campanha",
    CAMPAIGN_DESCRIPTION_PLACEHOLDER: "Digite a descrição aqui...",
    CAMPAIGN_SCRIPT_PLACEHOLDER: "Digite o roteiro aqui...",
    TAG_PLACEHOLDER: "Digite o nome da Tag aqui...",
    DDC_PLACEHOLDER: "Digite o código DDC aqui...",
    SEGMENT_PLACEHOLDER: "Digite o código do segmento aqui...",
    ERROR_HANDLER: "Algo deu errado. Por favor, tente novamente mais tarde.",
    ERROR_LIMIT_CSV: "O campo csv excede seu tamanho máximo permitido de 1MB.",
    MESSAGE_SUCCESS: "A campanha foi criada com sucesso.",
    MESSAGE_SUCCESS_UPDATE: "A campanha foi editada com sucesso.",
    MESSAGE_SUCCESS_DELETE: "A campanha foi excluída com sucesso.",
    MSG_MANDATORY_CAMPAIGN_NAME: "Este campo é obrigatório.",
    MSG_MANDATORY_CAMPAIGN_TAG: "Este campo é obrigatório.",
    MSG_MANDATORY_CAMPAIGN_DESCRIPTION: "Este campo é obrigatório.",
    MSG_MANDATORY_CAMPAIGN_SCRIPT: "Este campo é obrigatório.",
    MSG_MAX_SIZE_CAMPAIGN_TAG:
      "A frase é muito longa. Por favor, não exceda 30 caracteres.",
    MSG_NO_ACCEPT_SPACES_CAMPAIGN_TAG:
      "Este campo não aceita espaços. Por favor escreva sem espaçamento.",
    MSG_START_WITH_PLACEHOLDER_CAMPAIGN_TAG: 'Insira "#" antes do nome da tag.',
    MSG_AT_LEAST_ONE_CAMPAIGN_SKU: "Este campo é obrigatório.",
    START_DATE: "Data de início",
    END_DATE: "Data final",
    CSV_LIST: "Carregar POC IDs",
    COVER_IMAGE: "Cover image (670x90)",
    DRAG_DROP_CSV: "Arraste e solte o arquivo CSV",
    DRAG_DROP_IMAGE: "Arraste e solte uma imagem .png ou .jpg",
    CSV_FILE_REQUIRED: "Este campo é obrigatório.",
    MSG_END_DATE_BEFORE_START_DATE:
      "A data de término não pode ser anterior ou igual à data de início.",
    SEGMENTATION: "Especialização do agente",
    SEGMENTATION_PLACEHOLDER: "ex: 387",
    PRIMARY: "Missão principal",
    SECONDARY: "Missão secundária",
    PRIMARY_TOOLTIP:
      "Os POCs da campanha serão selecionados para contato com a Campanha B2O como missão principal.",
    SECONDARY_TOOLTIP:
      "Os POCs da campanha serão incluídos como uma missão adicional para o POC que já estava planejado para ser contatado no dia.",
    AGENT_SPECIALIZATION_TOOLTIP:
      "Defina uma segmentação para identificar agentes específicos que trabalharão nesta campanha.",
    DOWNLOAD_FILE: "Baixe aqui o arquivo de exemplo",
  },
  DELETE_CAMPAIGNS: {
    DELETE_CAMPAIGN: "Excluir campanha",
    DELETE_CAMPAIGN_DESCRIPTION: "Deseja mesmo excluir esta campanha?",
    DELETE: "Deletar",
  },
  OPTIONS: {
    EDIT: "Editar",
    DELETE: "Deletar",
  },
  SEARCH_OPTIONS: {
    TOTAL_CAMPAIGN_NAMES: "resultados no nome da campanha",
    TOTAL_CAMPAIGN_NAME: "resultado no nome da campanha",
    TOTAL_DELIVERY_CENTER_IDS: "resultados em DDC",
    TOTAL_DELIVERY_CENTER_ID: "resultado em DDC",
    TOTAL_SEGMENTS: "resultados no segmento",
    TOTAL_SEGMENT: "resultado no segmento",
    TOTAL_SKUS: "resultados no SKU",
    TOTAL_SKU: "resultado no SKU",
    NO_CAMPAIGN: "Não há campanhas a serem exibidas.",
  },
  AGENT_SEGMENTATION: {
    CONFIRM_SEGMENTATION: "Confirmação",
    DESCRIPTION_CONFIRM_SEGMENTATION:
      "Tem certeza de que deseja atualizar a segmentação do agente?",
    MODAL_TITLE: "Configuração do agente",
    SEGMENTATION: "Segmentação",
    AGENT: "Agente",
    CONFIRM_BUTTON: "Confirmar",
    PLACEHOLDER_SEGMENTATION: "Selecione ou digite o nome do agente",
    UPDATE_MESSAGE: "A segmentação do agente foi atualizada com sucesso.",
    ERROR_HANDLER: "Algo deu errado. Por favor, tente novamente mais tarde.",

    AGENT_SETUP: "Configuração do agente",
    AGENT_SETUP_SUBTITLE: "É possível definir especializações para um agente.",
    AGENT_SETUP_PLACEHOLDER: "Selecione um agente",
    MISSIONS_MANAGEMENT_TITLE: "Gerenciamento de missões",
    SPECIALIZATION_TITLE: "Especialização",
    INSERT_SPECIALIZATION_PLACEHOLDER: "Inserir especialização",
    INSERT_SPECIALIZATION_ADD_BUTTON: "Adicionar",
    SAVE_CHANGES: "As alterações foram feitas com sucesso.",
    SAVE_ERROR:
      "Algo deu errado! Suas alterações não foram salvas, tente novamente.",
    MISSIONS_AND_SPECIALIZATION_APPLY_CHANGES: "Aplicar mudanças",
    NO_AGENT_FOUND: "Nenhum agente encontrado",
    ERROR_TO_LOAD: "Não foi possível carregar a configuração do agente",

    APPLY_CHANGES_MODAL_TITLE: "Tem certeza que deseja fazer essas mudanças?",
    APPLY_CHANGES_MODAL_DESCRIPTION:
      "Levará apenas alguns minutos para ativar suas alterações.",
    APPLY_CHANGES_CONFIRM: "Sim, aplicar",
    APPLY_CHANGES_CANCEL: "Não, cancelar",
  },
  GLOBAL_MESSAGE: {
    NO_DATA_AVAILABLE: "Sem dados disponíveis",
  },
  TYPE: {
    B2O: "B2O",
    FOCUS_SKU: "Focus SKU",
  },
  POC: {
    POC_WITH_ERROR: "POCs com erros ",
    CONTACTED: "Contatado ",
    TO_BE_CONTACTED: "A ser contactado ",
  },
  BLOCK_LIST: {
    MODAL_TITLE: "Block list",
    CSV_TITLE: "Lista CSV",
    CONFIRM_BUTTON: "Confirmar",
    DESCRIPTION: "Tem certeza de que deseja atualizar a block list?",
    UPDATE_MESSAGE:
      "A lista de bloqueio foi atualizada com sucesso e ficará disponível amanhã.",
    DELETE_MESSAGE:
      "A lista de bloqueio foi excluída com sucesso. Esta mudança entrará em vigor amanhã.",
    ERROR_HANDLER: "Algo deu errado, a block list não foi atualizada.",
    NO_CSV: "A lista de bloqueio não pôde ser atualizada. Tente novamente.",
    CURRENT_BLOCK_LIST: "A lista atual de bloqueio não pôde ser recuperada.",
    LOADING: "Carregando a block list..",
    DRAG_DROP_CSV: "Arraste e solte o arquivo CSV",
    CURRENT_CSV: "Baixar block list atual",
    DELETE_DESCRIPTION: "Tem certeza de que deseja excluir a block list?",
    REFRESH_PAGE: "Atualizar a página",
  },
  MANAGEMENT: {
    TITLE: "Management",
  },
  MISSIONS_MANAGEMENT: {
    TITLE: "Gerenciamento de missões",
    PROMOTE_REWARDS_CHALLENGE_MISSION_TITLE: "Desafios",
    SPONSORED_MISSION_TITLE: "Patrocinadas",
    SERVICE_MISSION_TITLE: "Serviços",
    SERVICE_MISSION_TOOLTIP: "Esta missão requer exclusividade do agente",
    LOCAL_MISSIONS_TITLE: "Missões locais",
  },
  LOCAL_MISSIONS: {
    TITLE: "Missões locais",
  },
  TEAM_STRUCTURE: {
    TITLE: "Estrutura do time",
  },
  GLOBAL_MISSIONS: {
    TITLE: "Missões globais",
  },
  TEAM_MANAGEMENT: {
    TITLE: "Times",
  },
};

import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { isFeatureEnabled } from "grow-shared-services";
import {
  GROW_ORGANIZATION_ACTIVATION,
  TLP_MULTICONTRACT_ACCOUNT_SERVICE,
} from "@config/featureToggles";
import axios from "../../config/axios/axiosInstance";
import { TLP_ACCOUNT_SERVICE } from "../../config/services";
import {
  GetAllFailedPocsConverter,
  GetAllFailedPocsConverterV3,
} from "../../converters/campaigns/GetAllFailedPocsConverter";
import { PocInformation, PocInformationV3 } from "../../domains/PocInformation";
import { getEndPoint, getApiHost } from "../host/HostService";
import {
  AllFailedPocsRequestPropsV3,
  AllFailedPocsRequestProps,
} from "./domains/GetAllFailedPocsResponse";

export const ENDPOINT_PATH_V1 = "/tlp-account/admin";
export const ENDPOINT_PATH_V2 = "/tlp-account/admin/v2";
export const ENDPOINT_PATH_V3 = "/v3/accounts-campaign";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export const getAllFailedPocsRequest = ({
  params,
  headers,
  url,
}: AllFailedPocsRequestPropsV3 | AllFailedPocsRequestProps): Promise<
  PocInformation | PocInformationV3
> => {
  const isGrowOrganizationActivation = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );
  const log = useLogService();
  return axios
    .get(`${url}`, {
      params,
      headers,
    })
    .then((response) => {
      log.info(`Convert Get All Campaigns received from ${url} to our model`);

      return isGrowOrganizationActivation
        ? GetAllFailedPocsConverterV3(response.data)
        : GetAllFailedPocsConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
};

export const getAllFailedPocs = (
  campaignTag: string,
  campaignType: string,
  vendorId: string,
  vendorName: string,
  vendorIdMulticontract: string | undefined,
): Promise<PocInformation | PocInformationV3> => {
  const isMulticontractServiceEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_ACCOUNT_SERVICE,
  );

  const endpointPath = isMulticontractServiceEnable
    ? `${ENDPOINT_PATH_V2}/${campaignTag}/pocid-infos?vendorId=${
        vendorIdMulticontract || ""
      }`
    : `${ENDPOINT_PATH_V1}/${campaignTag}/pocid-infos`;

  const url = `${getApiHost()}${getEndPoint(
    TLP_ACCOUNT_SERVICE,
  )}${endpointPath}`;

  return getAllFailedPocsRequest({
    params: { campaignType },
    headers: {
      vendorId,
      vendorName,
    },
    url,
  });
};

export const getAllFailedPocsV3 = (
  campaignTag: string,
  country: string,
  orgId: string,
  requestTraceId: string,
  projection: "INVALID_ACCOUNTS" | "ALL",
): Promise<PocInformationV3 | PocInformation> => {
  const endpointPath = `${ENDPOINT_PATH_V3}/${campaignTag}`;

  const url = `${getApiHost()}${getEndPoint(
    TLP_ACCOUNT_SERVICE,
  )}${endpointPath}`;

  return getAllFailedPocsRequest({
    params: { projection },
    headers: {
      country,
      orgId,
      requestTraceId,
    },
    url,
  });
};

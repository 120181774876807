import { createStyles, makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#505050",
      letterSpacing: "0",
      lineHeight: "24px",
      borderBottom: "1px solid #9e9e9e",
      paddingBottom: "8px",
    },
    dialogBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      padding: "18px",
    },
    dialogAction: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    dialogContainer: {
      width: "370px",
      height: "266px",
      margin: "0",
    },
    description: {
      color: "#505050",
    },
    titleBox: {
      padding: "8px",
      margin: "8px",
    },
    descriptionContent: {
      padding: "8px",
      margin: "8px",
    },
    button: {
      height: "40px",
      width: "148px",
      fontSize: "14px",
      fontWeight: 600,
      display: "flex",
      borderRadius: "3px",
      textTransform: "uppercase",
    },
    cancelButton: {
      backgroundColor: "#eaeaea",
      color: "#7a7a7a",
    },
    confirmationButton: {
      backgroundColor: "#505050",
      color: theme.palette.common.white,
      margingLeft: "10px",
    },
    test: {
      color: "black",
    },
  }),
);

export default styles;

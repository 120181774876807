import { makeStyles, createStyles } from "@material-ui/core/styles";

const styles = makeStyles(() =>
  createStyles({
    title: {
      color: "#505050",
      fontSize: "12px",
      fontWeight: "bold",
    },
    draggableFlexContainer: {
      flex: 1,
      border: "0.5px solid #C0C3C8",
      borderRadius: "0.4rem",
    },
    draggableContainer: {
      backgroundColor: "white",
      borderRadius: "0.4rem",
    },
  }),
);

export default styles;

import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    flexContainer: {
      flex: 1,
      minWidth: 350,
    },
    divider: {
      padding: "0 1rem",
    },
  }),
);

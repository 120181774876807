import { makeStyles, createStyles } from "@material-ui/core";

const styles = makeStyles(() =>
  createStyles({
    createNewButton: {
      width: "170px",
      height: "32px",
      fontSize: "14px",
      fontFamily: "roboto",
      borderRadius: "3px",
    },
    addIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "32px",
    },
    label: {
      width: "137px",
      height: "32px",
      fontWeight: "bold",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderLeft: "1px solid #ffffff",
    },
  }),
);

export default styles;

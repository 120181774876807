import { createEffect, createEvent } from "effector";
import { execute } from "../../../usecase/campaigns/items/CampaignItemsUseCase";

interface RequestCampaignItems {
  campaingTag: string;
  vendorId: string;
  vendorName: string;
  country?: string;
  segment: string[];
  deliveryCenterId: string[];
}

export const getCampaignItemsEffect = createEffect({
  async handler(requestCampaignItems: RequestCampaignItems) {
    return execute(
      requestCampaignItems.campaingTag,
      requestCampaignItems.vendorId,
      requestCampaignItems.vendorName,
      requestCampaignItems.segment,
      requestCampaignItems.deliveryCenterId,
      requestCampaignItems.country,
    );
  },
});

export const clearCampaignItems = createEvent("clearCampaignItems");

export const setHasBeenAddedToDb = createEvent<boolean>("setHasBeenAddedToDb");

export const setCalledTag = createEvent<string>("setCalledTag");

import { isFeatureEnabled } from "grow-shared-services";
import { TLP_MULTICONTRACT_CAMPAIGN_SERVICE } from "../../config/featureToggles";
import { Campaigns } from "../../domains/Campaigns";
import { getAllCampaigns } from "../../services/campaigns/GetAllCampaignsService";

export const execute = (
  isCanaryRelease?: boolean,
  vendorId?: string,
  vendorName?: string,
  isCampaignFocusVendorEnabled?: boolean,
): Promise<Campaigns> => {
  const isMulticontractCampaignServiceEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
  );

  let vendorIdParam = "";
  let vendorNameParam = "";

  if (
    !isMulticontractCampaignServiceEnable &&
    vendorId &&
    vendorName &&
    isCampaignFocusVendorEnabled
  ) {
    vendorIdParam = vendorId;
    vendorNameParam = vendorName;
  }

  if (isMulticontractCampaignServiceEnable && vendorId) {
    vendorIdParam = vendorId;
  }

  return getAllCampaigns(false, vendorIdParam, vendorNameParam);
};

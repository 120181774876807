/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { FormikValues, FormikTouched, FormikErrors } from "formik";
import { Box } from "@material-ui/core";
import classNames from "classnames";
import { formatMessage } from "../../../../i18n/formatters";
import SkuID from "../skuID/SkuID";
import useStyles from "../modalGeneric/ModalGenericCampaignStyle";
import DragAndDrop from "../dragDrop/DragDrop";

interface FocusSkuFormProps {
  titleModal: string;
  valuesForm: FormikValues;
  handleChange: (
    eventOrPath: string | React.ChangeEvent<any>,
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  touched: FormikTouched<FormikValues>;
  errors: FormikErrors<FormikValues>;
}

const FocusSkuForm: React.FC<FocusSkuFormProps> = ({
  titleModal,
  valuesForm,
  handleChange,
  setFieldValue,
  touched,
  errors,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.containerInner}>
        <label htmlFor="campaignName" className={classes.label}>
          <span
            className={classes.FirstlabelText}
            data-testid="modal-campaign-label-name"
          >
            {formatMessage({ id: "MODAL_CAMPAIGNS.CAMPAIGN_NAME" })}
          </span>

          <input
            id="campaignName"
            name="campaignName"
            type="text"
            placeholder={formatMessage({
              id: "MODAL_CAMPAIGNS.CAMPAIGN_NAME_PLACEHOLDER",
            })}
            className={classNames(classes.inputText, {
              [classes.details]: !(
                /* istanbul ignore next */
                (!!errors.campaignName && touched.campaignName)
              ),
              [classes.detailsError]:
                /* istanbul ignore next */
                !!errors.campaignName && touched.campaignName,
            })}
            value={valuesForm.campaignName}
            onChange={handleChange}
            data-testid="modal-campaign-input-name"
          />
          {
            /* istanbul ignore next */
            !!errors.campaignName && touched.campaignName && (
              <span
                className={classes.errorMessage}
                data-testid="modal-campaign-input-name-error"
              >
                {errors.campaignName}
              </span>
            )
          }
        </label>
        <label htmlFor="campaignTag" className={classes.label}>
          <span
            className={classes.labelText}
            data-testid="modal-campaign-label-tag"
          >
            {formatMessage({ id: "MODAL_CAMPAIGNS.CAMPAIGN_TAG" })}
          </span>
          <input
            id="campaignTag"
            name="campaignTag"
            type="text"
            placeholder={`# ${formatMessage({
              id: "MODAL_CAMPAIGNS.TAG_PLACEHOLDER",
            })}`}
            className={classNames(classes.inputText, {
              [classes.details]: !(
                /* istanbul ignore next */
                (!!errors.campaignTag && touched.campaignTag)
              ),
              [classes.detailsError]:
                /* istanbul ignore next */
                !!errors.campaignTag && touched.campaignTag,
            })}
            value={valuesForm.campaignTag}
            onChange={handleChange}
            data-testid="modal-campaign-input-tag"
            disabled={
              titleModal ===
              formatMessage({ id: "MODAL_CAMPAIGNS.TITLE_MODAL_EDIT_CAMPAIGN" })
            }
          />
          {
            /* istanbul ignore next */
            !!errors.campaignTag && touched.campaignTag && (
              <span
                className={classes.errorMessage}
                data-testid="modal-campaign-input-tag-error"
              >
                {errors.campaignTag}
              </span>
            )
          }
        </label>
        <SkuID
          skus={valuesForm.skus}
          handleChange={
            /* istanbul ignore next */
            (skus) => {
              setFieldValue("sku_0", skus[0]);
              setFieldValue("skus", skus);
            }
          }
          errors={errors}
          touched={touched}
        />
        <label htmlFor="ddc" className={classes.label}>
          <span
            className={classes.labelText}
            data-testid="modal-campaign-label-ddc"
          >
            {formatMessage({ id: "MODAL_CAMPAIGNS.DDC_OPTIONAL" })}
          </span>
          <input
            id="ddc"
            name="ddc"
            type="text"
            placeholder={formatMessage({
              id: "MODAL_CAMPAIGNS.DDC_PLACEHOLDER",
            })}
            className={classes.inputText}
            value={valuesForm.ddc}
            onChange={handleChange}
            data-testid="modal-campaign-input-ddc"
          />
        </label>

        <label htmlFor="campaignSegment" className={classes.label}>
          <span
            className={classes.labelText}
            data-testid="modal-campaign-label-segment"
          >
            {formatMessage({ id: "MODAL_CAMPAIGNS.SEGMENT_OPTIONAL" })}
          </span>
          <input
            id="campaignSegment"
            name="segment"
            type="text"
            placeholder={formatMessage({
              id: "MODAL_CAMPAIGNS.SEGMENT_PLACEHOLDER",
            })}
            className={classes.inputText}
            value={valuesForm.segment}
            onChange={handleChange}
            data-testid="modal-campaign-input-segment"
          />
        </label>
        {titleModal ===
        formatMessage({
          id: "MODAL_CAMPAIGNS.TITLE_MODAL_EDIT_CAMPAIGN",
        }) ? null : (
          <label htmlFor="csvList" className={classes.label}>
            <span
              className={classes.labelText}
              data-testid="modal-campaign-label-csvList"
            >
              {formatMessage({ id: "MODAL_CAMPAIGNS.CSV_LIST" })}
            </span>
            <DragAndDrop
              onSelect={(files) => {
                setFieldValue("csvList", files[0]);
              }}
              fileTypeAccepted={[".csv"]}
              maxFiles={1}
              text={formatMessage({ id: "MODAL_CAMPAIGNS.DRAG_DROP_CSV" })}
              testId="csv-list-sku-focus"
            />
            {
              /* istanbul ignore next */
              !!errors.csvList && touched.csvList && (
                <span
                  className={classes.errorMessage}
                  data-testid="modal-campaign-input-tag-error"
                >
                  {errors.csvList}
                </span>
              )
            }
          </label>
        )}
      </Box>
    </>
  );
};

export default FocusSkuForm;

import React from "react";

const LoadingBeesIcon: React.FC = () => {
  return (
    <svg
      width="67"
      height="42"
      viewBox="0 0 67 42"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-trash-2"
    >
      <defs>
        <circle id="a" cx="3.5" cy="3.5" r="3.5" />
        <circle id="c" cx="3.5" cy="3.5" r="3.5" />
        <circle id="e" cx="3.5" cy="3.5" r="3.5" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <path
            d="M21.595 8.86C29.827 1.111 30.1.027 33.243.027c2.46 0 6.055 1.853 6.055 6.232 0 1.353-.346 2.85-1.227 3.695L21.595 8.859z"
            fill="#E1DCF1"
          />
          <path
            d="M21.132 8.29c10.95-3.095 13.7-4.074 15.57-4.074 3.82 0 7.971 3.274 7.971 8.12 0 3.212-2.296 6.855-4.744 7.57L21.132 8.29z"
            fill="#F0ECFC"
          />
          <path
            d="m30.72 26.227.14-.256c-.685 8.119-5.922 14.221-7.742 14.952-1.82.732-9.822-.049-15.934-5.436 6.996 2.325 19.827-2.725 23.537-9.26zm-3.93-14.638.001.001c.946 1.3 1.834 2.912 2.633 4.902.386.961.69 1.914.92 2.853l.13.562v.001c-.036.085-2.418 5.506-12.022 9.4-9.628 3.836-15.098 1.57-15.183 1.535h-.002c-.6-.978-1.14-2.04-1.603-3.193C.864 25.66.39 23.881.173 22.29c.149.053 5.1 1.812 14.655-2 9.276-3.756 11.76-8.303 11.95-8.675l.011-.022.002-.003zm-4.138-3.75.007.02C20.25 12.396 5.477 18.351.574 16.734l-.007-.02C-1.474 11.599 2.776 6.482 8.377 4.23c5.6-2.25 12.207-1.498 14.275 3.61z"
            fill="#000"
          />
          <path
            d="M4.481 11.33c.15.965-.227 1.617-.803 1.706-.576.09-1.133-.417-1.283-1.383-.15-.966.165-2.025.74-2.114.576-.09 1.196.824 1.346 1.79z"
            fill="#FFF"
          />
          <path
            d="M18.452 29.308c-9.628 3.836-15.098 1.57-15.183 1.535a20.458 20.458 0 0 0 3.915 4.644c7.087 2.355 20.161-2.857 23.677-9.516a20.463 20.463 0 0 0-.386-6.063c-.037.085-2.419 5.506-12.023 9.4zm8.338-17.72v.001s-2.282 4.78-11.962 8.7C5.128 24.16.173 22.289.173 22.289v-.001c-.3-2.197-.102-4.016.401-5.552 4.903 1.617 19.676-4.338 22.085-8.877.714.38 1.42.865 2.112 1.478a13.98 13.98 0 0 1 2.02 2.253z"
            fill="#FF0"
          />
        </g>
        <g transform="translate(30.523 32.736)">
          <mask id="b" fill="#fff">
            <use href="#a" />
          </mask>
          <g mask="url(#b)" fill="#939191">
            <path d="M0 0h7v7H0z" />
          </g>
        </g>
        <g transform="translate(41.523 32.736)">
          <mask id="d" fill="#fff">
            <use href="#c" />
          </mask>
          <g mask="url(#d)" fill="#000">
            <path d="M0 0h7v7H0z" />
          </g>
        </g>
        <g transform="translate(52.523 32.736)">
          <mask id="f" fill="#fff">
            <use href="#e" />
          </mask>
          <g mask="url(#f)" fill="#939191">
            <path d="M0 0h7v7H0z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LoadingBeesIcon;

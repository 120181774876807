import { useHasPermission } from "admin-portal-shared-services";

import { isFeatureEnabled } from "grow-shared-services";
import {
  GROW_ADMIN_LOCAL_MISSION_MENU,
  TEAM_STRUCTURE,
  TLP_PRIMARY_MISSIONS_MANAGEMENT,
  GROW_ADMIN_DISABLE_B2O_CAMPAIGN,
  GROW_DISABLE_FOCUS_SKU,
  GROW_ADMIN_BETWEEN_MISSION_MANAGEMENT,
  GROW_ORGANIZATION_ACTIVATION,
} from "../../config/featureToggles";

interface PropsReturn {
  isTeamStructureEnabled: boolean;
  isMissionsManagementEnabled: boolean;
  isGrowAdminLocalMissionMenuEnabled: boolean;
  isDisableB2oCampaign: boolean;
  isDisableFocusSKUCampaign: boolean;
  isGrowAdminBetweenMissionManagement: boolean;
  isGrowOrganizationActivation: boolean;
  hasBeesGrowAll: boolean;
  hasMissionManagementWrite: boolean;
  hasCampaignWritePermission: boolean;
  hasBlockListWritePermission: boolean;
  hasAgentWritePermission: boolean;
  hasTeamStructureWritePermission: boolean;
  hasTeamStructureReadPermission: string;
  hasTeamsOrganizationPermission: boolean;
}

/* istanbul ignore next */
const useSideBar = (): PropsReturn => {
  const isTeamStructureEnabled = isFeatureEnabled(TEAM_STRUCTURE);

  const isMissionsManagementEnabled = isFeatureEnabled(
    TLP_PRIMARY_MISSIONS_MANAGEMENT,
  );

  const isGrowAdminLocalMissionMenuEnabled = isFeatureEnabled(
    GROW_ADMIN_LOCAL_MISSION_MENU,
  );

  const isDisableB2oCampaign = isFeatureEnabled(
    GROW_ADMIN_DISABLE_B2O_CAMPAIGN,
  );

  const isDisableFocusSKUCampaign = isFeatureEnabled(GROW_DISABLE_FOCUS_SKU);

  const isGrowAdminBetweenMissionManagement = isFeatureEnabled(
    GROW_ADMIN_BETWEEN_MISSION_MANAGEMENT,
  );

  const isGrowOrganizationActivation = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const hasBeesGrowAll = useHasPermission("BeesGrow.All.Write");
  const hasMissionManagementWrite = useHasPermission("BeesGrow.Mission.Write");
  const hasCampaignWritePermission = useHasPermission(
    "BeesGrow.Campaign.Write",
  );
  const hasBlockListWritePermission = useHasPermission(
    "BeesGrow.BlockList.Write",
  );
  const hasAgentWritePermission = useHasPermission("BeesGrow.Agent.Write");

  const hasTeamStructureWritePermission = useHasPermission(
    "BeesGrow.TeamStructure.Write",
  );

  // temporarily validate Read  team structure READ permission without Scope created
  const hasTeamStructureReadPermission = "BeesGrow.TeamStructure.Write";

  const hasTeamsOrganizationPermission = useHasPermission(
    "BeesGrow.Organization.Write",
  );

  return {
    isTeamStructureEnabled,
    isMissionsManagementEnabled,
    isGrowAdminLocalMissionMenuEnabled,
    isDisableB2oCampaign,
    isDisableFocusSKUCampaign,
    isGrowAdminBetweenMissionManagement,
    isGrowOrganizationActivation,
    hasBeesGrowAll,
    hasMissionManagementWrite,
    hasCampaignWritePermission,
    hasBlockListWritePermission,
    hasAgentWritePermission,
    hasTeamStructureWritePermission,
    hasTeamStructureReadPermission,
    hasTeamsOrganizationPermission,
  };
};

export default useSideBar;

/* eslint-disable react/require-default-props */
import React from "react";

import { getUserInfos } from "@config/utils/functions";
import Moment from "moment";
import Ellipsis from "react-ellipsis-text";
import ReactNumberFormat from "react-number-format";

import { formatMessage } from "../../i18n/formatters";

interface Props {
  type?: "text" | "currency" | "number" | "date";
  toFixed?: number;
  value?: number | string | Date;
  prefix?: string;
  suffix?: string;
  ellipsis?: boolean;
  ellipsisSize?: number;
  className?: string;
  testId?: string;
  emptyMessage?: string;
}

const Data: React.FunctionComponent<Props> = ({
  type,
  toFixed,
  value,
  prefix,
  suffix,
  ellipsis,
  ellipsisSize,
  className,
  testId,
  emptyMessage,
}) => {
  const { country } = getUserInfos();

  const setNumberPrefix = () => {
    let numberPrefix = "";
    if (type === "currency" && prefix === undefined) {
      numberPrefix = "$ ";
    }
    if (prefix) {
      numberPrefix = prefix;
    }
    return numberPrefix;
  };

  const setNumberValue = () => {
    let numberValue = value;
    if (type === "currency") {
      numberValue = Number(value).toFixed(2);
    }
    if (toFixed) {
      numberValue = Number(value).toFixed(toFixed);
    }
    return Number(numberValue);
  };

  const formatSeparator = (separator: string) => {
    if (separator === "tousand") {
      return formatMessage({ id: `Formater.${country}.THOUSAND_SEPARATOR` });
    }

    return formatMessage({ id: `Formater.${country}.DECIMAL_SEPARATOR` });
  };

  /* istanbul ignore next */
  const formatDate = () => {
    return formatMessage({ id: "Formater.DATE_FORMATTER" });
  };

  if (value === null || value === undefined || value === "") {
    return (
      <span className={className} data-testid={testId}>
        {emptyMessage && formatMessage({ id: emptyMessage })}
      </span>
    );
  }

  if (type === "currency" || type === "number") {
    return (
      <ReactNumberFormat
        data-testid={testId}
        value={setNumberValue()}
        displayType="text"
        thousandSeparator={formatSeparator("tousand")}
        decimalSeparator={formatSeparator("decimal")}
        prefix={setNumberPrefix()}
        suffix={suffix}
        decimalScale={type === "currency" ? 2 : toFixed}
        fixedDecimalScale
        className={className}
      />
    );
  }

  /* istanbul ignore next */
  if (type === "date") {
    const date = Moment(value, "YYYY-MM-DD");
    let formatedDateValue = "";
    if (Moment(date).isValid()) {
      formatedDateValue = Moment(date).format(formatDate());
    } else {
      formatedDateValue = formatMessage({
        id: "GLOBAL_MESSAGE.NO_DATA_AVAILABLE",
      });
    }

    return (
      <span className={className} data-testid={testId}>
        {formatedDateValue}
      </span>
    );
  }

  if (ellipsisSize || ellipsis) {
    return (
      <Ellipsis
        text={value.toString()}
        length={ellipsisSize || 40}
        className={className}
        data-testid={testId}
      />
    );
  }

  return (
    <span className={className} data-testid={testId}>
      {value}
    </span>
  );
};

Data.defaultProps = {
  toFixed: 0,
  type: "text",
  prefix: undefined,
  suffix: "",
  ellipsis: false,
  ellipsisSize: 0,
  testId: "",
  emptyMessage: "GLOBAL_MESSAGE.NO_DATA_AVAILABLE",
};

export default Data;

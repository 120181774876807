import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      borderBottomRightRadius: "16px",
      borderBottomLeftRadius: "16px",
      width: "100%",
      marginBottom: "2px",
      alignSelf: "center",
    },
    error: {
      backgroundColor: palette.error.main,
    },
    success: {
      backgroundColor: palette.common.black,
    },
    update: {
      backgroundColor: palette.common.black,
    },
    delete: {
      backgroundColor: palette.common.black,
    },
    message: {
      fontSize: "12px",
      fontWeight: "bold",
      fontStretch: "normal",
      fonStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      padding: "9px 0",
      color: palette.common.white,
    },
    closeIcon: {
      fontSize: "14px",
      color: palette.common.white,
    },
  }),
);

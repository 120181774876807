import React from "react";

const FocusSkuIcon: React.FunctionComponent = () => (
  <svg
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Group 32</title>
    <g
      id="Bees-Grow---Current"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="SPRINT-60-Focus-SKU-Tags"
        transform="translate(-335.000000, -643.000000)"
      >
        <g id="Group-32" transform="translate(335.000000, 643.000000)">
          <circle
            id="ee"
            fill="#505050"
            fillRule="nonzero"
            cx="13"
            cy="13"
            r="13"
          />
          <circle
            id="Oval"
            stroke="#FFFFFF"
            strokeWidth="2"
            cx="13"
            cy="13"
            r="7"
          />
          <g
            id="Group-14"
            transform="translate(12.000000, 2.000000)"
            fill="#FFFFFF"
          >
            <rect id="Rectangle" x="0" y="0" width="2" height="9" rx="1" />
            <rect id="Rectangle" x="0" y="13" width="2" height="9" rx="1" />
          </g>
          <g
            id="Group-14"
            transform="translate(13.000000, 13.000000) rotate(-270.000000) translate(-13.000000, -13.000000) translate(12.000000, 2.000000)"
            fill="#FFFFFF"
          >
            <rect id="Rectangle" x="0" y="0" width="2" height="9" rx="1" />
            <rect id="Rectangle" x="0" y="13" width="2" height="9" rx="1" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default FocusSkuIcon;

import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { formatMessage } from "../../../../../i18n/formatters";
import useStyles from "./styles";
import Button from "../../../../../components/core/button/Button";
import AddIcon from "../../../../../assets/icons/AddIconCreateNewButton";
import ModalCreateCampaign from "../../modalCreateCampaign/ModalCreateCampaign";
import { campaignUxButtonClicked } from "../../../../../config/typewriter";
import { useAnalytics } from "../../../../../analytics/useAnalytics";

const ButtonCreateNewFocusSku: React.FC = () => {
  const classes = useStyles();
  const { dispatchGenericEvent } = useAnalytics();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    dispatchGenericEvent(campaignUxButtonClicked, {
      screen_name: "Campaigns",
      screen_section: "Campaigns Header",
      button_label: "create-new-campaign-button",
      button_name: "Create new",
      filter_option: "",
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box data-testid="focus-sku-button-create-new">
      <Button className={classes.createNewButton} onClick={handleOpen}>
        <div className={classes.addIcon}>
          <AddIcon />
        </div>
        <div className={classes.label}>
          {formatMessage({ id: "BUTTON.CREATE_NEW" })}
        </div>
      </Button>
      <ModalCreateCampaign open={open} onClose={handleClose} />
    </Box>
  );
};

export default ButtonCreateNewFocusSku;

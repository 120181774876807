import React from "react";

function ErrorIconToastify(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M12.387 0c.217 0 .425.086.579.24l4.794 4.794c.154.154.24.362.24.579v6.774a.818.818 0 0 1-.24.579l-4.794 4.794a.818.818 0 0 1-.579.24H5.613a.818.818 0 0 1-.579-.24L.24 12.966a.818.818 0 0 1-.24-.579V5.613c0-.217.086-.425.24-.579L5.034.24A.818.818 0 0 1 5.613 0zm-.34 1.636H5.952L1.636 5.951v6.097l4.316 4.316h6.095l4.317-4.317V5.952l-4.316-4.316zm-3.039 9.819a.818.818 0 0 1 .096 1.63L9 13.091a.818.818 0 0 1-.095-1.63l.103-.006zM9 4.909c.42 0 .765.316.813.723l.005.095V9a.818.818 0 0 1-1.63.095L8.181 9V5.727c0-.452.366-.818.818-.818z"
          id="mp4kb6s2ca"
        />
      </defs>
      <g transform="translate(3 3)" fill="none" fillRule="evenodd">
        <mask id="3wi1iwnzab" fill="#fff">
          <use xlinkHref="#mp4kb6s2ca" />
        </mask>
        <use fill="#C9201D" fillRule="nonzero" xlinkHref="#mp4kb6s2ca" />
        <g mask="url(#3wi1iwnzab)" fill="#C9201D">
          <path d="M-3-3h24v24H-3z" />
        </g>
      </g>
    </svg>
  );
}

export default ErrorIconToastify;

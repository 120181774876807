import React from "react";

const DragDropIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="28"
      viewBox="0 0 33 28"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g stroke="#505050" strokeWidth="2.667">
          <g>
            <g>
              <g>
                <path
                  d="M20.444 17.333L15.111 12 9.778 17.333M15.111 12L15.111 24"
                  transform="translate(-590 -471) translate(535 65) translate(32 388) translate(24 20)"
                />
                <path
                  d="M26.298 20.52c2.675-1.458 4.02-4.545 3.27-7.498-.752-2.953-3.41-5.02-6.457-5.022h-1.68C20.326 3.725 16.701.573 12.314.072c-4.387-.5-8.629 1.753-10.67 5.669-2.04 3.916-1.457 8.684 1.467 11.992"
                  transform="translate(-590 -471) translate(535 65) translate(32 388) translate(24 20)"
                />
                <path
                  d="M20.444 17.333L15.111 12 9.778 17.333"
                  transform="translate(-590 -471) translate(535 65) translate(32 388) translate(24 20)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DragDropIcon;

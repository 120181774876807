import { makeStyles, createStyles } from "@material-ui/core/styles";

const styles = makeStyles(({ palette }) =>
  createStyles({
    tableContainer: {
      overflowY: "auto",
      maxHeight: "666px",
      "@media (max-width:1367px)": {
        maxHeight: "502px",
      },
    },
    root: {
      padding: "16px 24px",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
      "& hr": {
        border: "none",
        height: "1px",
        backgroundColor: " #eaeaea",
        color: "#eaeaea",
      },
      "&:nth-child(odd)": {
        backgroundColor: " #eaeaea",
        "& hr": {
          border: "none",
          height: "1px",
          backgroundColor: " #f8f8f8",
          color: "#f8f8f8",
        },
      },
      "& > *": {
        borderBottom: "unset",
        padding: 0,
      },
    },
    rowTitleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #FFFFFF",
    },
    title: {
      fontSize: 12,
      fontWeight: 900,
      color: "#505050",
      textTransform: "uppercase",
    },
    expandRowContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      height: 0,
    },
    line: {
      width: "1006px",
      marginLeft: "0px",
    },
    expandRowButton: {
      top: "22px",
    },
    optionsBtn: {
      marginBottom: 20,
      marginLeft: "16px",
    },
    productContainer: { marginTop: "8px", boxShadow: "none" },
    productContent: { paddingBottom: 0, paddingTop: 0, boxShadow: "none" },
    productDDCandSegment: {
      fontSize: 10,
      fontWeight: 500,
      color: "#505050",
    },
    productDDCandSegmentCollapse: {
      fontSize: 10,
      fontWeight: 500,
      color: "#505050",
      backgroundColor: "#ffffff",
    },
    productSku: {
      fontSize: 10,
      fontWeight: 500,
      color: "#505050",
      lineHeight: 1,
    },
    optionButton: {
      width: "100%",
      borderBottom: "1px solid #eaeaea",
      fontSize: 12,
      fontWeight: 500,
      height: "50%",
      textTransform: "uppercase",
      justifyContent: "flex-start",
      padding: 16,
    },
    popover: {
      "& .beesgrow-MuiPopover-paper": {
        width: 100,
        height: 99,
        marginTop: 9,
        overflowY: "unset",
        overflowX: "unset",
      },
      "&  .beesgrow-MuiPopover-paper::before": {
        content: '""',
        position: "absolute",
        right: 8,
        top: -8,
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: "0 8px 8px 8px",
        borderColor: "transparent transparent white transparent",
        zIndex: 999,
      },
    },
    box: {
      maxHeight: "480px",
      overflow: "hidden",
      overflowY: "scroll",
    },
    tag: {
      marginRight: "0px",
      marginBottom: "18px",
    },
    campaignTag: {
      fontSize: "10px",
      fontWeight: "bold",
      color: "#505050",
      textTransform: "uppercase",
    },
    ddcSegmentTitle: {
      fontSize: "10px",
      fontWeight: "bold",
      color: "#505050",
    },
    ddcSegmentValue: {
      fontSize: "10px",
      color: "#505050",
    },
    startDateTitle: {
      fontSize: "10px",
      fontWeight: "bold",
      color: "#148cc6",
    },
    startDateValue: {
      fontSize: "10px",
      color: "#148cc6",
    },
    endDateTitle: {
      fontSize: "10px",
      fontWeight: "bold",
      color: "#d00505",
    },
    endDateValue: {
      fontSize: "10px",
      color: "#d00505",
    },
    segmentationTitle: {
      fontSize: "10px",
      fontWeight: "bold",
      color: "#505050",
    },
    segmentationValue: {
      fontSize: "10px",
      color: "#505050",
    },
    descriptionTitle: {
      fontSize: "10px",
      fontWeight: "bold",
      color: "#505050",
    },
    descriptionValue: {
      fontSize: "10px",
      color: "#505050",
    },
    noItemsText: {
      height: "15vh",
      fontSize: "1rem",
      color: palette.secondary.main,
      paddingBottom: "14px",
      textAlign: "center",
      paddingTop: "7vh",
    },
    productItemsOpen: {
      backgroundColor: "#ffffff",
      width: "1006px",
      paddingLeft: "8px",
    },
    productItems: {
      background: "transparent",
    },
    emptyB2OEdit: {
      width: "30px",
      display: "inline-block",
    },
  }),
);

export default styles;

import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    campaignSelect: {
      width: "100%",
      fontSize: 12,
      fontWeight: 500,
      margin: "24px 0",
    },
  }),
);

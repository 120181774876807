import CampaignItems, { Item } from "../../domains/items/CampaignItems";
import GetItemsByCampaignsResponse from "../../services/campaigns/domains/GetItemsByCampaignsResponse";

export const ResponseToCampaignItemsConverter = (
  data: Array<GetItemsByCampaignsResponse>,
): CampaignItems => {
  const campaignItems = data.map(
    ({
      campaignTag,
      sku,
      unitPrice,
      productName,
      image,
      fullContainerDescription,
      fullPackageDescription,
    }) => {
      return <Item>{
        campaignTag,
        sku,
        unitPrice,
        productName,
        image,
        fullContainerDescription,
        fullPackageDescription,
        campaignAndSku: `${campaignTag}_${sku}`,
      };
    },
  );

  return { campaignItems };
};

export const ResponseToCampaignItemsConverterV3 = (
  data: Array<GetItemsByCampaignsResponse>,
): CampaignItems => {
  const campaignItems = data.map(
    ({
      campaignTag,
      vendorItemId: sku,
      unitPrice,
      productName,
      image,
      fullContainerDescription,
      fullPackageDescription,
    }) => {
      return <Item>{
        campaignTag,
        sku,
        unitPrice,
        productName,
        image,
        fullContainerDescription,
        fullPackageDescription,
        campaignAndSku: `${campaignTag}_${sku}`,
      };
    },
  );

  return { campaignItems };
};

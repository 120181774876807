import React from "react";

const TableOptionsIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="4"
      viewBox="0 0 24 4"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#505050">
          <g>
            <g transform="translate(-1265 -155) translate(235 143) translate(1030 12)">
              <circle cx="2" cy="2" r="2" />
              <circle cx="12" cy="2" r="2" />
              <circle cx="22" cy="2" r="2" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TableOptionsIcon;

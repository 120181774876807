import React, { useEffect, useState, useCallback } from "react";

import { Paper } from "@material-ui/core";
import { useStore } from "effector-react";
import Moment from "moment";

import { isFeatureEnabled } from "grow-shared-services";
import { useAnalytics } from "../../../analytics/useAnalytics";
import BeeLoading from "../../../components/BeeLoading/BeeLoading";
import CustomToast from "../../../components/CustomToast/CustomToast";
import LoadingError from "../../../components/LoadingError/LoadingError";
import { LOCAL_STORAGE_TEAM_STRUCTURE_AGENT_EMAIL } from "../../../config/constants";
import {
  GROW_ADMIN_MISSIONS_MANAGEMENT_V2,
  GROW_ADMIN_BUSINESS_MISSION,
} from "../../../config/featureToggles";
import {
  agentSegmentationAssigned,
  errorMessageViewed,
} from "../../../config/typewriter";
import { getStorageItem, getUserInfos } from "../../../config/utils/functions";
import { formatMessage } from "../../../i18n/formatters";
import {
  fetchAllAgents,
  setAgentMissionPriority,
  setAgentNewMissionPriority,
  setAgentSegmentation,
  setIsAgentUpdated,
} from "../../../stores/agents/AgentsEvents";
import AgentsStore from "../../../stores/agents/AgentsStore";
import { updateAgentEffect } from "../../../stores/agents/UpdateAgentEvents";
import {
  getAllFlexibleMissionsEffect,
  getGlobalAndLocalMissionsEffect,
  getPrimaryMissionsEffect,
} from "../../../stores/missions/MissionsEvent";
import MissionsStore from "../../../stores/missions/MissionsStore";
import AgentSelect from "../components/AgentSelect/AgentSelect";
import MissionsAndSpecializationContainer from "../components/MissionsAndSpecializationContainer/MissionsAndSpecializationContainer";
import Title from "../components/Title/Title";
import useMissionsManagement from "../hooks/useMissionsManagement";
import { AgentOptions } from "../Types";
import AgentSetupPageNewLayoutStyles from "./AgentSetupPageNewLayout.styles";

const AgentSetupPageNewLayout = (): JSX.Element => {
  const classes = AgentSetupPageNewLayoutStyles();
  const { dispatchGenericEvent } = useAnalytics();
  const { agents, error, isLoading: isLoadingAgents } = useStore(AgentsStore);
  const { errorToLoad, loading: isLoadingMissions } = useStore(MissionsStore);
  const { updateAgentMissions, missionsManagement } = useMissionsManagement();

  const [selectedAgent, setSelectedAgent] = useState<AgentOptions | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [segmentations, setSegmentations] = useState<string[]>([]);
  const [agentOptions, setAgentOptions] = useState<AgentOptions[]>([]);

  const isNewMissionsManagementEnabled = isFeatureEnabled(
    GROW_ADMIN_MISSIONS_MANAGEMENT_V2,
  );

  const isGrowAdminBusinessMissionEnabled = isFeatureEnabled(
    GROW_ADMIN_BUSINESS_MISSION,
  );

  const { vendorId, country } = getUserInfos();

  useEffect(() => {
    fetchAllAgents({
      isCanaryRelease: false,
      vendorId,
    });

    const missionsProps = {
      vendorId,
    };

    if (isGrowAdminBusinessMissionEnabled) {
      getAllFlexibleMissionsEffect(vendorId);
    } else if (isNewMissionsManagementEnabled) {
      getGlobalAndLocalMissionsEffect(missionsProps);
    } else {
      getPrimaryMissionsEffect(missionsProps);
    }
  }, [
    vendorId,
    isNewMissionsManagementEnabled,
    isGrowAdminBusinessMissionEnabled,
  ]);

  useEffect(() => {
    const storageTeamStructureEmail: string = getStorageItem(
      LOCAL_STORAGE_TEAM_STRUCTURE_AGENT_EMAIL,
    );

    /* istanbul ignore next */
    if (
      storageTeamStructureEmail &&
      agentOptions.length > 0 &&
      !isLoadingAgents
    ) {
      const selectOptionsFiltered = agentOptions.filter(
        (agent) => agent.email === storageTeamStructureEmail,
      );
      handleAgentSelectChange(selectOptionsFiltered[0]);
      localStorage.removeItem(LOCAL_STORAGE_TEAM_STRUCTURE_AGENT_EMAIL);
    }
  }, [agentOptions, isLoadingAgents]);

  useEffect(() => {
    updateAgentMissions(selectedAgent);
  }, [selectedAgent, updateAgentMissions]);

  useEffect(() => {
    if (agents) {
      const formattedAgents = agents.map((each) => {
        return {
          id: each.id,
          label: `${each.firstName} ${each.lastName} - ${each.email}`,
          segmentation: each.segmentation,
          missionPriority: each.missionPriority,
          newMissionPriority: each.newMissionPriority,
          email: each.email,
        };
      });

      setAgentOptions(formattedAgents);
    }
  }, [agents]);

  const handleAgentSelectChange = (agent: AgentOptions) => {
    setSelectedAgent(agent);
    setSegmentations(agent?.segmentation || []);
  };

  const convertToMissionPriority = (missions: string[]): string[] | null => {
    const priority: string[] = [];

    if (!missions.length) {
      return null;
    }

    missions.forEach((mission) => {
      priority.push(mission);
    });

    return priority;
  };

  const callAgentSegmentationAssigned = useCallback(
    (missionPriority: string[]) => {
      dispatchGenericEvent(agentSegmentationAssigned, {
        agent_id: selectedAgent?.email || null,
        agent_segmentation: segmentations,
        mission_priority: convertToMissionPriority(missionPriority),
      });
    },
    [dispatchGenericEvent, segmentations, selectedAgent?.email],
  );

  const handleApplyChanges = useCallback(() => {
    /* istanbul ignore next */
    if (!selectedAgent) {
      return;
    }

    setIsLoading(true);

    const missionPriority =
      missionsManagement?.enabled.map((each) => each.mission) || [];

    const agentMissionsPriorities = agents.find(
      (agent) => agent.id === selectedAgent.id,
    );

    const updatedAgent = {
      segmentation: segmentations,
      missionPriority: isNewMissionsManagementEnabled
        ? agentMissionsPriorities?.missionPriority
        : missionPriority,
      newMissionPriority: isNewMissionsManagementEnabled
        ? missionPriority
        : agentMissionsPriorities?.newMissionPriority,
    };

    const update = {
      id: selectedAgent?.id || "",
      country,
      agent: updatedAgent,
      vendorId,
    };

    updateAgentEffect(update);

    const watchUpdateAgentDone = updateAgentEffect.done.watch(() => {
      setIsLoading(false);
      setIsAgentUpdated(false);

      CustomToast({
        type: "success",
        message: formatMessage({ id: "AGENT_SEGMENTATION.SAVE_CHANGES" }),
      });

      if (isNewMissionsManagementEnabled) {
        setAgentNewMissionPriority({
          agentId: selectedAgent?.id || "",
          newMissionPriority: missionPriority,
        });
      } else {
        setAgentMissionPriority({
          agentId: selectedAgent?.id || "",
          missionPriority,
        });
      }

      setAgentSegmentation({
        agentId: selectedAgent?.id || "",
        segmentation: segmentations,
      });

      callAgentSegmentationAssigned(missionPriority);

      /* istanbul ignore next */
      if (typeof watchUpdateAgentDone === "function") {
        watchUpdateAgentDone();
        watchUpdateAgentFail();
      }
    });

    const watchUpdateAgentFail = updateAgentEffect.fail.watch(() => {
      setIsLoading(false);
      CustomToast({
        type: "error",
        message: formatMessage({ id: "AGENT_SEGMENTATION.SAVE_ERROR" }),
      });

      dispatchGenericEvent(errorMessageViewed, {
        error_type: "save agent setup error",
        error_message:
          "Something went wrong! The changes were not save, try applying them again.",
        screen_name: "Agent setup",
        screen_section: "Agent setup",
        time_of_day: Moment().local().hour(),
      });

      /* istanbul ignore next */
      if (typeof watchUpdateAgentFail === "function") {
        watchUpdateAgentFail();
        watchUpdateAgentDone();
      }
    });
  }, [
    selectedAgent,
    missionsManagement?.enabled,
    agents,
    segmentations,
    isNewMissionsManagementEnabled,
    country,
    vendorId,
    callAgentSegmentationAssigned,
    dispatchGenericEvent,
  ]);

  const handleRemoveSpecialization = React.useCallback(
    (index) => {
      const result = segmentations.filter(
        /* istanbul ignore next */
        (_, itemIndex) => index !== itemIndex,
      );
      setSegmentations(result);
      setIsAgentUpdated(true);
    },
    [segmentations],
  );

  const renderErrorToLoad = () => {
    const analyticsProps = {
      errorType: "get agent setup error",
      errorMessage: "It was not possible to load the agent setup",
      screenName: "Agent setup",
      screenSection: "Agent setup",
    };

    return (
      <LoadingError
        subtitleTranslationId="AGENT_SEGMENTATION.ERROR_TO_LOAD"
        testId="error-load-agent-setup"
        onClick={() =>
          fetchAllAgents({
            isCanaryRelease: false,
            vendorId,
          })
        }
        analyticsProperties={analyticsProps}
      />
    );
  };

  return (
    <main className={classes.container} data-testid="agent-setup-new-layout">
      <div className={classes.content}>
        <section
          className={classes.titleContainer}
          data-testid="agent-setup-new-layout-title"
        >
          <Title />
        </section>

        {(error || errorToLoad) && renderErrorToLoad()}

        {!(error || errorToLoad) && (isLoadingAgents || isLoadingMissions) && (
          <section data-testid="agent-setup-new-layout-section-loading">
            <Paper elevation={3} className={classes.beeloadingContainer}>
              <BeeLoading
                testid="agent-setup-new-layout"
                data-testid="agent-setup-new-layout-beeloading"
              />
            </Paper>
          </section>
        )}

        {!(error || errorToLoad) && !(isLoadingAgents || isLoadingMissions) && (
          <section data-testid="agent-setup-new-layout-agent-select-section">
            <AgentSelect
              options={agentOptions}
              value={selectedAgent as AgentOptions}
              onChange={handleAgentSelectChange}
              data-testid="agent-setup-new-layout-agent-select"
            />
          </section>
        )}

        {selectedAgent && (
          <section
            className={classes.missionsAndSpecializationContainer}
            data-testid="agent-setup-new-layout-missions-and-specialization-section"
          >
            <MissionsAndSpecializationContainer
              agentId={selectedAgent?.id}
              isLoading={isLoading}
              setSegmentations={setSegmentations}
              segmentations={segmentations}
              handleApplyChanges={handleApplyChanges}
              data-testid="agent-setup-new-layout-missions-and-specialization"
              handleRemove={handleRemoveSpecialization}
            />
          </section>
        )}
      </div>
    </main>
  );
};

export default AgentSetupPageNewLayout;

import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette }) =>
  createStyles({
    card: {
      padding: "1rem",
      width: "100%",
      borderRadius: "0.4rem",
      backgroundColor: "white",
    },
    cardContainer: {
      alignItems: "center",
      display: "flex",
      height: "28px",
    },
    info: {
      display: "flex",
      flex: 1,
      marginLeft: "15px",
    },
    sequence: {
      fontFamily: "Work Sans",
      fontSize: "13px",
      color: palette.info.dark,
      width: "15px",
    },
    title: {
      fontFamily: "Work Sans",
      fontSize: "14px",
      color: palette.info.dark,
      letterSpacing: "0.4px",
    },
    buttonContent: {
      display: "flex",
      alignItems: "center",
    },
    switchAndIcon: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "0px 25px",
    },
    draggableContainer: {
      width: "24px",
      height: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    draggable: {
      margin: "0px",
      cursor: "pointer",
    },
  }),
);

/* eslint-disable react/require-default-props */
import React, { useState, useRef } from "react";
import { ButtonBase, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { ENTER_CODE } from "../../../../../config/constants";
import searchBarStyles from "./SearchBarStyles";
import SearchOptions from "../../searchOptions/SearchOptions";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import { campaignSearchInteraction } from "../../../../../config/typewriter";

interface Props {
  value: string;
  setValue: (value: string) => void;
  onSearch: (type: string) => void;
  testId: string;
  handleItemsOnChoose: (value: string) => void;
  totalCampaignNames?: number;
  totalDeliveryCenterIds?: number;
  totalSegments?: number;
  totalSkus?: number;
  loadingSearch: boolean;
}
export enum SEARCH_METHOD {
  ENTER_KEY = "enter",
  SEARCH_BUTTON = "go_button",
}

enum BUTTON_LABELS {
  SEARCH_FIELD = "search-field",
  GO_BUTTON = "go-button",
}
const SearchBar: React.FC<Props> = ({
  value,
  setValue,
  testId,
  onSearch,
  totalCampaignNames,
  totalDeliveryCenterIds,
  totalSegments,
  totalSkus,
  handleItemsOnChoose,
  loadingSearch,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement>();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const classes = searchBarStyles();
  const divRef = useRef<HTMLInputElement>();
  const { dispatchGenericEvent } = useAnalytics();

  const handleSearch = (buttonType: string) => {
    onSearch(value);

    dispatchGenericEvent(campaignSearchInteraction, {
      button_label: buttonType,
      search_query: value,
    });
    /* istanbul ignore else */
    if (value) {
      setAnchorEl(divRef.current);
      setIsPopoverOpen(true);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ENTER_CODE) {
      handleSearch(BUTTON_LABELS.SEARCH_FIELD);
    }
  };

  return (
    <>
      <div
        className={classes.searchInputContainer}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={divRef}
      >
        <TextField
          fullWidth
          variant="outlined"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          data-testid={`${testId}-input`}
          InputProps={{
            classes: {
              root: classes.searchTextField,
            },
          }}
        />
        <ButtonBase
          className={classes.searchIconContainer}
          onClick={() => {
            handleSearch(BUTTON_LABELS.GO_BUTTON);
          }}
          data-testid={`${testId}-button`}
        >
          <SearchIcon className={classes.searchIconColor} />
        </ButtonBase>
      </div>
      <SearchOptions
        anchorEl={anchorEl}
        open={isPopoverOpen}
        setOpen={setIsPopoverOpen}
        data-testid="sku-search-options"
        handleItemsOnChoose={handleItemsOnChoose}
        totalCampaignNames={totalCampaignNames}
        totalDeliveryCenterIds={totalDeliveryCenterIds}
        totalSegments={totalSegments}
        totalSkus={totalSkus}
        loadingSearch={loadingSearch}
      />
    </>
  );
};

export default SearchBar;

import { createEffect } from "effector";
import * as UpdateAgentUseCase from "../../usecase/agents/UpdateAgentUseCase";
import UpdateAgentRequest from "../../services/agents/domains/UpdateAgentRequest";

interface AgentWithCountry {
  id: string;
  country: string;
  agent: UpdateAgentRequest;
  vendorId?: string;
}

export const updateAgentEffect = createEffect({
  async handler(agentUpdate: AgentWithCountry) {
    const { id, country, agent, vendorId } = agentUpdate;
    return UpdateAgentUseCase.execute(id, country, agent, vendorId);
  },
});

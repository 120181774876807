import { isFeatureEnabled } from "grow-shared-services";
import { TLP_MULTICONTRACT_CAMPAIGN_SERVICE } from "../../config/featureToggles";
import { UpdateCampaignBody } from "../../services/campaigns/domains/CreateCampaignRequest";
import * as UpdateCampaignsService from "../../services/campaigns/UpdateCampaignsService";

export function execute(
  country: string,
  campaignTag: string,
  campaign: UpdateCampaignBody,
  vendorId?: string,
  vendorName?: string,
  isCampaignFocusVendorEnabled?: boolean,
  idCampaign?: string,
): Promise<void> {
  const isMulticontractCampaignServiceEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
  );

  let vendorIdParam = "";
  let vendorNameParam = "";

  if (
    !isMulticontractCampaignServiceEnable &&
    vendorId &&
    vendorName &&
    isCampaignFocusVendorEnabled
  ) {
    vendorIdParam = vendorId;
    vendorNameParam = vendorName;
  }

  if (isMulticontractCampaignServiceEnable && vendorId) {
    vendorIdParam = vendorId;
  }

  return UpdateCampaignsService.updateCampaign(
    country,
    campaignTag,
    vendorIdParam,
    vendorNameParam,
    campaign,
    {},
    idCampaign,
  );
}

export const TLP_FLAG_MISSION = "tlp-flag-mission";
export const TLP_FLAG_MISSION_ADMIN = "tlp-flag-mission-admin";

export const TLP_BLOCK_LIST = "tlp-block-list";
export const TLP_BLOCK_LIST_ADMIN = "tlp-block-list-admin";

export const TLP_PRIMARY_MISSIONS_MANAGEMENT =
  "tlp-primary-missions-management";
export const TLP_PRIMARY_MISSIONS_MANAGEMENT_ADMIN =
  "tlp-primary-missions-management-admin";

export const TLP_REWARDS_CHALLENGES_MISSION = "tlp-rewards-challenges-mission";
export const TLP_REWARDS_CHALLENGES_MISSION_ADMIN =
  "tlp-rewards-challenges-mission-admin";

export const TLP_SPONSORED_MISSION_ADMIN = "tlp-sponsored-mission-admin";

export const TLP_GROW_CONFIG_MULTI_CONTRACT = "tlp-grow-config-multi-contract";

export const TLP_SPONSORED_MISSIONS_EDIT = "tlp-sponsored-missions-edit";

export const TLP_CREATE_NEW_SPONSORED_MISSION =
  "tlp-create-new-sponsored-mission";

export const TLP_SERVICE_MISSION_ADMIN = "tlp-service-mission-admin";

export const TLP_SERVICE_MISSION_DEDICATED = "tlp-service-mission-dedicated";
export const TEAM_STRUCTURE = "team-structure";

export const TEAM_STRUCTURE_ADMIN = "team-structure-admin";

export const TLP_CAMPAIGN_FOCUS_VENDOR = "tlp-campaign-focus-vendor";

export const TLP_CHECK_FEATURE_TOGGLES_ARE_LOADED =
  "tlp-check-feature-toggles-are-loaded";

export const TLP_MULTICONTRACT_GROW_ADMIN_CONFIG_SERVICE =
  "tlp-multicontract-grow-admin-config-service";

export const TLP_MULTICONTRACT_ITEM_SERVICE = "tlp-multicontract-item-service";

export const TLP_MULTICONTRACT_ONCUSTOMER_B2C_USERS =
  "tlp-multicontract-oncustomer-b2c-users";

export const TLP_MULTICONTRACT_ACCOUNT_SERVICE =
  "tlp-multicontract-account-service";

export const TLP_MULTICONTRACT_CAMPAIGN_SERVICE =
  "tlp-multicontract-campaign-service";

export const GROW_ADMIN_LOCAL_MISSION_MENU =
  "grow-admin-local-mission-menu-BEESGR-5955";

export const GROW_ADMIN_DISABLE_B2O_CAMPAIGN =
  "grow-admin-disable-b2o-campaign";

export const GROW_ADMIN_MISSIONS_MANAGEMENT_V2 =
  "grow-admin-missions-management-v2-BEESGR-5955";

export const GROW_ADMIN_BUSINESS_MISSION =
  "grow-admin-business-mission-BEESGR-11243";

export const GROW_DISABLE_FOCUS_SKU = "grow-disable-focus-sku";

export const GROW_ADMIN_BETWEEN_MISSION_MANAGEMENT =
  "grow-admin-between-mission-management-BEESGR-12552";

export const GROW_ORGANIZATION_ACTIVATION = "grow-organization-activation";

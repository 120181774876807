import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    button: {
      height: "48px",
      lineHeight: "15px",
      padding: "12px 24px",
      fontSize: "18px",
      fontWeight: 500,
      borderRadius: "40px",
    },
    disabled: {
      backgroundColor: "#f2f2f2 !important",
      color: "#939191 !important",
      border: "0px !important",
    },
    contained: {
      backgroundColor: palette.primary.main,
      color: "#fff",
    },
    outlined: {
      backgroundColor: "#fff",
      color: palette.primary.main,
      border: "1px solid #000",
    },
    loading: {
      color: "white",
    },
    loadingOutlined: {
      color: palette.primary.main,
    },
    loadingContained: {
      color: "white",
    },
    loadingDisabled: {
      color: "#939191",
    },
  }),
);

import React, { useEffect, useState } from "react";

import { Campaign } from "domains/Campaign";
import { FormikValues } from "formik";
import { isFeatureEnabled, getVendorName } from "grow-shared-services";

import {
  CAMPAIGNS,
  CAMPAIGN_CLASSIFICATION,
} from "../../../../config/constants";
import { TLP_CAMPAIGN_FOCUS_VENDOR } from "../../../../config/featureToggles";
import { getUserInfos } from "../../../../config/utils/functions";
import { formatMessage } from "../../../../i18n/formatters";
import { clearCampaignItems } from "../../../../stores/campaigns/items/CampaignItemsEvents";
import { updateCampaignEffect } from "../../../../stores/campaigns/UpdateCampaignsEvents";
import ModalGenericCampaign from "../modalGeneric/ModalGenericCampaign";

interface Props {
  open: boolean;
  onClose: () => void;
  campaign?: Campaign;
}

function ModalEditCampaign({
  open,
  onClose,
  campaign,
}: Props): React.ReactElement {
  const [campaignType, setCampaignType] = useState("");

  const isCampaignFocusVendorEnabled = isFeatureEnabled(
    TLP_CAMPAIGN_FOCUS_VENDOR,
  );

  const { vendorId, country } = getUserInfos();

  useEffect(() => {
    setCampaignType(CAMPAIGNS[0].value);
  }, []);

  const onRegisterHandler = (values: FormikValues) => {
    const { campaignName, campaignTag, ddc, segment, skus, classification } =
      values;

    const vendorName = getVendorName(vendorId);

    const campaignWithCountry = {
      idCampaign: campaign?.id,
      country,
      campaignTag,
      vendorId,
      vendorName,
      isCampaignFocusVendorEnabled,
      campaign: {
        campaignType,
        campaignName,
        skus,
        filterOptions: {
          deliveryCenterId: ddc,
          segment,
        },
        classification: classification || CAMPAIGN_CLASSIFICATION.PRIMARY,
      },
    };
    updateCampaignEffect(campaignWithCountry);
  };

  useEffect(() => {
    const checkoutEffectUpdate = updateCampaignEffect.done.watch(() => {
      clearCampaignItems();
      onClose();

      /* istanbul ignore next */
      if (typeof checkoutEffectUpdate === "function") {
        checkoutEffectUpdate();
      }
    });
  }, [onClose]);

  return (
    <ModalGenericCampaign
      open={open}
      onClose={onClose}
      titleModal={formatMessage({
        id: "MODAL_CAMPAIGNS.TITLE_MODAL_EDIT_CAMPAIGN",
      })}
      labelButton={formatMessage({ id: "MODAL_CAMPAIGNS.EDIT_BUTTON" })}
      onRegisterHandler={onRegisterHandler}
      campaign={campaign}
      campaignType={campaignType}
      setCampaignType={setCampaignType}
    />
  );
}

ModalEditCampaign.defaultProps = {
  campaign: undefined,
};

export default ModalEditCampaign;

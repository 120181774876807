import React, { useEffect, useState } from "react";

import { FormikValues } from "formik";
import Moment from "moment";
import { isFeatureEnabled, getVendorName } from "grow-shared-services";

import { useAnalytics } from "../../../../analytics/useAnalytics";
import {
  CAMPAIGNS,
  CAMPAIGN_CLASSIFICATION,
} from "../../../../config/constants";
import { TLP_CAMPAIGN_FOCUS_VENDOR } from "../../../../config/featureToggles";
import { campaignCreated } from "../../../../config/typewriter/index";
import { getUserInfos } from "../../../../config/utils/functions";
import { formatMessage } from "../../../../i18n/formatters";
import { createCampaignB2OEffect } from "../../../../stores/campaigns/CreateCampaignB2OEvents";
import { createCampaignEffect } from "../../../../stores/campaigns/CreateCampaignEvents";
import { clearCampaignItems } from "../../../../stores/campaigns/items/CampaignItemsEvents";
import ModalGenericCampaign from "../modalGeneric/ModalGenericCampaign";

interface Props {
  open: boolean;
  onClose: () => void;
}

function ModalCreateCampaign({ open, onClose }: Props): React.ReactElement {
  const { dispatchGenericEvent } = useAnalytics();
  const [campaignType, setCampaignType] = useState("");

  const { vendorId, country } = getUserInfos();

  const isCampaignFocusVendor = isFeatureEnabled(TLP_CAMPAIGN_FOCUS_VENDOR);

  const vendorName = getVendorName(vendorId);

  useEffect(() => {
    setCampaignType(CAMPAIGNS[0].value);
  }, []);

  const getProductsAnalytics = (skus: Array<string>) => {
    return skus.map((sku: string) => {
      return { sku };
    });
  };

  const onRegisterHandler = async (values: FormikValues) => {
    if (campaignType === CAMPAIGNS[0].value) {
      const {
        campaignName,
        campaignTag,
        ddc,
        segment,
        skus,
        csvList,
        classification,
      } = values;

      const campaignFocusSkuWithCountry = {
        country,
        vendorId,
        vendorName,
        campaign: {
          campaignTag,
          campaignName,
          campaignType,
          skus,
          filterOptions: {
            deliveryCenterId: ddc,
            segment,
          },
          csvList,
          classification: classification || CAMPAIGN_CLASSIFICATION.PRIMARY,
        },
        isCampaignFocusVendorEnabled: isCampaignFocusVendor,
      };
      /* istanbul ignore next */
      const createCampaignEffectDone = createCampaignEffect.done.watch(() => {
        handleAfterClick(
          campaignName,
          campaignTag,
          getProductsAnalytics(skus),
          ddc,
          segment,
          classification,
        );

        if (typeof createCampaignEffectDone === "function") {
          createCampaignEffectDone();
        }
      });
      /* istanbul ignore next */
      const createCampaignEffectFail = createCampaignEffect.fail.watch(() => {
        if (typeof createCampaignEffectFail === "function") {
          createCampaignEffectDone();
          createCampaignEffectFail();
        }
      });
      await createCampaignEffect(campaignFocusSkuWithCountry);
    } else {
      const {
        campaignName,
        campaignTag,
        campaignDescription,
        campaignScript,
        startDate,
        endDate,
        skus,
        csvList,
        segmentation,
        classification,
      } = values;

      /* istanbul ignore next */
      const campaignB2OWithCountry = {
        country,
        vendorId,
        vendorName,
        campaign: {
          campaignTag,
          campaignName,
          campaignType,
          campaignDescription,
          campaignScript,
          startDate,
          endDate,
          segmentation,
          skus,
          filterOptions: {
            deliveryCenterId: null,
            segment: null,
          },
          csvList,
          classification: classification || CAMPAIGN_CLASSIFICATION.PRIMARY,
        },
        isCampaignFocusVendorEnabled: isCampaignFocusVendor,
      };
      /* istanbul ignore next */
      const createCampaignB2OEffectDone = createCampaignB2OEffect.done.watch(
        () => {
          handleAfterClick(
            campaignName,
            campaignTag,
            getProductsAnalytics(skus),
            null,
            null,
            segmentation,
            campaignDescription,
            campaignScript,
            startDate,
            endDate,
            classification,
          );

          if (typeof createCampaignB2OEffectDone === "function") {
            createCampaignB2OEffectDone();
          }
        },
      );
      /* istanbul ignore next */
      const createCampaignB2OEffectfail = createCampaignB2OEffect.fail.watch(
        () => {
          if (typeof createCampaignB2OEffectfail === "function") {
            createCampaignB2OEffectDone();
            createCampaignB2OEffectfail();
          }
        },
      );
      await createCampaignB2OEffect(campaignB2OWithCountry);
    }
  };

  /* istanbul ignore next */
  const handleAfterClick = (
    campaignName: string,
    campaignTag: string,
    products: Array<Record<string, string>>,
    ddc?: string | null,
    segment?: string | null,
    segmentation?: string | null,
    campaignDescription?: string | null,
    campaignScript?: string | null,
    startDate?: string,
    endDate?: string,
    classification?: string,
  ) => {
    dispatchGenericEvent(campaignCreated, {
      campaign_type: campaignType,
      campaign_name: campaignName,
      tag_name: campaignTag,
      products,
      DDC: ddc,
      segment,
      campaign_segmentation: segmentation,
      campaign_description: campaignDescription,
      campaign_script: campaignScript,
      start_date: startDate ? Moment(startDate).format("YYYY-MM-DD") : null,
      end_date: endDate ? Moment(endDate).format("YYYY-MM-DD") : null,
      campaign_priority:
        classification === CAMPAIGN_CLASSIFICATION.PRIMARY ? 1 : 2,
    });
  };

  useEffect(() => {
    const checkoutEffectDone = createCampaignEffect.done.watch(() => {
      clearCampaignItems();
      onClose();
      /* istanbul ignore next */
      if (typeof checkoutEffectDone === "function") {
        checkoutEffectDone();
      }
    });
  }, [onClose]);

  useEffect(() => {
    const createCampaignB2OEffectDone = createCampaignB2OEffect.done.watch(
      () => {
        onClose();
        /* istanbul ignore next */
        if (typeof createCampaignB2OEffectDone === "function") {
          createCampaignB2OEffectDone();
        }
      },
    );
  }, [onClose]);
  return (
    <ModalGenericCampaign
      open={open}
      onClose={onClose}
      titleModal={formatMessage({
        id: "MODAL_CAMPAIGNS.TITLE_MODAL_NEW_CAMPAIGN",
      })}
      labelButton={formatMessage({ id: "MODAL_CAMPAIGNS.CREATE_BUTTON" })}
      campaignType={campaignType}
      setCampaignType={setCampaignType}
      onRegisterHandler={onRegisterHandler}
    />
  );
}

export default ModalCreateCampaign;

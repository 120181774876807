/* istanbul ignore file */
import { useState, useEffect, useCallback } from "react";

import { useStore } from "effector-react";
import { isFeatureEnabled } from "grow-shared-services";

import {
  TLP_REWARDS_CHALLENGES_MISSION,
  TLP_SPONSORED_MISSION_ADMIN,
  TLP_SERVICE_MISSION_DEDICATED,
  TLP_SERVICE_MISSION_ADMIN,
  GROW_ADMIN_MISSIONS_MANAGEMENT_V2,
  GROW_ADMIN_BUSINESS_MISSION,
} from "../../../config/featureToggles";
import { Mission } from "../../../domains/Missions";
import { formatMessage } from "../../../i18n/formatters";
import { setMissionsManagement } from "../../../stores/agents/AgentsEvents";
import AgentsStore from "../../../stores/agents/AgentsStore";
import MissionsStore from "../../../stores/missions/MissionsStore";
import { DnDListItem } from "../components/DragAndDropComponents/Types";

interface MissionsManagement {
  disabled: Array<Mission>;
  enabled: Array<Mission>;
}

interface Agent {
  id: string;
  label: string;
  segmentation: string[];
  missionsManagement?: {
    disabled: Array<Mission>;
    enabled: Array<Mission>;
  };
  missionPriority?: Array<string>;
  newMissionPriority?: Array<string>;
}

interface UseMissionsManagementReturn {
  missionsManagement: MissionsManagement;
  draggableItems: DnDListItem[];
  disabledDraggableItems: DnDListItem[];
  updateAgentMissions: (agentSelected: Agent | null) => void;
  handleToggleMission: (id: string, type?: string) => void;
  updateMissionsTemporarily: (result: Array<DnDListItem>) => void;
  updateMissionsPriorityOrder: (
    result: Array<string> | undefined,
    missionItens: MissionsManagement,
  ) => MissionsManagement;
}

type MissionsName = "enabled" | "disabled";

const useMissionsManagement = (): UseMissionsManagementReturn => {
  const { missions } = useStore(MissionsStore);
  const { missionsManagement } = useStore(AgentsStore);

  const isRewardsChallengesMissionEnabled = isFeatureEnabled(
    TLP_REWARDS_CHALLENGES_MISSION,
  );

  const isSponsoredMissionEnabled = isFeatureEnabled(
    TLP_SPONSORED_MISSION_ADMIN,
  );

  const isServiceMissionEnabled = isFeatureEnabled(TLP_SERVICE_MISSION_ADMIN);

  const isServicesMissionDedicated = isFeatureEnabled(
    TLP_SERVICE_MISSION_DEDICATED,
  );

  const isNewMissionsManagementEnabled = isFeatureEnabled(
    GROW_ADMIN_MISSIONS_MANAGEMENT_V2,
  );

  const isGrowAdminBusinessMissionEnabled = isFeatureEnabled(
    GROW_ADMIN_BUSINESS_MISSION,
  );

  const [draggableItems, setDraggableItems] = useState<Array<DnDListItem>>([]);
  const [disabledDraggableItems, setDisabledDraggableItems] = useState<
    Array<DnDListItem>
  >([]);

  const rewardsChallengesTitleTranslation = formatMessage({
    id: "MISSIONS_MANAGEMENT.PROMOTE_REWARDS_CHALLENGE_MISSION_TITLE",
  });

  const sponsoredTitleTranslation = formatMessage({
    id: "MISSIONS_MANAGEMENT.SPONSORED_MISSION_TITLE",
  });

  const serviceTitleTranslation = formatMessage({
    id: "MISSIONS_MANAGEMENT.SERVICE_MISSION_TITLE",
  });

  const localMissionsTitle = formatMessage({
    id: "MISSIONS_MANAGEMENT.LOCAL_MISSIONS_TITLE",
  });

  const titles = new Map([
    ["#TopLineGAP", "Topline gap"],
    ["#marketplaceactivation", "Marketplace"],
    ["#promoterewardschallenges", rewardsChallengesTitleTranslation],
    ["#sponsored", sponsoredTitleTranslation],
    ["#LocalMissions", localMissionsTitle],
  ]);
  if (isServiceMissionEnabled) {
    titles.set("#digital_services", serviceTitleTranslation);
  }

  const getMissionId = (mission: Mission) => mission.missionId || mission.id;

  const updateAgentMissions = useCallback(
    (agentSelected: Agent | null) => {
      const disableMissions: Mission[] = [];
      const enabledMissions: Mission[] = [];

      missions.forEach((each) => {
        const { mission } = each;

        const foundMission = isNewMissionsManagementEnabled
          ? agentSelected?.newMissionPriority?.findIndex(
              (eachMission) => eachMission === mission,
            )
          : agentSelected?.missionPriority?.findIndex(
              (eachMission) => eachMission === mission,
            );

        if (
          (!isRewardsChallengesMissionEnabled &&
            getMissionId(each) === "#promoterewardschallenges") ||
          (!isSponsoredMissionEnabled && getMissionId(each) === "#sponsored")
        ) {
          return;
        }

        if (foundMission === -1 || foundMission === undefined) {
          disableMissions.push(each);
        } else {
          enabledMissions.push(each);
        }
      });

      setMissionsManagement(
        updateMissionsPriorityOrder(
          isNewMissionsManagementEnabled
            ? agentSelected?.newMissionPriority
            : agentSelected?.missionPriority,
          {
            disabled: disableMissions,
            enabled: enabledMissions,
          },
        ),
      );
    },
    [
      missions,
      isNewMissionsManagementEnabled,
      isRewardsChallengesMissionEnabled,
      isSponsoredMissionEnabled,
    ],
  );

  const formateMissions = (
    missionsItems: Mission[],
    activated: boolean,
  ): Array<DnDListItem> => {
    let filteredMissions = [...missionsItems];

    if (!isGrowAdminBusinessMissionEnabled) {
      filteredMissions = missionsItems?.filter((mission) =>
        Boolean(titles.get(getMissionId(mission))),
      );
    }

    return filteredMissions?.map((each) => ({
      id: getMissionId(each),
      type: "MISSION",
      title: titles.get(getMissionId(each)) || each.mission,
      isEnabled: activated,
      mission: each.mission,
    }));
  };

  const handleControlMissionsEnabled = (
    missionsObj: MissionsManagement,
    oldMissionList: MissionsName,
    newMissionsList: MissionsName,
    id: string,
  ): {
    [x: string]: Mission[];
  } => {
    const oldList = [
      ...missionsObj[oldMissionList as keyof MissionsManagement],
    ];
    const index = oldList.findIndex((each) => getMissionId(each) === id);
    const mission = { ...oldList[index], enabled: !oldList[index]?.enabled };
    oldList.splice(index, 1);
    let newList = [
      ...missionsObj[newMissionsList as keyof MissionsManagement],
      mission,
    ];

    if (isServicesMissionDedicated) {
      if (newMissionsList === "enabled") {
        // Enable Services and deactivate all other missions
        if (getMissionId(mission) === "#digital_services") {
          const digitalServiceOnOldList = oldList.find(
            (miss) => getMissionId(miss) === getMissionId(mission),
          );
          newList.forEach((mission) => {
            if (
              getMissionId(mission) !== "#digital_services" &&
              !digitalServiceOnOldList
            ) {
              oldList.push({ ...mission, enabled: false });
            }
          });
          newList = [mission];
        }
        // Enable another mission and deactivate "Services"
        if (getMissionId(mission) !== "#digital_services") {
          const digitalServicesIndex = newList.findIndex(
            (mission) => getMissionId(mission) === "#digital_services",
          );
          if (digitalServicesIndex !== -1)
            oldList.push(...newList.splice(digitalServicesIndex, 1));
        }
      }
    }

    return {
      [oldMissionList]: oldList,
      [newMissionsList]: newList,
    };
  };

  const handleToggleMission = (id: string, type = "enabled"): void => {
    const oldListName = type === "enabled" ? "disabled" : "enabled";
    const newListName = type === "enabled" ? "enabled" : "disabled";
    setMissionsManagement(
      handleControlMissionsEnabled(
        missionsManagement,
        oldListName,
        newListName,
        id,
      ) as unknown as MissionsManagement,
    );
  };

  const updateMissionsPriorityOrder = (
    result: Array<string> | undefined,
    missionItens: MissionsManagement,
  ) => {
    const aux: MissionsManagement = {
      enabled: [...missionItens?.enabled],
      disabled: [...missionItens?.disabled],
    };

    let counter = 0;
    result?.forEach((eachResult) => {
      const findEnabledMissionIndex = missionItens?.enabled?.findIndex(
        (each) => each.mission === eachResult,
      );

      if (
        findEnabledMissionIndex !== undefined &&
        findEnabledMissionIndex !== -1
      ) {
        aux.enabled[counter] = {
          ...missionItens?.enabled[findEnabledMissionIndex],
          order: counter,
        };
        counter += 1;
      }
    });

    return aux;
  };

  const updateMissionsTemporarily = (result: Array<DnDListItem>) => {
    const aux: MissionsManagement = {
      enabled: [...missionsManagement?.enabled],
      disabled: [...missionsManagement?.disabled],
    };
    result.forEach((eachResult, index) => {
      const findEnabledMissionIndex = missionsManagement?.enabled?.findIndex(
        (each) => getMissionId(each) === eachResult.id,
      );

      if (
        findEnabledMissionIndex !== -1 &&
        findEnabledMissionIndex !== undefined
      ) {
        aux.enabled[index] = {
          ...missionsManagement?.enabled[findEnabledMissionIndex],
          order: index,
        };
      }
    });
    setMissionsManagement(aux);
  };

  useEffect(() => {
    setDraggableItems(formateMissions(missionsManagement?.enabled, true));
    setDisabledDraggableItems(
      formateMissions(missionsManagement?.disabled, false),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionsManagement]);

  return {
    missionsManagement,
    draggableItems,
    disabledDraggableItems,
    updateAgentMissions,
    handleToggleMission,
    updateMissionsTemporarily,
    updateMissionsPriorityOrder,
  };
};

export default useMissionsManagement;

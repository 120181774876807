import React from "react";

const SingleArrowLeft: React.FC = () => {
  return (
    <svg width="7px" height="12px" viewBox="0 0 7 12" version="1.1">
      <title>Path 3</title>
      <g
        id="Admin"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="Artboard"
          transform="translate(-53.000000, -40.000000)"
          fillRule="nonzero"
          stroke="#505050"
          strokeWidth="1.5"
        >
          <path
            d="M54,41 L58.2923759,45.2860974 C58.6831866,45.676335 58.6836501,46.3094998 58.2934117,46.7003099 C58.2934114,46.7003101 58.2934112,46.7003104 58.2934102,46.7003099 L54,51 L54,51"
            id="Path-3"
            transform="translate(56.500000, 46.000000) scale(-1, 1) translate(-56.500000, -46.000000) "
          />
        </g>
      </g>
    </svg>
  );
};

export default SingleArrowLeft;

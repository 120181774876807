/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */
/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from "ajv";
/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler = (message, violations) => {
  const msg = JSON.stringify(
    {
      type: "Typewriter JSON Schema Validation Error",
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        "Tracking Plan spec.",
      errors: violations,
    },
    undefined,
    2,
  );
  console.warn(msg);
};
let onViolation = defaultValidationErrorHandler;
let analytics = () => {
  return window.analytics;
};
/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options) {
  analytics = options.analytics
    ? () => options.analytics || window.analytics
    : analytics;
  onViolation = options.onViolation || onViolation;
}
/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message, schema) {
  const ajv = new Ajv({ schemaId: "auto", allErrors: true, verbose: true });
  ajv.addMetaSchema(require("ajv/lib/refs/json-schema-draft-06.json"));
  ajv.addMetaSchema(require("ajv/lib/refs/json-schema-draft-04.json"));
  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}
/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message = {}) {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: "javascript",
        version: "7.4.1",
      },
    },
  };
}
/**
 * @typedef AgentSegmentationAssigned
 * @property {any[] | null} agent_segmentation - Segmentation of the agent. Sample: C2B
 */
/**
 * @typedef BlockListDeleted
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 */
/**
 * @typedef BlockListDownloaded
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 */
/**
 * @typedef BlockListUploaded
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 */
/**
 * @typedef CampaignCreated
 * @property {string | null} DDC - direct distribution center. Sample: 0575
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {number | null} campaign_priority - The prioritization rank of the campaign. 1 if primary and 2 if secondary
 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {any[] | null} products - the array of all product objects in the list
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 */
/**
 * @typedef ProductsItem
 * @property {string} [sku] - product SKU. Sample: ABIC001431
 */
/**
 * @typedef CampaignDeleted
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {ProductsItem[]} products - the array of all product objects in the list
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef ProductsItem1
 * @property {string} [sku] - product SKU. Sample: ABIC001431
 */
/**
 * @typedef CampaignEdited
 * @property {string | null} DDC - direct distribution center. Sample: 0575
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {ProductsItem1[]} products - the array of all product objects in the list
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 */
/**
 * @typedef ProductsItem2
 * @property {string} sku - product SKU. Sample: ABIC001431
 */
/**
 * @typedef CampaignRegistrationFailed
 * @property {string | null} DDC - direct distribution center. Sample: 0575
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {any[] | null} campaign_error_type - The error type(s) that occured in the campaign registration. Sample: invalid CSV format
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {number | null} campaign_priority - The prioritization rank of the campaign. 1 if primary and 2 if secondary
 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {ProductsItem2[]} products - the array of all product objects in the list
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 */
/**
 * @typedef CampaignSearchInteraction
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} search_query - Text typed in the product search field. Sample: Brahma Pilsen
 */
/**
 * @typedef CampaignStatusViewed
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 */
/**
 * @typedef CampaignUxButtonClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core. Null if not applicable
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef ErrorMessageViewed
 * @property {string | null} error_message - Text displayed in the UI that describes the error. Sample: Field required
 * @property {string | null} error_type - Error that occured. Sample: no_connection

 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 * @property {number | null} time_of_day - The hour of day the event occured (drill down to hour only). Samples: 15, 09
 */
/**
 * @typedef LanguageChanged
 * @property {string | null} language_selected - UI language selected. Samples: EN, ES
 */
/**
 * @typedef PageViewed
 * @property {Record<string, any>} [] -
 * @property {Record<string, any>} [path] - Path of the screen. Samples: /call
 * @property {Record<string, any>} [referrer] - URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
 * @property {Record<string, any>} [search] - The term used in search modules. Samples: Invoices
 * @property {Record<string, any>} [title] - Title of the page displayed to the use. Sample: Gap & Status
 * @property {Record<string, any>} [url] - URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call
 */
/**
 * @typedef RetryButtonClicked
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef SidebarInteraction
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} button_selection - Selected option. Samples: English, spanish
 */
/**
 * User clicks on "confirm" in the agent setup modal and assigns a segmentation with success
 *
 * @param {AgentSegmentationAssigned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function agentSegmentationAssigned(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      'User clicks on "confirm" in the agent setup modal and assigns a segmentation with success',
    properties: {
      context: {},
      properties: {
        properties: {
          agent_segmentation: {
            description: "Segmentation of the agent. Sample: C2B",
            type: ["array", "null"],
          },
        },
        required: ["agent_segmentation"],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    required: ["properties"],
    title: "Agent Segmentation Assigned",
    type: "object",
  };
  const message = {
    event: "Agent Segmentation Assigned",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Agent Segmentation Assigned",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on confirm button inside block list deletion modal and the block list is deleted
 *
 * @param {BlockListDeleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function blockListDeleted(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      "User clicks on confirm button inside block list deletion modal and the block list is deleted",
    properties: {
      context: {},
      properties: {
        properties: {
          block_list: {
            description: "Name of the block list. Sample: block_list_january",
            type: ["string", "null"],
          },
        },
        required: ["block_list"],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    title: "Block List Deleted",
    type: "object",
  };
  const message = {
    event: "Block List Deleted",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Block List Deleted",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on the block list download button and the list is downloaded
 *
 * @param {BlockListDownloaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function blockListDownloaded(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      "User clicks on the block list download button and the list is downloaded",
    properties: {
      context: {},
      properties: {
        properties: {
          block_list: {
            description: "Name of the block list. Sample: block_list_january",
            type: ["string", "null"],
          },
        },
        required: [],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    title: "Block List Downloaded",
    type: "object",
  };
  const message = {
    event: "Block List Downloaded",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Block List Downloaded",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on confirm button inside confirmation modal and a new block list is submitted
 *
 * @param {BlockListUploaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function blockListUploaded(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      "User clicks on confirm button inside confirmation modal and a new block list is submitted",
    properties: {
      context: {},
      properties: {
        properties: {
          block_list: {
            description: "Name of the block list. Sample: block_list_january",
            type: ["string", "null"],
          },
        },
        required: ["block_list"],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    title: "Block List Uploaded",
    type: "object",
  };
  const message = {
    event: "Block List Uploaded",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Block List Uploaded",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on the "create" button in the creation modal and the campaign is created with success
 *
 * @param {CampaignCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignCreated(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      'User clicks on the "create" button in the creation modal and the campaign is created with success',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: "direct distribution center. Sample: 0575",
            type: ["string", "null"],
          },
          campaign_description: {
            description:
              "Description of the campaign. Sample: campaign to offer our Special Colorado Beers",
            type: ["string", "null"],
          },
          campaign_name: {
            description: "Name of the campaign. Sample: Summer Favourites\n",
            type: ["string", "null"],
          },
          campaign_priority: {
            description:
              "The prioritization rank of the campaign. 1 if primary and 2 if secondary",
            type: ["integer", "null"],
          },
          campaign_script: {
            description:
              "Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood",
            type: ["string", "null"],
          },
          campaign_segmentation: {
            description: "Segmentation of the campaign. Sample: C2B",
            type: ["string", "null"],
          },
          campaign_type: {
            description: "Type of the campaign. Samples: Focus SKU, B2O",
            type: ["string", "null"],
          },
          end_date: {
            description:
              "End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z",
            type: ["string", "null"],
          },
          products: {
            description: "the array of all product objects in the list",
            type: ["array", "null"],
          },
          segment: {
            description: "Segment of the campaign. Sample: 04",
            type: ["string", "null"],
          },
          start_date: {
            description:
              "Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z",
            type: ["string", "null"],
          },
          tag_name: {
            description: "Tag name of the campaign. Sample: #Summer2021\n",
            type: ["string", "null"],
          },
        },
        required: [
          "segment",
          "campaign_priority",
          "campaign_segmentation",
          "campaign_name",
          "end_date",
          "DDC",
          "campaign_type",
          "products",
          "start_date",
          "campaign_description",
          "tag_name",
          "campaign_script",
        ],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    required: ["properties"],
    title: "Campaign Created",
    type: "object",
  };
  const message = {
    event: "Campaign Created",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Campaign Created",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on the "delete" button in the confirmation modal and the campaign is deleted with success
 *
 * @param {CampaignDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignDeleted(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      'User clicks on the "delete" button in the confirmation modal and the campaign is deleted with success',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          campaign_name: {
            description: "Name of the campaign. Sample: Summer Favourites\n",
            type: ["string", "null"],
          },
          end_date: {
            description:
              "End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z",
            type: ["string", "null"],
          },
          products: {
            description: "the array of all product objects in the list",
            items: {
              description: "an individual product in the list",
              properties: {
                sku: {
                  description: "product SKU. Sample: ABIC001431",
                  type: "string",
                },
              },
              required: [],
              type: "object",
            },
            type: "array",
          },
          start_date: {
            description:
              "Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z",
            type: ["string", "null"],
          },
        },
        required: ["start_date", "end_date", "campaign_name", "products"],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    required: ["properties"],
    title: "Campaign Deleted",
    type: "object",
  };
  const message = {
    event: "Campaign Deleted",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Campaign Deleted",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on the "confirm" button in the edit modal and the campaign is edited with success
 *
 * @param {CampaignEdited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignEdited(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      'User clicks on the "confirm" button in the edit modal and the campaign is edited with success',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: "direct distribution center. Sample: 0575",
            type: ["string", "null"],
          },
          campaign_description: {
            description:
              "Description of the campaign. Sample: campaign to offer our Special Colorado Beers",
            type: ["string", "null"],
          },
          campaign_name: {
            description: "Name of the campaign. Sample: Summer Favourites\n",
            type: ["string", "null"],
          },
          campaign_script: {
            description:
              "Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood",
            type: ["string", "null"],
          },
          campaign_segmentation: {
            description: "Segmentation of the campaign. Sample: C2B",
            type: ["string", "null"],
          },
          campaign_type: {
            description: "Type of the campaign. Samples: Focus SKU, B2O",
            type: ["string", "null"],
          },
          end_date: {
            description:
              "End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z",
            type: ["string", "null"],
          },
          products: {
            description: "the array of all product objects in the list",
            items: {
              description: "an individual product in the list",
              properties: {
                sku: {
                  description: "product SKU. Sample: ABIC001431",
                  type: "string",
                },
              },
              required: [],
              type: "object",
            },
            type: "array",
          },
          segment: {
            description: "Segment of the campaign. Sample: 04",
            type: ["string", "null"],
          },
          start_date: {
            description:
              "Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z",
            type: ["string", "null"],
          },
          tag_name: {
            description: "Tag name of the campaign. Sample: #Summer2021\n",
            type: ["string", "null"],
          },
        },
        required: [
          "campaign_script",
          "products",
          "campaign_name",
          "tag_name",
          "segment",
          "DDC",
          "campaign_type",
          "end_date",
          "start_date",
          "campaign_segmentation",
          "campaign_description",
        ],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    required: ["properties"],
    title: "Campaign Edited",
    type: "object",
  };
  const message = {
    event: "Campaign Edited",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Campaign Edited",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User tries to create/edit a campaign, but the action returns one or more errors
 *
 * @param {CampaignRegistrationFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignRegistrationFailed(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      "User tries to create/edit a campaign, but the action returns one or more errors",
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: "direct distribution center. Sample: 0575",
            type: ["string", "null"],
          },
          campaign_description: {
            description:
              "Description of the campaign. Sample: campaign to offer our Special Colorado Beers",
            type: ["string", "null"],
          },
          campaign_error_type: {
            description:
              "The error type(s) that occured in the campaign registration. Sample: invalid CSV format",
            type: ["array", "null"],
          },
          campaign_name: {
            description: "Name of the campaign. Sample: Summer Favourites\n",
            type: ["string", "null"],
          },
          campaign_priority: {
            description:
              "The prioritization rank of the campaign. 1 if primary and 2 if secondary",
            type: ["integer", "null"],
          },
          campaign_script: {
            description:
              "Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood",
            type: ["string", "null"],
          },
          campaign_segmentation: {
            description: "Segmentation of the campaign. Sample: C2B",
            type: ["string", "null"],
          },
          campaign_type: {
            description: "Type of the campaign. Samples: Focus SKU, B2O",
            type: ["string", "null"],
          },
          end_date: {
            description:
              "End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z",
            type: ["string", "null"],
          },
          products: {
            description: "the array of all product objects in the list",
            items: {
              description: "an individual product in the list",
              properties: {
                sku: {
                  description: "product SKU. Sample: ABIC001431",
                  type: "string",
                },
              },
              required: ["sku"],
              type: "object",
            },
            type: "array",
          },
          segment: {
            description: "Segment of the campaign. Sample: 04",
            type: ["string", "null"],
          },
          start_date: {
            description:
              "Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z",
            type: ["string", "null"],
          },
          tag_name: {
            description: "Tag name of the campaign. Sample: #Summer2021\n",
            type: ["string", "null"],
          },
        },
        required: [
          "campaign_script",
          "segment",
          "start_date",
          "DDC",
          "tag_name",
          "campaign_segmentation",
          "campaign_error_type",
          "campaign_description",
          "end_date",
          "campaign_type",
          "campaign_name",
          "products",
          "campaign_priority",
        ],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    required: ["properties"],
    title: "Campaign Registration Failed",
    type: "object",
  };
  const message = {
    event: "Campaign Registration Failed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Campaign Registration Failed",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User interacts with the search field/button in the Focus SKU page
 *
 * @param {CampaignSearchInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignSearchInteraction(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      "User interacts with the search field/button in the Focus SKU page",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description:
              "Name of the button as is on the code. Sample: update-agents-kpis-card",
            type: ["string", "null"],
          },
          search_query: {
            description:
              "Text typed in the product search field. Sample: Brahma Pilsen",
            type: ["string", "null"],
          },
        },
        required: ["button_label", "search_query"],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    title: "Campaign Search Interaction",
    type: "object",
  };
  const message = {
    event: "Campaign Search Interaction",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Campaign Search Interaction",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on the warning icon in the campaigns listing and a list of invalid POCs is loaded
 *
 * @param {CampaignStatusViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignStatusViewed(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      "User clicks on the warning icon in the campaigns listing and a list of invalid POCs is loaded",
    properties: {
      context: {},
      properties: {
        properties: {
          campaign_name: {
            description: "Name of the campaign. Sample: Summer Favourites\n",
            type: ["string", "null"],
          },
          tag_name: {
            description: "Tag name of the campaign. Sample: #Summer2021\n",
            type: ["string", "null"],
          },
        },
        required: ["campaign_name", "tag_name"],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    title: "Campaign Status Viewed",
    type: "object",
  };
  const message = {
    event: "Campaign Status Viewed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Campaign Status Viewed",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * > User clicks on ""Create new"" to create a new campaign |
> User clicks on ""expand"" or ""collapse"" campaign details
 *
 * @param {CampaignUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignUxButtonClicked(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      '> User clicks on ""Create new"" to create a new campaign |\n> User clicks on ""expand"" or ""collapse"" campaign details',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description:
              "Name of the button as is on the code. Sample: update-agents-kpis-card",
            type: ["string", "null"],
          },
          button_name: {
            description:
              "Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only",
            type: ["string", "null"],
          },
          filter_option: {
            description:
              "Filter selection. Samples: gap only, premium, core. Null if not applicable",
            type: ["string", "null"],
          },
          screen_name: {
            description:
              "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            type: ["string", "null"],
          },
          screen_section: {
            description:
              "Location of the screen. Samples: suggested order, last orders",
            type: ["string", "null"],
          },
        },
        required: [
          "button_label",
          "filter_option",
          "screen_name",
          "screen_section",
          "button_name",
        ],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    title: "Campaign UX Button Clicked",
    type: "object",
  };
  const message = {
    event: "Campaign UX Button Clicked",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Campaign UX Button Clicked",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * An error is displayed to the user or an error screen is loaded
 *
 * @param {ErrorMessageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function errorMessageViewed(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      "An error is displayed to the user or an error screen is loaded",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          error_message: {
            description:
              "Text displayed in the UI that describes the error. Sample: Field required",
            type: ["string", "null"],
          },
          error_type: {
            description: "Error that occured. Sample: no_connection\n",
            type: ["string", "null"],
          },
          screen_name: {
            description:
              "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            type: ["string", "null"],
          },
          screen_section: {
            description:
              "Location of the screen. Samples: suggested order, last orders",
            type: ["string", "null"],
          },
          time_of_day: {
            description:
              "The hour of day the event occured (drill down to hour only). Samples: 15, 09",
            type: ["integer", "null"],
          },
        },
        required: [
          "error_type",
          "error_message",
          "screen_name",
          "screen_section",
          "time_of_day",
        ],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    title: "Error Message Viewed",
    type: "object",
  };
  const message = {
    event: "Error Message Viewed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Error Message Viewed",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User selects a language through header dropdown menu
 *
 * @param {LanguageChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function languageChanged(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "User selects a language through header dropdown menu",
    properties: {
      context: {},
      properties: {
        properties: {
          language_selected: {
            description: "UI language selected. Samples: EN, ES",
            type: ["string", "null"],
          },
        },
        required: ["language_selected"],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    title: "Language Changed",
    type: "object",
  };
  const message = {
    event: "Language Changed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Language Changed",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * When the user accesses another page in the application
 *
 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "When the user accesses another page in the application",
    properties: {
      context: {},
      properties: {
        properties: {
          "": {
            description: "",
            type: "object",
          },
          path: {
            description: "Path of the screen. Samples: /call",
            type: "object",
          },
          referrer: {
            description:
              "URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login",
            type: "object",
          },
          search: {
            description: "The term used in search modules. Samples: Invoices",
            type: "object",
          },
          title: {
            description:
              "Title of the page displayed to the use. Sample: Gap & Status",
            type: "object",
          },
          url: {
            description:
              "URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call",
            type: "object",
          },
        },
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    title: "Page Viewed",
    type: "object",
  };
  const message = {
    event: "Page Viewed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Page Viewed",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on any retry button after an error occurs (e.g.: click on "reload list" after it fails to load)
 *
 * @param {RetryButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function retryButtonClicked(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description:
      'User clicks on any retry button after an error occurs (e.g.: click on "reload list" after it fails to load)',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description:
              "Name os the screen user is in. Samples: Agent's KPIs, GAP & Status",
            type: ["string", "null"],
          },
          screen_section: {
            description:
              "Location of the screen. Samples: suggested order, last orders",
            type: ["string", "null"],
          },
        },
        required: ["screen_section", "screen_name"],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    title: "Retry Button Clicked",
    type: "object",
  };
  const message = {
    event: "Retry Button Clicked",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Retry Button Clicked",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
/**
 * User clicks on any button inside sidebar section
 *
 * @param {SidebarInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sidebarInteraction(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    description: "User clicks on any button inside sidebar section",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description:
              "Name of the button as is on the code. Sample: update-agents-kpis-card",
            type: ["string", "null"],
          },
          button_name: {
            description:
              "Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only",
            type: ["string", "null"],
          },
          button_selection: {
            description: "Selected option. Samples: English, spanish",
            type: ["string", "null"],
          },
        },
        required: ["button_name", "button_label", "button_selection"],
        type: "object",
      },
      traits: {
        type: "object",
      },
    },
    title: "Sidebar Interaction",
    type: "object",
  };
  const message = {
    event: "Sidebar Interaction",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      "Sidebar Interaction",
      props || {},
      withTypewriterContext(options),
      callback,
    );
  }
}
const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,
  /**
   * User clicks on "confirm" in the agent setup modal and assigns a segmentation with success
   *
   * @param {AgentSegmentationAssigned} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  agentSegmentationAssigned,
  /**
   * User clicks on confirm button inside block list deletion modal and the block list is deleted
   *
   * @param {BlockListDeleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  blockListDeleted,
  /**
   * User clicks on the block list download button and the list is downloaded
   *
   * @param {BlockListDownloaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  blockListDownloaded,
  /**
   * User clicks on confirm button inside confirmation modal and a new block list is submitted
   *
   * @param {BlockListUploaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  blockListUploaded,
  /**
   * User clicks on the "create" button in the creation modal and the campaign is created with success
   *
   * @param {CampaignCreated} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignCreated,
  /**
   * User clicks on the "delete" button in the confirmation modal and the campaign is deleted with success
   *
   * @param {CampaignDeleted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignDeleted,
  /**
   * User clicks on the "confirm" button in the edit modal and the campaign is edited with success
   *
   * @param {CampaignEdited} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignEdited,
  /**
   * User tries to create/edit a campaign, but the action returns one or more errors
   *
   * @param {CampaignRegistrationFailed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignRegistrationFailed,
  /**
   * User interacts with the search field/button in the Focus SKU page
   *
   * @param {CampaignSearchInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignSearchInteraction,
  /**
   * User clicks on the warning icon in the campaigns listing and a list of invalid POCs is loaded
   *
   * @param {CampaignStatusViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignStatusViewed,
  /**
     * > User clicks on ""Create new"" to create a new campaign |
    > User clicks on ""expand"" or ""collapse"" campaign details
     *
     * @param {CampaignUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
  campaignUxButtonClicked,
  /**
   * An error is displayed to the user or an error screen is loaded
   *
   * @param {ErrorMessageViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  errorMessageViewed,
  /**
   * User selects a language through header dropdown menu
   *
   * @param {LanguageChanged} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  languageChanged,
  /**
   * When the user accesses another page in the application
   *
   * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pageViewed,
  /**
   * User clicks on any retry button after an error occurs (e.g.: click on "reload list" after it fails to load)
   *
   * @param {RetryButtonClicked} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  retryButtonClicked,
  /**
   * User clicks on any button inside sidebar section
   *
   * @param {SidebarInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sidebarInteraction,
};
export default new Proxy(clientAPI, {
  get(target, method) {
    if (typeof method === "string" && target.hasOwnProperty(method)) {
      return target[method];
    }
    return () => {
      console.warn(`⚠️  You made an analytics call (${String(
        method,
      )}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_1sLaHQgU12daYzLv3kSiK4HPkrn`);
      const a = analytics();
      if (a) {
        a.track(
          "Unknown Analytics Call Fired",
          {
            method,
          },
          withTypewriterContext(),
        );
      }
    };
  },
});

import React from "react";
import { formatMessage } from "../../../i18n/formatters";
import AlertDialog from "../AlertDialog/AlertDialog";

interface Props {
  show?: boolean;
  handleCancel?: (buttonClicked: boolean) => void;
  handleConfirm?: (
    e: React.MouseEvent | React.FormEvent<HTMLFormElement>,
  ) => void;
}

const DeleteDialog: React.FC<Props> = ({
  show,
  handleConfirm,
  handleCancel,
}: Props) => {
  return (
    <AlertDialog
      id="logout-dialog"
      title={formatMessage({ id: "DELETE_CAMPAIGNS.DELETE_CAMPAIGN" })}
      description={formatMessage({
        id: "DELETE_CAMPAIGNS.DELETE_CAMPAIGN_DESCRIPTION",
      })}
      buttonConfirmText={formatMessage({ id: "DELETE_CAMPAIGNS.DELETE" })}
      show={show}
      handleConfirm={handleConfirm}
      handleCancel={handleCancel}
    />
  );
};

DeleteDialog.defaultProps = {
  show: false,
  handleCancel: undefined,
  handleConfirm: undefined,
};

export default DeleteDialog;

/* eslint-disable react/no-unused-prop-types */
import React from "react";
import { Box, Typography, ButtonBase, TextField } from "@material-ui/core";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import classNames from "classnames";
import CustomToast from "@components/CustomToast/CustomToast";
import { formatMessage } from "../../../../i18n/formatters";
import AddIconSkuID from "../../../../assets/icons/AddIconSkuID";
import DeleteIconSkuID from "../../../../assets/icons/DeleteIconSkuID";

import SkuIDStyle from "./SkuIDStyle";

const EMPTY = "";

interface SkuProps {
  id: string;
  value: string;
  hasAdd?: boolean;
  hasDelete?: boolean;
}

interface SkuIDProps {
  skus: Array<string>;
  handleChange: (skus: Array<string>) => void;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
}

const SkuID: React.FC<SkuIDProps> = (props) => {
  const classes = SkuIDStyle();
  const { handleChange, errors, touched } = props;

  const onChange = (id: string, value: string) => {
    const newSkus = skus.map((skuItem) => {
      if (skuItem.id === id) {
        skuItem.value = value;
      }
      return skuItem;
    });
    handleChange(newSkus.map(({ value: valueItem }) => valueItem));
  };

  const handleButtons = (skusHandle: Array<SkuProps>) => {
    const { length } = skusHandle;
    let newSkus = [];
    if (length === 1) {
      const first = skusHandle[0];
      first.hasAdd = true;
      first.hasDelete = false;
      newSkus.push(first);
    } else {
      newSkus = skusHandle.map((item, index) => {
        const hasAdd = index === length - 1;
        const hasDelete = index !== 0;
        return {
          ...item,
          hasAdd,
          hasDelete,
        };
      });
    }
    return newSkus;
  };

  const getInitialProps = (propsParams: SkuIDProps): Array<SkuProps> => {
    const { skus: skuProps } = propsParams;
    if (skuProps.length === 0) {
      skuProps.push(EMPTY);
    }
    return handleButtons(
      skuProps.map((sku, index) => {
        return {
          id: `${index}`,
          value: sku,
        };
      }),
    );
  };

  const add = () => {
    const id = `${skus.length}`;
    skus.push({ id, value: "", hasAdd: true, hasDelete: true });

    handleChange(skus.map(({ value }) => value));
    return false;
  };

  const remove = (id: string) => {
    const newSkus = skus.filter((sku) => sku.id !== id);
    handleChange(newSkus.map(({ value }) => value));
  };

  const skus = getInitialProps(props);

  return (
    <Box className={classes.container}>
      {skus.map((sku) => (
        <SkuIdComponent
          key={sku.id}
          sku={sku}
          add={add}
          remove={remove}
          onChange={onChange}
          errors={errors}
          touched={touched}
        />
      ))}
    </Box>
  );
};

interface SkuIdComponentProps {
  sku: SkuProps;
  add: () => void;
  onChange: (id: string, value: string) => void;
  remove: (id: string) => void;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
}

const SkuIdComponent = (props: SkuIdComponentProps) => {
  const classes = SkuIDStyle();
  const { sku: skuProp, add, remove, onChange, errors, touched } = props;
  const { id, value, hasAdd, hasDelete } = skuProp;

  if (errors.skus) {
    CustomToast({
      type: "error",
      message: formatMessage({ id: "ERROR_MESSAGE.DUPLICATED_SKU" }),
    });
  }

  return (
    <Box className={classes.innerContainer}>
      <Typography className={classes.inputTitle}>
        {formatMessage({ id: "ADMIN.SKU_ID" })}
      </Typography>
      <Box className={classes.containerInputIcon}>
        <Box style={{ width: "100%" }}>
          <TextField
            fullWidth
            variant="outlined"
            name={`sku_${skuProp.id}`}
            InputProps={{
              classes: {
                root: classNames(classes.input, {
                  [classes.details]: errors[`sku_${skuProp.id}`],
                  [classes.detailsError]:
                    /* istanbul ignore next */
                    !!errors[`sku_${skuProp.id}`] && touched?.skus
                      ? [skuProp.id]
                      : "" || !!errors.skus,
                }),
              },
            }}
            onChange={(ev) => {
              const { value: valueTarget } = ev.target;
              onChange(id, valueTarget);
            }}
            value={value}
            placeholder={formatMessage({ id: "ADMIN.SKU_ID_PLACEHOLDER" })}
            data-testid={`modal-campaign-input-sku_${skuProp.id}`}
          />
          {
            /* istanbul ignore next */
            !!errors[`sku_${skuProp.id}`] && touched?.skus
              ? [skuProp.id] && (
                  <span
                    className={classes.errorMessage}
                    data-testid={`modal-campaign-input-sku_${skuProp.id}-error`}
                  >
                    {errors[`sku_${skuProp.id}`]}
                  </span>
                )
              : ""
          }
        </Box>
        <Box className={classes.containerButton}>
          {hasDelete && (
            <ButtonBase
              color="secondary"
              className={classes.deleteButton}
              onClick={() => remove(id)}
              data-testid="btn-remove-sku-id"
            >
              <DeleteIconSkuID />
            </ButtonBase>
          )}
          {hasAdd && (
            <ButtonBase
              color="secondary"
              className={classes.addButton}
              onClick={add}
              data-testid="btn-add-sku-id"
            >
              <AddIconSkuID />
            </ButtonBase>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(SkuID);

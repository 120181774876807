import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette }) =>
  createStyles({
    paperRoot: {
      padding: 32,
      borderRadius: "16px",
      backgroundColor: palette.common.white,
      "&.beesgrow-MuiPaper-elevation3": {
        boxShadow: "2px 2px 10px 0px rgba(0,0,0,0.1)",
      },
    },
    content: {
      display: "flex",
      justifyContent: "space-between",
    },
    container: {
      width: "100%",
    },
    title: {
      marginBottom: "8px",
      fontFamily: "Work Sans",
    },
    draggableListContainer: {
      padding: "8px",
      border: "1px solid #D6D6D6",
      borderRadius: "8px",
    },
    scrollContainer: {
      overflow: "auto",
      maxHeight: "300px",
      minWidth: "20vw",
    },
    divider: {
      margin: "0px 35px",
    },
    applyChangesButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "3.6rem",
    },
    applyChangesButtonLabel: {
      height: "40px",
      fontFamily: "Work Sans",
      fontSize: "18px",
      lineHeight: "37px",
      color: palette.common.white,
    },
  }),
);

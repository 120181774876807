import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    tooltip: {
      backgroundColor: "black",
      fontSize: "14px",
      padding: "8px",
      borderRadius: "8px",
      textAlign: "center",
    },
    arrow: {
      color: "black",
    },
  }),
);

import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";

import { isFeatureEnabled } from "grow-shared-services";
import axios from "../../config/axios/axiosInstance";
import {
  TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
  GROW_ORGANIZATION_ACTIVATION,
} from "../../config/featureToggles";
import { TLP_CAMPAIGN_SERVICE } from "../../config/services";
import GetAllCampaignsConverter from "../../converters/campaigns/GetAllCampaignsConverter";
import { Campaigns } from "../../domains/Campaigns";
import { getEndPoint, getApiHost } from "../host/HostService";
import GetAllCampaingsResponse from "./domains/GetAllCampaignsResponse";
import { CAMPAIGNS_PROJECTION } from "../../config/constants";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

interface HeadersData {
  country: string;
  requestTraceId: string;
  authorization: string;
}

export function getAllCampaigns(
  isCanaryRelease: boolean,
  vendorId: string,
  vendorName: string,
  headers?: HeadersData,
): Promise<Campaigns> {
  const log = useLogService();

  const isMulticontractCampaignServiceEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
  );

  const isGrowOrganizationActivationEnable = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const getUrl = () => {
    let url = `${getApiHost()}${getEndPoint(TLP_CAMPAIGN_SERVICE)}`;
    let campaignsEndpoint = "";

    if (isGrowOrganizationActivationEnable) {
      campaignsEndpoint = `/v3/campaigns`;
      url += campaignsEndpoint;
    } else if (isMulticontractCampaignServiceEnable) {
      campaignsEndpoint = `/tlp-campaign/v2/admin/products?vendorId=${vendorId}`;
      url += campaignsEndpoint;
    } else {
      campaignsEndpoint = "/tlp-campaign/admin/products";
      url += campaignsEndpoint;
    }

    /* istanbul ignore next */
    if (isCanaryRelease) url = `${getApiHost()}${campaignsEndpoint}`;

    return url;
  };

  const url = getUrl();

  const getHeaders = () => {
    let headersData = {};
    if (!isMulticontractCampaignServiceEnable)
      headersData = {
        vendorId,
        vendorName,
      };
    const anotherHeadersData = headers || {};
    return { ...headersData, ...anotherHeadersData };
  };

  return axios
    .get<GetAllCampaingsResponse[]>(url, {
      headers: getHeaders(),
      params: isGrowOrganizationActivationEnable
        ? { projection: CAMPAIGNS_PROJECTION.ADMIN }
        : {},
    })
    .then((response) => {
      log.info(`Convert Get All Campaigns received from ${url} to our model`);
      return GetAllCampaignsConverter(
        response.data,
        isGrowOrganizationActivationEnable,
      );
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}

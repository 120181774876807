import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette }) =>
  createStyles({
    containerInputIcon: {
      display: "flex",
    },
    container: {
      display: "flex",
      flexDirection: "column",
    },
    innerContainer: {
      marginTop: "24px",
    },
    inputTitle: {
      fontSize: "12px",
      fontWeight: "bold",
      color: "#525252",
      marginBottom: "3px",
    },
    input: {
      reisze: "none",
      fontSize: "0.8rem",
      height: "32px",
      borderRadius: "3px",
      border: `solid 1px ${palette.text.secondary}`,
      padding: "0",
      outline: "none",
      "&:focus": {
        border: `solid 1px ${palette.common.black}`,
      },
    },
    addButton: {
      width: "32px",
      height: "32px",
      borderRadius: "3px",
      alignItems: "center",
      display: "flex",
      cursor: "pointer",
      padding: 0,
      textTransform: "none",
      marginLeft: "14px",
      backgroundColor: palette.secondary.main,
    },
    deleteButton: {
      width: "32px",
      height: "32px",
      borderRadius: "3px",
      border: "solid 1px",
      alignItems: "center",
      display: "flex",
      cursor: "pointer",
      padding: 0,
      textTransform: "none",
      borderColor: "#d00505",
      backgroundColor: "#ffffff",
      marginLeft: "14px",
    },
    containerButton: {
      display: "flex",
      flexDirection: "row",
    },
    details: {
      border: `solid 1px ${palette.text.secondary}`,
    },
    detailsError: {
      border: `solid 1px ${palette.error.main}`,
      borderRadius: "3px",
    },
    errorMessage: {
      marginTop: "4px",
      marginBottom: "-24px",
      fontSize: "10px",
      fontFamily: "Roboto",
      fontWeight: "normal",
      color: palette.error.main,
    },
  }),
);

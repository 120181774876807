import { makeStyles, createStyles } from "@material-ui/core/styles";

const styles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: "#f8f8f8",
    },
    containerWhite: {
      backgroundColor: "#ffffff",
      borderRadius: "3px",
    },
    innerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: "20px",
    },
    title: {
      marginLeft: "20px",
      color: "#505050",
      fontSize: "12px",
    },
    fileTitle: {
      marginLeft: "20px",
      color: "#505050",
      fontSize: "12px",
    },
    error: {
      color: "#d00505",
    },
    deleteButton: {
      width: "32px",
      height: "32px",
      alignItems: "center",
      display: "flex",
      cursor: "pointer",
      padding: 0,
      textTransform: "none",
      borderColor: "#d00505",
      marginLeft: "8px",
    },
    containerFiles: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  }),
);

export default styles;

import React, { useEffect } from "react";
import { Box, ButtonBase } from "@material-ui/core";
import Moment from "moment";
import RetryIcon from "../../assets/RetryIcon";
import ErrorHandlerRetryStyles from "./ErrorHandlerRetryStyles";
import {
  errorMessageViewed,
  retryButtonClicked,
} from "../../config/typewriter";
import { useAnalytics } from "../../analytics/useAnalytics";
import { formatMessage } from "../../i18n/formatters";

interface Props {
  onClick: () => void;
  testId?: string;
  screenName: string;
  screenSection: string;
}

const ErrorHandlerRetry: React.FunctionComponent<Props> = ({
  onClick,
  testId,
  screenName,
  screenSection,
}) => {
  const classes = ErrorHandlerRetryStyles();
  const { dispatchGenericEvent } = useAnalytics();

  const clickAction = () => {
    dispatchGenericEvent(retryButtonClicked, {
      screen_name: screenName,
      screen_section: screenSection,
    });
    onClick();
  };

  useEffect(() => {
    dispatchGenericEvent(errorMessageViewed, {
      error_message: "Information not found",
      error_type: "Get campaigns error",
      screen_name: screenName,
      screen_section: screenSection,
      time_of_day: Moment().local().hour(),
    });
  }, [dispatchGenericEvent, screenName, screenSection]);

  return (
    <Box
      className={classes.errorContainer}
      data-testid={`${testId}-retry-container`}
    >
      <div className={classes.errorText}>
        {formatMessage({ id: "ErrorHandlerRetry.INFORMATION_NOT_FOUND" })}
      </div>
      <ButtonBase onClick={clickAction} data-testid={`${testId}-retry-button`}>
        <span className={classes.buttonLabel}>
          {formatMessage({ id: "ErrorHandlerRetry.RELOAD_BUTTON_LABEL" })}
        </span>
        <RetryIcon />
      </ButtonBase>
    </Box>
  );
};

ErrorHandlerRetry.defaultProps = {
  testId: "",
};

export default ErrorHandlerRetry;

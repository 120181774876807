import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    homeIcon: {
      padding: 0,
      margin: "0px 16px",
    },
  }),
);

import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette }) =>
  createStyles({
    mainTitle: {
      fontFamily: "Barlow",
      fontSize: "40px",
      fontWeight: 600,
      color: palette.info.dark,
    },
    subtitle: {
      fontFamily: "Work Sans",
      width: "70%",
      marginTop: "1rem",
      color: palette.text.disabled,
    },
  }),
);

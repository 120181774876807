import { makeStyles, createStyles } from "@material-ui/core/styles";

const styles = makeStyles(() =>
  createStyles({
    title: {
      color: "#505050",
      fontSize: "12px",
      fontWeight: "bold",
    },
    input: {
      color: "#505050",
      padding: 0,
      fontSize: "1rem",
      fontWeight: 500,
    },
    select: {
      borderRadius: "8px",
      height: "48px",
      width: "362px",
      "& input: first-child": {
        padding: "0 0 0 16px",
      },
    },

    textField: {
      "& div fieldset ": {
        borderRadius: "8px",
      },
    },
  }),
);

export default styles;

import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    optionBtn: {
      height: 47,
      width: "100%",
      borderBottom: "1px solid #eaeaea",
      display: "flex",
      padding: 16,
      justifyContent: "left",
    },
    popover: {
      "& .beesgrow-MuiPopover-paper": {
        width: "270px",
        overflowY: "unset",
        overflowX: "unset",
      },
    },
    container: {
      width: " 100%",
      height: 100,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
  }),
);

import { createEffect } from "effector";
import * as UpdateCampaignUseCase from "../../usecase/campaigns/UpdateCampaignUseCase";
import { UpdateCampaignBody } from "../../services/campaigns/domains/CreateCampaignRequest";

interface CampaignWithCountryAndTag {
  country: string;
  campaignTag: string;
  campaign: UpdateCampaignBody;
  vendorId?: string;
  vendorName?: string;
  isCampaignFocusVendorEnabled?: boolean;
  idCampaign?: string;
}

export const updateCampaignEffect = createEffect({
  async handler(updateCampaign: CampaignWithCountryAndTag) {
    const {
      country,
      campaignTag,
      campaign,
      vendorId,
      vendorName,
      isCampaignFocusVendorEnabled,
      idCampaign,
    } = updateCampaign;
    return UpdateCampaignUseCase.execute(
      country,
      campaignTag,
      campaign,
      vendorId,
      vendorName,
      isCampaignFocusVendorEnabled,
      idCampaign,
    );
  },
});

import React from "react";
import { ListItem, Box } from "@material-ui/core";
import DraggableCard from "../DraggableCard/DraggableCard";
import { DnDListItem } from "../Types";
import useStyle from "./DisabledList.styles";
import useMissionsManagement from "../../../hooks/useMissionsManagement";

interface DisabledListProps {
  disabledDraggableItems: Array<DnDListItem>;
}

const DisabledList = ({
  disabledDraggableItems,
}: DisabledListProps): JSX.Element => {
  const classes = useStyle();
  const { handleToggleMission } = useMissionsManagement();

  const sortedMissionDisabled = [...disabledDraggableItems].sort((a, b) => {
    return a.title.normalize().localeCompare(b.title.normalize());
  });

  return (
    <div data-testid="disabled-list">
      {sortedMissionDisabled?.map((each, index) => (
        <React.Fragment key={each.id}>
          <ListItem
            data-testid="disabled-list-item"
            key={each.id}
            className={classes.listItem}
          >
            <DraggableCard
              listItemId={each.id}
              type={each.type}
              isActive={each.isEnabled}
              title={each.title}
              handleAction={() => {
                handleToggleMission(each.id, "enabled");
              }}
              data-testid="disabled-mission-card-list-item"
            />
          </ListItem>

          {disabledDraggableItems.length - 1 !== index && (
            <Box className={classes.inner} data-testid="divider-item" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default DisabledList;

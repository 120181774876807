import React from "react";

const SingleArrowRight: React.FC = () => {
  return (
    <svg width="7px" height="12px" viewBox="0 0 7 12" version="1.1">
      <title>Path 3</title>
      <g
        id="Admin"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="SPRINT-61-Admin---Portal-SEARCH"
          transform="translate(-1264.000000, -719.000000)"
          fillRule="nonzero"
          stroke="#505050"
          strokeWidth="1.5"
        >
          <path
            d="M1265,720 L1269.29238,724.286097 C1269.68319,724.676335 1269.68365,725.3095 1269.29341,725.70031 C1269.29341,725.70031 1269.29341,725.70031 1269.29341,725.70031 L1265,730 L1265,730"
            id="Path-3"
          />
        </g>
      </g>
    </svg>
  );
};

export default SingleArrowRight;

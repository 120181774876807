import React, { useEffect } from "react";

import { Box, Dialog, Typography, ButtonBase } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import classNames from "classnames";
import { useStore } from "effector-react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { isFeatureEnabled, getVendorName } from "grow-shared-services";

import { v4 as guid } from "uuid";
import {
  GROW_ORGANIZATION_ACTIVATION,
  TLP_CAMPAIGN_FOCUS_VENDOR,
} from "@config/featureToggles";
import LoadingView from "@components/loadingView/LoadingView";
import { getUserInfos } from "@config/utils/functions";
import {
  getAllFailedPocsEffect,
  getAllOrInvalidPocsEffectV3,
} from "@stores/campaigns/GetAllFailedPocsEvents";
import GetAllFailedPocsStore from "@stores/campaigns/GetAllFailedPocsStore";
import { formatMessage } from "../../../../i18n/formatters";
import PocIdsInfoModalStyles from "./PocIdsInfoModalStyles";

interface ListProps {
  itemData: Array<string>;
  row: React.FunctionComponent<ListChildComponentProps>;
}
export const requestTraceId = guid();
/* TODO: Refactor this function into a new component, or remove function and render this component directly */
/* istanbul ignore next */
export const List: React.FC<ListProps> = ({ itemData, row }) => {
  return (
    <FixedSizeList
      height={300}
      width={400}
      itemSize={30}
      itemCount={itemData.length}
      itemData={itemData}
    >
      {row}
    </FixedSizeList>
  );
};

interface Props {
  open: boolean;
  onClose: () => void;
  campaignTag: string;
  loadInfos: boolean;
  isB2OCampaign: boolean;
  setLoadInfos: (loadInfos: boolean) => void;
}

const PocIdsInfoModal: React.FC<Props> = ({
  open,
  onClose,
  campaignTag,
  loadInfos,
  isB2OCampaign,
  setLoadInfos,
}) => {
  const classes = PocIdsInfoModalStyles();
  const { pocInformation, pocInformationV3 } = useStore(GetAllFailedPocsStore);

  const { vendorId: userVendorId } = getUserInfos();

  const isCampaignFocusVendorEnabled = isFeatureEnabled(
    TLP_CAMPAIGN_FOCUS_VENDOR,
  );
  const isGrowOrganizationActivationEnabled = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const selectedPocInformationVersion = isGrowOrganizationActivationEnabled
    ? pocInformationV3
    : pocInformation;

  useEffect(() => {
    if (campaignTag !== "" && open === true) {
      const vendorName = getVendorName(userVendorId);

      setLoadInfos(true);
      if (isGrowOrganizationActivationEnabled) {
        const { country, orgId } = getUserInfos();
        getAllOrInvalidPocsEffectV3({
          campaignTag,
          country,
          orgId,
          requestTraceId,
          projection: "INVALID_ACCOUNTS",
        });
      } else {
        getAllFailedPocsEffect({
          campaignTag,
          campaignType: isB2OCampaign ? "b2o" : "focus_sku",
          vendorId: userVendorId,
          vendorIdMulticontract: userVendorId || undefined,
          vendorName,
          isCampaignFocusVendorEnabled,
        });
      }
    }
  }, [
    campaignTag,
    setLoadInfos,
    open,
    isB2OCampaign,
    userVendorId,
    isCampaignFocusVendorEnabled,
    isGrowOrganizationActivationEnabled,
  ]);

  /* TODO: Refactor this function into a new component, or remove function and render this component directly */
  /* istanbul ignore next */
  const Row = (props: ListChildComponentProps) => {
    const { index, style } = props;
    const failedPoc = selectedPocInformationVersion?.invalid?.failedPocs[index];

    return (
      <ListItem
        button
        className={classes.pocIdContent}
        style={style}
        key={`failedPoc${index}`}
      >
        <Typography className={classes.pocID}>{failedPoc}</Typography>
        <Typography className={classes.error}>Invalid ID</Typography>
      </ListItem>
    );
  };

  const renderInformations = () => {
    return (
      <>
        <div className={classes.title}>
          <Typography
            className={classes.titlePocIdsTotal}
            data-testid="failed-pocs-quantity"
          >
            {formatMessage({ id: "POC.POC_WITH_ERROR" })}
            {selectedPocInformationVersion?.invalid?.failedPocs?.length || 0}
          </Typography>

          {isB2OCampaign && (
            <div>
              <Typography
                className={classes.titleToBeContactedTotal}
                data-testid="total-pocs-pending"
              >
                {formatMessage({ id: "POC.TO_BE_CONTACTED" })}
                <span className={classes.totalToBeContacted}>
                  {selectedPocInformationVersion?.invalid?.totalPending}
                </span>
              </Typography>
              <Typography
                className={classes.titleContactedTotal}
                data-testid="total-pocs-contacted"
              >
                {formatMessage({ id: "POC.CONTACTED" })}
                <span className={classes.contactedTotal}>
                  {selectedPocInformationVersion?.invalid?.totalCompleted}
                </span>
              </Typography>
            </div>
          )}
        </div>
        <div data-testid="failed-pocs">
          <List
            itemData={selectedPocInformationVersion?.invalid?.failedPocs}
            row={Row}
          />
        </div>
        <Box className={classes.buttonsContainer}>
          <ButtonBase
            onClick={onClose}
            className={classNames(classes.button, classes.confirmButton)}
            data-testid="modal-pocIdInfo-close-button"
          >
            {formatMessage({ id: "BUTTON.CLOSE" })}
          </ButtonBase>
        </Box>
      </>
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} disableBackdropClick>
        <Box className={classes.container}>
          {loadInfos ? (
            <div className={classes.loading}>
              <LoadingView />
            </div>
          ) : (
            renderInformations()
          )}
        </Box>
      </Dialog>
    </div>
  );
};

export default React.memo(PocIdsInfoModal);

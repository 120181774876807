/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";

import { getUserInfos } from "@config/utils/functions";
import { Box, Typography, ButtonBase } from "@material-ui/core";
import classNames from "classnames";
import { Form, Formik, FormikValues } from "formik";
import Moment from "moment";

import { useAnalytics } from "../../analytics/useAnalytics";
import AlertDialog from "../../components/core/AlertDialog/AlertDialog";
import { blockListUploaded, errorMessageViewed } from "../../config/typewriter";
import { AlertInfo } from "../../domains/Alert";
import { formatMessage } from "../../i18n/formatters";
import {
  deleteBlockListEffect,
  getBlockListEffect,
  updateBlockListEffect,
} from "../../stores/management/BlockListEvents";
import BlockListMainPageStyle from "./BlockListMainPageStyle";
import CsvList from "./components/CsvList/CsvList";

const BlockListMainPage: React.FC = () => {
  const classes = BlockListMainPageStyle();
  const { dispatchGenericEvent } = useAnalytics();

  const [isFileInputed, setIsFileInputed] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState<AlertInfo>({
    message: "",
    type: "error",
    refresh: false,
  });

  const { vendorId } = getUserInfos();

  const shouldDisableConfirmButton = !isFileInputed;

  const initialValues: FormikValues = {
    blockList: null,
  };

  const onRegisterHandler = (values: FormikValues) => {
    setOpenAlert(false);
    setAlertStatus({
      ...alertStatus,
      message: "",
    });
    /* istanbul ignore next */
    if (values) {
      const update = {
        vendorId,
        blockList: {
          updateAt: new Date(),
          blockList: values.blockList || [],
        },
      };
      updateBlockListEffect(update);
      updateBlockListEffect.done.watch(() => {
        setAlertStatus({
          message: formatMessage({ id: "BLOCK_LIST.UPDATE_MESSAGE" }),
          type: "success",
          refresh: false,
        });
        dispatchGenericEvent(blockListUploaded, {
          block_list: values.name,
        });
      });
    }
  };

  useEffect(() => {
    updateBlockListEffect.fail.watch(() => {
      setAlertStatus({
        message: formatMessage({ id: "BLOCK_LIST.NO_CSV" }),
        type: "error",
        refresh: false,
      });
      dispatchGenericEvent(errorMessageViewed, {
        error_type: "block list file processing error",
        error_message: "Something went wrong, the block list was not updated.",
        screen_name: "Block List",
        screen_section: "Block List",
        time_of_day: Moment().local().hour(),
      });
      setOpenAlert(false);
    });
  }, [dispatchGenericEvent]);

  useEffect(() => {
    deleteBlockListEffect.done.watch(() => {
      setAlertStatus({
        message: formatMessage({ id: "BLOCK_LIST.DELETE_MESSAGE" }),
        type: "success",
        refresh: false,
      });
    });
  }, []);

  useEffect(() => {
    deleteBlockListEffect.fail.watch(() => {
      setAlertStatus({
        message: formatMessage({ id: "BLOCK_LIST.NO_CSV" }),
        type: "error",
        refresh: false,
      });
    });
  }, []);

  useEffect(() => {
    getBlockListEffect.done.watch(() => {
      setOpenAlert(false);
    });
  }, [alertStatus, openAlert]);

  const dispatchEventError = useCallback(() => {
    dispatchGenericEvent(errorMessageViewed, {
      error_type: "block list retrieval error",
      error_message: formatMessage({ id: "BLOCK_LIST.CURRENT_BLOCK_LIST" }),
      screen_name: "Block List",
      screen_section: "Block List",
      time_of_day: Moment().local().hour(),
    });
  }, [dispatchGenericEvent]);

  useEffect(() => {
    getBlockListEffect.fail.watch(() => {
      dispatchEventError();
      setAlertStatus({
        message: formatMessage({ id: "BLOCK_LIST.CURRENT_BLOCK_LIST" }),
        type: "error",
        refresh: true,
      });
    });
  }, [dispatchEventError]);

  return (
    <div className={classes.container}>
      <div className={classes.modalContent}>
        <Box className={classes.modal}>
          <Typography className={classes.title}>
            {formatMessage({ id: "BLOCK_LIST.MODAL_TITLE" })}
          </Typography>
          <Formik initialValues={initialValues} onSubmit={onRegisterHandler}>
            {({ errors, setFieldValue, values, touched, handleSubmit }) => (
              <Form className={classes.form}>
                <CsvList
                  testId="block-list"
                  title={formatMessage({ id: "BLOCK_LIST.CSV_TITLE" })}
                  errors={errors}
                  touched={touched}
                  alertStatus={alertStatus}
                  setFieldValue={setFieldValue}
                  values={values}
                  setAlertStatus={setAlertStatus}
                  setIsFileInputed={setIsFileInputed}
                />
                <AlertDialog
                  show={openAlert}
                  id="block-list-dialog"
                  title={formatMessage({
                    id: "BLOCK_LIST.CONFIRM_BUTTON",
                  })}
                  description={formatMessage({
                    id: "BLOCK_LIST.DESCRIPTION",
                  })}
                  handleCancel={
                    /* istanbul ignore next */ () => {
                      setOpenAlert(false);
                    }
                  }
                  handleConfirm={handleSubmit}
                />
              </Form>
            )}
          </Formik>
          <ButtonBase
            type="button"
            className={classNames(
              classes.button,
              classes.registerButton,
              shouldDisableConfirmButton ? classes.confirmButtonDisabled : "",
            )}
            data-testid="block-list-register-button"
            onClick={() => setOpenAlert(true)}
            disabled={shouldDisableConfirmButton}
          >
            {formatMessage({ id: "BLOCK_LIST.CONFIRM_BUTTON" })}
          </ButtonBase>
        </Box>
      </div>
    </div>
  );
};
export default BlockListMainPage;

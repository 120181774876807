import { createStore } from "effector";
import * as CampaignItemsEvents from "./CampaignItemsEvents";
import CampaignItemsState from "./CampaignItemsState";
import CampaignItemsDb from "../../../indexDB/campaignItems/CampaignItemsDb";

const initialState = <CampaignItemsState>{
  loading: false,
  done: false,
  error: false,
  hasBeenAddedToDb: false,
};

const CampaignItemsStore = createStore(initialState)
  .on(
    CampaignItemsEvents.getCampaignItemsEffect.done,
    (state: CampaignItemsState, { result }) => ({
      ...state,
      done: true,
      error: false,
      loading: false,
      campaignItems: result?.campaignItems,
    }),
  )
  .on(
    CampaignItemsEvents.getCampaignItemsEffect,
    (state: CampaignItemsState) => ({
      ...state,
      done: false,
      error: false,
      loading: true,
    }),
  )
  .on(
    CampaignItemsEvents.getCampaignItemsEffect.fail,
    (state: CampaignItemsState) => ({
      ...state,
      done: true,
      error: true,
      loading: false,
    }),
  )
  .on(CampaignItemsEvents.clearCampaignItems, (state: CampaignItemsState) => {
    new CampaignItemsDb().campaignItems.toCollection().delete();

    return {
      ...state,
      hasBeenAddedToDb: false,
    };
  })
  .on(
    CampaignItemsEvents.setHasBeenAddedToDb,
    (state: CampaignItemsState, hasBeenAddedToDb: boolean) => ({
      ...state,
      hasBeenAddedToDb,
    }),
  )
  .on(
    CampaignItemsEvents.setCalledTag,
    (state: CampaignItemsState, calledTag: string) => ({
      ...state,
      calledTag,
    }),
  );

export default CampaignItemsStore;

import React from "react";
import classNames from "classnames";
import ButtonBase, { ButtonBaseProps } from "@material-ui/core/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import ButtonStyles from "./Button.styles";

interface Props extends ButtonBaseProps {
  loading?: boolean;
  variant?: "contained" | "outlined";
  testId?: string;
  disabled?: boolean;
}

const Button: React.ForwardRefExoticComponent<Props> = React.forwardRef(
  (
    { loading, disabled, variant, className, testId, children, ...props },
    ref,
  ) => {
    const classes = ButtonStyles();

    return (
      <ButtonBase
        disabled={loading || disabled}
        className={classNames(className, {
          [classes.button]: !className,
          [classes.contained]: variant === "contained",
          [classes.outlined]: variant === "outlined",
          [classes.disabled]: disabled,
        })}
        data-testid={testId}
        ref={ref}
        {...props}
      >
        {loading ? (
          <CircularProgress
            size={15}
            className={classNames(classes.loading, {
              [classes.loadingContained]: variant === "contained",
              [classes.loadingOutlined]: variant === "outlined",
              [classes.loadingDisabled]: disabled,
            })}
          />
        ) : (
          children
        )}
      </ButtonBase>
    );
  },
);

Button.defaultProps = {
  disabled: false,
  variant: "contained",
  loading: false,
  testId: "",
};

export default Button;

import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { isFeatureEnabled } from "grow-shared-services";

import axios from "../../config/axios/axiosInstance";
import {
  TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
  GROW_ORGANIZATION_ACTIVATION,
} from "../../config/featureToggles";
import { TLP_CAMPAIGN_SERVICE } from "../../config/services";
import { getEndPoint, getApiHost } from "../host/HostService";

export const CAMPAINGS_ENDPOINT = "/tlp-campaign";
export const CAMPAIGNS_ENDPOINT_END = "/admin/products";
const campaigns = "campaigns";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

interface HeadersData {
  country: string;
  requestTraceId: string;
  authorization: string;
}

interface HeadersDataV3 {
  country: string;
  orgId: string;
  requestTraceId: string;
}

export function deleteCampaign(
  country: string,
  campaignTag: string,
  vendorId: string,
  vendorName?: string,
  campaignType?: string,
  headers?: HeadersData | HeadersDataV3,
  idCampaign?: string,
): Promise<void> {
  const log = useLogService();
  const isMulticontractCampaignServiceEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
  );

  const isGrowOrganizationActivationEnable = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const url = `${getApiHost()}${getEndPoint(
    TLP_CAMPAIGN_SERVICE,
  )}${CAMPAINGS_ENDPOINT}${
    isMulticontractCampaignServiceEnable
      ? `/v2/${campaignTag}${CAMPAIGNS_ENDPOINT_END}?vendorId=${vendorId}`
      : `/${campaignTag}${CAMPAIGNS_ENDPOINT_END}`
  }`;

  const urlV3 = `${getApiHost()}${getEndPoint(
    TLP_CAMPAIGN_SERVICE,
  )}/v3/${campaigns}/${idCampaign}`;

  const getParams = () => {
    return campaignType
      ? {
          campaignType,
        }
      : undefined;
  };
  const getHeaders = () => {
    let headersData;
    if (
      !isMulticontractCampaignServiceEnable &&
      !isGrowOrganizationActivationEnable
    )
      headersData = {
        country,
        vendorId,
        vendorName,
      };
    else
      headersData = {
        country,
      };
    const anotherHeadersData = headers ?? {};
    return { ...headersData, ...anotherHeadersData };
  };

  const urlToDelete = isGrowOrganizationActivationEnable ? urlV3 : url;

  return axios
    .delete(urlToDelete, {
      params: getParams(),
      headers: getHeaders(),
    })
    .then(() => {
      log.info("Campaign deleted successfully");
    })
    .catch((error) => {
      log.error(error?.response);
      throw error?.response;
    });
}

import React from "react";
import { useStore } from "effector-react";
import LogoutStore from "../../../stores/logout/LogoutStore";
import * as LogoutEvents from "../../../stores/logout/LogoutEvents";
import AlertDialog from "../AlertDialog/AlertDialog";
import { logout } from "../../../config/logout/logout";
import { formatMessage } from "../../../i18n/formatters";

const LogoutDialog: React.FC = () => {
  const logoutState = useStore(LogoutStore);

  const handleCancel = (): void => {
    LogoutEvents.toggleLogoutDialog();
  };

  const handleConfirm = (): void => {
    logout();
  };

  return (
    <AlertDialog
      id="logout-dialog"
      title={formatMessage({ id: "SIGN_OUT" })}
      description={formatMessage({ id: "LOGOUT_DIALOG" })}
      show={logoutState.logoutDialog.show}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
    />
  );
};

export default LogoutDialog;

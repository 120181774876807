import { createEffect, createEvent } from "effector";

import { DnDListItem } from "../../routes/agentsSetup/components/DragAndDropComponents/Types";
import { getAllFlexibleMissions } from "../../services/flexibleMissions/FlexibleMissionsService";
import {
  getGlobalAndLocalMissions,
  getPrimaryMissions,
} from "../../services/missions/MissionsService";

interface GetMissionsInput {
  vendorId?: string;
  country?: string;
}

export const getPrimaryMissionsEffect = createEffect({
  async handler(input: GetMissionsInput) {
    const { vendorId } = input;
    return getPrimaryMissions(vendorId);
  },
});

export const getGlobalAndLocalMissionsEffect = createEffect({
  async handler(input: GetMissionsInput) {
    const { vendorId } = input;
    return getGlobalAndLocalMissions(vendorId);
  },
});

export const getAllFlexibleMissionsEffect = createEffect({
  async handler(vendorId: string | undefined) {
    return getAllFlexibleMissions(vendorId);
  },
});

export const resetMissions = createEvent("resetMissions");

export const setIsMissionsEnabled = createEvent<string>("setIsMissionsEnabled");

export const setTemporarilyNewEnabledMissionsEmpty = createEvent(
  "setTemporarilyNewEnabledMissionsEmpty",
);

export const setIsMissionsDisabled = createEvent<string>(
  "setIsMissionsDisabled",
);

export const updateMissionsTemporarilyEffect = createEvent<
  DnDListItem[] | DnDListItem
>("updateMissionsTemporarilyEffect");

/* eslint-disable react/require-default-props */
import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import PoductCardStyles from "./ProductCardStyles";
import Data from "../data/Data";
import { formatMessage } from "../../i18n/formatters";

// eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
const productCardDefaultImage = require("../../assets/images/ProductPlaceholder.png");

interface Product {
  sku: string;
  productName?: string;
  image?: string;
  fullContainerDescription?: string;
  fullPackageDescription?: string;
}

const ProductCard: React.FC<Product> = ({
  sku,
  productName,
  image,
  fullContainerDescription,
  fullPackageDescription,
}) => {
  const classes = PoductCardStyles();
  const [src, setSrc] = useState(image);

  return (
    <Card className={classes.card}>
      <div className={classes.image}>
        <img
          src={src || productCardDefaultImage}
          onError={() => setSrc(productCardDefaultImage)}
          alt=""
        />
      </div>

      <CardContent>
        <Typography className={classes.title}>
          <Data ellipsis ellipsisSize={100} value={productName} />
        </Typography>

        {(fullContainerDescription || fullPackageDescription) && (
          <Typography className={classes.title}>
            {`${
              fullContainerDescription ||
              formatMessage({ id: "PRODUCTS.NO_DATA_AVAILABLE" })
            } - ${
              fullPackageDescription ||
              formatMessage({ id: "PRODUCTS.NO_DATA_AVAILABLE" })
            }`}
          </Typography>
        )}

        <Typography className={classes.sku}>{sku}</Typography>
      </CardContent>
    </Card>
  );
};

export default ProductCard;

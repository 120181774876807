import React from "react";

function CloseIcon(props: Record<string, unknown>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...props}
    >
      <defs>
        <path
          id="closeIcon"
          d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 2.182c-5.422 0-9.818 4.396-9.818 9.818S6.578 21.818 12 21.818s9.818-4.396 9.818-9.818S17.422 2.182 12 2.182zm4.044 5.774a1.09 1.09 0 01.09 1.44l-.09.103L13.543 12l2.501 2.501a1.09 1.09 0 01-1.44 1.634l-.103-.09L12 13.542l-2.501 2.501a1.09 1.09 0 01-1.634-1.44l.09-.103 2.503-2.5-2.502-2.501a1.09 1.09 0 011.44-1.634l.103.09L12 10.458l2.501-2.501a1.09 1.09 0 011.543 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 4)">
        <mask id="js2ygxut5b" fill="#fff">
          <use xlinkHref="#closeIcon" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#closeIcon" />
        <g fill="#000" mask="url(#js2ygxut5b)">
          <path d="M-4-4h32v32H-4z" />
        </g>
      </g>
    </svg>
  );
}

export default CloseIcon;

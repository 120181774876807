import { makeStyles, createStyles } from "@material-ui/core/styles";

const styles = makeStyles(() =>
  createStyles({
    NewSideBarMenuNav: {
      marginBottom: "1.5em",
    },
    ul: {
      listStyle: "none",
      padding: 0,
      margin: 0,
      display: "flex",
      alignItems: "center",
    },
    NewSideBarMenuUl: {
      marginRight: 24,
      listStyle: "none",
      padding: 0,
      margin: 0,
      display: "flex",
      alignItems: "center",
    },
    paginationButton: {
      height: 30,
      width: 30,
      borderRadius: 3,
      fontWeight: 500,
      fontSize: 14,
    },
  }),
);

export default styles;

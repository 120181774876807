import { createEffect } from "effector";
import * as DeleteCampaignUseCase from "../../usecase/campaigns/DeleteCampaignUseCase";

interface DeleteCampaignWithCountry {
  country: string;
  campaignTag: string;
  vendorId?: string;
  vendorName?: string;
  isCampaignFocusVendorEnabled?: boolean;
  idCampaign?: string;
}

export const deleteCampaignEffect = createEffect({
  async handler(deleteCampaign: DeleteCampaignWithCountry) {
    return DeleteCampaignUseCase.deleteCampaign(
      deleteCampaign.country,
      deleteCampaign.campaignTag,
      deleteCampaign.vendorId,
      deleteCampaign.vendorName,
      deleteCampaign.isCampaignFocusVendorEnabled,
      deleteCampaign.idCampaign,
    );
  },
});

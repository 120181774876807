import { useLogService } from "admin-portal-shared-services";
import { FlexibleMissionsResponseToMissions } from "../../converters/flexibleMissions/FlexibleMissionsResponseToMissions";
import { getApiHost } from "../host/HostService";
import axios from "../../config/axios/axiosInstance";
import {
  DefaultMission,
  FlexibleMission,
  Mission,
} from "../../domains/Missions";

const GROW_FLEXIBLE = "grow-flexible-missions/admin";
const MISSIONS_MANAGEMENT = "missions";

export async function getAllFlexibleMissions(
  vendorId?: string,
): Promise<Mission[]> {
  const log = useLogService();

  const endpoint = `${GROW_FLEXIBLE}/${MISSIONS_MANAGEMENT}`;

  const url = `${getApiHost()}/grow-flexible-missions-service/${endpoint}`;

  const params = new URLSearchParams();

  params.append("vendorId", vendorId ?? "");

  try {
    const response = await axios.get<FlexibleMission[] | DefaultMission[]>(
      url,
      {
        params,
      },
    );
    log.info(
      `Convert getAllFlexibleMissions from ${endpoint} to our model`,
      response,
    );
    return FlexibleMissionsResponseToMissions(response.data);
  } catch (error) {
    log.error(error);
    throw error;
  }
}

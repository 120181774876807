import * as UpdateAgentService from "../../services/agents/UpdateAgentService";
import UpdateAgentRequest from "../../services/agents/domains/UpdateAgentRequest";

export function execute(
  id: string,
  country: string,
  agent: UpdateAgentRequest,
  vendorId?: string,
): Promise<void> {
  return UpdateAgentService.updateAgent(id, country, agent, vendorId);
}

import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      width: "100%",
      height: 42,
      display: "flex",
      alignItems: "baseline",
    },
    dot: {
      width: 10,
      height: 10,
      margin: "1px 3px",
      borderRadius: "50%",
      opacity: 1,
      animation: `$bounceAnimation 0.6s infinite alternate`,
    },
    dot2: {
      animationDelay: "0.2s",
    },
    dot3: {
      animationDelay: "0.4s",
    },
    "@keyframes bounceAnimation": {
      "0%": {
        opacity: 1,
      },
      "100%": {
        opacity: 0.1,
      },
    },
  }),
);

/* eslint-disable react/require-default-props */
import React, { useCallback } from "react";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

import { formatMessage } from "../../../../i18n/formatters";
import GeneralTooltip from "../../../../components/core/Tooltip/GeneralTooltip";
import useStyles from "./FlagMissionStyles";

interface Props {
  handleChange: (classification: string) => void;
  testId: string;
  defaultValue: string;
}

const FlagMission: React.FC<Props> = ({
  handleChange,
  testId,
  defaultValue,
}) => {
  const classes = useStyles();

  const onChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      handleChange(event.currentTarget.value);
    },
    [handleChange],
  );

  return (
    <FormControl
      key="control-flag-mission"
      component="fieldset"
      data-testid={testId}
    >
      <RadioGroup
        row
        onChange={onChange}
        key="flag-mission-group"
        defaultValue={defaultValue}
        className={classes.span}
      >
        <div className={classes.boxPrimary}>
          <div className={classes.textLabel}>
            <FormControlLabel
              key="flag-mission-primary"
              value={VALUES.PRIMARY}
              control={<Radio size="small" color="default" />}
              label={formatMessage({ id: "MODAL_CAMPAIGNS.PRIMARY" })}
              classes={{ label: classes.label }}
            />
            <GeneralTooltip
              text={formatMessage({ id: "MODAL_CAMPAIGNS.PRIMARY_TOOLTIP" })}
            />
          </div>
          <div className={classes.textLabel}>
            <FormControlLabel
              key="flag-mission-secondary"
              value={VALUES.SECONDARY}
              control={<Radio size="small" color="default" />}
              label={formatMessage({ id: "MODAL_CAMPAIGNS.SECONDARY" })}
              classes={{ label: classes.label }}
            />
            <GeneralTooltip
              text={formatMessage({ id: "MODAL_CAMPAIGNS.SECONDARY_TOOLTIP" })}
            />
          </div>
        </div>
      </RadioGroup>
    </FormControl>
  );
};

const VALUES = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

FlagMission.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  testId: "flag-mission-component",
};

export default React.memo(FlagMission);

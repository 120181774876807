import { createEffect } from "effector";
import UpdateBlockListRequest from "services/management/domains/UpdateBlockListRequest";

import * as BlockListUseCase from "../../usecase/management/BlockListUseCase";

interface UpdateBlockList {
  blockList: UpdateBlockListRequest;
  vendorId?: string;
}

export const updateBlockListEffect = createEffect({
  async handler(updateBlockList: UpdateBlockList) {
    const { blockList, vendorId } = updateBlockList;
    return BlockListUseCase.updateBlockList(blockList, vendorId);
  },
});

interface GetBlockList {
  vendorId?: string;
}

export const getBlockListEffect = createEffect({
  async handler(getBlockList: GetBlockList) {
    const { vendorId } = getBlockList;
    return BlockListUseCase.getBlockList(vendorId);
  },
});

interface DeleteBlockListInput {
  vendorId?: string;
}

export const deleteBlockListEffect = createEffect({
  async handler(deleteBlockListInput: DeleteBlockListInput) {
    const { vendorId } = deleteBlockListInput;
    return BlockListUseCase.deleteBlockList(vendorId);
  },
});

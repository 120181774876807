import React from "react";
import LoadingBeesIcon from "../../assets/icons/LoadingBeesIcon";
import { formatMessage } from "../../i18n/formatters";

function LoadingBlockList(): React.ReactElement {
  return (
    <>
      <LoadingBeesIcon />
      <span style={{ marginTop: "20px" }}>
        {formatMessage({ id: "BLOCK_LIST.LOADING" })}
      </span>
    </>
  );
}
export default LoadingBlockList;

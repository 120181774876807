import React, { useCallback } from "react";
import { Box, Typography, ButtonBase } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import useStyles from "./styles";
import DragDropIcon from "../../../../assets/icons/DragDropIcon";
import DeleteIconDragDrop from "../../../../assets/icons/DeleteIconDragDrop";

const DragDrop: React.FC<Props> = ({
  text,
  multiple,
  maxFiles,
  onSelect,
  testId,
  fileTypeAccepted,
  classesName,
}) => {
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles) => {
      onSelect(acceptedFiles);
    },
    [onSelect],
  );

  const extensionValidation = useCallback(
    (file: File) => {
      const { name } = file;
      const extension = name.split(".").pop();

      if (!fileTypeAccepted?.includes(`.${extension}`)) {
        return {
          code: "file-validator",
          message: `Only ${fileTypeAccepted?.join(", ")} will be accepted`,
        };
      }
      return null;
    },
    [fileTypeAccepted],
  );

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxFiles,
      multiple,
      onDrop,
      validator: extensionValidation,
    });

  const removeFile = (file: File) => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    onSelect(acceptedFiles);
  };

  const filesAcceptedItems = acceptedFiles.map((file: File) => {
    const { name } = file;
    return (
      <span key={name}>
        <Box className={classes.containerFiles}>
          <span>{name}</span>
          <span>
            <ButtonBase
              color="secondary"
              className={classes.deleteButton}
              onClick={(e) => {
                removeFile(file);
                e.stopPropagation();
              }}
              data-testid="btn-remove-file"
            >
              <DeleteIconDragDrop />
            </ButtonBase>
          </span>
        </Box>
      </span>
    );
  });

  const fileRejectionItems = fileRejections.map(({ errors }) =>
    errors.map((e) => (
      <li key={e.code} className={classes.error}>
        {e.message}
      </li>
    )),
  );

  const changeBackground = () => {
    const howStyle = classesName ? classes.containerWhite : classes.container;
    return howStyle;
  };

  return (
    <Box
      data-testid={testId}
      {...getRootProps({ className: "dropzone" })}
      className={changeBackground()}
    >
      <input {...getInputProps()} />
      <Box className={classes.innerContainer}>
        <DragDropIcon />
        <Typography variant="subtitle1" classes={{ root: classes.title }}>
          {text}
        </Typography>
      </Box>
      {filesAcceptedItems && filesAcceptedItems.length > 0 && (
        <Typography variant="subtitle1" classes={{ root: classes.fileTitle }}>
          {filesAcceptedItems}
        </Typography>
      )}
      {fileRejectionItems && fileRejectionItems.length > 0 && (
        <Typography variant="subtitle1" classes={{ root: classes.fileTitle }}>
          {fileRejectionItems}
        </Typography>
      )}
    </Box>
  );
};

export default React.memo(DragDrop);

DragDrop.defaultProps = {
  fileTypeAccepted: [".jpeg", ".jpg", ".png"],
  multiple: false,
  maxFiles: 1,
  testId: "drag-drop-component",
};

interface Props {
  text: string;
  testId?: string;
  fileTypeAccepted?: Array<string>;
  multiple?: boolean;
  maxFiles?: number;
  onSelect: (files: File[]) => void;
  // eslint-disable-next-line react/require-default-props
  classesName?: string;
}

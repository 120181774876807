import { createStore } from "effector";
import {
  fetchAllAgents,
  setMissionsManagement,
  setAgentMissionPriority,
  setAgentSegmentation,
  addNewSegmentation,
  setIsAgentUpdated,
  setAgentNewMissionPriority,
} from "./AgentsEvents";
import { AgentsState } from "./AgentsState";

const initialState = <AgentsState>{
  done: false,
  error: false,
  isLoading: false,
  isLoaded: true,
  missionsManagement: {
    disabled: [],
    enabled: [],
  },
  agents: [],
  isAgentUpdated: false,
};

const store = createStore(initialState)
  .on(fetchAllAgents, (state) => {
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      done: false,
      error: false,
    };
  })
  .on(fetchAllAgents.fail, (state) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      done: true,
      error: true,
    };
  })
  .on(fetchAllAgents.done, (state, { result }) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      done: true,
      error: false,
      agents: result.agents,
    };
  })
  .on(setMissionsManagement, (state, payload) => {
    return {
      ...state,
      missionsManagement: payload,
    };
  })
  .on(setAgentMissionPriority, (state, payload) => {
    return {
      ...state,
      agents: state.agents.map((agent) => {
        if (agent.id === payload.agentId) {
          agent.missionPriority = payload.missionPriority;
        }

        return agent;
      }),
    };
  })
  .on(setAgentNewMissionPriority, (state, payload) => {
    return {
      ...state,
      agents: state.agents.map((agent) => {
        if (agent.id === payload.agentId) {
          agent.newMissionPriority = payload.newMissionPriority;
        }

        return agent;
      }),
    };
  })
  .on(setAgentSegmentation, (state, payload) => {
    return {
      ...state,
      agents: state.agents.map((agent) => {
        if (agent.id === payload.agentId)
          agent.segmentation = payload.segmentation;
        return agent;
      }),
    };
  })
  .on(addNewSegmentation, (state, { id, segmentation }) => {
    state.agents.forEach((agent) => {
      if (agent.id === id) {
        agent.segmentation.push(segmentation);
      }
    });

    return {
      ...state,
    };
  })
  .on(setIsAgentUpdated, (state, isUpdated) => {
    return {
      ...state,
      isAgentUpdated: isUpdated,
    };
  });

export default store;

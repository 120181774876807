import React, { useCallback } from "react";

import { Box, Dialog, Typography, ButtonBase } from "@material-ui/core";
import classNames from "classnames";
import { addDays } from "date-fns";
import { Campaign } from "domains/Campaign";
import { Form, Formik, FormikValues } from "formik";
import { object, string, date, array } from "yup";

import { isFeatureEnabled } from "grow-shared-services";
import { CAMPAIGNS } from "../../../../config/constants";
import {
  TLP_FLAG_MISSION,
  GROW_ADMIN_LOCAL_MISSION_MENU,
  GROW_ADMIN_DISABLE_B2O_CAMPAIGN,
  GROW_DISABLE_FOCUS_SKU,
} from "../../../../config/featureToggles";
import { formatMessage } from "../../../../i18n/formatters";
import B2OForm from "../b2oForm/B2OForm";
import CampaignsOptions from "../campaignsOptions/CampaignsOptions";
import FocusSkuForm from "../focusSkuForm/FocusSkuForm";
import ModalGenericCampaignStyle from "./ModalGenericCampaignStyle";

interface Props {
  open: boolean;
  onClose: () => void;
  titleModal: string;
  labelButton: string;
  onRegisterHandler: (values: FormikValues) => void;
  // eslint-disable-next-line react/require-default-props
  campaign?: Campaign;
  campaignType: string;
  setCampaignType: (value: string) => void;
}

const ModalGenericCampaign: React.FC<Props> = ({
  open,
  onClose,
  titleModal,
  labelButton,
  onRegisterHandler,
  campaign,
  campaignType,
  setCampaignType,
}) => {
  const classes = ModalGenericCampaignStyle();

  const flagMissionEnable = isFeatureEnabled(TLP_FLAG_MISSION);

  const isLocalMissionsMenuEnabled = isFeatureEnabled(
    GROW_ADMIN_LOCAL_MISSION_MENU,
  );

  const isDisableB2oCampaign = isFeatureEnabled(
    GROW_ADMIN_DISABLE_B2O_CAMPAIGN,
  );

  const isDisableFocusSKUCampaign = isFeatureEnabled(GROW_DISABLE_FOCUS_SKU);

  const { campaignName, campaignTag, products } = campaign || {};

  /* istanbul ignore next */
  const firstDDC =
    products != null ? products[0]?.filterOptions?.deliveryCenterId : "";

  /* istanbul ignore next */
  const firstSegment =
    products != null ? products[0]?.filterOptions?.segment : "";

  /* istanbul ignore next */
  const listSkus = products?.map((each) => {
    return each.sku;
  });

  let filteredCampaigns =
    isLocalMissionsMenuEnabled || isDisableB2oCampaign
      ? CAMPAIGNS.filter((campaignType) => campaignType.value !== "b2o")
      : CAMPAIGNS;

  filteredCampaigns = isDisableFocusSKUCampaign
    ? filteredCampaigns.filter(
        (campaignType) => campaignType.value !== "focus_sku",
      )
    : filteredCampaigns;

  const initialValues: FormikValues = {
    campaignName: campaignName || "",
    campaignTag: campaignTag || "",
    skus: listSkus || [],
    sku_0: listSkus?.[0] || "",
    ddc: firstDDC || "",
    segment: firstSegment || "",
    campaignDescription: "",
    campaignScript: "",
    csvList: null,
    coverImage: null,
    startDate: "",
    endDate: "",
    segmentation: "",
    classification: flagMissionEnable ? "SECONDARY" : "PRIMARY",
  };
  const uniqueArrayValidator = useCallback(
    (array: string[]) => array.length === new Set(array).size,
    [],
  );

  const validationSchema = object().shape({
    campaignName: string()
      .trim()
      .min(
        1,
        formatMessage({ id: "MODAL_CAMPAIGNS.MSG_MANDATORY_CAMPAIGN_NAME" }),
      )
      .required(
        formatMessage({ id: "MODAL_CAMPAIGNS.MSG_MANDATORY_CAMPAIGN_NAME" }),
      ),
    campaignTag: string()
      .matches(
        /^#\S+/,
        formatMessage({
          id: "MODAL_CAMPAIGNS.MSG_START_WITH_PLACEHOLDER_CAMPAIGN_TAG",
        }),
      )
      .matches(
        /^\S+$/,
        formatMessage({
          id: "MODAL_CAMPAIGNS.MSG_NO_ACCEPT_SPACES_CAMPAIGN_TAG",
        }),
      )
      .max(
        30,
        formatMessage({ id: "MODAL_CAMPAIGNS.MSG_MAX_SIZE_CAMPAIGN_TAG" }),
      )
      .required(
        formatMessage({ id: "MODAL_CAMPAIGNS.MSG_MANDATORY_CAMPAIGN_TAG" }),
      ),
    sku_0: string()
      .trim()
      .min(
        1,
        formatMessage({ id: "MODAL_CAMPAIGNS.MSG_AT_LEAST_ONE_CAMPAIGN_SKU" }),
      )
      .required(
        formatMessage({ id: "MODAL_CAMPAIGNS.MSG_AT_LEAST_ONE_CAMPAIGN_SKU" }),
      ),
    skus: array().test("is-unique", "duplicated", uniqueArrayValidator),
    campaignDescription:
      campaignType === CAMPAIGNS[0].value
        ? string().trim()
        : string()
            .trim()
            .required(
              formatMessage({
                id: "MODAL_CAMPAIGNS.MSG_MANDATORY_CAMPAIGN_DESCRIPTION",
              }),
            ),
    campaignScript:
      campaignType === CAMPAIGNS[0].value
        ? string().trim()
        : string()
            .trim()
            .required(
              formatMessage({
                id: "MODAL_CAMPAIGNS.MSG_MANDATORY_CAMPAIGN_SCRIPT",
              }),
            ),
    startDate:
      campaignType === CAMPAIGNS[0].value
        ? string().trim()
        : date().required("This field is required."),
    endDate:
      campaignType === CAMPAIGNS[0].value
        ? string().trim()
        : date()
            .required("This field is required.")
            .when(
              "startDate",
              /* istanbul ignore next */
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (startDate: any, yup: any) =>
                startDate &&
                yup.min(
                  addDays(new Date(startDate), 1),
                  formatMessage({
                    id: "MODAL_CAMPAIGNS.MSG_END_DATE_BEFORE_START_DATE",
                  }),
                ),
            ),
  });

  const handleChangeCampaignType = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    setCampaignType(`${e.target.value}`);
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} disableBackdropClick>
        <Box className={classes.container}>
          <Typography className={classes.title}>{titleModal}</Typography>
          <CampaignsOptions
            handleChange={handleChangeCampaignType}
            options={filteredCampaigns}
            value={campaignType}
            data-testid="campaigns-options"
          />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onRegisterHandler}
          >
            {({
              values: valuesForm,
              errors,
              touched,
              handleChange,
              setFieldValue,
            }) => (
              <Form className={classes.form} noValidate>
                {campaignType === CAMPAIGNS[0].value ? (
                  <FocusSkuForm
                    titleModal={titleModal}
                    valuesForm={valuesForm}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    errors={errors}
                  />
                ) : (
                  <B2OForm
                    titleModal={titleModal}
                    valuesForm={valuesForm}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    errors={errors}
                  />
                )}
                <Box className={classes.buttonsContainer}>
                  <ButtonBase
                    onClick={onClose}
                    className={classNames(classes.button, classes.cancelButton)}
                    data-testid="modal-campaign-cancel-button"
                  >
                    {formatMessage({ id: "MODAL_CAMPAIGNS.CANCEL_BUTTON" })}
                  </ButtonBase>
                  <ButtonBase
                    type="submit"
                    className={classNames(
                      classes.button,
                      classes.registerButton,
                    )}
                    data-testid="modal-campaign-register-button"
                  >
                    {labelButton}
                  </ButtonBase>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Dialog>
    </div>
  );
};

export default React.memo(ModalGenericCampaign);

import { createStore } from "effector";
import { MISSION_TYPES } from "../../domains/Missions";
import {
  getAllFlexibleMissionsEffect,
  getGlobalAndLocalMissionsEffect,
  getPrimaryMissionsEffect,
  resetMissions,
  setTemporarilyNewEnabledMissionsEmpty,
} from "./MissionsEvent";
import { MissionsState } from "./MissionsState";

const initialState: MissionsState = {
  done: false,
  errorToLoad: false,
  errorToUpdate: false,
  loading: false,
  hasChanged: false,
  missions: [],
  temporarilyNewEnabledMissions: [],
};

const MissionsStore = createStore(initialState)
  .on(getPrimaryMissionsEffect.done, (state: MissionsState, { result }) => ({
    ...state,
    done: true,
    errorToLoad: false,
    missions: result,
    loading: false,
  }))
  .on(getPrimaryMissionsEffect.fail, (state: MissionsState) => ({
    ...state,
    done: true,
    errorToLoad: true,
    loading: false,
  }))
  .on(getPrimaryMissionsEffect, (state: MissionsState) => ({
    ...state,
    done: false,
    loading: true,
    errorToLoad: false,
  }))
  .on(
    getGlobalAndLocalMissionsEffect.done,
    (state: MissionsState, { result }) => ({
      ...state,
      done: true,
      errorToLoad: false,
      missions: result,
      loading: false,
    }),
  )
  .on(getGlobalAndLocalMissionsEffect.fail, (state: MissionsState) => ({
    ...state,
    done: true,
    errorToLoad: true,
    loading: false,
  }))
  .on(getGlobalAndLocalMissionsEffect, (state: MissionsState) => ({
    ...state,
    done: false,
    loading: true,
    errorToLoad: false,
  }))
  .on(getAllFlexibleMissionsEffect.done, (state: MissionsState, { result }) => {
    const filteredMissions = result?.filter(
      (mission) =>
        mission.type === MISSION_TYPES.GLOBAL ||
        mission.type === MISSION_TYPES.LOCAL,
    );
    return {
      ...state,
      done: true,
      errorToLoad: false,
      missions: filteredMissions,
      loading: false,
    };
  })
  .on(getAllFlexibleMissionsEffect.fail, (state: MissionsState) => ({
    ...state,
    done: true,
    errorToLoad: true,
    loading: false,
  }))
  .on(getAllFlexibleMissionsEffect, (state: MissionsState) => ({
    ...state,
    done: false,
    loading: true,
    errorToLoad: false,
  }))
  .on(setTemporarilyNewEnabledMissionsEmpty, (state: MissionsState) => ({
    ...state,
    temporarilyNewEnabledMissions: [],
  }))
  .reset(resetMissions);

export default MissionsStore;

export const ROOT_NAME = "/";
export const BASE_NAME = "/beesgrow";
export const HOME_ROUTE = "campaigns";
export const ENTER_CODE = "Enter";
export const LINK_SERVER_ERROR = "/error/500";
export const WRAPPER_PROJECT_NAME_INSTANCE = "cx-tlp-portal-admin-mfe";

export const LOCAL_STORAGE_EXPIRES_IN = "expires_in";
export const LOCAL_STORAGE_AZURE_AUTH = "azure_auth";
export const LOCAL_STORAGE_NOT_BEFORE = "not_before";
export const LOCAL_STORAGE_REFRESH_TOKEN = "refresh_token";
export const LOCAL_STORAGE_PORTAL_CONFIG = "portal-config";
export const LOCAL_STORAGE_AUTH_TIMESTAMP = "authTimestamp";
export const LOCAL_STORAGE_REFRESH_TOKEN_EXPIRES_IN =
  "refresh_token_expires_in";
export const LOCAL_STORAGE_EXPIRES_ON = "expires_on";
export const LOCAL_STORAGE_AUTH_HEADER = "authHeader";
export const LOCAL_STORAGE_I18_NEXT_LNG = "i18nextLng";
export const LOCAL_STORAGE_TEAM_STRUCTURE_AGENT_EMAIL =
  "TeamStructure_agent_email";
export const PORTAL_CONFIG_PORTAL_TYPE = "ADMIN";
export const PORTAL_CONFIG_ENV_PROD = "prod";
export const PORTAL_CONFIG_ENV_UAT = "uat";
export const PORTAL_CONFIG_ENV_SIT = "qa";
export const PORTAL_CONFIG_ENV_DEV = "dev";
export const PORTAL_CONFIG_ENV_TYPE_PROD = "production";
export const PORTAL_CONFIG_ENV_TYPE_UAT = "staging";
export const PORTAL_CONFIG_ENV_TYPE_DEV = "development";

export const UPDATE_LOCAL_STORAGE_LOGOUT: string[] = [
  LOCAL_STORAGE_EXPIRES_IN,
  LOCAL_STORAGE_AZURE_AUTH,
  LOCAL_STORAGE_NOT_BEFORE,
  LOCAL_STORAGE_REFRESH_TOKEN,
  LOCAL_STORAGE_PORTAL_CONFIG,
  LOCAL_STORAGE_AUTH_TIMESTAMP,
  LOCAL_STORAGE_REFRESH_TOKEN_EXPIRES_IN,
  LOCAL_STORAGE_EXPIRES_ON,
  LOCAL_STORAGE_AUTH_HEADER,
  LOCAL_STORAGE_I18_NEXT_LNG,
];

export const CAMPAIGNS = [
  {
    value: "focus_sku",
    label: "Focus SKU",
  },
  {
    value: "b2o",
    label: "B2O",
  },
];

export enum CAMPAIGN_TYPE {
  B2O = "b2o",
  FOCUS_SKU = "focus_sku",
}

export enum CAMPAIGN_CLASSIFICATION {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export const VENDOR_ID = "";

export const CAMPAIGNS_PROJECTION: { [key: string]: string } = {
  ALL: "ALL",
  BASIC: "BASIC",
  ADMIN: "ADMIN",
};

import React from "react";
import Select from "../../../../components/select/Select";
import useStyles from "./CampaignsOptionsStyles";

interface Option {
  value: string | number;
  label: string;
}

interface CampaignsOptionsProps {
  value: string;
  options: Array<Option>;
  handleChange: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
}

const CampaignsOptions: React.FC<CampaignsOptionsProps> = ({
  handleChange,
  options,
  value,
}) => {
  const classes = useStyles();
  return (
    <Select
      name="campaign-select"
      value={value}
      options={options}
      onChange={handleChange}
      testId="campaign-select"
      className={classes.campaignSelect}
    />
  );
};

export default React.memo(CampaignsOptions);

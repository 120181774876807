import { createStore } from "effector";
import {
  getBlockListEffect,
  updateBlockListEffect,
  deleteBlockListEffect,
} from "./BlockListEvents";
import BlockListState from "./BlockListState";

const initialState = <BlockListState>{
  done: false,
  error: false,
  isLoading: false,
  isLoaded: true,
};

const setState = (state: BlockListState) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: true,
    error: false,
  };
};

const setStateFail = (state: BlockListState) => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: true,
  };
};

const store = createStore(initialState)
  .on(updateBlockListEffect, setState)
  .on(updateBlockListEffect.done, () => {
    return initialState;
  })
  .on(updateBlockListEffect.fail, setStateFail)
  .on(getBlockListEffect, setState)
  .on(getBlockListEffect.done, (state, { result }) => {
    return {
      ...state,
      csv: result,
      isLoading: false,
    };
  })
  .on(getBlockListEffect.fail, setStateFail)
  .on(deleteBlockListEffect, setState)
  .on(deleteBlockListEffect.done, () => {
    return initialState;
  })
  .on(deleteBlockListEffect.fail, setStateFail);
export default store;

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import translationEN from "./locale-data/translationEN";
// import translationPT from "./locale-data/translationPT.TS";
// import translationES from "./locale-data/translationES.TS";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // lng: "en-US", Firefox always maintain "lng" like the current language [OCTLP-3900]
    fallbackLng: ["en-US", "es-419", "pt-BR"],
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    react: {
      useSuspense: false,
      wait: true,
    },
  });

// i18n.addResourceBundle("en-US", "translation", translationEN, true);
// i18n.addResourceBundle("es-419", "translation", translationES, true);
// i18n.addResourceBundle("pt-BR", "translation", translationPT, true);

export default i18n;

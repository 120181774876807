/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from "react";
import { Box, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./Autocomplete.styles";

interface AutoCompleteProps {
  testId?: string;
  value: any;
  options: Array<any>;
  onChange: (value: any) => void;
  placeholder?: string;
  noOptionsText?: string;
  overrideInput?: string;
}

const AutoComplete: React.FC<AutoCompleteProps> = ({
  testId,
  value,
  options,
  onChange,
  placeholder,
  noOptionsText,
  overrideInput,
}) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (event, selectedValue) => {
      onChange(selectedValue);
    },
    [onChange],
  );

  return (
    <Box data-testid={testId}>
      <Autocomplete
        value={value}
        options={options}
        renderOption={(option) => <div>{option.label}</div>}
        getOptionLabel={(option) => option.label}
        noOptionsText={noOptionsText}
        classes={{ inputRoot: classes.select, input: overrideInput }}
        popupIcon={<ExpandMoreIcon data-testid="agentSetup-expandMore" />}
        data-testid="agentSetup-select-autocomplete"
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              data-testid="agentSetup-select-textField"
              variant="outlined"
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                classes: {
                  input: classes.input,
                },
              }}
              fullWidth
            />
          );
        }}
        onChange={handleChange}
        className={classes.textField}
      />
    </Box>
  );
};

export default React.memo(AutoComplete);

AutoComplete.defaultProps = {
  testId: "agent-setup-agent",
  placeholder: "",
  noOptionsText: "No options",
  overrideInput: "",
};

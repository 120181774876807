import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { DropResult } from "react-beautiful-dnd";
import { setIsAgentUpdated } from "../../../../stores/agents/AgentsEvents";
import { DnDListItem } from "../DragAndDropComponents/Types";
import DraggableList from "../DragAndDropComponents/DraggableList/DraggableList";
import DisabledList from "../DragAndDropComponents/DisabledList/DisabledList";
import useStyles from "./MissionsManagement.styles";

interface MissionsManagementInterface {
  testId?: string;
  activatedMissions?: Array<DnDListItem>;
  inactivatedMissions?: Array<DnDListItem>;
  updateMissionsTemporarily: (result: Array<DnDListItem>) => void;
}

const MissionsManagement = ({
  testId,
  activatedMissions,
  inactivatedMissions,
  updateMissionsTemporarily,
}: MissionsManagementInterface) => {
  const classes = useStyles();
  const [draggableItems, setDraggableItems] = useState<Array<DnDListItem>>([]);
  const [disabledDraggableItems, setDisabledDraggableItems] = useState<
    Array<DnDListItem>
  >([]);

  useEffect(() => {
    if (activatedMissions) {
      setDraggableItems(activatedMissions);
    }
    if (inactivatedMissions) {
      setDisabledDraggableItems(inactivatedMissions);
    }
  }, [inactivatedMissions, activatedMissions]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const result = Array.from(draggableItems);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);

    setDraggableItems(result);
    updateMissionsTemporarily(result);

    setIsAgentUpdated(true);
  };

  return (
    <Box>
      {((activatedMissions && activatedMissions.length > 0) ||
        (inactivatedMissions && inactivatedMissions?.length > 0)) && (
        <Box data-testid={testId}>
          <div className={classes.draggableContainer}>
            <div>
              <DraggableList
                draggableList={draggableItems}
                data-testid="draggable-list"
                onDragEnd={onDragEnd}
                hasLastItem={disabledDraggableItems.length === 0}
              />
              <DisabledList
                disabledDraggableItems={disabledDraggableItems}
                data-testid="not-draggable-list"
              />
            </div>
          </div>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(MissionsManagement);

MissionsManagement.defaultProps = {
  testId: "agent-setup-missions-management",
  activatedMissions: [],
  inactivatedMissions: [],
};

import React, { useEffect } from "react";
import Moment from "moment";
import { Typography } from "@material-ui/core";
import Button from "../Button/Button";
import { formatMessage } from "../../i18n/formatters";
import { useAnalytics } from "../../analytics/useAnalytics";
import {
  errorMessageViewed,
  retryButtonClicked,
} from "../../config/typewriter";
import LoadingErrorIcon from "../../assets/icons/LoadingErrorIcon";
import LoadingErrorStyles from "./LoadingError.styles";

type analyticsProperties = {
  errorType: string;
  errorMessage: string;
  screenName: string;
  screenSection: string;
};

interface LoadingErrorProps {
  subtitleTranslationId: string;
  testId: string;
  onClick: () => void;
  analyticsProperties: analyticsProperties;
}

const LoadingError = ({
  subtitleTranslationId,
  testId,
  onClick,
  analyticsProperties,
}: LoadingErrorProps): JSX.Element => {
  const classes = LoadingErrorStyles();
  const { dispatchGenericEvent } = useAnalytics();

  useEffect(() => {
    dispatchGenericEvent(errorMessageViewed, {
      error_type: analyticsProperties.errorType,
      error_message: analyticsProperties.errorMessage,
      screen_name: analyticsProperties.screenName,
      screen_section: analyticsProperties.screenSection,
      time_of_day: Moment().local().hour(),
    });
    // eslint-disable-next-line
  }, []);

  const handleRetryButton = () => {
    onClick();

    dispatchGenericEvent(retryButtonClicked, {
      screen_name: analyticsProperties.screenName,
      screen_section: analyticsProperties.screenSection,
    });
  };

  return (
    <div className={classes.container} data-testid={testId}>
      <div className={classes.loadErrorIcon}>
        <LoadingErrorIcon />
      </div>
      <div className={classes.infoContainer}>
        <Typography variant="h2" className={classes.title}>
          {formatMessage({ id: "ERROR_MESSAGE.GENERIC_LOAD_ERROR" })}
        </Typography>
        <Typography className={classes.subtitle}>
          {formatMessage({ id: subtitleTranslationId })}
        </Typography>

        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            data-testid={`${testId}-button`}
            onClick={() => handleRetryButton()}
          >
            {formatMessage({ id: "ERROR_MESSAGE.GENERIC_TRY_AGAIN" })}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoadingError;

import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    card: {
      display: "flex",
      boxShadow: "0 0 0 black",
      backgroundColor: "transparent",
    },
    image: {
      display: "flex",
      alignItems: "center",
      paddingLeft: "9px",
      paddingRight: 0,
      height: "100%",
      padding: 16,
      "& img": {
        width: 53,
        height: 60,
      },
    },
    title: {
      fontSize: 10,
      color: "#505050",
      fontWeight: "bold",
      fontStyle: "normal",
      lineHeight: "normal",
    },
    price: {
      color: "#148cc6",
      fontSize: "10px",
      fontWeight: "bold",
      margin: "4px 0px 4px",
      fontStyle: "normal",
      lineHeight: "normal",
    },
    sku: {
      margin: "4px 0px",
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: "normal",
      color: "#505050",
      fontSize: "10px",
    },
  }),
);

import React from "react";

const TrashIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      stroke="#484b4d"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-trash-2"
    >
      <g>
        <g>
          <g>
            <title>Trash icon</title>
            <polyline points="3 6 5 6 21 6" />
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
            <line x1="10" y1="11" x2="10" y2="17" />
            <line x1="14" y1="11" x2="14" y2="17" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TrashIcon;

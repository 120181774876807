import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    listItem: {
      padding: "1px",
    },
    draggingListItem: {
      background: "#FFFFFF",
    },
    inner: {
      width: "95%",
      height: "1px",
      margin: "0px auto",
      borderBottom: "0.5px solid #C0C3C8",
    },
  }),
);

import { createEffect } from "effector";
import {
  execute,
  executeV3,
} from "../../usecase/campaigns/GetAllFailedPocsUseCase";

interface GetAllFailedPocs {
  campaignTag: string;
  campaignType: string;
  vendorId?: string;
  vendorIdMulticontract?: string;
  vendorName?: string;
  isCampaignFocusVendorEnabled?: boolean;
}

interface GetAllFailedPocsV3 {
  campaignTag: string;
  country: string;
  orgId: string;
  requestTraceId: string;
  projection: "INVALID_ACCOUNTS" | "ALL";
}

export const getAllFailedPocsEffect = createEffect({
  async handler(campaign: GetAllFailedPocs) {
    return execute(
      campaign.campaignTag,
      campaign.campaignType,
      campaign.vendorId,
      campaign.vendorIdMulticontract,
      campaign.vendorName,
      campaign.isCampaignFocusVendorEnabled,
    );
  },
});

export const getAllOrInvalidPocsEffectV3 = createEffect({
  async handler(campaign: GetAllFailedPocsV3) {
    return executeV3(
      campaign.campaignTag,
      campaign.country,
      campaign.orgId,
      campaign.requestTraceId,
      campaign.projection,
    );
  },
});

import { makeStyles, createStyles } from "@material-ui/core/styles";

const styles = makeStyles(() =>
  createStyles({
    content: {
      flexGrow: 1,
      paddingBottom: 0,
      paddingTop: 0,
      marginLeft: 0,
      background: "#ffffff",
    },
    NewSideBarMenuContent: {
      display: "flex",
      width: "100%",
      height: "100%",
    },
  }),
);

export default styles;

import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { isFeatureEnabled } from "grow-shared-services";

import axios from "../../config/axios/axiosInstance";
import {
  GROW_ORGANIZATION_ACTIVATION,
  TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
} from "../../config/featureToggles";
import { TLP_CAMPAIGN_SERVICE } from "../../config/services";
import { getEndPoint, getApiHost } from "../host/HostService";
import { UpdateCampaignBody } from "./domains/CreateCampaignRequest";

export const CAMPAINGS_ENDPOINT = "/tlp-campaign";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function updateCampaign(
  country: string,
  campaignTag: string,
  vendorId: string,
  vendorName: string,
  request: UpdateCampaignBody,
  headers = {},
  idCampaign?: string,
): Promise<void> {
  const log = useLogService();

  const isMulticontractCampaignServiceEnable = isFeatureEnabled(
    TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
  );

  const isGrowOrganizationActivationEnable = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const formatedCampaignTag = campaignTag.replace("#", "");

  const getUrl = () => {
    const baseUrl = `${getApiHost()}${getEndPoint(
      TLP_CAMPAIGN_SERVICE,
    )}${CAMPAINGS_ENDPOINT}`;

    if (isGrowOrganizationActivationEnable)
      return `${getApiHost()}${getEndPoint(
        TLP_CAMPAIGN_SERVICE,
      )}/v3/campaigns/${idCampaign}`;

    if (isMulticontractCampaignServiceEnable)
      return `${baseUrl}/v2/${formatedCampaignTag}/admin/products?vendorId=${vendorId}`;

    return `${baseUrl}/${formatedCampaignTag}/admin/products`;
  };

  const getHeaders = () => {
    let headersData;
    if (isMulticontractCampaignServiceEnable) headersData = { country };
    else headersData = { country, vendorId, vendorName };
    return { ...headersData, ...headers };
  };

  const newRequest = {
    ...request,
    campaignId: campaignTag,
  };

  return axios
    .put(getUrl(), newRequest, {
      headers: getHeaders(),
    })
    .then(() => {
      log.info("Campaign updated Successfully");
    })
    .catch((error) => {
      log.error(error?.response);
      throw error?.response;
    });
}

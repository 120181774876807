import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    input: {
      padding: "1rem",
    },
    overrideInput: {
      fontFamily: "Work Sans",
      fontSize: "14px",
    },
    addButton: {
      fontFamily: "Work Sans",
      fontWeight: 500,
      fontSize: "14px",
      borderRadius: "16px",
      padding: "10px 16px",
    },
  }),
);

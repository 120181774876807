import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette }) =>
  createStyles({
    input: {
      reisze: "none",
      fontSize: "10px",
      height: "32px",
      borderRadius: "3px",
      border: `solid 1px ${palette.text.secondary}`,
      outline: "none",
      borderBottom: "none",
      justifyContent: "normal",
      paddingLeft: "8px",
      display: "block",
    },

    title: {
      fontSize: "12px",
      fontWeight: "bold",

      height: "14px",
      color: "#505050",
      display: "flex",
      flexDirection: "column",
      marginBottom: "8px",
      marginTop: "24px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      width: "82px",
      marginRight: "24px",
    },
    error: {
      borderColor: "red",
    },
  }),
);
